import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="19" height="16" viewBox="0 0 19 16" fill="none">
    <path
      d="M0 16L9.26316 0L18.5263 16H0ZM2.90526 14.3158H15.6211L9.26316 3.36842L2.90526 14.3158ZM9.26316 13.4737C9.50175 13.4737 9.7019 13.3928 9.86358 13.2312C10.0247 13.07 10.1053 12.8702 10.1053 12.6316C10.1053 12.393 10.0247 12.1931 9.86358 12.032C9.7019 11.8703 9.50175 11.7895 9.26316 11.7895C9.02456 11.7895 8.8247 11.8703 8.66358 12.032C8.5019 12.1931 8.42105 12.393 8.42105 12.6316C8.42105 12.8702 8.5019 13.07 8.66358 13.2312C8.8247 13.3928 9.02456 13.4737 9.26316 13.4737ZM8.42105 10.9474H10.1053V6.73684H8.42105V10.9474Z"
      fill="#DA0F0F"
    />
  </svg>
);

export default Icon;
