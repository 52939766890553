import { useCallback, useContext, useState } from 'react';
import { AttributeFilter } from '@Types/query/AttributeFilter';
import {
  UB_PRODUCT_GROUPS_IMAGE_BASE_URL,
  UB_PRODUCT_TYPE_SINGLE,
  UB_PRODUCT_TYPE_MULTI,
  UB_PRODUCT_TYPE_PERSONALIZE,
  UB_OPTIONAL_FIELD_TEXT,
  UB_REQUIRED_FIELD_TEXT,
  UB_PERSONALIZE_INPUT_MAX_LENGTH,
  UB_APPAREL_CONFIG_IMAGE_PROPS,
  UB_SELECT_TYPE_TEXT,
  UB_SELECT_UPTO_TEXT,
  UB_PRODUCT_TYPE_MULTI_INFO_TEXT,
  UB_PRODUCT_NOT_FOUND_TEXT,
} from 'helpers/constants/aafes';
import { useProduct } from 'frontastic';
import { UniformBuilderContext } from 'frontastic/provider/uniform-builder';
import UBConfigItemListDetails from './UBConfigItemListDetails';
import UBImageSection from './UBImageSection';
import UBTextInputConfigItem from './UBTextInputConfigItem';
import UBTypeaheadConfigItem from './UBTypeaheadConfigItem';
const UBApparelConfigWrapper = (props: any) => {
  const {
    displayName,
    product,
    pgKey,
    parent_item_id,
    subHeading,
    minQuantity,
    maxQuantity,
    setShowLoader,
    productSku,
  } = props;
  const [ubState, setUbState, NestedUpdateOrInsert] = useContext(UniformBuilderContext);
  const { fetchProductsByProductGroups } = useProduct();
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const [productNotFound, setProductNotFound] = useState<boolean>(false);
  let productType = ubState?.[`productGroups_${pgKey}`]?.productType;
  if (!productType && product?.[0]?.personalizationTextLines) {
    productType = UB_PRODUCT_TYPE_PERSONALIZE;
  }
  const addUpdateConfigProducts = useCallback((option: any) => {
    NestedUpdateOrInsert({
      currentState: ubState,
      idKey: 'id',
      idValue: parent_item_id,
      arrayName: 'productGroups',
      targetKey: 'key',
      targetValue: pgKey,
      nestedArrayName: 'product',
      option: option,
      setState: setUbState,
    });
  }, []);

  const removeUpdateConfigProducts = useCallback((option: any, isUpdate?: boolean) => {
    NestedUpdateOrInsert({
      currentState: ubState,
      idKey: 'id',
      idValue: parent_item_id,
      arrayName: 'productGroups',
      targetKey: 'key',
      targetValue: pgKey,
      nestedArrayName: 'product',
      removeKey: 'sku',
      ...(isUpdate ? { updateValue: option?.sku, option: option } : { removeValue: option?.sku }),
      setState: setUbState,
    });
  }, []);

  const getProductInfo = useCallback(async () => {
    setShowLoader(true);
    setProductNotFound(false);
    let data = await fetchProductsByProductGroups([
      {
        type: 'string',
        name: 'productGroups',
        value: pgKey,
      },
      {
        type: 'set',
        name: 'attachmentMethod',
        values: [ubState?.selectedPatchType?.value, 'Pin On', 'SewOn & HookLoop'],
      },
    ] as AttributeFilter[]);
    if (data?.count > 0) {
      const filteredItems = data?.items?.filter((item) => item?.variants?.[0]?.isOnStock);
      data = {
        ...data,
        items: filteredItems,
        count: filteredItems?.length || 0,
      };
    }
    let productType = UB_PRODUCT_TYPE_PERSONALIZE;
    const {
      count = 0,
      items: [{ variants: [{ attributes: { isPersonalizable = false } = {} } = {}] = [] } = {}] = [],
    } = data || {};
    if (!count) {
      setShowLoader(false);
      setProductNotFound(true);
      return '';
    }
    if ((count === 1 && isPersonalizable) || count > 1) {
      if (!isPersonalizable) {
        productType = UB_PRODUCT_TYPE_MULTI;
      }
    } else {
      productType = UB_PRODUCT_TYPE_SINGLE;
      const item = extractProductDetails(data?.items?.[0]);
      addUpdateConfigProducts(item);
    }
    const newData = {
      ...data,
      productType,
    };
    NestedUpdateOrInsert({
      currentState: ubState,
      newKey: `productGroups_${pgKey}`,
      newValue: newData,
      setState: setUbState,
    });
    if (productType === UB_PRODUCT_TYPE_PERSONALIZE) setUpdateMode(true);
    setShowLoader(false);
    return newData;
  }, []);

  const onTypeaheadSelect = useCallback((option: any) => {
    addUpdateConfigProducts(option);
  }, []);

  const onItemRemove = useCallback((option: any) => {
    removeUpdateConfigProducts(option);
  }, []);

  const extractProductDetails = useCallback((product: any) => {
    if (product) {
      const variant = product?.variants?.[0];
      const xlargeImage = variant?.imageSet?.find((innerItem: any) => innerItem?.type === 'xlarge');
      return {
        name: product?.name || 'Unavailable',
        sku: variant?.sku || 'Unavailable',
        image: xlargeImage?.src?.[0]?.url || 'Unavailable',
        imageAltTxt: xlargeImage?.src?.[0]?.label || 'Unavailable',
        price: variant?.price || 'Unavailable',
        attachmentMethod: variant?.attributes?.attachmentMethod,
        personalizationLinesMaxChars: variant?.attributes?.personalizationLinesMaxChars,
      };
    }
  }, []);

  const RenderUBAddConfigButton = () => {
    const pDetail = ubState?.[`productGroups_${pgKey}`];
    if (
      (product.length < maxQuantity && productType === UB_PRODUCT_TYPE_SINGLE) ||
      (product.length < maxQuantity && !pDetail)
    ) {
      return (
        <div className="my-2 flex items-center">
          <UBImageSection
            image={pgKey ? `${UB_PRODUCT_GROUPS_IMAGE_BASE_URL}${pgKey}-large` : 'Unavailable'}
            altText={`${pgKey}`}
            zoomProps={UB_APPAREL_CONFIG_IMAGE_PROPS}
          />
          <div className="ml-5">
            <button
              className="w-[106px] rounded bg-[rgba(4,60,99,1)] px-3 py-2 text-xs font-bold text-white disabled:cursor-not-allowed disabled:opacity-50"
              onClick={getProductInfo}
              disabled={!productSku}
            >
              {product?.length ? 'Add Another' : 'Add'}
            </button>
            {productNotFound && <p className="mt-2 text-sm text-red-200">{UB_PRODUCT_NOT_FOUND_TEXT}</p>}
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderTextInputItem = () => {
    const pDetail = ubState?.[`productGroups_${pgKey}`];
    const item = product.length > 0 ? product?.[0] : extractProductDetails(pDetail?.items?.[0]);
    return (
      <UBTextInputConfigItem
        image={pgKey ? `${UB_PRODUCT_GROUPS_IMAGE_BASE_URL}${pgKey}-large` : 'Unavailable'}
        imageAltTxt={pgKey ? `${pgKey}.jpg` : 'Unavailable'}
        onItemRemove={(value: string) => {
          removeUpdateConfigProducts({ ...item, personalizationTextLines: [value] });
        }}
        onAddOrUpdate={(value: string) => {
          if (!product?.length) {
            addUpdateConfigProducts({ ...item, personalizationTextLines: [value] });
          } else {
            removeUpdateConfigProducts({ ...item, personalizationTextLines: [value] }, true);
          }
        }}
        updateMode={updateMode}
        setUpdateMode={setUpdateMode}
        currentText={item?.personalizationTextLines?.[0]}
        maxLength={item?.personalizationLinesMaxChars ?? UB_PERSONALIZE_INPUT_MAX_LENGTH}
      />
    );
  };

  const RenderTypeaheadItem = () => {
    const items = ubState?.[`productGroups_${pgKey}`]?.items ?? [];
    const typeaheadOptions = items.map((item: any) => extractProductDetails(item));
    return (
      <UBTypeaheadConfigItem
        image={pgKey ? `${UB_PRODUCT_GROUPS_IMAGE_BASE_URL}${pgKey}-large` : 'Unavailable'}
        altText={pgKey ? `${pgKey}.jpg` : 'Unavailable'}
        typeaheadHeading={`Select ${displayName}`}
        typeaheadOptions={typeaheadOptions}
        onTypeaheadSelect={onTypeaheadSelect}
        selectedProducts={product}
        onItemRemove={onItemRemove}
        maxQuantity={maxQuantity}
      />
    );
  };

  const RenderUBConfigItemType = (productType: string) => {
    if (productType === UB_PRODUCT_TYPE_PERSONALIZE) {
      return RenderTextInputItem();
    }
    if (productType === UB_PRODUCT_TYPE_MULTI) {
      return RenderTypeaheadItem();
    }
  };

  const RenderUBConfigItemList = () => {
    return (
      <UBConfigItemListDetails
        updateMode={updateMode}
        product={product}
        productType={productType}
        onItemRemove={onItemRemove}
        setUpdateMode={setUpdateMode}
        displayName={displayName}
      />
    );
  };
  return (
    <div className="border-b border-[#00000033] py-6 last:border-0">
      <div className="mb-4">
        <h2 className="text-[#333333]">
          <span className="text-sm font-semibold not-italic leading-normal">{`Add ${displayName}`}</span>
          {subHeading && (
            <span className="ml-1 text-sm font-normal not-italic leading-normal">{`(${subHeading})`}</span>
          )}
          {minQuantity > 0 && (
            <span className="ml-1 text-sm font-normal not-italic leading-normal text-red-700">
              {UB_REQUIRED_FIELD_TEXT}
              {productType === UB_PRODUCT_TYPE_MULTI && (
                <span className="ml-1 text-[#333333]">{`(${UB_SELECT_UPTO_TEXT} ${maxQuantity} ${UB_SELECT_TYPE_TEXT})`}</span>
              )}
            </span>
          )}
          <span className="ml-1 text-sm font-normal not-italic leading-normal">{minQuantity === 0}</span>
          {minQuantity === 0 && (
            <span className="ml-1 text-sm font-normal not-italic leading-normal">
              {UB_OPTIONAL_FIELD_TEXT}
              {productType === UB_PRODUCT_TYPE_MULTI && (
                <span className="ml-1 text-[#333333]">{`(${UB_SELECT_UPTO_TEXT} ${maxQuantity} ${UB_SELECT_TYPE_TEXT})`}</span>
              )}
            </span>
          )}
        </h2>
        {productType === UB_PRODUCT_TYPE_MULTI && maxQuantity > 1 && (
          <p className="mt-1.5 text-sm font-normal not-italic leading-4 text-[#333333]">
            {UB_PRODUCT_TYPE_MULTI_INFO_TEXT}
          </p>
        )}
      </div>
      {RenderUBConfigItemList()}
      {RenderUBConfigItemType(productType)}
      {RenderUBAddConfigButton()}
    </div>
  );
};

export default UBApparelConfigWrapper;
