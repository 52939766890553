type ScrollOptions = {
  yPosition?: number;
  element?: string | HTMLElement;
  offset?: number;
  duration?: number;
};

const SmoothScroller = (() => {
  const scrollToY = (options: ScrollOptions) => {
    const { yPosition = 0, duration = 1000 } = options;

    if ('scrollTo' in window) {
      (window as Window).scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });
    } else {
      (window as Window).scrollTo(0, yPosition);
    }
  };

  const scrollToElement = (options: ScrollOptions) => {
    const { element, offset = 0, duration = 1000 } = options;

    if (!element) return;

    const targetElement = typeof element === 'string' ? document.querySelector(element) : element;

    if (targetElement) {
      const yPosition = targetElement.getBoundingClientRect().top + window.scrollY + offset;
      scrollToY({ yPosition, duration });
    }
  };

  const scrollToTop = (duration: number = 1000) => {
    scrollToY({ yPosition: 0, duration });
  };

  const scrollToBottom = (duration: number = 1000) => {
    const maxY = document.body.scrollHeight - window.innerHeight;
    scrollToY({ yPosition: maxY, duration });
  };

  return {
    scrollToY,
    scrollToElement,
    scrollToTop,
    scrollToBottom,
  };
})();

export default SmoothScroller;
