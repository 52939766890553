import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path
      d="M23.2551 8.46012C23.2551 8.58831 23.2123 8.67377 23.0842 8.75923C22.4432 9.22923 21.7596 9.74197 21.1187 10.212C20.3923 10.7247 19.6659 11.2802 18.9395 11.7929C18.555 12.0493 18.1704 12.3484 17.8286 12.6475C17.5723 12.8184 17.3586 12.7756 17.1877 12.5193C16.6322 11.5365 15.8631 10.7247 14.7949 10.2547C14.154 9.95561 13.4704 9.78469 12.7867 9.74196C12.0603 9.69923 11.4622 9.44287 10.9494 8.97287C10.4794 8.50286 10.0094 7.99012 9.36849 7.69103C9.06939 7.52012 9.06939 7.3492 9.19758 7.00738C9.41122 6.40919 9.58213 5.811 9.79577 5.21281C10.0094 4.57189 10.223 3.97369 10.4367 3.33277C10.6503 2.73458 10.8212 2.1364 10.9922 1.53821C11.1203 1.11093 11.2912 0.683647 11.4622 0.21364C11.4622 0.0427279 11.5903 0 11.7613 0C11.8894 0 12.0176 0.0854559 12.0604 0.21364C12.2313 0.769103 12.4022 1.28184 12.5731 1.79458C12.7867 2.47823 13.0004 3.11914 13.214 3.80278C13.3849 4.40097 13.6413 4.95644 13.7695 5.55463C13.8122 5.811 13.9404 6.02464 14.0258 6.28101C14.1967 6.79375 14.3677 7.26375 14.4958 7.77649C14.5386 7.99013 14.6668 8.16104 14.9658 8.16104C15.6068 8.16104 16.2477 8.16104 16.8886 8.16104C18.8968 8.16104 20.905 8.16104 22.9132 8.16104C23.0842 8.11831 23.2551 8.20376 23.2551 8.46012Z"
      fill="#0E2D51"
    />
    <path
      d="M3.85663 8.20377C5.01028 8.20377 6.16394 8.16104 7.3176 8.20377C8.30034 8.2465 9.11216 8.67377 9.75308 9.40015C10.5649 10.3402 11.5904 10.8102 12.8295 10.8956C14.2822 10.9811 15.3077 11.7502 16.0341 12.9466C16.2477 13.2884 16.3759 13.7157 16.5041 14.1002C16.5468 14.2711 16.5896 14.442 16.4186 14.5275C16.2905 14.6557 16.1195 14.5702 15.9914 14.442C15.1368 13.7584 14.1541 13.6302 13.1286 13.5448C12.6159 13.502 12.1886 13.2884 11.7186 13.1175C11.1204 12.9038 10.7358 12.3911 10.2658 11.9638C9.75309 11.4938 9.15489 11.152 8.47124 11.0238C7.36032 10.8102 6.20666 10.9384 5.09574 10.9384C4.45482 10.9384 3.8139 10.8529 3.17298 10.682C2.87388 10.5965 2.66025 10.4256 2.40388 10.2547C1.72024 9.7847 1.12204 9.18651 0.395664 8.7165C0.310208 8.67377 0.224752 8.63105 0.182024 8.58832C0.0538403 8.50286 -0.0316129 8.4174 0.011115 8.28922C0.053843 8.16104 0.182032 8.16104 0.310216 8.16104C1.37841 8.20377 2.48933 8.20377 3.55753 8.20377C3.64298 8.20377 3.77117 8.20377 3.85663 8.20377Z"
      fill="#780008"
    />
    <path
      d="M7.06129 13.6302C7.61675 13.6302 8.17221 13.6302 8.72767 13.6302C9.58223 13.6302 10.4368 13.7157 11.1632 14.1857C11.505 14.3993 11.8041 14.5702 12.0605 14.8693C12.7014 15.5957 13.4278 16.1512 14.4105 16.2794C15.0514 16.3648 15.6496 16.493 16.2478 16.7921C17.1878 17.3048 17.8287 18.0739 18.1278 19.0994C18.3842 19.8685 18.6406 20.6803 18.8969 21.4494C18.9397 21.5776 19.0251 21.7485 18.8542 21.834C18.6833 21.9622 18.5551 21.9194 18.3842 21.7913C17.786 21.364 17.2305 20.9367 16.6323 20.5094C15.6069 19.783 14.5387 19.0567 13.5132 18.2876C13.1287 18.0312 12.8723 17.6894 12.5732 17.3903C11.8468 16.7066 10.9495 16.3221 9.92406 16.2794C9.58224 16.2794 9.24041 16.2794 8.89858 16.2794C7.87311 16.2794 7.06129 15.8521 6.24946 15.2539C5.73672 14.8693 5.26671 14.4848 4.71125 14.143C4.58307 14.0575 4.41216 13.9293 4.49761 13.7584C4.54034 13.5875 4.71125 13.5875 4.88216 13.5875C5.60853 13.6302 6.33491 13.6302 7.06129 13.6302Z"
      fill="#780008"
    />
    <path
      d="M10.2658 17.903C10.2658 17.9885 10.223 18.0739 10.1376 18.1166C9.66759 18.5012 9.19757 18.8857 8.72756 19.2276C7.95846 19.8258 7.18936 20.3812 6.37753 20.9367C5.95025 21.2358 5.5657 21.5776 5.13842 21.8767C4.88205 22.0476 4.58296 22.0476 4.4975 21.834C4.41205 21.6631 4.4975 21.4921 4.54023 21.3212C4.66842 20.9367 4.83933 20.5094 4.92478 20.0821C4.96751 19.8685 5.13843 19.6976 5.22388 19.4839C5.48025 18.7576 5.69388 17.9885 5.95025 17.2621C5.99298 17.1339 6.03571 17.0484 6.07844 16.963C6.1639 16.7493 6.33481 16.7066 6.50572 16.8348C7.36028 17.3048 8.30028 17.5612 9.32575 17.5184C9.53939 17.5184 9.75303 17.4757 9.96667 17.5612C10.0949 17.6039 10.223 17.6894 10.2658 17.903Z"
      fill="#0E2D51"
    />
  </svg>
);

export default Icon;
