import React from 'react';

type Props = {
  props?: any;
  className?: string;
  fill?: string;
  onClick?: () => void;
};

const Icon: React.FC<Props> = ({ props, className, fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: 'new -932 485 25.4 76.5',
    }}
    viewBox="-932 485 25.4 76.5"
    height={30}
    {...props}
  >
    <path
      d="M-932 523.1V523.7c0 .3.1.5.3.8l20.7 35.8c.6 1.1 2.1 1.5 3.2.8 1.1-.6 1.5-2.1.8-3.2l-20-34.7 20.1-34.7c.6-1.1.3-2.5-.8-3.2-1.1-.6-2.5-.3-3.2.8l-20.8 36c-.1.2-.2.5-.3.8v.2z"
      style={{
        fill: fill,
      }}
    />
  </svg>
);

export default Icon;
