import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      d="M3.01974 15L4.30263 9.45395L0 5.72368L5.68421 5.23026L7.89474 0L10.1053 5.23026L15.7895 5.72368L11.4868 9.45395L12.7697 15L7.89474 12.0592L3.01974 15Z"
      fill="#043C63"
    />
  </svg>
);

export default Icon;
