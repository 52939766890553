import React, { useEffect, useState } from 'react';
import AssetsCMS from 'components/commercetools-ui/aafes/assets-cms';
import { useAccount } from 'frontastic/provider';
import { useRouter } from 'next/router';
const LoginModal = dynamic(() => import('components/commercetools-ui/aafes/Modals/Login/login'));
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Loader from 'components/commercetools-ui/loader/Loader';

const AssetsCmsTastics = (cmsContent) => {
  const { loggedIn, isAddressesLoading } = useAccount();
  const [loginModal, setLoginModal] = useState(false);
  const [isRestrictPage, setIsRestrictPage] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  const checkForMarketPlace = () => {
    const segments: any = window.location.href;
    const segmentsRegex = /(marketplace)(\/.*)?$/;
    const isSegmentsMatch = segments?.match(segmentsRegex);
    if (isSegmentsMatch && !loggedIn) {
      setLoginModal(true);
      setIsRestrictPage(true);
      setLoading(false);
    } else {
      setLoginModal(false);
      setIsRestrictPage(false);
      setLoading(false);
    }
  };

  const RestrictCMSContent = () => {
    return (
      <>
        <section className="mt-10 mb-5">
          <section>{cmsContent?.data?.descriptionField}</section>
          <Link href={cmsContent?.data?.redirectTo}>
            <button className="mx-auto mt-2 block rounded-sm bg-blue-900 p-2 text-affes-xl font-bold text-white">
              {cmsContent?.data?.label}
            </button>
          </Link>
        </section>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    checkForMarketPlace();
  }, [loggedIn]);

  if (loading || isAddressesLoading) {
    return <Loader />;
  }

  return (
    <>
      {isRestrictPage ? !loginModal ? <RestrictCMSContent /> : null : <AssetsCMS cmsContent={cmsContent?.data} />}
      <LoginModal
        loginModal={loginModal}
        closeModal={() => {
          setLoginModal(false);
        }}
        closeAccountFlyout={() => {
          setLoginModal(false);
        }}
        loginCallBack={() => router.push(router?.asPath)}
      />
    </>
  );
};

export default AssetsCmsTastics;
