import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="20" viewBox="0 0 120 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.555 7.21954V7.99174H117.106V6.07292C117.283 6.03782 117.531 6.01442 117.85 6.01442C118.217 6.01442 118.382 6.07292 118.524 6.15482C118.63 6.23672 118.713 6.38883 118.713 6.57603C118.713 6.78663 118.548 6.95043 118.311 7.02063V7.04403C118.5 7.11423 118.607 7.25464 118.666 7.51204C118.725 7.80454 118.76 7.92154 118.808 7.99174H118.323C118.264 7.92154 118.229 7.74604 118.169 7.52374C118.134 7.31314 118.016 7.21954 117.768 7.21954H117.555ZM117.567 6.35373V6.90363H117.779C118.028 6.90363 118.229 6.82173 118.229 6.62283C118.229 6.44733 118.099 6.33033 117.815 6.33033C117.697 6.33033 117.614 6.34203 117.567 6.35373ZM117.862 5.06671C118.985 5.06671 119.848 5.92082 119.848 6.98553C119.848 8.07364 118.985 8.92775 117.862 8.92775C116.751 8.92775 115.865 8.07364 115.865 6.98553C115.865 5.92082 116.751 5.06671 117.862 5.06671ZM117.862 5.45282C116.999 5.45282 116.361 6.14312 116.361 6.98553C116.361 7.83964 116.999 8.51825 117.874 8.51825C118.725 8.51825 119.351 7.83964 119.351 6.99723C119.351 6.14312 118.725 5.45282 117.862 5.45282Z"
      fill="#80C600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.147 10.8771C113.147 10.1661 112.841 8.97507 111.503 8.97507C110.279 8.97507 109.781 10.0894 109.705 10.8771H113.147ZM109.724 12.8754C109.82 14.0854 111.005 14.662 112.363 14.662C113.357 14.662 114.161 14.5275 114.945 14.2776L115.327 16.2566C114.371 16.6407 113.205 16.8329 111.942 16.8329C108.768 16.8329 106.951 14.9886 106.951 12.0492C106.951 9.66656 108.424 7.03455 111.675 7.03455C114.696 7.03455 115.843 9.39767 115.843 11.7223C115.843 12.2221 115.786 12.6637 115.748 12.8754H109.724Z"
      fill="#80C600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.427 4.55615V7.2459H106.511V9.39785H104.427V12.7983C104.427 13.9319 104.695 14.4507 105.574 14.4507C105.938 14.4507 106.225 14.4122 106.435 14.3737L106.454 16.5832C106.071 16.7369 105.383 16.8331 104.561 16.8331C103.624 16.8331 102.84 16.5062 102.381 16.0261C101.846 15.4688 101.578 14.5659 101.578 13.2402V9.39785H100.335V7.2459H101.578V5.20941L104.427 4.55615Z"
      fill="#80C600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.204 16.6408H99.1108V7.24585H96.204V16.6408ZM99.2063 4.63308C99.2063 5.44003 98.5946 6.09303 97.6385 6.09303C96.7204 6.09303 96.1085 5.44003 96.1277 4.63308C96.1085 3.78764 96.7204 3.15363 97.6576 3.15363C98.5946 3.15363 99.1874 3.78764 99.2063 4.63308Z"
      fill="#80C600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.5471 13.6245C87.3308 14.0279 88.5357 14.4315 89.7784 14.4315C91.1174 14.4315 91.8248 13.8744 91.8248 13.029C91.8248 12.222 91.2131 11.7607 89.6637 11.2037C87.5221 10.4544 86.1262 9.26313 86.1262 7.38059C86.1262 5.17116 87.9619 3.48029 91.0024 3.48029C92.4561 3.48029 93.5266 3.78767 94.2917 4.13354L93.6413 6.49666C93.1252 6.24701 92.2073 5.88189 90.9452 5.88189C89.6829 5.88189 89.071 6.45817 89.071 7.13067C89.071 7.95687 89.7978 8.32173 91.4613 8.956C93.737 9.80118 94.8079 10.9925 94.8079 12.8176C94.8079 14.9885 93.1443 16.8331 89.6065 16.8331C88.1342 16.8331 86.6806 16.4487 85.9543 16.0454L86.5471 13.6245Z"
      fill="#80C600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.2302 11.2229C81.2302 11.0692 81.2111 10.8578 81.1919 10.7044C81.0198 9.91642 80.3887 9.26316 79.4901 9.26316C78.1708 9.26316 77.482 10.4545 77.482 11.9339C77.482 13.5286 78.2663 14.5275 79.4709 14.5275C80.3124 14.5275 80.9815 13.9512 81.1536 13.125C81.2111 12.9136 81.2302 12.7024 81.2302 12.4525V11.2229ZM84.1367 3V13.836C84.1367 14.8926 84.175 16.0069 84.2133 16.6409H81.6317L81.4979 15.2574H81.4595C80.8668 16.3143 79.7385 16.8523 78.5336 16.8523C76.3157 16.8523 74.5372 14.9503 74.5372 12.0299C74.5183 8.85982 76.4878 7.03449 78.7252 7.03449C79.8726 7.03449 80.7713 7.4381 81.1919 8.09135H81.2302V3H84.1367Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8196 12.8277C67.9152 14.0377 69.1006 14.6143 70.4585 14.6143C71.4529 14.6143 72.2561 14.4798 73.0401 14.2299L73.4224 16.2089C72.4663 16.593 71.3 16.7852 70.0376 16.7852C66.8635 16.7852 65.0467 14.9409 65.0467 12.0015C65.0467 9.61889 66.5192 6.98688 69.77 6.98688C72.7914 6.98688 73.9388 9.35 73.9388 11.6746C73.9388 12.1744 73.8814 12.616 73.8433 12.8277H67.8196ZM71.2425 10.8294C71.2425 10.1185 70.9366 8.92741 69.5981 8.92741C68.3743 8.92741 67.8768 10.0417 67.8002 10.8294H71.2425Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.451 4.55615V7.2459H64.5354V9.39785H62.451V12.7983C62.451 13.9319 62.7189 14.4507 63.5984 14.4507C63.9618 14.4507 64.2486 14.4122 64.4588 14.3737L64.4779 16.5832C64.0954 16.7369 63.4071 16.8331 62.5848 16.8331C61.6478 16.8331 60.8638 16.5062 60.4049 16.0261C59.8696 15.4688 59.6019 14.5659 59.6019 13.2402V9.39785H58.359V7.2459H59.6019V5.20941L62.451 4.55615Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.1491 14.0855C51.6847 14.4122 52.7938 14.7965 53.6542 14.7965C54.5339 14.7965 54.8974 14.4889 54.8974 14.0088C54.8974 13.5285 54.6106 13.2978 53.5206 12.933C51.5891 12.2797 50.8432 11.2229 50.8623 10.1085C50.8623 8.36043 52.354 7.03467 54.6678 7.03467C55.7577 7.03467 56.733 7.28432 57.3066 7.57272L56.7902 9.58997C56.3696 9.3593 55.5664 9.05192 54.7633 9.05192C54.0559 9.05192 53.6542 9.34006 53.6542 9.82038C53.6542 10.2625 54.0176 10.4929 55.165 10.8962C56.9435 11.5113 57.6892 12.4142 57.7083 13.7974C57.7083 15.5457 56.3313 16.833 53.6542 16.833C52.4304 16.833 51.3404 16.5639 50.633 16.1798L51.1491 14.0855Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.5425 13.6245C49.5425 14.854 49.5806 15.8534 49.6191 16.6408H47.095L46.9609 15.3153H46.9037C46.5403 15.8916 45.6605 16.8523 43.9778 16.8523C42.0846 16.8523 40.6887 15.661 40.6887 12.76V7.24585H43.6146V12.299C43.6146 13.6627 44.0544 14.4889 45.068 14.4889C45.8709 14.4889 46.3298 13.9319 46.5211 13.4708C46.5977 13.2978 46.6166 13.0672 46.6166 12.8368V7.24585H49.5425V13.6245Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7087 10.3391C33.7087 8.95583 33.6706 8.05292 33.6323 7.24597H36.1373L36.2328 8.97507H36.3095C36.7875 7.61109 37.9347 7.03455 38.8334 7.03455C39.1013 7.03455 39.2351 7.03455 39.4455 7.07303V9.82051C39.2351 9.78202 38.9866 9.74354 38.6615 9.74354C37.5907 9.74354 36.8639 10.3201 36.6726 11.223C36.6346 11.4149 36.6151 11.6456 36.6151 11.8762V16.641H33.7087V10.3391Z"
      fill="#363832"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3789 6.15079H23.9174V3.69171H33.8232V6.15079H30.3045V16.6409H27.3789V6.15079Z"
      fill="#363832"
    />
    <circle cx="10" cy="10" r="10" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7242 20H0V5.27596C0 2.36208 2.36212 0 5.27577 0H20V14.724C20 17.6379 17.6379 20 14.7242 20ZM15.9193 4.18584C15.7571 4.02139 15.4913 4.02139 15.3288 4.18584L7.94832 11.6575C7.78587 11.822 7.52027 11.822 7.35808 11.6575L4.6709 8.93741C4.50871 8.77295 4.24285 8.77295 4.0804 8.93741L2.30915 10.7305C2.14695 10.895 2.14695 11.1639 2.30915 11.3283L7.35808 16.4394C7.52027 16.6035 7.78587 16.6035 7.94832 16.4394L17.6909 6.57677C17.853 6.41231 17.853 6.14343 17.6909 5.97897L15.9193 4.18584Z"
      fill="#80C600"
    />
  </svg>
);

export default Icon;
