import React from 'react';
import Header from './header';

function Index({
  militaryStarApply,
  cartList,
  militaryStar,
  data,
  leftColumn,
  rightColumn,
  findStore,
  exchangeLogo,
  militaryStarParentURL,
  weeklyAd,
}) {
  return (
    <>
      <Header
        menuData={data}
        leftColumn={leftColumn}
        rightColumn={rightColumn}
        findStore={findStore}
        exchangeLogo={exchangeLogo}
        militaryStar={militaryStar}
        militaryStarApply={militaryStarApply}
        cartList={cartList}
        militaryStarParentURL={militaryStarParentURL}
        weeklyAd={weeklyAd}
      />
    </>
  );
}

export default Index;
