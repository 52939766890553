import React from 'react';
import TypeAhead from 'components/commercetools-ui/aafes/typeahead';
import Price from 'components/commercetools-ui/price';
import { UB_APPAREL_CONFIG_IMAGE_PROPS } from 'helpers/constants/aafes';
import UBImageSection from './UBImageSection';
interface UBTypeaheadConfigItemProps {
  image?: string;
  altText?: string;
  typeaheadOptions?: any[];
  typeaheadHeading?: string;
  onTypeaheadSelect?: (value: any) => void;
  onItemRemove?: (value: any) => void;
  selectedProducts?: any[];
  minQuantity?: number;
  maxQuantity?: number;
}
interface ImageWithTypeaheadProps {
  image: string;
  altText: string;
  typeaheadHeading?: string;
  typeaheadOptions?: any[];
  onTypeaheadSelect?: (value: any) => void;
  selectedProducts?: any[];
}

const ImageWithTypeahead: React.FC<ImageWithTypeaheadProps> = ({
  image,
  altText,
  typeaheadHeading,
  typeaheadOptions,
  onTypeaheadSelect,
  selectedProducts,
}) => {
  return (
    <div className="mt-4 flex flex-wrap items-start" key={`ImageWithTypeahead_key_${selectedProducts?.length}`}>
      <UBImageSection image={image} altText={altText} zoomProps={UB_APPAREL_CONFIG_IMAGE_PROPS} />
      <div className="mt-3.5 block w-full sm:ml-5 sm:mt-[-8px] sm:flex sm:w-auto">
        <TypeAhead
          label={typeaheadHeading}
          options={typeaheadOptions}
          onSearch={({ text, options, setFilteredOptions }) => {
            const newOptions = text
              ? options.filter((option) => option.name.toLowerCase().includes(text.toLowerCase()))
              : options;
            setFilteredOptions(newOptions);
          }}
          onSelect={(option: any) => {
            onTypeaheadSelect(option);
          }}
          renderOption={(option: any, index) => (
            <>
              <div className={`flex cursor-pointer text-[#333] ${index !== 0 ? 'mt-2.5' : ''}`}>
                {option?.image && <UBImageSection image={option?.image} altText={option?.image} />}
                <div className="ml-3">
                  <div className="text-sm font-normal leading-normal">{option.name}</div>
                  <div>
                    <Price price={option.price} className="text-sm font-bold leading-normal" />
                  </div>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export const UBTypeaheadConfigItem: React.FC<UBTypeaheadConfigItemProps> = ({
  image,
  altText,
  typeaheadHeading,
  typeaheadOptions,
  onTypeaheadSelect,
  selectedProducts,
  maxQuantity,
}) => {
  return (
    <div>
      {selectedProducts?.length < maxQuantity && typeaheadOptions?.length > 0 && (
        <ImageWithTypeahead
          selectedProducts={selectedProducts}
          image={image}
          altText={altText}
          typeaheadHeading={typeaheadHeading}
          typeaheadOptions={typeaheadOptions}
          onTypeaheadSelect={onTypeaheadSelect}
        />
      )}
    </div>
  );
};

export default UBTypeaheadConfigItem;
