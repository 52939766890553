import React, { useCallback, useContext, useState } from 'react';
import { Variant } from '@Types/product/Variant';
import { UB_APPAREL_MAXBUTTON } from 'helpers/constants/aafes';
import { UniformBuilderContext } from 'frontastic/provider/uniform-builder';
import UBApparelConfigWrapper from './UBApparelConfigWrapper';
import UBApparelSelector from './UBApparelSelector';
const UBPeronaliserWrapper = (props: any) => {
  const [ubState, setUbState, NestedUpdateOrInsert] = useContext(UniformBuilderContext);

  const personalizerItem = props?.item || {};

  const { name, variants, id, productGroups, productSku, isMandatory } = personalizerItem;

  const setShowLoader = props?.setShowLoader;

  const buttonOptions = variants
    .map((variant: Variant) => {
      let label = undefined;
      let skuError = false;
      try {
        const parsedVariantPickers = JSON.parse(variant?.attributes?.variantPickers || '[]');
        label = parsedVariantPickers?.find((variantPicker) => variantPicker?.label === 'Size')?.desc;
      } catch (error) {
        console.error('Error parsing variantPickers:', error);
        skuError = true;
      }

      if (!skuError && label) {
        return {
          value: variant.sku,
          label: label,
          isOnStock: variant?.isOnStock,
          image:
            variant?.imageSet?.find((innerItem: any) => {
              return innerItem?.type == 'xlarge';
            })?.src?.[0]?.url || 'Unavailable',
        };
      }
    })
    .filter(Boolean);

  const [selectedSku, setSelectedSku] = useState(buttonOptions.find((item: any) => item.value === productSku));

  const handleButtonClick = useCallback(
    (value: any) => {
      const selectedOption = buttonOptions.find((option: any) => option.value === value?.value);
      if (selectedOption) {
        setSelectedSku(selectedOption);
        NestedUpdateOrInsert({
          currentState: ubState,
          idKey: 'id',
          idValue: id,
          targetKey: 'productSku',
          targetValue: selectedOption?.value,
          setState: setUbState,
        });
      }
    },
    [buttonOptions],
  );
  return (
    <div className="my-4 rounded-md border border-[#00000033] bg-white px-6 py-5">
      {buttonOptions?.length > 0 ? (
        <>
          <UBApparelSelector
            defaultImageApparel={buttonOptions?.[0]?.image}
            title={name}
            sizeText="Size:"
            selectedSku={selectedSku}
            buttonOptions={buttonOptions.map((option: any) => ({
              value: option?.value,
              label: option?.label,
              isOnStock: option?.isOnStock,
            }))}
            maxButtonsPerRow={UB_APPAREL_MAXBUTTON}
            buttonWidth={90}
            gap={10}
            onSelect={(value: any) => {
              handleButtonClick(value);
            }}
            isMandatory={isMandatory}
          />
          {productGroups?.length > 0 &&
            productGroups.map((item: any) => {
              return (
                <UBApparelConfigWrapper
                  key={`key_${item?.key}`}
                  {...{ ...item, parent_item_id: id, pgKey: item?.key, setShowLoader, productSku }}
                />
              );
            })}
        </>
      ) : (
        <p>Product Not Found.</p>
      )}
    </div>
  );
};

export default UBPeronaliserWrapper;
