import React from 'react';

type AddToCartCTAProps = {
  addToCartLabel: string;
  isAddToCartDisabled: boolean;
  loading: boolean;
  added: boolean;
  handleAddToCart: () => Promise<any>;
};

const AddToCart = ({ addToCartLabel, isAddToCartDisabled, added, loading, handleAddToCart }: AddToCartCTAProps) => {
  return (
    <section className="flex justify-center">
      <button
        type="button"
        onClick={() => {
          !loading && !added && handleAddToCart();
        }}
        className="flex h-[44px] w-full flex-1 items-center justify-center rounded-md border border-transparent bg-[#043C63] fill-white py-3 px-8 text-base  font-medium text-white transition duration-150 ease-out hover:bg-accent-500 focus:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 md:w-[277px] xl:max-w-[277px]"
        disabled={isAddToCartDisabled}
      >
        {!loading && !added && <>{addToCartLabel}</>}
        {loading && (
          <svg className="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
            <path d="M8,8.5A3.5,3.5,0,1,1,4.5,5,3.5,3.5,0,0,1,8,8.5ZM4.5,14A3.5,3.5,0,1,0,8,17.5,3.5,3.5,0,0,0,4.5,14Zm16-2A3.5,3.5,0,1,0,17,8.5,3.5,3.5,0,0,0,20.5,12Zm0,2A3.5,3.5,0,1,0,24,17.5,3.5,3.5,0,0,0,20.5,14Zm-8,4A3.5,3.5,0,1,0,16,21.5,3.5,3.5,0,0,0,12.5,18Zm0-18A3.5,3.5,0,1,0,16,3.5,3.5,3.5,0,0,0,12.5,0Z" />
          </svg>
        )}
        {!loading && added && (
          <svg className="h-6 w-6" viewBox="0 0 80.588 61.158">
            <path
              d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611
                     c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374
                     c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z
                     "
            />
          </svg>
        )}
      </button>
    </section>
  );
};

export default AddToCart;
