import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import useSWR from 'swr';
import AddNewCard from 'components/commercetools-ui/aafes/Modals/addNewCard';
import CreditCardsModal from 'components/commercetools-ui/aafes/Modals/CreditCardsModal';
import EditBillingAddressModal from 'components/commercetools-ui/aafes/Modals/editBillingAddressModal';
import ShippingModal from 'components/commercetools-ui/aafes/Modals/shippingModal';
import SubscriptionServicesModal from 'components/commercetools-ui/aafes/Modals/SubscriptionServicesModal';
import Price from 'components/commercetools-ui/price';
import { GA_ACCOUNT_SUBSCRIPTIONS } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { fetchApiHub, revalidateOptions, useAccount, useCart, useCustomMessages } from 'frontastic';
import { removePayment } from 'frontastic/actions/cart';
import EditPencil from '../../../../icons/editPencil';
import blueBuffalo from '../../../../images/blue-buffalo.png';
import msc from '../../../../images/military-star-cardholders.png';

const Subscriptions = ({ cartList, onSubmit, giftCard }) => {
  const { trackMyAccount } = useGATrackUserAction();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const {
    getSubscriptionTemplateOrders,
    cancelSubscriptionOrder,
    getSubscriptionOrders,
    skipNextSubscriptionOrder,
    getCreditCards,
    updateSubscriptionPayment,
  } = useAccount();

  const [getAllCustomMessage, setGetCustomMessages] = useState(null);
  const { account } = useAccount();

  useEffect(() => {
    trackMyAccount(GA_ACCOUNT_SUBSCRIPTIONS, 'Account Subscriptions Page', account);
  }, []);

  const queryParams = new URLSearchParams({
    messageKey: 'deliverEvery',
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  const getCustomMessageData = async () => {
    setGetCustomMessages(data);
  };
  const { getShippingForLineItem, addCreditCardForPayment, studioConfig, setCartProductDetailEventSent } = useCart();
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const [toggle1, setToggle1] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addrError, setAddrError] = useState(false);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [showCancel, setShowCancel] = useState([]);
  const [subscriptionOrderData, setSubscriptionOrderData] = useState([]);
  const [getCardType, setGetCardType] = useState(null);
  const [showCardSelector, setShowCardSelector] = useState(false);
  const [isShipOpen, setIsShipOpen] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const [cardError, setCardError] = useState(false);
  const [showServicesModal, setServicesModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [giftCardsApplied, setGiftCardsApplied] = useState<any>({});
  const [bankCards, setBankCards] = useState([]);
  const [productFrequences, setProductFrequences] = useState([]);
  const [cancelMessage, setCancelMessage] = useState([]);
  const [addPaymentError, setAddPaymentError] = useState('');
  const selectSubscriptionMessage = getAllCustomMessage?.deliverEvery;
  const [isEditBillingModalOpen, setIsEditBillingModalOpen] = useState(false);
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false);
  const [subscriptionSkipped, setSubscriptionSkipped] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const [currentLineItem, setCurrentLineItem] = useState({});
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [reFetch, setReFetch] = useState(false);
  const [selectedCart, setSelectedCart] = useState({});
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [cardType, setCardType] = useState('');
  const [orderLineId, setOrderLineId] = useState('');
  const [cardDropDown, setCardDropDown] = useState(false);
  const nonCancelledDataLength = subscriptionOrderData.filter((item) => item?.orderState !== 'Cancelled').length;
  const [subscriptionDefaultFrequency, setSubscriptionDefaultFrequency] = useState(null);
  const handleGetSubscriptionTemplate = async () => {
    const data = await getSubscriptionTemplateOrders();
    setReFetch(false);
    if (data?.errorCode !== 500 && data.length > 0) {
      // Filter out canceled items
      const nonCancelledData = data.filter((item) => item?.orderState !== 'Cancelled');
      // Sort the non-canceled data array based on custom.nextDeliveryDate in ascending order
      nonCancelledData.sort((a, b) => {
        const dateA: Date = new Date(a.custom?.fields?.nextDeliveryDate);
        const dateB: Date = new Date(b.custom?.fields?.nextDeliveryDate);
        return dateA.getTime() - dateB.getTime();
      });
      // Extract canceled items and set the cancel message
      const canceledItems = data.filter((item) => item?.orderState === 'Cancelled');
      const cancelMessages = canceledItems.map((item) => ({ orderId: item?.cartId, cancelMessage: item?.orderState }));
      // Concatenate non-canceled and canceled items to display canceled items at the bottom
      const sortedData = [...nonCancelledData, ...canceledItems];
      setSubscriptionOrderData(sortedData);
      setCancelMessage((prevState) => [...prevState, ...cancelMessages]);
    } else {
      setSubscriptionError(true);
    }
  };

  useEffect(() => {
    handleGetSubscriptionTemplate();
  }, [isShipOpen, isEditBillingModalOpen, showServicesModal, isOpen, subscriptionSkipped, reFetch]);

  useEffect(() => {
    const handleSplitCreditCards = (creditCards) => {
      const newMilstarCards = creditCards?.response?.milstaraccount ?? [];
      const newBankCards = creditCards?.response?.bankcardaccount ?? [];
      const mergedCards = [...newMilstarCards, ...newBankCards];
      return { newMilstarCards, newBankCards, mergedCards };
    };
    const setCardsAndPayments = async () => {
      setShowLoader(true);
      const { newMilstarCards, newBankCards, mergedCards } = handleSplitCreditCards(getCreditCards);
      if (allCards.length && mergedCards.length == allCards.length + 1) {
        const newCard =
          newBankCards.length > bankCards.length
            ? newBankCards[newBankCards.length - 1]
            : newMilstarCards[newMilstarCards.length - 1];
        await addCreditCardPayment(newCard);
        setAllCards(mergedCards);
        setBankCards(newBankCards);
        return;
      }
      setAllCards(mergedCards);
      setBankCards(newBankCards);
      let creditCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
      if (creditCardPayment) {
        const isPaymentPresentInAccount = mergedCards.find((payment) => payment.token === creditCardPayment.token);
        if (!isPaymentPresentInAccount) {
          await removePayment(creditCardPayment.id);
          creditCardPayment = undefined;
        }
      }
      if (!(allCards.length && mergedCards.length == allCards.length + 1)) {
        if (creditCardPayment) {
          setSelectedCardDetails(creditCardPayment);
          setShowCardSelector(false);
        } else {
          const preferredcard = mergedCards.find((card) => card.preferredcard.toLowerCase() === 'y');
          setSelectedCardDetails(preferredcard);
          if (preferredcard && cartList?.unallocatedAmount?.centAmount > 0) {
            await addCreditCardPayment(preferredcard);
          } else {
            setShowCardSelector(true);
          }
        }
      }
      const giftCardPayments = cartList?.payments?.filter((payment) => payment.paymentMethod === 'giftCard');
      if (giftCardPayments?.length) {
        let giftSum = 0;
        for (const payment of giftCardPayments) {
          giftSum += payment.amountPlanned.centAmount;
        }
        setGiftCardsApplied({
          centAmount: -giftSum,
          currencyCode: 'USD',
          fractionDigits: 2,
        });
      } else {
        setGiftCardsApplied({});
      }
      setShowLoader(false);
    };
    setCardsAndPayments();
  }, []);
  const addCreditCardPayment = async (card) => {
    if (cartList?.unallocatedAmount?.centAmount > 0) {
      setShowLoader(true);
      const payload = {
        creditCardToken: card.token,
        paymentMethod: 'creditCard',
        cardType: card.cardtype,
        expDate: card.expirydate,
        billingAddress: {
          addressId: card.addressId,
          firstName: card.cardholdername.split(' ')[0],
          lastName: card.cardholdername.split(' ')[1],
          streetName: card.billingaddress1,
          streetNumber: card.billingaddress2,
          postalCode: card.zipcode,
          city: card.city,
          country: card.country,
          state: card.state,
          phone: card.billingphone,
        },
      };
      try {
        const res = await addCreditCardForPayment(payload);
        if (res?.errorCode) {
          setAddPaymentError(res.body);
        } else {
          setAddrError(false);
          setCardError(false);
          return;
        }
      } catch (e) {
        console.error(e);
      } finally {
        setShowLoader(false);
      }
    } else return;
  };

  const toggleCancelMessage = (orderId, message) => {
    setCancelMessage((prevState) => [...prevState, { orderId, cancelMessage: message }]);
  };

  const handleCancelSubscription = async (orderId) => {
    try {
      const res = await cancelSubscriptionOrder(orderId);
      setShowCancel((prevState) => [...prevState, { orderId }]);
      toggleCancelMessage(orderId, res?.state?.obj?.name?.en);
      setIsSubscriptionCancelled(true);
    } catch (error) {
      console.error('Error while cancelling subscription:', error);
    }
    handleGetSubscriptionTemplate();
  };

  const handleSkipSubscription = async (orderId) => {
    try {
      const res = await skipNextSubscriptionOrder(orderId);
      setSubscriptionSkipped((prevState) => [...prevState, { orderId }]);
      setButtonVisible(false);
      setTimeout(() => {
        setSubscriptionSkipped((prevState) => prevState.filter((item) => item?.orderId !== orderId));
      }, 2000);
    } catch (error) {
      console.error('Error while skipping subscription:', error);
    }
  };

  const formatNextDeliveryDate = (deliveryDate: string) => {
    const nextDeliveryDate = new Date(deliveryDate);
    return `${nextDeliveryDate.getDate()} ${nextDeliveryDate.toLocaleString('default', {
      month: 'short',
    })} ${nextDeliveryDate.getFullYear()}`;
  };

  const addCardForPayment = async (selectedCard) => {
    const existingCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
    if (cartList?.unallocatedAmount?.centAmount > 0 || selectedCard !== existingCardPayment?.token) {
      const selectedCardDetails = allCards.find((card) => card.token === selectedCard);
      if (selectedCardDetails) {
        setShowLoader(true);
        const payload = {
          creditCardToken: selectedCard,
          paymentMethod: 'creditCard',
          cardType: selectedCardDetails.cardtype,
          expDate: selectedCardDetails.expirydate,
          billingAddress: {
            addressId: selectedCardDetails.addressId,
            firstName: selectedCardDetails.cardholdername.split(' ')[0],
            lastName: selectedCardDetails.cardholdername.split(' ')[1],
            streetName: selectedCardDetails.billingaddress1,
            streetNumber: selectedCardDetails.billingaddress2,
            postalCode: selectedCardDetails.zipcode,
            city: selectedCardDetails.city,
            country: selectedCardDetails.country,
            state: selectedCardDetails.state,
            phone: selectedCardDetails.billingphone,
          },
        };
        addCreditCardForPayment(payload)
          .then((res) => {
            if (res?.errorCode) {
              setAddPaymentError(res.body);
            } else {
              setAddrError(false);
              setCardError(false);
              setCardType(res?.payments[0]?.cardType);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setShowLoader(false));
      }
    }
  };

  function showShippingModal(lineItem: LineItem) {
    getShippingMethod(lineItem);
    setIsShipOpen(true);
  }

  function closeShippingModal() {
    setIsShipOpen(false);
  }

  const getShippingMethod = async (lineItem: LineItem) => {
    const res = await getShippingForLineItem(lineItem?.lineItemId);
    setShippingMethods(res);
  };

  const toggleCancel = (cartId) => {
    const existingSubscription = showCancel.find((subscription) => subscription.cartId === cartId);

    if (existingSubscription) {
      setShowCancel((prevState) => prevState.filter((subscription) => subscription.cartId !== cartId));
    } else {
      setShowCancel((prevState) => [...prevState, { cartId }]);
    }
  };

  const togglePayments = (cartId) => {
    const existingSubscription = toggle1.find((item) => item.cartId === cartId);
    if (existingSubscription) {
      setToggle1((prevState) => prevState.filter((item) => item.cartId !== cartId));
    } else {
      setToggle1((prevState) => [...prevState, { cartId }]);
    }
  };
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'subscriptions',
            cart: {
              ...cartList,
            },
          },
          monetateConfig,
        );
      };

      if (subscriptionOrderData && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [subscriptionOrderData, monetateConfig]);

  return (
    <>
      <section aria-labelledby="subscription-section" className="">
        <h3 id="subscriptions-section" className="text-[21px] font-bold leading-6 text-gray-900">
          {formatCommonMessage({ id: 'subscriptions', defaultMessage: 'Subscriptions' })} ({nonCancelledDataLength})
        </h3>
        {subscriptionOrderData.map((subscriptionTemplate: any, i: number) => (
          <section key={i}>
            {subscriptionTemplate?.lineItems?.map((lineItem: any, j: number) => (
              <div key={j}>
                <section className="mt-[15px] rounded border p-4 text-sm md:mt-[10px]">
                  {!cancelMessage.find(
                    (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                  ) && (
                    <h4 className="text-sm text-gray-900">
                      {formatAccountMessage({ id: 'nextDelivery', defaultMessage: 'Next Delivery' })}:{' '}
                      <strong>{formatNextDeliveryDate(subscriptionTemplate?.custom?.fields?.nextDeliveryDate)}</strong>
                    </h4>
                  )}

                  <section className="justify-between xl:flex">
                    <section className="mt-[22px] w-full xl:w-1/3">
                      <section className="flex">
                        <Link href={subscriptionTemplate?.lineItems?.[0]?.slug}>
                          <img
                            src={subscriptionTemplate?.lineItems?.[0]?.variant?.images[0]}
                            alt="Product Image"
                            className="mt-2 h-24 w-24 cursor-pointer"
                          />
                        </Link>
                        <section className="mt-2 text-sm">
                          <Link href={subscriptionTemplate?.lineItems?.[0]?.slug}>
                            <p className="cursor-pointer font-bold text-[rgba(4,60,99,1)]">
                              {subscriptionTemplate?.lineItems?.[0].name}
                            </p>
                          </Link>
                          <p className="mt-3 text-[rgba(102,102,102,1)]">
                            {formatCommonMessage({ id: 'item', defaultMessage: 'Item' })}
                            {subscriptionTemplate?.lineItems[0]?.variant?.sku}
                          </p>
                          <p className="mt-3 hidden text-[rgba(102,102,102,1)] xl:block">
                            {formatCommonMessage({ id: 'qty', defaultMessage: 'Qty' })} :
                            {subscriptionTemplate?.lineItems?.[0]?.count}
                          </p>
                        </section>
                      </section>
                      <section className="mt-2 ml-4  flex justify-between">
                        <p className=" block text-[rgba(102,102,102,1)] xl:hidden">
                          {formatCommonMessage({ id: 'qty', defaultMessage: 'Qty' })} :{' '}
                          {subscriptionTemplate?.lineItems?.[0]?.count}
                        </p>
                        <p className="mt-3 text-right font-bold text-gray-900 md:block xl:hidden">
                          {' '}
                          <Price
                            price={subscriptionTemplate?.lineItems?.[0]?.price}
                            className={` text-affes-base text-gray-900`}
                          />
                        </p>
                      </section>
                    </section>

                    <hr className="my-4 block  xl:hidden" />

                    <section className="xl:w-[280px]">
                      <section className="mb-2 flex items-center font-semibold text-[rgba(102,102,102,1)]">
                        {formatCartMessage({
                          id: 'shippingTo',
                          defaultMessage: 'Shipping to',
                        })}
                        :
                        {!cancelMessage.find(
                          (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                        ) && (
                          <button
                            className="ml-2"
                            onClick={() => {
                              showShippingModal(lineItem);
                              setCurrentOrderId(subscriptionTemplate?.cartId);
                              setCurrentLineItem(lineItem);
                            }}
                          >
                            <EditPencil />
                          </button>
                        )}
                      </section>
                      <p className="text-gray-900">
                        {subscriptionTemplate?.shippingAddress?.streetName}{' '}
                        {subscriptionTemplate?.shippingAddress?.streetNumber}
                      </p>
                      <p className="text-gray-900">
                        {subscriptionTemplate?.shippingAddress?.city} {subscriptionTemplate?.shippingAddress?.state}
                      </p>
                      <p className="text-gray-900">
                        {subscriptionTemplate?.shippingAddress?.country}{' '}
                        {subscriptionTemplate?.shippingAddress?.postalCode}
                      </p>

                      <section className="mt-4">
                        <p className="font-semibold text-[rgba(102,102,102,1)]">
                          {formatCartMessage({
                            id: 'deliveryMethod',
                            defaultMessage: 'Delivery Method ',
                          })}
                          :
                        </p>
                        <p className="text-gray-900">{subscriptionTemplate?.shippingInfo?.name}</p>
                      </section>

                      <section className="mt-4">
                        <section className="flex items-center font-semibold text-[rgba(102,102,102,1)]">
                          {formatCartMessage({
                            id: 'deliveryFrequency',
                            defaultMessage: 'Delivery Frequency',
                          })}
                          :
                          {!cancelMessage.find(
                            (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                          ) && (
                            <button
                              className="ml-2"
                              onClick={async () => {
                                await getCustomMessageData();
                                setCurrentOrderId(subscriptionTemplate?.cartId);
                                setSelectedCart(subscriptionTemplate);
                                setProductFrequences(
                                  subscriptionTemplate?.lineItems?.[0]?.variant?.attributes?.productFrequences,
                                );
                                setServicesModal(true);
                                setSubscriptionDefaultFrequency(
                                  subscriptionTemplate?.custom?.fields?.subscriptionFrequencySettings,
                                );
                              }}
                            >
                              <EditPencil />
                            </button>
                          )}
                        </section>
                        <p className="text-gray-900">
                          {subscriptionTemplate?.custom?.fields?.subscriptionFrequencySettings}
                        </p>
                      </section>
                    </section>

                    <section className="my-5">
                      <section className="xl:mx-auto xl:w-[145px]">
                        <Link href={subscriptionTemplate?.lineItems?.[0]?.slug}>
                          <div>
                            <button
                              className={`my-1 h-9 w-full rounded p-2 text-sm text-white ${
                                !subscriptionTemplate?.lineItems?.[0]?.price?.centAmount
                                  ? 'bg-gray-400'
                                  : 'bg-[rgba(4,60,99,1)]'
                              }`}
                              disabled={!subscriptionTemplate?.lineItems?.[0]?.price?.centAmount}
                            >
                              Buy Again
                            </button>
                          </div>
                        </Link>{' '}
                        <div key={i} className="relative flex flex-col">
                          {cancelMessage.find(
                            (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                          ) ? (
                            <p className="text-center text-sm text-gray-600">
                              {
                                cancelMessage.find(
                                  (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                                ).cancelMessage
                              }
                            </p>
                          ) : (
                            <button
                              className="w-145 relative my-1 h-9 rounded border border-[#043C63] font-bold text-[#043C63]"
                              onClick={() => toggleCancel(subscriptionTemplate.cartId)}
                            >
                              {formatCartMessage({
                                id: 'cancelSubscription',
                                defaultMessage: 'Cancel Subscription',
                              })}
                            </button>
                          )}

                          {subscriptionSkipped?.find((item) => item?.orderId === subscriptionTemplate.cartId) ? (
                            <div className="my-1">
                              <p className="text-center text-sm text-gray-600">
                                {subscriptionSkipped &&
                                  formatCartMessage({
                                    id: 'skipnextdelivery',
                                    defaultMessage: 'Confirmed: your next delivery date will be skipped.',
                                  })}
                              </p>
                            </div>
                          ) : cancelMessage.find(
                              (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                            ) ? (
                            <button
                              className={`w-145 relative my-1 h-9 rounded border font-bold ${
                                isSubscriptionCancelled || subscriptionTemplate?.orderState === 'Cancelled'
                                  ? 'border-gray-300 text-gray-300'
                                  : 'border-[#043C63] text-[#043C63]'
                              }`}
                              disabled
                              onClick={() => handleSkipSubscription(subscriptionTemplate?.cartId)}
                            >
                              {formatCartMessage({
                                id: 'skipNextDelivery',
                                defaultMessage: 'Skip Next Delivery',
                              })}
                            </button>
                          ) : (
                            <>
                              <button
                                className="w-145 my-1 h-9 rounded border border-[#043C63] font-bold text-[#043C63]"
                                onClick={() => handleSkipSubscription(subscriptionTemplate?.cartId)}
                              >
                                {formatCartMessage({
                                  id: 'skipNextDelivery',
                                  defaultMessage: 'Skip Next Delivery',
                                })}
                              </button>
                            </>
                          )}

                          {showCancel.find(
                            (cancelSubscription) => cancelSubscription.cartId === subscriptionTemplate.cartId,
                          ) && (
                            <section
                              className="absolute top-10 left-1/2 z-10 -translate-x-1/2 rounded bg-gray-100 p-4"
                              style={{ minWidth: '200px' }}
                            >
                              <p className="font-bold text-gray-900">
                                {formatCartMessage({
                                  id: 'cancelitem',
                                  defaultMessage: 'Do you want to cancel the subscription?',
                                })}
                              </p>
                              <div
                                className="absolute top-[-15px] left-1/2 -translate-x-1/2 border-t-2 border-gray-100"
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  backgroundColor: 'rgb(243 244 246 / var(--tw-bg-opacity)',
                                  zIndex: '-1',
                                  clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                                }}
                              ></div>
                              <div className="mt-2 flex justify-around">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleCancelSubscription(subscriptionTemplate.cartId);
                                    toggleCancel(subscriptionTemplate.cartId);
                                  }}
                                  className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                                >
                                  {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                                </button>
                                <button
                                  type="button"
                                  onClick={() => toggleCancel(subscriptionTemplate.cartId)}
                                  className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                                >
                                  {formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
                                </button>
                              </div>
                            </section>
                          )}
                        </div>
                        <p className="mt-3 hidden text-right font-bold text-gray-900 md:hidden xl:block">
                          <Price
                            price={subscriptionTemplate?.lineItems?.[0]?.price}
                            className={`text-affes-base text-gray-900 md:pt-4`}
                          />
                        </p>
                      </section>
                    </section>
                  </section>
                  <hr className="my-4" />
                  {toggle1?.find((item) => item?.cartId === subscriptionTemplate.cartId) ? (
                    <button
                      className=" text-[rgba(4,60,99,1)]"
                      onClick={() => {
                        togglePayments(subscriptionTemplate?.cartId);
                      }}
                    >
                      {formatAccountMessage({
                        id: 'hidePaymentDetails',
                        defaultMessage: 'Hide Payment Details',
                      })}
                    </button>
                  ) : (
                    <button
                      className=" text-[rgba(4,60,99,1)]"
                      onClick={() => {
                        togglePayments(subscriptionTemplate?.cartId);
                      }}
                    >
                      {formatAccountMessage({
                        id: 'showPaymentDetails',
                        defaultMessage: 'Show Payment Details',
                      })}
                    </button>
                  )}
                  {toggle1?.find((item) => item?.cartId === subscriptionTemplate.cartId) && (
                    <section className="block w-3/4 justify-between py-2 xl:flex xl:w-full">
                      <section className="my-3 md:my-0 md:ml-24">
                        <p className="my-2 font-semibold text-[rgba(102,102,102,1)]">
                          {formatCartMessage({
                            id: 'payment',
                            defaultMessage: 'Payment',
                          })}
                          :{' '}
                          {!cancelMessage.find(
                            (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                          ) && (
                            <button
                              className="my-auto ml-2"
                              onClick={() => {
                                setCardDropDown(!cardDropDown);
                                setOrderLineId(lineItem?.lineItemId);
                              }}
                            >
                              <EditPencil />
                            </button>
                          )}
                        </p>

                        <CreditCardsModal
                          orderId={subscriptionTemplate?.cartId}
                          lineItem={lineItem}
                          orderLineId={orderLineId}
                          allCards={allCards}
                          addPaymentError={addPaymentError}
                          cardError={cardError}
                          payments={subscriptionTemplate?.payments}
                          billingAddress={subscriptionTemplate?.billingAddress}
                          setShowCardSelector={setCardDropDown}
                          showCardSelector={cardDropDown}
                          setReFetch={setReFetch}
                        />
                        {!cancelMessage.find(
                          (cancelSubscription) => cancelSubscription.orderId === subscriptionTemplate.cartId,
                        ) && (
                          <button
                            className="my-2  cursor-pointer text-[rgba(4,60,99,1)]"
                            onClick={() => {
                              setIsOpen(true);
                              setCurrentOrderId(subscriptionTemplate?.cartId);
                            }}
                          >
                            {formatCartMessage({
                              id: 'addNewCard',
                              defaultMessage: 'Add New Card',
                            })}
                          </button>
                        )}
                      </section>
                      <section>
                        <p className="my-2 font-semibold text-[rgba(102,102,102,1)]">
                          {formatCartMessage({
                            id: 'orderSummary',
                            defaultMessage: 'Order Summary',
                          })}
                        </p>
                        <section className="flex justify-between text-gray-900">
                          <section className="leading-6 ">
                            <p>
                              {formatCartMessage({
                                id: 'merchandiseSubtotal',
                                defaultMessage: 'Merchandise Subtotal',
                              })}
                              :
                            </p>
                            <p>
                              {formatCartMessage({
                                id: 'shipping',
                                defaultMessage: 'Shipping',
                              })}
                              :
                            </p>
                            <p>
                              {formatCartMessage({
                                id: 'yourBenefit',
                                defaultMessage: 'Your benefit',
                              })}
                            </p>
                            <p>
                              {formatCartMessage({
                                id: 'Discount',
                                defaultMessage: 'Discount',
                              })}
                              :
                            </p>
                            <p className="font-bold ">
                              {formatCartMessage({
                                id: 'total',
                                defaultMessage: 'Total',
                              })}
                              :
                            </p>
                          </section>
                          <section className="text-right leading-6">
                            <p>
                              <Price
                                price={subscriptionTemplate?.subTotal}
                                className={` text-affes-base text-gray-900`}
                              />
                            </p>
                            <p>
                              <Price
                                price={subscriptionTemplate?.shippingInfo?.price}
                                className={` text-affes-base text-gray-900`}
                              />
                            </p>
                            <p>No Sales Tax</p>
                            <p>
                              {' '}
                              <Price
                                price={subscriptionTemplate?.discount}
                                className={` text-affes-base text-gray-900`}
                              />
                            </p>
                            <p className="font-bold">
                              <Price price={subscriptionTemplate?.total} className={` text-affes-base text-gray-900`} />
                            </p>
                          </section>
                        </section>
                      </section>
                    </section>
                  )}
                </section>
              </div>
            ))}
          </section>
        ))}
        {subscriptionError && (
          <p className="my-5 text-sm">
            {formatCommonMessage({ id: 'nosubscriptions', defaultMessage: 'No subscription orders found' })}
          </p>
        )}

        {isShipOpen && (
          <ShippingModal
            lineItem={currentLineItem}
            closeSippingModal={closeShippingModal}
            shippingMethods={shippingMethods}
            orderId={currentOrderId}
            isOrderShipping={true}
          />
        )}

        {isOpen && (
          <AddNewCard
            orderId={currentOrderId}
            showCardField={true}
            data={getCardType}
            closeModal={() => setIsOpen(false)}
            setSelectedCardData={setSelectedCardDetails}
            isSubscriptionCard={true}
            handleGetSubscriptionTemplate={handleGetSubscriptionTemplate}
          />
        )}

        {showServicesModal && (
          <SubscriptionServicesModal
            orderId={currentOrderId}
            servicesModal={showServicesModal}
            setServicesModal={setServicesModal}
            subscriptionServices={productFrequences}
            lineItem={currentLineItem}
            selectSubscriptionTitle={selectSubscriptionMessage}
            isSubscription={true}
            subscriptionDefaultFrequency={subscriptionDefaultFrequency}
          />
        )}

        {isEditBillingModalOpen && (
          <EditBillingAddressModal
            cart={selectedCart as Cart}
            closeEditBillingAddressModal={() => setIsEditBillingModalOpen(false)}
            isOrderBilling={true}
            orderId={currentOrderId}
          ></EditBillingAddressModal>
        )}
      </section>
    </>
  );
};

export default Subscriptions;
