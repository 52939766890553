import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { Variant } from '@Types/product/Variant';
import LeftChevronIcon from 'components/icons/left-arrow';
import RightChevronIcon from 'components/icons/right-arrow';
import MediaIcon from 'components/images/media-icon.png';
import { VISIBLE_THUMBNAILS_COUNT, PDP_IMAGE_ZOOM_PROPS } from 'helpers/constants/aafes';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { UIProduct } from '..';
import AafesSlider from '../../../aafes/aafes-slider';
import ImageMagnifier from '../../ImageMagnifier';
import VideoModal from './videoModal';
type Props = {
  product?: UIProduct;
  variant?: Variant;
  setCurrentVariantId?: string;
};

const Thumbnails = ({ sliceNumber, setSliceNumber, selectedImage, setSelectedImage, images }) => {
  const ProductImageLength = images?.length;
  const handleThumbnails = ({ operation }) => {
    if (operation === 'ShowPrevious') {
      sliceNumber > VISIBLE_THUMBNAILS_COUNT &&
        setSliceNumber((prevState: number) => prevState - sliceNumber + VISIBLE_THUMBNAILS_COUNT);
    }
    if (operation === 'ShowNext') {
      ProductImageLength - sliceNumber > 0 &&
        setSliceNumber((prevState: number) => prevState + ProductImageLength - VISIBLE_THUMBNAILS_COUNT);
    }
  };

  return (
    <section>
      <div>
        <div className="mt-[27px] flex items-center justify-between gap-5">
          {ProductImageLength > VISIBLE_THUMBNAILS_COUNT && (
            <button
              type="button"
              disabled={!(sliceNumber > VISIBLE_THUMBNAILS_COUNT)}
              onClick={() => handleThumbnails({ operation: 'ShowPrevious' })}
            >
              <LeftChevronIcon fill={`${sliceNumber > VISIBLE_THUMBNAILS_COUNT ? '#0E2D51' : '#bebebe'}`} />
            </button>
          )}
          <div
            className={`flex w-full gap-4 ${
              ProductImageLength >= VISIBLE_THUMBNAILS_COUNT ? 'justify-between' : 'justify-start'
            }`}
          >
            {images?.slice(sliceNumber - VISIBLE_THUMBNAILS_COUNT, sliceNumber)?.map((image: any, index: number) => (
              <div
                key={index}
                className={`h-[60px] w-[60px] shrink-0 cursor-pointer
                ${selectedImage === image ? 'border-[3px] border-[#043C63] ' : ''}`}
                onClick={() => setSelectedImage(image)}
              >
                <Image
                  loader={(options) => options.src}
                  src={image?.url ?? '#'}
                  alt={image?.label}
                  height={60}
                  width={60}
                />
              </div>
            ))}
          </div>
          {ProductImageLength > VISIBLE_THUMBNAILS_COUNT && (
            <button
              type="button"
              disabled={sliceNumber >= ProductImageLength}
              onClick={() => handleThumbnails({ operation: 'ShowNext' })}
            >
              <RightChevronIcon fill={`${sliceNumber >= ProductImageLength ? '#bebebe' : '#0E2D51'}`} />
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

const ImageGallery = ({ product, variant }: Props) => {
  const IsProductVideoAvailable = variant?.attributes?.productVideos;
  const [quickViewVideoModalOpen, setQuickViewVideoModalOpen] = useState(false);
  const [isDesktopSize] = useMediaQuery(desktop);
  const [sliceNumber, setSliceNumber] = useState(VISIBLE_THUMBNAILS_COUNT);
  const largeProductImages = product?.imageSets?.find((imageSet) => imageSet?.type === 'xlarge')?.src;
  const xLargeProductImages = product?.imageSets?.find((imageSet) => imageSet?.type === 'xlarge')?.src;
  const smallProductImages = product?.imageSets?.find((imageSet) => imageSet?.type === 'xsmall')?.src;
  const smallImage = smallProductImages?.find((image: any) => image?.label === 'main');
  const largeImage = largeProductImages?.find((image: any) => image?.label === 'main');
  const xLargeImage = xLargeProductImages?.find((image: any) => image?.label === 'main');
  const [selectedThumbImage, setSelectedThumbImage] = useState<any>(smallImage);
  const [selectedLargeImage, setSelectedLargeImage] = useState<any>(largeImage);
  const [selectedXLargeImage, setSelectedXLargeImage] = useState<any>(xLargeImage);

  const openQuickViewModal = (e) => {
    e.preventDefault();
    setQuickViewVideoModalOpen(true);
  };
  const closeQuickVideoViewModal = () => {
    setQuickViewVideoModalOpen(false);
  };

  useEffect(() => {
    setSelectedThumbImage(smallProductImages?.[0]);
    setSliceNumber(VISIBLE_THUMBNAILS_COUNT);
  }, [product, variant]);

  useEffect(() => {
    const selectedImageLabel = selectedThumbImage?.label;
    const selectLargeImage = largeProductImages?.find((image: any) => image?.label === selectedImageLabel);
    setSelectedLargeImage(selectLargeImage?.url ? selectLargeImage : selectedThumbImage);
  }, [selectedThumbImage?.url]);

  useEffect(() => {
    const selectedImageLabel = selectedLargeImage?.label;
    const selectXLargeImage = xLargeProductImages?.find((image: any) => image?.label === selectedImageLabel);
    setSelectedXLargeImage(selectXLargeImage?.url ? selectXLargeImage : selectedLargeImage);
  }, [selectedLargeImage?.url]);

  return (
    <>
      {isDesktopSize ? (
        <section className="w-11/12">
          <section className="flex w-full flex-col">
            <section className="relative aspect-[4/3] w-full">
              <ImageMagnifier zoomProps={PDP_IMAGE_ZOOM_PROPS}>
                <Image
                  loader={({ src }) => src}
                  src={selectedLargeImage?.url ?? '#'}
                  height={500}
                  width={500}
                  alt={selectedLargeImage?.label}
                  id="zoomableImage"
                />
              </ImageMagnifier>
            </section>
          </section>
          <section className="hidden md:block">
            <Thumbnails
              setSliceNumber={setSliceNumber}
              sliceNumber={sliceNumber}
              selectedImage={selectedThumbImage}
              setSelectedImage={setSelectedThumbImage}
              images={smallProductImages}
            />
          </section>
          {IsProductVideoAvailable && (
            <button type="button" onClick={openQuickViewModal} className="mt-[36px] h-[57px] w-[50px]">
              <Image loader={({ src }) => src} src={MediaIcon} alt="media icon desktop" width={50} height={57} />
            </button>
          )}
        </section>
      ) : (
        <section>
          <AafesSlider>
            {largeProductImages?.map((image: any, index: number) => (
              <div className="flex w-full justify-center" key={index}>
                <Image
                  loader={({ src }) => src}
                  width={369}
                  height={369}
                  src={image?.url ?? '#'}
                  alt={image?.label}
                  className="w-full"
                />
              </div>
            ))}
          </AafesSlider>
          {IsProductVideoAvailable && (
            <button type="button" onClick={openQuickViewModal} className="mt-[36px] h-[57px] w-[50px]">
              <Image loader={({ src }) => src} src={MediaIcon} alt="media icon mobile" width={50} height={57} />
            </button>
          )}
        </section>
      )}
      <VideoModal open={quickViewVideoModalOpen} variant={variant} onClose={closeQuickVideoViewModal} />
    </>
  );
};

export default ImageGallery;
