import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import SmoothScroller from 'helpers/utils/SmoothScroller';

type Props = {
  listFeatureAndBenefits?: Array<string>;
  isProductOverview?: boolean;
};

export default function ListFeatureAndBenefits({ listFeatureAndBenefits, isProductOverview }: Props) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const handleTabClick = () => {
    SmoothScroller?.scrollToElement({
      element: `#overview`,
      offset: 0,
      duration: 1000,
    });
  };

  return (
    <section className={`${!isProductOverview && 'my-3'}`}>
      <ul className="pl-4">
        {listFeatureAndBenefits
          .slice(0, isProductOverview ? listFeatureAndBenefits?.length : 5)
          .map((listFeature, index) => (
            <li
              key={index}
              className="list-outside list-disc text-base leading-5 text-gray-900"
              dangerouslySetInnerHTML={{ __html: listFeature }}
            ></li>
          ))}
      </ul>
      {!isProductOverview && listFeatureAndBenefits?.length > 5 && (
        <span
          onClick={(e) => {
            e.preventDefault();
            handleTabClick();
          }}
          className="cursor-pointer text-sm leading-5 text-blue-900 hover:underline"
        >
          {formatProductMessage({ id: 'seeMoreDetails', defaultMessage: 'See more details' })}
        </span>
      )}
    </section>
  );
}
