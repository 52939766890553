import React, { useContext } from 'react';
import { RadioButtonGroup, RadioButtonGroupProps } from 'components/commercetools-ui/aafes/radio-group';
import { UB_ATTACHMENT_CHANGE_PROMPT_TEXT } from 'helpers/constants/aafes';
import { UniformBuilderContext } from 'frontastic/provider/uniform-builder';
interface InsigniaAttachMethodProps {
  heading: string;
  info: string;
  note: string;
  radioOptions: RadioButtonGroupProps;
  defaultSelected?: string;
}

export const InsigniaAttachMethod: React.FC<InsigniaAttachMethodProps> = ({
  heading,
  info,
  note,
  radioOptions,
  defaultSelected,
}) => {
  const [ubState] = useContext(UniformBuilderContext);

  const { setSelectedPatchType, setUserSwitchedPatch } = ubState;
  return (
    <div className="rounded-md border border-[#00000033] bg-white p-6 text-[#333]">
      <h1 className="mb-3 text-[1rem] font-semibold leading-normal">{heading}</h1>
      <p className="mb-3 text-[0.875rem] font-normal leading-normal">{info}</p>
      <p className="mb-5 text-[0.875rem] font-normal leading-5">{note}</p>
      <RadioButtonGroup
        {...radioOptions}
        direction="horizontal"
        defaultSelected={defaultSelected}
        shouldPrompt
        promptText={UB_ATTACHMENT_CHANGE_PROMPT_TEXT}
        onValueChange={(option) => {
          setUserSwitchedPatch(true);
          setSelectedPatchType(option);
        }}
      />
    </div>
  );
};
