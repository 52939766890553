import React, { useMemo } from 'react';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';

export default function PriceDetails({ product, setLoginModal }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const { loggedIn } = useAccount();
  const sku = product?.variants?.[0];
  const listPrice = sku?.listPrice?.centAmount;
  const salePrice = sku?.salePrice?.centAmount;

  const listPriceValues = product?.variants?.map((variant) => variant?.listPrice?.centAmount || null);
  const filteredListPriceValues = listPriceValues?.filter((value) => typeof value === 'number');
  const uniqueListPriceValues = Array.from(new Set(filteredListPriceValues)) as number[];

  const salePriceValues = product?.variants?.map((variant) => variant?.salePrice?.centAmount || null);
  const filteredSalePriceValues = salePriceValues?.filter((value) => typeof value === 'number');
  const uniqueSalePriceValues = Array.from(new Set(filteredSalePriceValues)) as number[];

  const priceRangeMaxListPrice =
    product?.variants?.length === 1
      ? listPrice ?? undefined
      : uniqueListPriceValues?.length > 0
      ? Math.max(...uniqueListPriceValues)
      : undefined;
  const priceRangeMaxSalePrice =
    product?.variants?.length === 1
      ? salePrice ?? undefined
      : uniqueSalePriceValues?.length > 0
      ? Math.max(...uniqueSalePriceValues)
      : undefined;

  const isListPriceLarger = useMemo(() => {
    if (!priceRangeMaxSalePrice) return true;
    if (product?.variants?.length === 1) return listPrice > salePrice;
    else {
      return priceRangeMaxListPrice > priceRangeMaxSalePrice;
    }
  }, [listPrice, salePrice]);

  const displayListPriceRange = useMemo(() => {
    if (product?.variants?.length > 1) {
      if (uniqueListPriceValues?.length > 1) {
        const minListPrice = Math.min(...uniqueListPriceValues) as number;
        const maxListPrice = Math.max(...uniqueListPriceValues) as number;
        return `${CurrencyHelpers.formatForCurrency(minListPrice)} - ${CurrencyHelpers.formatForCurrency(
          maxListPrice,
        )}`;
      } else if (uniqueListPriceValues?.length === 1) {
        const uniqueListPrice = uniqueListPriceValues?.[0];
        return uniqueListPrice !== undefined ? CurrencyHelpers.formatForCurrency(uniqueListPrice) : null;
      }
    }

    // Default format when there is only one unique listPrice value or no variants
    return listPrice !== undefined ? CurrencyHelpers.formatForCurrency(listPrice) : null;
  }, [product?.variants]);

  const displaySalePriceRange = useMemo(() => {
    if (product?.variants?.length > 1) {
      if (uniqueSalePriceValues?.length > 1) {
        const minSalePrice = Math.min(...uniqueSalePriceValues) as number;
        const maxSalePrice = Math.max(...uniqueSalePriceValues) as number;
        return (
          isListPriceLarger &&
          `${CurrencyHelpers.formatForCurrency(minSalePrice)} - ${CurrencyHelpers.formatForCurrency(maxSalePrice)}`
        );
      } else if (uniqueSalePriceValues?.length === 1) {
        const uniqueSalePrice = uniqueSalePriceValues?.[0];
        return isListPriceLarger && uniqueSalePrice ? `${CurrencyHelpers.formatForCurrency(uniqueSalePrice)}` : null;
      }
    }
    // Default format when there is only one unique salePrice value or no variants

    return isListPriceLarger && salePrice ? `${CurrencyHelpers.formatForCurrency(salePrice)}` : null;
  }, [product?.variants]);

  const LoginForPriceSection = () => {
    return (
      <p
        onClick={() => setLoginModal(true)}
        className="w-fit cursor-pointer text-sm leading-5 text-blue-900 hover:underline"
      >
        {formatMessage({ id: 'logForPricing', defaultMessage: 'Log in for Exchange pricing' })}
      </p>
    );
  };

  const DisplayPricing = () => {
    if (isListPriceLarger && salePrice)
      return (
        displaySalePriceRange && (
          <p className="text-[16px] font-bold leading-5 text-[#DA0F0F]">{displaySalePriceRange}</p>
        )
      );
    else if (listPrice !== undefined)
      return <span className={`text-[16px] font-bold leading-5 text-gray-900`}>{displayListPriceRange}</span>;
    else
      return (
        <span className="text-base leading-5 text-gray-900">
          {formatMessage({ id: 'priceNotAvailable', defaultMessage: 'Price not available' })}
        </span>
      );
  };

  return sku?.attributes?.isMSRPRestricted ? (
    loggedIn ? (
      <DisplayPricing />
    ) : (
      <LoginForPriceSection />
    )
  ) : (
    <DisplayPricing />
  );
}
