import React, { useEffect, useState } from 'react';
import { BsCaretDownFill } from 'react-icons/bs';
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md';
import Loader from 'components/commercetools-ui/loader/Loader';
import InfoIcon from 'components/icons/infoErrorIcon';
import { PICKER_COLOR } from 'helpers/constants/aafes';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';

const NeedAnythingElse = ({
  product,
  variant,
  setVariant,
  productIndex,
  setQuantity,
  quantity,
  referencedProductsPrice,
}) => {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState(
    product?.pickers?.length > 1 ? product?.pickers?.[0]?.values?.[0] || null : null,
  );
  const [isVaraintPicked, setisVariantPicked] = useState<boolean>(false);
  const [MASTER_VARIANT, SETMASTER_VARIANT] = useState<number>(0);
  const [maxQuantity, setMaxQuantity] = useState<number>(variant?.attributes?.maxQty);
  const [isIncrement, setIncrement] = useState<boolean>(true);
  const [isDecrement, setDecrement] = useState<boolean>(false);
  const [isShowAllVariants, setIsShowAllVariants] = useState<boolean>(false);
  const [maxVariantsDisplay, setMaxVariantsDisplay] = useState<number>(9);
  const [sizeOptionsCount, setSizeOptionsCount] = useState(0);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { addItem, studioConfig } = useCart();
  const productMaxQty = studioConfig?.productMaxQty;
  const addToCart = async () => {
    if (loading || !variant.isOnStock) {
      return;
    }
    setLoading(true);
    await addItem(variant, quantity).then((cartResponse) => {
      if (cartResponse?.['errorCode'] === 500) {
        setErrorMessage(cartResponse?.['body']);
      }
    });
    setLoading(false);
    setAdded(true);
  };

  const handleChangeVariant = async (value, name) => {
    setIsLoad(true);
    setSelectedSize(value);
    setisVariantPicked(true);
    if (selectedColor) {
      const foundVariant = product?.variants?.find(
        (variant) => variant?.picker?.[name] === value && variant?.picker?.Color === selectedColor,
      );
      setVariant((prevData) => {
        return { ...prevData, [productIndex]: foundVariant };
      });
    } else {
      const foundVariant = product?.variants?.find((variant) => variant?.picker?.[name] === value);
      setVariant((prevData) => {
        return { ...prevData, [productIndex]: foundVariant };
      });
    }
    setIsLoad(false);
  };

  const handleQuantityIncrease = () => {
    setQuantity((prevData) => {
      return { ...prevData, [productIndex]: quantity + 1 };
    });
    setDecrement(true);
  };

  const handleQuantityDecrease = () => {
    setQuantity((prevData) => {
      return { ...prevData, [productIndex]: quantity - 1 };
    });
    setIncrement(true);
  };

  const handleQuantityChange = async (e) => {
    const value = parseInt?.(e?.target?.value);
    if (!isNaN(value)) {
      if (value >= 1 && value <= maxQuantity) {
        setQuantity((prevData) => {
          return { ...prevData, [productIndex]: value };
        });
      } else if (value === 0) {
        setQuantity((prevData) => {
          return { ...prevData, [productIndex]: 1 };
        });
      } else if (value >= maxQuantity) {
        setQuantity((prevData) => {
          return { ...prevData, [productIndex]: maxQuantity };
        });
      }
    } else {
      setQuantity((prevData) => {
        return { ...prevData, [productIndex]: null };
      });
    }
  };
  const handleUpdateQuantity = async () => {
    if (!quantity) {
      setQuantity((prevData) => {
        return { ...prevData, [productIndex]: 1 };
      });
    }
  };

  const getPriceFromSku = (sku: string) => {
    if (!sku) return null;
    const skuPriceObj = referencedProductsPrice?.find((priceObj: any) => priceObj?.[sku]);
    return skuPriceObj ? skuPriceObj?.[sku]?.price : null;
  };

  useEffect(() => {
    isShowAllVariants ? setMaxVariantsDisplay(sizeOptionsCount) : setMaxVariantsDisplay(9);
  }, [isShowAllVariants]);

  useEffect(() => {
    setIsLoad(true);
    setSelectedSize('');
    const foundVariant = product?.variants?.find((variant) => variant?.picker?.Color === selectedColor);
    if (product?.pickers?.length === 1 && product?.pickers[0]?.name === 'Color') {
      if (foundVariant && foundVariant.isOnStock) {
        setVariant((prevData) => {
          return { ...prevData, [productIndex]: foundVariant };
        });
      }
      setisVariantPicked(true);
    } else {
      variant &&
        setVariant((prevData) => {
          return { ...prevData, [productIndex]: foundVariant };
        });
      !variant &&
        product?.variants?.map((variant, index) => {
          if (variant?.picker?.Color === selectedColor) SETMASTER_VARIANT(index);
        });
      setisVariantPicked(false);
    }

    setIsLoad(false);
  }, [selectedColor]);

  useEffect(() => {
    quantity >= maxQuantity ? setIncrement(false) : setIncrement(true);
    quantity <= 1 ? setDecrement(false) : setDecrement(true);
  }, [quantity, maxQuantity]);

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  useEffect(() => {
    const tempMaxQuantity = variant?.attributes?.maxQty;
    if (!tempMaxQuantity) {
      if (productMaxQty) {
        setMaxQuantity(productMaxQty);
      }
    } else {
      setMaxQuantity(tempMaxQuantity);
    }
    setQuantity((prevData) => {
      return { ...prevData, [productIndex]: 1 };
    });
    setErrorMessage(null);
  }, [variant]);

  useEffect(() => {
    setIsLoad(true);
    if (product?.variants?.length === 1) {
      setisVariantPicked(true);
      setVariant((prevData) => {
        return { ...prevData, [productIndex]: product?.variants?.[MASTER_VARIANT] };
      });
    } else {
      setisVariantPicked(true);
      const variant = product?.variants?.find((entry) => entry?.isOnStock);
      if (variant)
        setVariant((prevData) => {
          return { ...prevData, [productIndex]: variant };
        });
    }

    setQuantity((prevData) => {
      return { ...prevData, [productIndex]: 1 };
    });

    const sizeOptionsCount = product?.pickers
      ?.filter((picker) => picker?.name !== PICKER_COLOR)
      .map((picker) => picker?.values?.length)?.[0];
    setSizeOptionsCount(sizeOptionsCount);
    setIsLoad(false);
  }, []);

  return (
    <section className="gap-x-4 py-5 md:flex" role="region" aria-live="polite">
      {isLoad && <Loader />}
      <section className="flex justify-center">
        <div className="max-h-full max-w-full md:h-[200px] md:w-[200px]">
          <Image src={variant?.images?.[0] || product?.variants?.[MASTER_VARIANT]?.images?.[0]} alt="Product Variant" />
        </div>
      </section>
      <section>
        <div className="mt-3 text-2xl font-normal leading-9 text-[#717171] md:mt-0" aria-label="Product Name">
          {product?.name}
        </div>
        <div className="mt-3 lg:flex">
          <section className="w-fit">
            <div className="text-xl font-normal leading-9 text-gray-900 lg:w-[270px]" aria-label="Product Price">
              {CurrencyHelpers.formatForCurrency(getPriceFromSku(variant?.sku))}
            </div>
          </section>
          <section>
            <section>
              {errorMessage && (
                <div
                  role="alert"
                  className="font-lato mb-5 flex gap-3 rounded-[4px] border border-[#dd001c] p-4 text-sm font-normal leading-5 text-[#dd001c]"
                >
                  <InfoIcon className="mt-1 shrink-0 rotate-180 fill-[#dd001c]" />
                  <p>{errorMessage}</p>
                </div>
              )}
            </section>
            <section className="flex-wrap gap-x-9 lg:flex">
              <section className={`lg:min-w-[360px] xl:min-w-[470px]`}>
                {product?.pickers?.map((picker) => {
                  return (
                    <>
                      {picker?.name === PICKER_COLOR ? (
                        <>
                          <p className="mt-3 text-base font-semibold leading-6 text-gray-900 md:mt-0">
                            {picker?.name}:{' '}
                            <span className="font-normal" aria-label="Picker Name">
                              {variant?.picker?.[picker?.name]}
                            </span>
                          </p>
                          <div className="my-3 flex w-full flex-wrap gap-3">
                            {picker?.values.map((value, valueIndex) => {
                              let isEnable = true;
                              if (product?.pickers?.length === 1 && product?.pickers[0]?.name === 'Color') {
                                const variant = product?.variants?.find((variant) => variant?.picker?.Color === value);
                                isEnable = variant?.isOnStock;
                              }
                              return (
                                <React.Fragment key={valueIndex}>
                                  <button
                                    key={value}
                                    className={`h-[42px] w-[42px] rounded-full ${isEnable ? '' : 'cursor-not-allowed'}`}
                                    disabled={!isEnable}
                                    onClick={() => {
                                      setSelectedColor(value);
                                    }}
                                    type="button"
                                  >
                                    {Object.keys(product?.swatches || {}).length > 0 ? (
                                      <Image
                                        className={`h-full w-full rounded-full ${
                                          selectedColor === value ? 'border-2 border-blue-900' : 'border'
                                        }`}
                                        src={product?.swatches[value]}
                                        alt="Product Swatch"
                                      />
                                    ) : (
                                      <div
                                        title={value}
                                        style={{ backgroundColor: value }}
                                        className={`h-full w-full rounded-full ${
                                          selectedColor === value ? 'border-2 border-blue-900' : 'border'
                                        }`}
                                      ></div>
                                    )}
                                  </button>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <p
                            key={picker}
                            className="mt-3 text-base font-semibold leading-6 text-gray-900 md:mt-0"
                            aria-label="Picker Name"
                          >
                            {picker?.name}: <span className="font-normal">{variant?.picker?.[picker?.name]}</span>
                          </p>
                          <div className="my-3 grid w-full grid-cols-3 gap-4">
                            {picker?.values?.slice(0, maxVariantsDisplay)?.map((value, valueIndex) => {
                              let isSizeAvailable = false;
                              if (selectedColor) {
                                const variantFound = product?.variants?.find(
                                  (variant) =>
                                    variant?.picker?.[picker?.name] === value &&
                                    variant?.picker?.Color === selectedColor,
                                );
                                isSizeAvailable = variantFound ? variantFound?.isOnStock : false;
                              } else {
                                const variantFound = product?.variants?.find(
                                  (variant) => variant?.picker?.[picker?.name] === value,
                                );
                                isSizeAvailable = variantFound ? variantFound?.isOnStock : false;
                              }
                              return (
                                <React.Fragment key={valueIndex}>
                                  <button
                                    type="button"
                                    key={value}
                                    onClick={() => {
                                      handleChangeVariant(value, picker?.name);
                                    }}
                                    disabled={!isSizeAvailable}
                                    className={`min-h-[36px] min-w-[110px] rounded px-[5px] py-2 text-lg leading-[19px] xl:w-[145px] ${
                                      variant && isVaraintPicked && variant?.picker?.[picker?.name] === value
                                        ? 'bg-blue-900 text-white hover:bg-[#3370A1]'
                                        : 'text-blue-900 hover:bg-[#E5F1FB]'
                                    } ${
                                      !isSizeAvailable
                                        ? 'cursor-not-allowed border-2 border-dashed border-[#BEBEBE] text-[#BEBEBE]'
                                        : 'border border-blue-900'
                                    }`}
                                  >
                                    {value}
                                  </button>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
                {sizeOptionsCount > 9 && (
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        setIsShowAllVariants(!isShowAllVariants);
                      }}
                      className="flex items-center justify-center gap-x-1 text-blue-900"
                    >
                      <span className="text-base font-semibold">
                        {isShowAllVariants
                          ? formatMessage({ id: 'showFewer', defaultMessage: 'Show Fewer' })
                          : formatMessage({ id: 'showMore', defaultMessage: 'Show More' })}
                      </span>{' '}
                      <BsCaretDownFill
                        className={`h-[14px] w-[14px] ${isShowAllVariants ? 'rotate-180' : 'rotate-0'}`}
                      />
                    </button>
                  </div>
                )}
              </section>
              <section className="">
                <p className="mt-3 text-base font-semibold leading-6 text-gray-900 md:mt-0" aria-label="Quantity">
                  {formatMessage({ id: 'quantity', defaultMessage: 'Quantity:' })}
                </p>
                <div className="flex justify-center md:block" role="group" aria-labelledby="quantity">
                  <div className="my-3 flex gap-3">
                    <button
                      type="button"
                      className={`flex h-9 w-10 items-center justify-center rounded border border-gray-600 text-blue-900 ${
                        !isDecrement ? 'cursor-not-allowed bg-[#EBEBEB]' : ''
                      }`}
                      onClick={handleQuantityDecrease}
                      disabled={!isDecrement}
                      aria-label="Decrease Quantity"
                    >
                      <MdOutlineRemove className={`${!isDecrement ? 'opacity-25' : ''}`} />
                    </button>
                    <label htmlFor="quantity" className="sr-only">
                      Quantity
                    </label>
                    <input
                      type="text"
                      className="font-lato flex h-9 w-12 items-center justify-center rounded-[3px] border-[1px] border-solid border-[#999999] bg-[#FFFFFF] text-center text-sm text-[#333333]"
                      value={quantity}
                      min={1}
                      id="quantity"
                      max={maxQuantity}
                      onChange={handleQuantityChange}
                      onBlur={handleUpdateQuantity}
                      aria-label="Quantity Input"
                      aria-live="assertive"
                    />
                    <button
                      type="button"
                      className={`flex h-9 w-10 items-center justify-center rounded border border-gray-600 text-blue-900 ${
                        !isIncrement ? 'cursor-not-allowed bg-[#EBEBEB]' : ''
                      }`}
                      onClick={handleQuantityIncrease}
                      disabled={!isIncrement}
                      aria-label="Increase Quantity"
                    >
                      <MdOutlineAdd className={`${!isIncrement ? 'opacity-25' : ''}`} />
                    </button>
                  </div>
                </div>
                <div className="flex justify-center md:block">
                  <button
                    type="button"
                    className={`h-9 w-full rounded border bg-[#3370A1] px-3 text-center text-white md:w-[118px] ${
                      !variant || !isVaraintPicked || !variant?.isOnStock
                        ? 'cursor-default'
                        : 'cursor-pointer hover:bg-blue-900'
                    }`}
                    onClick={addToCart}
                    disabled={!variant || !isVaraintPicked || !variant?.isOnStock}
                    aria-label={!loading && !added ? 'Add To Cart' : loading ? 'Adding to Cart...' : 'Added to Cart'}
                  >
                    {!loading && !added && formatMessage({ id: 'addToCart', defaultMessage: 'Add To Cart' })}
                    {loading && (
                      <svg
                        className="mx-auto h-6 w-6 animate-spin fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25 25"
                      >
                        <path d="M8,8.5A3.5,3.5,0,1,1,4.5,5,3.5,3.5,0,0,1,8,8.5ZM4.5,14A3.5,3.5,0,1,0,8,17.5,3.5,3.5,0,0,0,4.5,14Zm16-2A3.5,3.5,0,1,0,17,8.5,3.5,3.5,0,0,0,20.5,12Zm0,2A3.5,3.5,0,1,0,24,17.5,3.5,3.5,0,0,0,20.5,14Zm-8,4A3.5,3.5,0,1,0,16,21.5,3.5,3.5,0,0,0,12.5,18Zm0-18A3.5,3.5,0,1,0,16,3.5,3.5,3.5,0,0,0,12.5,0Z" />
                      </svg>
                    )}
                    {!loading && added && (
                      <svg className="mx-auto h-6 w-6 fill-white" viewBox="0 0 80.588 61.158">
                        <path
                          d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611
                     c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374
                     c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z
                     "
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </section>
            </section>
          </section>
        </div>
      </section>
    </section>
  );
};

export default NeedAnythingElse;
