import React from 'react';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';

export default function FilterCMSContents({ content }) {
  return (
    <>
      <section className="p-1">
        <TemplateCMS cmsContent={{ assetID: content }} />
      </section>
    </>
  );
}
