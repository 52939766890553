import React, { useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import SubscriptionServicesModal from '../Modals/SubscriptionServicesModal';

const SubscriptionServices = ({ lineItem, getAllCustomMessages, showEditSubcriptionIcon }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [isCheckout, setIsCheckout] = useState(false);
  const [showServicesModal, setServicesModal] = useState(false);
  const refProducts = lineItem?.variant?.attributes?.productFrequences;

  const selectSubscriptionMessage = getAllCustomMessages?.deliverEvery;
  const [subscriptionDefaultFrequency, setSubscriptionDefaultFrequency] = useState(null);

  function handleShipClick() {
    setServicesModal(true);
    setSubscriptionDefaultFrequency(lineItem?.subscriptionFrequencySettings);
  }

  return (
    <section
      className={`${
        showEditSubcriptionIcon ? 'xl:w-[265px]' : 'xl:w-[657px]'
      } mt-3 block rounded border border-[rgba(153,153,153,0.3)] px-[15px] py-[10px] `}
    >
      <section className="flex pb-1 text-sm">
        <p className="font-bold text-gray-500">
          {formatCartMessage({ id: 'subscriptionSetting', defaultMessage: 'Subscription Setting' })}
        </p>
        {showEditSubcriptionIcon && (
          <button>
            <GoPencil className="my-auto ml-2 text-lg text-blue-900" onClick={handleShipClick} />
          </button>
        )}
      </section>
      <p
        className={`text-affes-base leading-[20px] text-blue-900 ${
          (!lineItem?.subscriptionFrequencySettings || lineItem?.subscriptionFrequencySettings == null) && 'hidden'
        }`}
      >
        {formatCartMessage({ id: 'deliveryEvery', defaultMessage: 'Delivery every' })}{' '}
        {lineItem?.subscriptionFrequencySettings}
      </p>
      {showServicesModal && (
        <SubscriptionServicesModal
          servicesModal={showServicesModal}
          setServicesModal={setServicesModal}
          subscriptionServices={refProducts}
          lineItem={lineItem}
          selectSubscriptionTitle={selectSubscriptionMessage}
          orderId={null}
          isSubscription={false}
          subscriptionDefaultFrequency={subscriptionDefaultFrequency}
        />
      )}
    </section>
  );
};

export default SubscriptionServices;
