import { useEffect, useState } from 'react';
import JsonConvert from './jsonConverter';
import { useFormat } from 'helpers/hooks/useFormat';
const RenderVariantPickers = (data) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [parsedData, setParsedData] = useState([]);
  useEffect(() => {
    if (data?.variant?.picker) {
      setParsedData(data?.variant?.picker);
    }
  }, [data]);
  return (
    <div className="text-sm font-medium">
      {parsedData &&
        Object.keys(parsedData).map((key) => (
          <p className="seprator mr-1" key={key}>
            {key} : {parsedData[key]}
          </p>
        ))}
    </div>
  );
};

export default RenderVariantPickers;
