export function openIframePopup() {
  const iframeWidth = 400; // Width of the popup
  const iframeHeight = 300; // Height of the popup

  // Calculate dimensions for centering the popup
  const left = window.innerWidth / 2 - iframeWidth / 2;
  const top = window.innerHeight / 2 - iframeHeight / 2;

  // Open the popup window with the iframe
  const popupWindow = window.open('', '_blank', `width=${iframeWidth},height=${iframeHeight},left=${left},top=${top}`);

  // Check if the popup was successfully opened
  if (popupWindow) {
    const iframe = document.createElement('iframe');
    iframe.src = '/testing'; // Replace with your desired URL
    iframe.width = iframeWidth.toString();
    iframe.height = iframeHeight.toString();

    // Append the iframe to the popup's body
    popupWindow.document.body.appendChild(iframe);
  } else {
    // Handle cases where the popup is blocked by the browser
    alert('Please allow popups for this site to work correctly.');
  }
}
