import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
interface FlixMediaProps {
  mpn: string;
  config?: any;
}

const FlixMedia: FC<FlixMediaProps> = ({ mpn, config }) => {
  const { script, distributor, inpage, language } = config;
  const router = useRouter();
  useEffect(() => {
    const flixScript = document.createElement('script');
    flixScript.type = 'text/javascript';
    flixScript.src = script;
    flixScript.async = true;
    flixScript.setAttribute('data-flix-distributor', distributor);
    flixScript.setAttribute('data-flix-language', language);
    flixScript.setAttribute('data-flix-mpn', mpn);
    flixScript.setAttribute('data-flix-inpage', inpage);
    document.body.appendChild(flixScript);

    const handleFlixHotspots = (newUrl: string) => {
      const isPdp = typeof newUrl === 'string' && /\/([^\/]+)\/(DSCO\d{10}|\d{7})/.test(newUrl);
      const element = document.getElementById('flix_hotspots');
      if (element && !isPdp) {
        element.remove();
      }
    };
    router.events.on('routeChangeStart', handleFlixHotspots);
    return () => {
      const previousScript = document.getElementById('flixmedia');
      if (previousScript && previousScript?.parentNode) {
        previousScript.parentNode.removeChild(previousScript);
      }
      router.events.off('routeChangeStart', handleFlixHotspots);
    };
  }, []);
  return (
    <div>
      <div id="flix-minsite"></div>
      <div id="flix-inpage"></div>
    </div>
  );
};

export default FlixMedia;
