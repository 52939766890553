import { useRef, useEffect } from 'react';

export const usePreventClickAfterDrag = () => {
  const isDragging = useRef(false);

  useEffect(() => {
    const onMouseMove = () => {
      isDragging.current = true;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const initDragListeners = () => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousedown', initDragListeners);

    const cleanup = () => {
      document.removeEventListener('mousedown', initDragListeners);
    };

    return cleanup;
  }, []);

  const shouldPreventClickOnDrag = () => {
    const wasDragging = isDragging.current;
    isDragging.current = false;
    return wasDragging;
  };

  return shouldPreventClickOnDrag;
};

export default usePreventClickAfterDrag;
