import React, { useEffect } from 'react';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useAccount } from 'frontastic/provider';
import { GA_ERROR_PAGE, GA_PAGE_VIEW } from 'helpers/constants/aafes';

const AssetsCmsTastics = (cmsContent) => {
  const { trackErrorPage, trackCMSPage } = useGATrackUserAction();
  const { account } = useAccount();

  useEffect(() => {
    const trackLoginAndErrorPage = setTimeout(() => {
      if (window.location.pathname === '/not-found') {
        trackErrorPage(GA_ERROR_PAGE, '404', account);
      }
    }, 1000);
    return () => clearTimeout(trackLoginAndErrorPage);
  }, [account]);

  useEffect(() => {
    const trackHomePage = setTimeout(() => {
      if (window.location.pathname === '/' && cmsContent.data.assetID.includes('homepage')) {
        trackCMSPage(GA_PAGE_VIEW, document.title, window.location.href, ['Home Page'], account);
      }
    }, 1000);
    return () => clearTimeout(trackHomePage);
  }, [account]);

  return <>{cmsContent && <TemplateCMS cmsContent={cmsContent?.data} />}</>;
};

export default AssetsCmsTastics;
