import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ProjectConfiguration } from '@Types/ProjectConfiguration';
import OrderCancelModal from 'components/commercetools-ui/account/details/modals/cancelOrderConfirmation';
import Loader from 'components/commercetools-ui/loader/Loader';
import { APO_FPO_CITIES_MATCH, APO_FPO_STATES_MATCH } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import { itemReturnLabel } from 'frontastic/actions/account';
import ReturnItem from '../modals/itemReturn/itemReturn';
const formatDate = (dateString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const date = new Date(dateString.replace(/\s/g, ''));
  const monthIndex = date.getMonth();
  const monthName = months[monthIndex];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day} ${monthName} ${year}`;
};

const formatOrderDate = (dateString) => {
  let date = new Date(`${dateString}Z`);
  date = new Date(date?.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  return `${monthIndex + 1}/${day}/${year}`;
};

const OrderStatusFilter = ({ onChangeFilter, orderDetailsStatusMapping, isVisible }) => {
  const orderDetailsStatusMappingArrayData = Array.from(JSON.parse(orderDetailsStatusMapping));

  return (
    <>
      {isVisible && (
        <select
          onChange={(e) => onChangeFilter(e.target.value)}
          className="select h-full w-full min-w-[190px] items-center whitespace-nowrap rounded-[3px] border border-[rgba(0,0,0,0.20)] p-[10px] pl-[10px] pr-[15px] text-sm leading-[16.8px] text-[#333333] focus:border-none focus:outline-none"
        >
          {orderDetailsStatusMappingArrayData?.map((item: any, id) => (
            <>
              <option key={id} value={item?.value}>
                {item?.label}
              </option>
            </>
          ))}
        </select>
      )}
    </>
  );
};

const OrderGroup = ({
  orderInfo,
  status,
  orders,
  projectConfiguration,
  setCancelStatusMAO,
  orderLine,
  setIsVisible,
  isVisible,
  setToFetchOrderDetails,
}) => {
  const returnExchangeUrl = projectConfiguration?.returnExchangeUrl;
  const bvPreAuthenticatedURL = projectConfiguration?.bvPreAuthenticatedURL;
  const fulfillmentStatusMapping = projectConfiguration?.fulfillmentStatusMapping
    ?.slice(1, -1)
    ?.replace(/'/g, '')
    ?.split(',');
  const lineItemReturnStatusMapping = projectConfiguration?.lineItemReturnStatusMapping
    ?.slice(1, -1)
    ?.replace(/'/g, '')
    ?.split(',');
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [itemReturnModal, setItemReturnModal] = useState(false);
  const [returnOrder, setReturnOrder] = useState(null);
  const [returnId, setReturnId] = useState('');

  function showModal(order) {
    setItemReturnModal(true);
    setReturnOrder(order);
  }

  function closeModal() {
    setItemReturnModal(false);
  }

  const [storesData, setStoresData] = useState(null);

  if (orders[0]?.lineItemStatus === 'Cancelled') {
    setCancelStatusMAO(true);
  }
  const [loading, setLoading] = useState(false);
  const handleReturnLabel = async (returnLabelDocId) => {
    setLoading(true);
    await itemReturnLabel(returnLabelDocId)
      .then((response) => {
        if (response) {
          const base64String = response?.orderReturnLabel;
          const buffer = Buffer.from(base64String, 'base64');
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);
          window.open(pdfUrl, '_blank');
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const checkApoFpo = (orderLine) => {
    let isApoFpo = false;
    const selectedFullAddress = orderLine?.shipToAddress?.Address;
    if (selectedFullAddress?.City != null || selectedFullAddress?.State != null) {
      if (
        APO_FPO_STATES_MATCH.includes(selectedFullAddress?.State) ||
        APO_FPO_CITIES_MATCH.find((apoCity) =>
          new RegExp('\\b' + apoCity + '\\b', 'i').test(selectedFullAddress?.City.toLowerCase()),
        )
      ) {
        return (isApoFpo = true);
      }
    }
    return isApoFpo;
  };

  function ReturnMessage({ returnExchangeUrl }) {
    return (
      isVisible && (
        <p className="returnPolicy text-[14px] font-normal leading-[16.8px] text-[#333333]">
          <span>
            {formatAccountMessage({
              id: 'item.ineligible',
              defaultMessage: 'Item is ineligible for return ',
            })}
          </span>
          <a href={returnExchangeUrl} className="text-[#043C63]">
            {formatAccountMessage({
              id: 'return.policy',
              defaultMessage: 'Return Policy',
            })}
          </a>
        </p>
      )
    );
  }
  return (
    <section>
      {loading && <Loader />}
      <ul>
        <section>
          {orders?.length > 0 ? (
            <ul className="mb-[15px] grid gap-[15px]">
              {orders?.map((order, id) => (
                <li key={id} className="rounded border border-[rgba(0,0,0,0.20)]">
                  <section className="rounded-t border-b border-[rgba(204,204,204,1)] bg-[#EDEDED] px-[15px] py-[10px]">
                    <p className="max-w-[294px] lg:max-w-full">
                      <span className="text-[14px] font-bold leading-5 text-[#333333]">
                        {formatAccountMessage({
                          id: 'order.status',
                          defaultMessage: 'Order Status: ',
                        })}
                        {status}{' '}
                      </span>
                      {order?.productType !== 'EGIFT_CARD' && status == 'Delivered' && (
                        <>
                          {order?.deliveredDate ? (
                            <span className="text-[14px] font-bold leading-5 text-[#333333]">
                              {'on'}
                              <time className="ml-1 text-[#333333]" dateTime={order?.deliveredDate.toString()}>
                                {formatDate(order?.deliveredDate.toString(0, 10))}
                              </time>
                            </span>
                          ) : (
                            <span className="text-[14px] font-bold leading-5 text-[#333333]">
                              {formatAccountMessage({
                                id: 'order.no',
                                defaultMessage: 'on',
                              })}{' '}
                              {formatAccountMessage({
                                id: 'notAvailable',
                                defaultMessage: 'N/A',
                              })}
                            </span>
                          )}
                        </>
                      )}
                      {order?.productType !== 'EGIFT_CARD' && status == 'Shipped' && (
                        <>
                          {order?.shippedDate ? (
                            <span className="text-[14px] font-bold leading-5 text-[#333333]">
                              {'on'}
                              <time className="ml-1 text-[#333333]" dateTime={order?.shippedDate.toString()}>
                                {formatDate(order?.shippedDate.toString(0, 10))}
                              </time>
                            </span>
                          ) : (
                            <span className="text-[14px] font-bold leading-5 text-[#333333]">
                              {formatAccountMessage({
                                id: 'order.no',
                                defaultMessage: 'on',
                              })}{' '}
                              {formatAccountMessage({
                                id: 'notAvailable',
                                defaultMessage: 'N/A',
                              })}
                            </span>
                          )}

                          <span className="text-[14px] font-normal leading-5 text-[#333333]">
                            {', Tracking - '}
                            <a
                              href={`${order?.carrierTrackingPageLink}${order?.trackingNumber}`}
                              className="text-[#043C63]"
                            >
                              {order?.trackingNumber}
                            </a>
                          </span>
                        </>
                      )}
                    </p>
                  </section>
                  <section>
                    <ul className="divide-y px-4">
                      <li
                        key={id}
                        className="lg:pt[15px] orderdetail-itemcontainer my-5 grid grid-cols-1 justify-between  gap-5 pt-5 first:pt-0"
                      >
                        <section className="grid gap-4">
                          <section className="flex gap-[14px] md:gap-[15px]">
                            <section className="shrink-0">
                              <Link href={`product/p/${order?.itemId}`}>
                                <img
                                  alt="Product Image"
                                  height={90}
                                  width={90}
                                  className="h-[75px] w-[75px] cursor-pointer md:h-[105px] md:w-[105px]"
                                  src={order?.smallImageURI}
                                />
                              </Link>
                            </section>
                            <section>
                              <section>
                                <a
                                  href={`product/p/${order?.itemId}`}
                                  className="mb-[6px] max-w-[324px] text-[14px] font-bold leading-5 text-[#043C63]"
                                >
                                  {order?.itemShortDescription}
                                </a>
                                <p className="my-[6px] text-[14px] font-medium leading-5 text-[#666]">
                                  {formatAccountMessage({
                                    id: 'item.id',
                                    defaultMessage: 'Item #: ',
                                  })}
                                  {order?.isMarketplaceItem ? (
                                    <span>{order?.mpItemId}</span>
                                  ) : (
                                    <span>{order?.itemId}</span>
                                  )}
                                </p>
                                <section className="hidden lg:block">
                                  <p className="text-[14px] font-medium leading-5 text-[#666]">
                                    {formatAccountMessage({
                                      id: 'Qty.count',
                                      defaultMessage: 'Qty: ',
                                    })}
                                    <span className="text-[#333]">{order?.quantity}</span>
                                  </p>
                                  <p className="pt-[15px] text-[14px] font-bold leading-[16.8px] text-[#333]">
                                    {`$`}
                                    {order?.orderLineTotal}
                                  </p>
                                </section>

                                {order?.additionalServices && (
                                  <section className="mt-3  block rounded border border-[rgba(153,153,153,0.3)] px-[15px] py-[10px]">
                                    <section className="grid gap-2">
                                      <section className="flex pb-1 text-sm">
                                        <p className="font-bold text-gray-500">
                                          {formatAccountMessage({
                                            id: '.count',
                                            defaultMessage: 'Additional Services:',
                                          })}
                                        </p>
                                      </section>
                                      {order?.additionalServices?.map((additionalService, index) => (
                                        <section className="flex text-sm" key={index}>
                                          <img
                                            className="mr-2 h-9 w-9"
                                            alt="Additinal Service Icon"
                                            src={order?.smallImageURI}
                                          />
                                          <div className="grid w-full grid-cols-5 gap-[32px]">
                                            <div className="col-span-3">
                                              <p className="text-affes-base text-blue-900">
                                                {additionalService?.itemShortDescription}
                                              </p>
                                            </div>
                                            <div className="col-span-2 text-right text-affes-base text-blue-900">
                                              <div className="inline">
                                                <p className="text-[14px] font-bold leading-[16.8px] text-[#333]">
                                                  {`$`}
                                                  {additionalService?.orderLineTotal}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      ))}
                                    </section>
                                  </section>
                                )}
                              </section>
                            </section>
                          </section>

                          <section className="border-[rgba(0,0,0,0.20);] flex items-center justify-between border-b pb-[14px] pl-[20px] lg:hidden">
                            <p className="text-[14px] font-medium leading-5 text-[#666]">
                              {formatAccountMessage({
                                id: 'Qty.count',
                                defaultMessage: 'Qty: ',
                              })}
                              <span className="text-[14px] font-medium leading-5 text-[#333]">{order?.quantity}</span>
                            </p>
                            <p className="text-[14px] font-bold text-[#333]">
                              {`$`}
                              {order?.orderLineTotal}
                            </p>
                          </section>
                        </section>

                        <section className="grid content-start gap-[10px]">
                          {order?.lineItemStatus !== 'Returned' && (
                            <section className="content-start gap-[10px]">
                              {order?.deliveryMethodId == 'ShipToAddress' ||
                              order?.shippingMethodId === 'FirstClassMail' ? (
                                <section>
                                  <p className="mb-2 text-[14px] font-bold leading-5 text-[#666]">
                                    {formatAccountMessage({
                                      id: 'shipping.details',
                                      defaultMessage: 'Shipping Details: ',
                                    })}
                                  </p>
                                  <section className="grid gap-1 text-[14px] font-normal leading-5 text-[#333333]">
                                    {order?.shippingMethodId !== 'FirstClassMail' ? (
                                      <section>
                                        <p>
                                          {`${order?.shipToAddress?.Address?.Address1} ${','}
                                          ${order?.shipToAddress?.Address?.City}`}
                                        </p>
                                        <p>
                                          {`${order?.shipToAddress?.Address?.State} ${','}
                                          ${order?.shipToAddress?.Address?.PostalCode}`}
                                        </p>

                                        <p className="mt-2 text-[14px] font-normal leading-5 text-[#333333]">
                                          {order?.shippingMethodId}
                                          {` `}
                                          {formatAccountMessage({
                                            id: 'shipping',
                                            defaultMessage: 'Shipping',
                                          })}
                                        </p>
                                      </section>
                                    ) : (
                                      <p>
                                        {formatCommonMessage({
                                          id: 'electronicDelivery',
                                          defaultMessage: 'Electronic Delivery',
                                        })}
                                      </p>
                                    )}
                                  </section>
                                </section>
                              ) : (
                                <section>
                                  <p className="mb-2 text-[14px] font-bold leading-5 text-[#666]">
                                    {formatAccountMessage({
                                      id: 'pickup.details',
                                      defaultMessage: 'Pickup Details: ',
                                    })}
                                  </p>
                                  <section className="grid gap-1 text-[14px] font-normal leading-5 text-[#333333]">
                                    {order?.pickupDetails && (
                                      <section>
                                        <p>{order?.pickupDetails?.name}</p>
                                        <p>
                                          {`${order?.pickupDetails?.address?.streetName} ${','}
                                              ${order?.pickupDetails?.address?.city}`}
                                        </p>
                                        <p>
                                          {`${order?.pickupDetails?.address?.state} ${','}
                                            ${order?.pickupDetails?.address?.postalCode}`}
                                        </p>
                                      </section>
                                    )}
                                  </section>
                                </section>
                              )}
                            </section>
                          )}
                        </section>

                        <section className="flex flex-col items-start gap-[14px] self-auto text-left lg:items-end lg:text-right ">
                          {order?.stockAvailability !== 'Instock' && isVisible && (
                            <p className="text-[14px] font-bold leading-[16.8px] text-[#DA0F0F]">
                              {formatAccountMessage({
                                id: 'out.stock',
                                defaultMessage: 'Out of Stock',
                              })}
                            </p>
                          )}
                          {order?.stockAvailability === 'Instock' && isVisible && (
                            <Link href={`product/p/${order?.itemId}`}>
                              <button
                                type="button"
                                className="returnBuyButton w-full rounded-[3px] bg-blue-900 py-[10px] text-[14px] font-bold leading-[16.8px] text-white lg:w-[214px] lg:max-w-[145px]"
                                aria-label="Button for Buy Again"
                              >
                                {formatAccountMessage({
                                  id: 'buy.again',
                                  defaultMessage: 'Buy Again',
                                })}
                              </button>
                            </Link>
                          )}

                          {!checkApoFpo(order) &&
                          (fulfillmentStatusMapping.includes(order.fulfillmentStatus) ||
                            lineItemReturnStatusMapping.includes(order.lineItemStatus)) &&
                          order?.isReturnable === true ? (
                            isVisible && (
                              <button
                                type="button"
                                className="returnItemButton w-full rounded-[3px] border border-[#043C63] py-[10px] text-[14px] font-bold leading-[16.8px] text-[#043C63] lg:w-[214px] lg:max-w-[145px]"
                                aria-label="Button for Return item"
                                onClick={() => showModal(order)}
                              >
                                {formatAccountMessage({
                                  id: 'return.item',
                                  defaultMessage: 'Return Item',
                                })}
                              </button>
                            )
                          ) : (
                            <></>
                          )}

                          {order?.lineItemStatus !== 'Cancelled' && isVisible && (
                            <a
                              className="returnWriteButton w-full rounded-[3px]  border border-[#043C63] py-[10px] text-center text-[14px] font-bold leading-[16.8px] text-[#043C63] lg:w-[214px] lg:max-w-[145px]"
                              href={`https:${bvPreAuthenticatedURL}${order?.itemId}`}
                              rel="noopener noreferrer"
                            >
                              {formatAccountMessage({
                                id: 'write.areview',
                                defaultMessage: 'Write a Review',
                              })}
                            </a>
                          )}

                          {!checkApoFpo(order) &&
                          order?.isReturnable === false &&
                          (fulfillmentStatusMapping.includes(order.fulfillmentStatus) ||
                            lineItemReturnStatusMapping.includes(order.lineItemStatus)) ? (
                            <ReturnMessage returnExchangeUrl={returnExchangeUrl} />
                          ) : checkApoFpo(order) ? (
                            <ReturnMessage returnExchangeUrl={returnExchangeUrl} />
                          ) : null}

                          {order?.returnIds?.length > 0 && (
                            <section className="returnText">
                              {isVisible && (
                                <>
                                  <p className="text-[14px] font-normal leading-[16.8px] text-[#333333]">
                                    {formatAccountMessage({
                                      id: 'Previously.returns',
                                      defaultMessage: 'Previously initiated returns. Returns fee may be applicable',
                                    })}
                                  </p>
                                  {order?.returnLabelIds?.map((returns, index) => (
                                    <div key={index}>
                                      <p className="text-[14px] font-normal leading-[16.8px] text-[#333333]">
                                        <span>{returns?.OrderId} </span>

                                        <button
                                          onClick={() => {
                                            handleReturnLabel(returns?.ReturnLabelDocId);
                                          }}
                                          className="text-[#043C63]"
                                        >
                                          {' '}
                                          <span> - </span>
                                          {formatAccountMessage({
                                            id: 'printreturn.label',
                                            defaultMessage: 'Print Return Label',
                                          })}
                                        </button>
                                      </p>
                                    </div>
                                  ))}
                                </>
                              )}
                            </section>
                          )}
                        </section>
                      </li>
                    </ul>
                  </section>
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </section>
      </ul>
      {itemReturnModal && (
        <ReturnItem
          itemReturnModal={itemReturnModal}
          setItemReturnModal={setItemReturnModal}
          closeModal={closeModal}
          returnOrder={returnOrder}
          setReturnId={setReturnId}
          setIsVisible={setIsVisible}
          setToFetchOrderDetails={setToFetchOrderDetails}
          orderInfo={orderInfo}
        />
      )}
    </section>
  );
};

function OrderDetail() {
  const router: any = useRouter();
  const { getOrderDetail } = useAccount();
  const [orderInfo, setOrderInfo] = useState([]);
  const [orderLine, setOrderLine] = useState([]);
  const [filterStatus, setFilterStatus] = useState('All');
  const [orderError, setOrderError] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [loading, setLoading] = useState(true);
  const [showOrderCancelModal, setShowOrderCancelModal] = useState(false);
  const [cancelStatusMAO, setCancelStatusMAO] = useState(false);
  const [toFetchOrderDetails, setToFetchOrderDetails] = useState(false);

  const getOrderData = async () => {
    const data = await getOrderDetail(router?.query?.id);
    if (data?.errorCode !== 500) {
      const consolidatedOrderLines = consolidateOrderLines(data?.maoOrderDetails?.orderLine);
      setOrderInfo(data?.maoOrderDetails?.orderInfo);
      setOrderLine(consolidatedOrderLines);
    } else if (data?.errorCode === 500) {
      setOrderError(data?.body);
    }
    setLoading(false);
  };

  const consolidateOrderLines = (orderLines) => {
    const consolidatedOrderLines = [];

    orderLines?.forEach((orderLine) => {
      if (orderLine.parentLineId) {
        const parentOrderLine = consolidatedOrderLines.find((line) => line.orderLineId === orderLine.parentLineId);
        if (parentOrderLine) {
          // If the parent order line already has an additionalServices array, add the current order line to it
          if (parentOrderLine.additionalServices) {
            parentOrderLine.additionalServices.push(orderLine);
          } else {
            // If this is the first additional service for the parent order line, create the additionalServices array
            parentOrderLine.additionalServices = [orderLine];
          }
        } else {
          // If it's not an additional service, it's a standalone line item
          consolidatedOrderLines.push(orderLine);
        }
      } else {
        // If it's not an additional service, it's a standalone line item
        consolidatedOrderLines.push(orderLine);
      }
    });

    return consolidatedOrderLines;
  };

  useEffect(() => {
    getOrderData();
    setToFetchOrderDetails(false);
  }, [toFetchOrderDetails]);

  const filterOrders = (status) => {
    setFilterStatus(status);
  };

  const filteredOrders =
    filterStatus === 'All'
      ? orderLine?.filter((order) => order)
      : orderLine.filter((order) => order.lineItemStatus === filterStatus);

  const uniqueStatuses = [...new Set(filteredOrders.map((order) => order.lineItemStatus))];
  const orderGroups = uniqueStatuses.map((status) => {
    return {
      status,
      orders: filteredOrders.filter((order) => order.lineItemStatus === status),
    };
  });
  const { studioConfig: projectConfiguration } = useCart();

  const gracePeriod = projectConfiguration?.gracePeriod;
  const orderDetailsStatusMapping = projectConfiguration?.orderDetailsStatusMapping;
  const innerRef = useRef<HTMLInputElement>(null);
  const [cancelOrderInfo, setCancelOrderInfo] = useState(false);
  const leading_zeros = (time) => {
    return `${Math.abs(time) < 10 ? '0' : ''}${time}`;
  };

  function convertCDTToLocal(cdtTimeString) {
    try {
      const date = new Date(cdtTimeString.replace(' ', 'T'));
      // Determine if the date is within the CDT range or CST range
      const year = date.getUTCFullYear();
      const cdtStart = new Date(Date.UTC(year, 2, 8));
      const cdtEnd = new Date(Date.UTC(year, 10, 1));
      cdtStart.setUTCDate(8 + ((7 - cdtStart.getUTCDay()) % 7));
      cdtEnd.setUTCDate(1 + ((7 - cdtEnd.getUTCDay()) % 7));
      const isCDT = date >= cdtStart && date < cdtEnd;
      // Set the correct offset based on whether it’s CDT or CST
      const offset = isCDT ? '-05:00' : '-06:00';
      const cdtTime = new Date(cdtTimeString.replace(' ', 'T') + offset);
      const localTime = cdtTime.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3,
        hour12: false,
        timeZoneName: 'short',
      });
      const localOrderTime = new Date(localTime);
      return localOrderTime;
    } catch (error) {
      console.error('Error converting time:', error);
      return null;
    }
  }
  useEffect(() => {
    if (orderInfo.length > 0) {
      const countDownDate = convertCDTToLocal(orderInfo[0]?.orderDate);
      countDownDate.setMinutes(countDownDate.getMinutes() + gracePeriod);
      if (countDownDate) {
        const timer = setInterval(() => {
          const currentTime = new Date().getTime();
          const timeLeft = countDownDate.getTime() - currentTime;
          const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setCancelOrderInfo(true);
          if (innerRef.current) {
            innerRef.current.innerHTML = `${leading_zeros(minutes)}:${leading_zeros(seconds)}`;
          }
          if (timeLeft < 0) {
            setCancelOrderInfo(false);
            clearInterval(timer);
          }
        }, 1000);
      }
    }
  }, [orderInfo]);

  useEffect(() => {
    sessionStorage.setItem('isRestrictGetOrders', 'true');
  }, []);

  return (
    <>
      {!loading && (
        <section className="lg:pt-[23px]" id="orderDetails">
          <section className="mx-auto max-w-[1147px]">
            <section className="mb-3 flex flex-col items-start gap-[15px] md:flex-row md:gap-[20px] lg:items-center lg:justify-between">
              <section>
                <h1 className="mb-[10px] text-[21px] font-bold leading-[25.2px] text-[#333333]">
                  {formatAccountMessage({
                    id: 'order.details',
                    defaultMessage: 'Order Details',
                  })}
                </h1>
                {orderInfo?.map((order, id) => (
                  <ul key={id} className="flex flex-wrap items-center gap-x-[10px] gap-y-2">
                    <li className="flex flex-wrap">
                      <p className="mr-1 text-[14px] font-normal leading-[16.8px] text-[#333333]">
                        {formatAccountMessage({
                          id: 'order.id',
                          defaultMessage: 'Order ID: ',
                        })}
                      </p>
                      <p className="text-[14px] font-bold leading-[16.8px] text-[#333333]">{order?.orderId}</p>
                    </li>
                    <li>|</li>
                    <li className="flex flex-wrap">
                      <p className="mr-1 text-[14px] font-normal leading-[16.8px] text-[#333333]">
                        {formatAccountMessage({
                          id: 'order.date',
                          defaultMessage: 'Order Date: ',
                        })}
                      </p>
                      <p className="text-[14px] font-bold leading-[16.8px] text-[#333333]">
                        <time className="text-[#333333]" dateTime={order?.orderDate.toString()}>
                          {formatOrderDate(order?.orderDate.toString(0, 10))}
                        </time>
                      </p>
                    </li>
                    <li>|</li>
                    <li className="flex flex-wrap">
                      <p className="mr-1 text-[14px] font-normal leading-[16.8px] text-[#333333]">
                        {formatAccountMessage({
                          id: 'items.title',
                          defaultMessage: 'Items: ',
                        })}
                      </p>
                      <p className="text-[14px] font-bold leading-[16.8px] text-[#333333]">{order?.orderLineCount}</p>
                    </li>
                    <li>|</li>
                    <li className="flex flex-wrap">
                      <p className="mr-1 text-[14px] font-normal leading-[16.8px] text-[#333333]">
                        {formatAccountMessage({
                          id: 'order.total',
                          defaultMessage: 'Order Total: ',
                        })}
                      </p>
                      <p className="text-[14px] font-bold leading-[16.8px] text-[#333333]">
                        {`$`}
                        {order?.orderTotal}
                      </p>
                    </li>
                  </ul>
                ))}
              </section>
              <section>
                <OrderStatusFilter
                  onChangeFilter={filterOrders}
                  orderDetailsStatusMapping={orderDetailsStatusMapping}
                  isVisible={isVisible}
                />
              </section>
            </section>

            {orderInfo?.map((_, id) => (
              <section key={id}>
                <section className="mb-6">
                  {cancelOrderInfo && !cancelStatusMAO && (
                    <div className="text-[14px] font-normal leading-[16.8px] text-[#333333]">
                      {formatAccountMessage({
                        id: 'order.event.text',
                        defaultMessage: 'In the event you need to ',
                      })}
                      <button
                        className="text-[#043C63]"
                        onClick={() => {
                          setShowOrderCancelModal(true);
                        }}
                      >
                        {formatAccountMessage({
                          id: 'cancel.your.order',
                          defaultMessage: 'cancel your order',
                        })}
                      </button>
                      {` `}
                      <span>
                        {formatAccountMessage({
                          id: 'youhave',
                          defaultMessage: ' you have ',
                        })}
                        {` `}
                        <span ref={innerRef}></span>
                        {` `}
                        {formatAccountMessage({
                          id: 'minremaining',
                          defaultMessage: ' minutes remaining.',
                        })}
                      </span>
                    </div>
                  )}
                </section>
              </section>
            ))}
            {orderError !== '' && (
              <p className="mb-5 rounded-sm text-center text-xl font-bold text-red-900">{orderError}</p>
            )}
            {orderGroups.map((group) => (
              <OrderGroup
                key={group.status}
                status={group.status}
                orders={group.orders}
                orderLine={orderLine}
                orderInfo={orderInfo}
                projectConfiguration={projectConfiguration}
                setCancelStatusMAO={setCancelStatusMAO}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                setToFetchOrderDetails={setToFetchOrderDetails}
              />
            ))}
          </section>
        </section>
      )}
      {showOrderCancelModal && (
        <OrderCancelModal
          data={orderInfo}
          getOrderData={getOrderData}
          closeModal={() => {
            setShowOrderCancelModal(false);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default OrderDetail;
