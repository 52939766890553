import React from 'react';
import { openIframePopup } from 'helpers/utils/maoChatIfram';
import BackToTop from './backToTop';
import CopyrightSection from './copyrightSection';
import Newsletter from './newsLetter';
import Services from './services';
function NewFooter({ footerColumns, otherLinks, dgDetails }) {
  return (
    <section className="relative">
      <Newsletter otherLinks={otherLinks} />
      <Services footerColumns={footerColumns} />
      <CopyrightSection otherLinks={otherLinks} dgDetails={dgDetails} />
      <button onClick={openIframePopup} className="hidden rounded border px-9 pt-2">
        Chat
      </button>
      <BackToTop />
    </section>
  );
}

export default NewFooter;
