import React, { useState, useEffect, useMemo } from 'react';

interface QuantitySelectorProps {
  quantity: number;
  minQuantity?: number;
  maxQuantity?: number;
  onChange: (value: number) => void;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  quantity: propQuantity,
  minQuantity = 1,
  maxQuantity,
  onChange,
}) => {
  const [quantity, setQuantity] = useState(propQuantity);

  useEffect(() => {
    onChange(quantity);
  }, [quantity]);

  const handleIncrement = () => {
    if (!maxQuantity || quantity < maxQuantity) {
      setQuantity((prev) => prev + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > minQuantity) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    if (rawValue === '') {
      setQuantity(null);
    } else {
      const value = Number(rawValue);
      const clampedValue = Math.min(Math.max(value, minQuantity), maxQuantity || value);
      setQuantity(clampedValue);
    }
  };

  const handleBlur = () => {
    if (quantity < minQuantity || !quantity || quantity === -1) {
      setQuantity(minQuantity);
    } else if (maxQuantity && quantity > maxQuantity) {
      setQuantity(maxQuantity);
    }
  };

  const decrementButtonStyles = useMemo(() => {
    return `flex h-[43px] w-[42px] items-center justify-center rounded-[3px] border-[1px] border-solid ${
      quantity <= minQuantity ? 'bg-[#EEEEEE] text-[#1c1b1f4d]' : 'bg-[#FFFFFF] text-[#043C63]'
    }`;
  }, [quantity, minQuantity]);

  const incrementButtonStyles = useMemo(() => {
    return `flex h-[43px] w-[42px] items-center justify-center rounded-[3px] border-[1px] border-solid ${
      maxQuantity && quantity >= maxQuantity ? 'bg-[#EEEEEE] text-[#1c1b1f4d]' : 'bg-[#FFFFFF] text-[#043C63]'
    }`;
  }, [quantity, maxQuantity]);

  return (
    <section className="flex gap-x-2.5">
      <button
        type="button"
        onClick={handleDecrement}
        className={decrementButtonStyles}
        disabled={quantity <= minQuantity}
        aria-label="Decrease Quantity"
      >
        <svg width="14" height="2" viewBox="0 0 14 2" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 2V0H14V2H0Z" fill="currentcolor" />
        </svg>
      </button>
      <label htmlFor="quantity" className="sr-only">
        Quantity
      </label>
      <input
        type="number"
        className="no-spinners font-lato h-[43px] w-[42px] rounded-[3px] border-[1px] border-solid border-[#999999] bg-[#FFFFFF] px-0 text-center text-sm text-[#333333] ring-transparent"
        id="quantity"
        value={quantity}
        min={minQuantity}
        max={maxQuantity}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        aria-label="Quantity"
        aria-describedby="quantity-description"
        aria-live="polite"
      />
      <button
        type="button"
        onClick={handleIncrement}
        className={incrementButtonStyles}
        disabled={!!(maxQuantity && quantity >= maxQuantity)}
        aria-label="Increase Quantity"
      >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="currentcolor" />
        </svg>
      </button>
    </section>
  );
};

export default QuantitySelector;
