import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import ClaimForm from './claimForm';
import ItemReturnForm from './itemReturnForm';
function ItemReturn({
  itemReturnModal,
  setItemReturnModal,
  closeModal,
  returnOrder,
  setReturnId,
  setIsVisible,
  setToFetchOrderDetails,
  orderInfo,
}) {
  return (
    <>
      <Transition appear show={itemReturnModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[80%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto  rounded bg-white pb-4 dark:bg-primary-200 md:mt-9 lg:mt-3 xl:w-[600px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="px-6 pt-6 text-left">
                      <section className="modalHeader relative">
                        <div className="mb-6">
                          <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">Return Item</h3>

                          <button className="absolute right-2 top-0 " onClick={closeModal}>
                            <GrClose />
                          </button>
                        </div>
                      </section>

                      <ItemReturnForm
                        returnOrder={returnOrder}
                        closeModal={closeModal}
                        setReturnId={setReturnId}
                        setIsVisible={setIsVisible}
                        setToFetchOrderDetails={setToFetchOrderDetails}
                        orderInfo={orderInfo}
                        setItemReturnModal={setItemReturnModal}
                      />
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ItemReturn;
