import { default as NextImage } from 'next/image';
import ImageMagnifier, { ImageZoomProps } from 'components/commercetools-ui/aafes/ImageMagnifier';
const UBImageSection: React.FC<{ image: string; altText: string; zoomProps?: ImageZoomProps }> = ({
  image,
  altText,
  zoomProps,
}) => {
  const myLoader = ({ src }: { src: string }) => {
    return src || '';
  };
  return (
    <div className="flex-none self-baseline border border-gray-300">
      {zoomProps ? (
        <ImageMagnifier zoomProps={zoomProps}>
          <NextImage src={image} alt={altText} width={82} height={82} loader={myLoader} id={`key_${image}`} />
        </ImageMagnifier>
      ) : (
        <NextImage src={image} alt={altText} width={82} height={82} loader={myLoader} id={`key_${image}`} />
      )}
    </div>
  );
};

export default UBImageSection;
