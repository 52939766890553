import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Store from './store';
function StoresList({
  searchInput,
  storeList,
  clickSearch,
  showSearchInput,
  setLoginModal,
  setFindStoreModal,
  channelId,
  updateChannelId,
}) {
  const { formatMessage } = useFormat({ name: 'common' });
  const notDefaultStoresPerPage = 3;
  return (
    <section>
      <ul>
        {showSearchInput && searchInput !== '' && (
          <p className="mb-4 text-affes-sm leading-[21px] text-gray-900">
            {formatMessage({ id: 'storesNear', defaultMessage: 'Stores near' })} {` " ${searchInput} " `}{' '}
          </p>
        )}
        <Store
          storeList={storeList}
          itemsPerPage={notDefaultStoresPerPage}
          setLoginModal={setLoginModal}
          setFindStoreModal={setFindStoreModal}
          channelId={channelId}
          updateChannelId={updateChannelId}
        />
      </ul>
    </section>
  );
}

export default StoresList;
