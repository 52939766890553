import React from 'react';
import MiniFooter from './footer';

function MiniFooterIndex({ data }) {
  return (
    <>
      <MiniFooter data={data} />
    </>
  );
}

export default MiniFooterIndex;
