import React from 'react';

type Props = {
  className?: string;
};

const IconTimeTravler: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="39" height="39" viewBox="0 0 39 39" fill="none">
    <path
      d="M19.5 13V19.5M19.5 26H19.5163M35.75 19.5C35.75 28.4746 28.4746 35.75 19.5 35.75C10.5254 35.75 3.25 28.4746 3.25 19.5C3.25 10.5254 10.5254 3.25 19.5 3.25C28.4746 3.25 35.75 10.5254 35.75 19.5Z"
      stroke="#083558"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconTimeTravler;
