import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

export interface PreOrder {
  preOrderStreetDate: any;
  pageId?: any;
}

const formatDate = (dateString) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateString);
  const monthIndex = date.getMonth();
  const monthName = months[monthIndex];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${monthName} ${day} ${year}`;
};

const PreOrder = ({ preOrderStreetDate, pageId }: PreOrder) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  //const releaseContent = (pageId==='cart') ? console.log('cart page') : <PDPreleaseDate />;
  return (
    <>
      {!pageId ? (
        <section className="font-lato mt-4 flex gap-y-[2px] gap-x-[3px] text-sm font-bold leading-[16.8px] text-[#666666] md:flex-col">
          <p>{formatProductMessage({ id: 'release.date', defaultMessage: 'Release Date:' })}</p>
          <p>{formatDate(preOrderStreetDate)}</p>
        </section>
      ) : (
        <section className="mt-4 text-sm font-medium">
          <p>
            <span className="font-bold">
              {formatProductMessage({ id: 'release.date', defaultMessage: 'Release Date:' })}
            </span>{' '}
            {formatDate(preOrderStreetDate)}
          </p>
        </section>
      )}
    </>
  );
};

export default PreOrder;
