import React from 'react';

type Props = {
  fill?: string;
  className?: string;
};

const Icon: React.FC<Props> = ({ fill, className }: Props) => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4.95C8.75776 4.95 9.48449 5.23973 10.0203 5.75546C10.5561 6.27118 10.8571 6.97065 10.8571 7.7C10.8571 8.06114 10.7832 8.41873 10.6397 8.75238C10.4961 9.08603 10.2856 9.38918 10.0203 9.64454C9.755 9.89991 9.44003 10.1025 9.09338 10.2407C8.74674 10.3789 8.37521 10.45 8 10.45C7.24224 10.45 6.51551 10.1603 5.9797 9.64454C5.44388 9.12882 5.14286 8.42935 5.14286 7.7C5.14286 6.97065 5.44388 6.27118 5.9797 5.75546C6.51551 5.23973 7.24224 4.95 8 4.95ZM8 0C10.1217 0 12.1566 0.811248 13.6569 2.25528C15.1571 3.69931 16 5.65783 16 7.7C16 13.475 8 22 8 22C8 22 0 13.475 0 7.7C0 5.65783 0.842855 3.69931 2.34315 2.25528C3.84344 0.811248 5.87827 0 8 0ZM8 2.2C6.48448 2.2 5.03103 2.77946 3.95939 3.81091C2.88775 4.84236 2.28571 6.24131 2.28571 7.7C2.28571 8.8 2.28571 11 8 18.381C13.7143 11 13.7143 8.8 13.7143 7.7C13.7143 6.24131 13.1122 4.84236 12.0406 3.81091C10.969 2.77946 9.51552 2.2 8 2.2Z"
      fill={fill}
    />
  </svg>
);

export default Icon;
