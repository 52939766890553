import React from 'react';
import { ALPHA, BRAND_SCROLL_DURATION } from 'helpers/constants/aafes';
import SmoothScroller from 'helpers/utils/SmoothScroller';
const Highlighter = ({ brands }) => {
  const activeLetters = new Set(
    brands?.map((brand: any) => {
      const firstChar = brand?.name?.[0]?.toUpperCase();
      return /^[A-Z]$/i.test(firstChar) ? firstChar : '#';
    }),
  );

  return (
    <ul className="my-6 grid grid-cols-9 flex-wrap gap-2 text-center lg:flex lg:justify-between lg:gap-0">
      {ALPHA?.map((char) => (
        <li
          key={`key_${char}`}
          className={`flex justify-center border-1 border-gray-300 pl-2 pr-2 lg:flex-1 lg:border-0 lg:p-0`}
        >
          <a
            href="javascript:void(0)"
            onClick={() => {
              SmoothScroller?.scrollToElement({
                element: `#brand_section_${char}`,
                offset: 0,
                duration: BRAND_SCROLL_DURATION,
              });
            }}
            className={`w-full ${activeLetters?.has(char) ? '' : 'cursor-not-allowed text-slate-400'}`}
          >
            {char}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Highlighter;
