import { LineItem } from '@Types/cart/LineItem';

const SHIP_TO_ADDRESS_ITEMS = 0;
const PICKUP_STORE_ITEMS = 1;
const ELECTRONIC_DELIVERY_ITEMS = 2;

export const getSeparatedDeliverySections = (lineItems: LineItem[]) => {
  const deliverySection = [];
  //ShipToAddress section
  for (const lineItem in lineItems?.[SHIP_TO_ADDRESS_ITEMS]) {
    for (let i = 0; i < lineItems?.[SHIP_TO_ADDRESS_ITEMS][lineItem]?.length; i++) {
      const item = lineItems?.[SHIP_TO_ADDRESS_ITEMS][lineItem][i];
      if (item?.isAttachment === null || item?.isAttachment === undefined) {
        deliverySection.push(item);
      }
    }
  }

  //PickupStore section
  for (const lineItem in lineItems?.[PICKUP_STORE_ITEMS]) {
    for (let i = 0; i < lineItems?.[PICKUP_STORE_ITEMS][lineItem]?.length; i++) {
      const item = lineItems?.[PICKUP_STORE_ITEMS][lineItem][i];
      deliverySection.push(item);
    }
  }

  // Electronic Delivery section
  lineItems?.[ELECTRONIC_DELIVERY_ITEMS]['EGIFT_CARD']?.map((item) => {
    deliverySection.push(item);
  });
  return deliverySection;
};
