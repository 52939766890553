import { useAccount, useSaveForLater } from 'frontastic/provider';
import SaveForLater from 'components/commercetools-ui/save-for-later';
import { useEffect, useState } from 'react';

const SaveForLaterTastic = ({ data }) => {
  const {
    data: wishlist,
    removeSaveForLaterItem,
    moveToWishlist,
    moveToCart,
    getPriceForSaveforLater,
  } = useSaveForLater();
  const [SFLPrices, setSFLPrices] = useState({});
  const { previewDateInSessionData } = useAccount();
  const getPrice = async () => {
    const prices = await getPriceForSaveforLater();
    setSFLPrices(prices);
  };
  useEffect(() => {
    if (wishlist?.lineItems) {
      getPrice();
    }
  }, [wishlist?.lineItems, previewDateInSessionData]);

  return (
    <SaveForLater
      saveForLater={wishlist}
      removeLineItem={removeSaveForLaterItem}
      moveToWishlist={moveToWishlist}
      moveToCart={moveToCart}
      pageTitle={data.pageTitle}
      emptyStateImage={data.emptyStateImage}
      emptyStateTitle={data.emptyStateTitle}
      emptyStateSubtitle={data.emptyStateSubtitle}
      emptyStateCTALabel={data.emptyStateCTALabel}
      emptyStateCTALink={data.emptyStateCTALink}
      SFLPrices={SFLPrices}
    />
  );
};

export default SaveForLaterTastic;
