import Typography from 'components/commercetools-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';

export interface TileProps {
  image: MediaItemWithMods;
  title: string;
  titleColor?: string;
  subtitle: string;
  subtitleColor?: string;
  ctaLabel?: string;
  ctaReference?: Reference;
  titleFont?: string;
  subtitleFont?: string;
  ctaAccountLabel?: string;
  ctaAccountReference?: Reference;
}

const Tile: React.FC<TileProps> = ({
  image,
  title,
  titleColor = 'text-black',
  titleFont = 'font-sans',
  subtitle,
  subtitleColor = 'text-black',
  subtitleFont = 'font-sans',
  ctaLabel,
  ctaReference,
  ctaAccountLabel,
  ctaAccountReference,
}) => {
  return (
    <div className="relative flex justify-center overflow-hidden py-2 align-middle">
      {image && (
        <div className=" w-full">
          <Image media={image} className="opacity-70 md:opacity-100" alt={title} />
        </div>
      )}

      <div className="absolute top-1/2 flex max-w-md -translate-y-1/2 flex-col justify-center text-center md:left-10 md:max-w-xl md:text-left">
        <div className={`text-md mb-2 md:font-medium ${subtitleFont} ${subtitleColor}`}>
          <Typography>{subtitle}</Typography>
        </div>
        <h2
          className={`w-full whitespace-pre-line px-10 text-center text-5xl font-extrabold ${titleFont} tracking-tight ${titleColor} sm:px-0 md:w-60 md:text-left md:text-4xl lg:text-5xl`}
        >
          <Typography>{title}</Typography>
        </h2>
        <div className="inline-block items-center">
          <div>
            {ctaLabel && ctaReference && (
              <div className=" mt-6 mr-40 mb-0.5 flex justify-center md:w-full md:justify-start  ">
                <ReferenceLink
                  target={ctaReference}
                  className=" w-40 rounded-md border-transparent bg-accent-500 text-center font-thin text-white hover:bg-accent-500 md:mt-8 md:w-40 md:py-2 md:px-4 md:text-base"
                >
                  <Typography>{ctaLabel}</Typography>
                </ReferenceLink>
              </div>
            )}
          </div>
          <div className="mb-5">
            {ctaAccountLabel && ctaAccountReference && (
              <div className="mr-40 flex justify-center md:w-full md:justify-start ">
                <ReferenceLink
                  target={ctaAccountReference}
                  className=" w-40 rounded-md border-transparent bg-accent-500 text-center font-thin text-white hover:bg-accent-500 md:mt-8 md:w-40 md:py-2 md:px-4 md:text-base"
                >
                  <Typography>{ctaAccountLabel}</Typography>
                </ReferenceLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
