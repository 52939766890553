const ValidateCardNumber = (cardNumber) => {
  const cardPatterns = [
    /^60194[45]\d{10}$/,
    /^4\d{12}(?:\d{3})?$/,
    /^(5[1-5]\d{14}|2[2-7]\d{14})$/,
    /^3[47]\d{13}$/,
    /^6\d{15}$/,
  ];

  for (const pattern of cardPatterns) {
    if (pattern.test(cardNumber)) {
      return true;
    }
  }

  return false;
};

export default ValidateCardNumber;
