import React, { useEffect } from 'react';
import useSWR from 'swr';

export default function DisplayCustomMessage({ orderDate, gracePeriod }) {
  const queryParams = new URLSearchParams({
    messageKey: 'orderConfirmationThankyouMessage',
    values: null,
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`);

  useEffect(() => {
    if (orderDate) {
      const element = document?.getElementById('counter');
      const balanceTime = new Date(orderDate);
      balanceTime.setMinutes(balanceTime.getMinutes() + gracePeriod ?? 15);
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeLeft = balanceTime.getTime() - currentTime;
        const minutes = (timeLeft / 1000 / 60) % 60 > 0 ? Math.floor((timeLeft / 1000 / 60) % 60) : 0;
        const seconds = (timeLeft / 1000) % 60 > 0 ? Math.floor((timeLeft / 1000) % 60) : 0;
        if (element) {
          element.innerHTML = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        }
        if (timeLeft < 0) {
          clearInterval(timer);
        }
      }, 1000);
    }
  }, [orderDate, gracePeriod, data]);

  return (
    <p className="mr-2 text-affes-sm leading-5 text-gray-900 lg:block">
      <span dangerouslySetInnerHTML={{ __html: data }}></span>
    </p>
  );
}
