import { Cart } from '@Types/cart/Cart';
import {
  CUSTOM_PRODUCT_TYPE,
  CUSTOM_PRODUCT_TYPES_TO_FILTER_ELECTRONIC_DELIVERY_SHIPPING_ADDRESS,
} from 'helpers/constants/aafes';

export const getGuestShippingAddresses = (cart: Cart) => {
  const electronicDeliveryShippingAddressId = cart?.lineItems?.find(
    (lineItem) =>
      CUSTOM_PRODUCT_TYPES_TO_FILTER_ELECTRONIC_DELIVERY_SHIPPING_ADDRESS?.includes(
        lineItem?.variant?.attributes?.[CUSTOM_PRODUCT_TYPE],
      ) && lineItem?.addressKey,
  )?.addressKey;
  return cart?.itemShippingAddresses?.filter(
    (shipiingAddress) => shipiingAddress?.addressId !== electronicDeliveryShippingAddressId,
  );
};
