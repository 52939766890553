import { useEffect, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

const PersonalizeLine = ({
  isPersonalizationRequired,
  personalizationLinesCount,
  personalizationLinesMaxChars,
  personalizationLinesData,
  setIsPersonalization,
  isPersonalizable,
  isMilitaryClothing,
}) => {
  const [customTxt, setCustomTxt] = useState(Array.from({ length: personalizationLinesCount }, () => ''));
  const [personalizationLineNumber, setPersonalizationLineNumber] = useState(0);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const blockSpecialChar = (e) => {
    const key = e.key || String.fromCharCode(e.which || e.keyCode);

    if (
      (key >= 'A' && key <= 'Z') ||
      (key >= 'a' && key <= 'z') ||
      key === 'Backspace' ||
      key === 'Tab' ||
      key === 'Enter' ||
      key === ' ' ||
      (key >= '0' && key <= '9')
    ) {
      return true;
    } else if (key === '+') {
      e.preventDefault();
      return false;
    } else {
      return false;
    }
  };

  const preventPaste = (event) => {
    const inputText = event.clipboardData.getData('text/plain');
    if (!/^[a-zA-Z0-9\s]*$/.test(inputText)) {
      event.preventDefault();
    }
  };

  const handleChange = (event, index) => {
    const inputData = [...customTxt];
    inputData[index] = event.target.value;
    setCustomTxt(inputData);
    personalizationLinesData(inputData);
  };
  useEffect(() => {
    const checkPersonalizationText = customTxt.some((personalization) => personalization.length > 0);
    setIsPersonalization(checkPersonalizationText);
  }, [customTxt]); // Listen for changes in customTxt

  const handleKeyPress = (event) => {
    if (!blockSpecialChar(event) && event.key !== '+') {
      event.preventDefault();
    }
  };

  return (
    <div>
      <label htmlFor="personalizationInput" className="font-lato text-xs font-[700] leading-[14px] text-[#666666]">
        {`${formatProductMessage({ id: 'personalize.text', defaultMessage: 'Add Personalized Text:' })} ${
          isPersonalizationRequired ? '*' : ''
        }`}
      </label>
      {Array.from(Array(personalizationLinesCount), (_, i) => (
        <input
          required={isPersonalizationRequired}
          maxLength={parseInt(personalizationLinesMaxChars)}
          id={`personalizationLines_${i}`}
          key={`personalizationLines_${i}`}
          name="personalizationLines"
          onChange={(event) => handleChange(event, i)}
          onFocus={() => setPersonalizationLineNumber(i)}
          type="text"
          placeholder=" "
          aria-labelledby="personalizationInput"
          className="my-[10px] block h-[36px] w-full max-w-[391px] appearance-none rounded-[3px] border border-[#999999] px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
          onKeyDown={isPersonalizable && isMilitaryClothing ? handleKeyPress : undefined}
          onPaste={isPersonalizable && isMilitaryClothing ? preventPaste : undefined}
        />
      ))}
      <span
        id="remainingChars"
        className="font-lato flex justify-end text-sm font-[400] leading-[17px] text-[#333333]  md:justify-start"
      >
        {parseInt(personalizationLinesMaxChars) -
          (customTxt[personalizationLineNumber]?.length === undefined
            ? 0
            : customTxt[personalizationLineNumber]?.length)}
        {` `}
        {formatProductMessage({ id: 'remaining.chars', defaultMessage: 'chars remaining' })}
      </span>
    </div>
  );
};
export default PersonalizeLine;
