import React, { useState } from 'react';
import TrustedSiteModal from '../aafesFooter/trustedSite';
import ModalContent from '../Modals/guestFooterContent';
import BackToTop from './backToTop';
function MiniFooter({ data }) {
  const [privacyAct, setPrivacyAct] = useState(false);
  const [siteterms, setSiteTerms] = useState(false);
  const [customerService, setCustomerService] = useState(false);
  const [FAQ, setFAQ] = useState(false);
  const [link, setLink] = useState('');
  const [showTrustedSiteModal, setTrustedSiteModal] = useState(false);
  function handlePrivacyAct() {
    setPrivacyAct(false);
  }
  function handleSiteTerms() {
    setSiteTerms(false);
  }
  function handleCustomerService() {
    setCustomerService(false);
  }
  function handleFAQ() {
    setFAQ(false);
  }
  const year = new Date().getFullYear();
  return (
    <>
      <section className="border-t border-[rgba(0,0,0,0.15)] ">
        <section className="px-4 pt-[14px] md:justify-between md:px-[30px] md:pb-[14px] xl:flex">
          <ul className="mt-3 mb-[18px] w-full xl:mb-0 xl:flex ">
            <li className="mb-[18px] h-[17px] text-affes-sm text-gray-900 xl:mr-[30px]  xl:mb-0">
              &#169;
              {year} {data?.footerCol11} <sup>{data?.footerCol12}</sup> {data?.footerCol13}
            </li>

            <li className="mb-[18px] text-affes-sm text-gray-900 xl:mr-[30px] xl:mb-0">
              <button
                onClick={() => {
                  setCustomerService(true);
                  setLink(data?.footerCol2);
                }}
                className=" h-[17px]"
              >
                {data?.footerCol1}
              </button>
            </li>
            <li className="mb-[18px] text-affes-sm text-gray-900 xl:mr-[30px] xl:mb-0">
              <button
                onClick={() => {
                  setFAQ(true);
                  setLink(data?.footerCol4);
                }}
                className=" h-[17px]"
              >
                {data?.footerCol3}
              </button>
            </li>
            <li className="mb-[18px] text-affes-sm text-gray-900 xl:mr-[30px] xl:mb-0 ">
              <button
                onClick={() => {
                  setPrivacyAct(true);
                  setLink(data?.footerCol6);
                }}
                className=" h-[17px]"
              >
                {data?.footerCol5}
              </button>
            </li>
            <li className="mb-[18px] text-affes-sm text-gray-900 xl:mr-[30px] xl:mb-0  ">
              <button
                onClick={() => {
                  setSiteTerms(true);
                  setLink(data?.footerCol8);
                }}
                className=" h-[17px]"
              >
                {data?.footerCol7}
              </button>
            </li>
          </ul>
          <section className="cursor-pointer ">
            <button onClick={() => setTrustedSiteModal(true)} className="h-10 w-[95px]">
              <img src={data?.iconCol6?.media?.file} alt="Trusted Site Icon" />
            </button>
            {showTrustedSiteModal && (
              <TrustedSiteModal showTrustedSiteModal={showTrustedSiteModal} setTrustedSiteModal={setTrustedSiteModal} />
            )}
          </section>
          <BackToTop />
        </section>
      </section>
      {privacyAct && <ModalContent closeModal={handlePrivacyAct} privacyActModal={privacyAct} link={link} />}
      {siteterms && <ModalContent closeModal={handleSiteTerms} privacyActModal={siteterms} link={link} />}
      {customerService && (
        <ModalContent closeModal={handleCustomerService} privacyActModal={customerService} link={link} />
      )}
      {FAQ && <ModalContent closeModal={handleFAQ} privacyActModal={FAQ} link={link} />}
    </>
  );
}

export default MiniFooter;
