import { REMEMBER_ME } from 'helpers/constants/localStorage';

const checkUserSessionExpired = (lastActivity: string, sessionAuthExpirationMins: number = 15) => {
  const timeDifferenceMs: number = new Date().getTime() - new Date(lastActivity).getTime();
  const timeDifferenceMins = timeDifferenceMs / (1000 * 60);
  if (timeDifferenceMins > sessionAuthExpirationMins) return true;
  else return false;
};

export const checkSessionExpiration = (lastActivity: string, sessionAuthExpirationMins: number) => {
  const keptSignedIn = window.localStorage.getItem(REMEMBER_ME);
  if (keptSignedIn) {
    const response = checkUserSessionExpired(lastActivity, sessionAuthExpirationMins);
    return {
      checkedKeepMeSignedIn: true,
      isUserSessionExpired: response,
    };
  } else {
    return { checkedKeepMeSignedIn: false };
  }
};
