import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';

type Props = {
  rank?: string;
  fetchedUniforms?: any[];
};

export default function SelectUniformType({ rank, fetchedUniforms }: Props) {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const [availableUniforms, setAvailableUniforms] = useState([]);
  const router = useRouter();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const MASTER_VARIANT = 0;
  let gridWidth: number;
  if (availableUniforms.length === 1 || availableUniforms.length === 2) {
    gridWidth = availableUniforms.length;
  } else {
    gridWidth = 3;
  }
  useEffect(() => {
    setIsLoad(true);
    const filterUniforms = fetchedUniforms?.filter((uniform) => {
      return uniform?.variants?.[0]?.attributes?.configuratorRank === rank;
    });
    setAvailableUniforms(filterUniforms);
    setIsLoad(false);
  }, []);

  return (
    <section>
      {isLoad && <Loader />}
      <div className="flex h-full w-full justify-center">
        <div>
          <div className="flex justify-center">
            <span className="font-lato my-[35px] h-[19px] min-w-[180px] text-base font-bold leading-[19.2px] text-gray-900">
              {formatMessage({ id: 'selectYourUniformType', defaultMessage: 'Select Your Uniform Type' })}{' '}
            </span>
          </div>
        </div>
      </div>
      <div className={`mx-8 grid grid-cols-${gridWidth} h-full gap-x-[34px]`}>
        {availableUniforms?.map((item, index) => {
          return (
            <div key={index} className={`col-span-3 mx-auto flex w-[155px] flex-col md:col-span-1 lg:col-span-1`}>
              <div className="mb-[15px] h-[149px] w-full border border-[#CCCCCC] ">
                <Image
                  src={
                    getThumbImage(item?.variants?.[MASTER_VARIANT]?.imageSet) ||
                    item?.variants?.[MASTER_VARIANT]?.images?.[0]
                  }
                  className="h-full w-full"
                  alt="Master Variant"
                />
              </div>
              <p className="font-lato mb-[21px] h-full w-full text-sm font-normal leading-[17.8px] text-gray-900">
                <span className="w-[155px] pb-[16.6px]">{item.name}</span>
              </p>
              <div className="mb-[35px] h-11">
                <button
                  type="button"
                  className="h-11 w-full rounded bg-blue-900 text-sm text-[#FFFFFF]"
                  onClick={() => {
                    setIsLoad(true);
                    router.push(`${item?._ubUrl}`);
                  }}
                >
                  {formatMessage({ id: 'personalize', defaultMessage: 'Personalize' })}{' '}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

const getThumbImage = (imageSet: { type: string; src: { label: string; url: string }[] }[]) => {
  const mediumImageSet = imageSet?.find((image) => image?.type === 'medium');
  const frontImage =
    mediumImageSet?.src?.find((imgSrc) => imgSrc?.label === 'front')?.url || mediumImageSet?.src?.[0]?.url;
  return frontImage;
};
