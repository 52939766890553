import React, { useEffect, useState } from 'react';
import { PRODUCT_TYPES_WITH_QUANTITY_RESTRICTION } from 'helpers/constants/aafes';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function QuantitySelector({ productType, quantity, setQuantity, maxQuantity, isAddToCartCtaDisable }) {
  const [isIncrement, setIncrement] = useState(true);
  const [isDecrement, setDecrement] = useState(false);
  function handleIncrement() {
    setQuantity((prevValue: number) => prevValue + 1);
    setDecrement(true);
  }

  function handleDecrement() {
    setQuantity((prevValue: number) => prevValue - 1);
    setIncrement(true);
  }
  const handleQuantityChange = async (e) => {
    if (isAddToCartCtaDisable) setQuantity(0);
    const value = parseInt(e?.target?.value);
    if (!isNaN(value)) {
      if (value >= 1 && value <= maxQuantity) setQuantity(value);
      else if (value >= maxQuantity) setQuantity(maxQuantity);
      else if (value === 0) setQuantity(1);
    } else {
      setQuantity(null);
    }
  };

  const handleUpdateQuantity = async () => {
    if (!quantity) {
      setQuantity(isAddToCartCtaDisable ? 0 : 1);
    }
  };

  useEffect(() => {
    if (PRODUCT_TYPES_WITH_QUANTITY_RESTRICTION?.includes(productType)) {
      setIncrement(false);
    }
  }, [productType]);

  useEffect(() => {
    quantity >= maxQuantity ? setIncrement(false) : setIncrement(true);
    quantity <= 1 ? setDecrement(false) : setDecrement(true);
  }, [quantity, maxQuantity]);

  return (
    <section>
      <div className="flex justify-center gap-x-2.5 py-6">
        {/* Decrease Quantity Button */}
        <button
          type="button"
          onClick={handleDecrement}
          className={classNames(
            'flex h-[43px] w-[42px] items-center justify-center rounded-[3px] border-[1px] border-solid border-[#999999]',
            isAddToCartCtaDisable || !isDecrement ? 'bg-[#EEEEEE] text-[#1c1b1f4d]' : 'bg-[#FFFFFF] text-[#043C63]',
          )}
          disabled={isAddToCartCtaDisable || !isDecrement}
          aria-label="Decrease Quantity"
        >
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M0 2V0H14V2H0Z" fill="currentcolor" />
          </svg>
        </button>
        {/* Quantity Input */}
        <div className="flex">
          <label htmlFor="quantityInput" className="sr-only">
            Quantity
          </label>
          <input
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            id="quantityInput"
            className="no-spinners font-lato flex h-[43px] w-[42px] items-center justify-center rounded-[3px] border-[1px] border-solid border-[#999999] bg-[#FFFFFF] px-0 text-center text-sm text-[#333333] ring-transparent"
            value={quantity}
            min={1}
            max={maxQuantity}
            onChange={handleQuantityChange}
            onBlur={handleUpdateQuantity}
            aria-label="Quantity"
            aria-live="polite"
          />
        </div>
        {/* Increase Quantity Button */}
        <button
          onClick={handleIncrement}
          className={classNames(
            'flex h-[43px] w-[42px] items-center justify-center rounded-[3px] border-[1px] border-solid border-[#999999]',
            isAddToCartCtaDisable || !isIncrement ? 'bg-[#EEEEEE] text-[#1c1b1f4d]' : 'bg-[#FFFFFF] text-[#043C63]',
          )}
          disabled={isAddToCartCtaDisable || !isIncrement}
          aria-label="Increase Quantity"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="currentcolor" />
          </svg>
        </button>
      </div>
    </section>
  );
}
