import React from 'react';
import SocialMediaLinks from 'components/commercetools-ui/aafes/socialMediaLink';
function index({ data }) {
  const facebookLinks = data.headerCol1;
  const twitterLinks = data.headerCol2;
  const instagramLinks = data.headerCol3;
  const dgLinks = data.headerCol4;
  const pinterest = data.headerCol5;

  const socialMediaLinks = {
    facebookLink: facebookLinks,
    twitterLink: twitterLinks,
    instagramLink: instagramLinks,
    dgLink: dgLinks,
    pinterest: pinterest,
  };

  return (
    <section>
      <SocialMediaLinks links={socialMediaLinks} />
    </section>
  );
}

export default index;
