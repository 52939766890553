import React from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/product/Category';
import DoubleArrow from 'components/icons/double-arrow';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

type UBBreadcrumbProps = {
  Separator?: React.ReactNode;
  categories?: Category[];
  activeItem?: string;
};

const Breadcrumb: React.FC<UBBreadcrumbProps> = ({ Separator = <DoubleArrow />, categories, activeItem }) => {
  const { studioConfig } = useCart();
  const uniformBuilderURL = studioConfig?.uniformBuilderBreadcrumbLink;
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const exchange = formatCommonMessage({ id: 'exchange', defaultMessage: 'Exchange' });
  return (
    <nav className="mb-5 flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-2 text-sm">
        <li>
          <div>
            <NextLink href="/">
              <a className="text-blue-900" aria-label="Go to homepage">
                <span className="sr-only">{exchange}</span>
                {exchange}
              </a>
            </NextLink>
          </div>
        </li>
        <li className="mt-[2.5px]">{Separator}</li>
        <li>
          <NextLink href={uniformBuilderURL || '#'}>
            <a className="text-blue-900" aria-label="Go to homepage">
              <span className="sr-only">
                {formatCommonMessage({ id: 'uniformBuilder', defaultMessage: 'Uniform Builder' })}
              </span>
              {formatCommonMessage({ id: 'uniformBuilder', defaultMessage: 'Uniform Builder' })}
            </a>
          </NextLink>
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
