import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M0 7L2.5 3.5L0 0H1.225L3.725 3.5L1.225 7H0ZM2.975 7L5.475 3.5L2.975 0H4.2L6.7 3.5L4.2 7H2.975Z"
      fill="black"
    />
  </svg>
);

export default Icon;
