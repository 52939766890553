import ProductTable from 'components/commercetools-ui/aafes/compareProducts';
import NoResultFound from 'components/commercetools-ui/aafes/plp-clp/noResultFound';
import React, { useEffect, useMemo, useState } from 'react';
const ProductsCompareTastic = ({ data }) => {
  const [compareProducts, setCompareProducts] = useState([]);
  const products = data?.products?.dataSource?.items;
  const skus = data?.products?.dataSource?.query?.skus;

  const isNoProducts = useMemo(() => {
    return Boolean(!compareProducts?.length);
  }, [compareProducts]);

  useEffect(() => {
    const compareProducts = skus?.map((sku) => {
      const product = products?.find((product) => product?.variants?.some((variant) => variant?.sku === sku));
      return product;
    });
    setCompareProducts(compareProducts);
  }, [products]);

  if (!data?.products?.dataSource?.items) return <NoResultFound />;
  if (compareProducts?.length < 1) return <NoResultFound isNoProducts={isNoProducts} />;
  if (compareProducts?.length > 4) return <NoResultFound isMoreProducts={true} />;

  return <ProductTable products={compareProducts} skus={data?.products?.dataSource?.query?.skus} />;
};
export default ProductsCompareTastic;
