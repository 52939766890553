export type StateOption = {
  display: string;
  data: string;
};

export const stateOptions: StateOption[] = [
  { display: 'AA', data: 'AA' },
  { display: 'AE', data: 'AE' },
  { display: 'Alabama', data: 'AL' },
  { display: 'Alaska', data: 'AK' },
  { display: 'American Samoa', data: 'AS' },
  { display: 'AP', data: 'AP' },
  { display: 'Arizona', data: 'AZ' },
  { display: 'Arkansas', data: 'AR' },
  { display: 'California', data: 'CA' },
  { display: 'Colorado', data: 'CO' },
  { display: 'Connecticut', data: 'CT' },
  { display: 'Delaware', data: 'DE' },
  { display: 'District Of Columbia', data: 'DC' },
  { display: 'Federated States Of Micronesia', data: 'FM' },
  { display: 'Florida', data: 'FL' },
  { display: 'Georgia', data: 'GA' },
  { display: 'Guam', data: 'GU' },
  { display: 'Hawaii', data: 'HI' },
  { display: 'Idaho', data: 'ID' },
  { display: 'Illinois', data: 'IL' },
  { display: 'Indiana', data: 'IN' },
  { display: 'Iowa', data: 'IA' },
  { display: 'Kansas', data: 'KS' },
  { display: 'Kentucky', data: 'KY' },
  { display: 'Louisiana', data: 'LA' },
  { display: 'Maine', data: 'ME' },
  { display: 'Marshall Islands', data: 'MH' },
  { display: 'Maryland', data: 'MD' },
  { display: 'Massachusetts', data: 'MA' },
  { display: 'Michigan', data: 'MI' },
  { display: 'Minnesota', data: 'MN' },
  { display: 'Mississippi', data: 'MS' },
  { display: 'Missouri', data: 'MO' },
  { display: 'Montana', data: 'MT' },
  { display: 'Nebraska', data: 'NE' },
  { display: 'Nevada', data: 'NV' },
  { display: 'New Hampshire', data: 'NH' },
  { display: 'New Jersey', data: 'NJ' },
  { display: 'New Mexico', data: 'NM' },
  { display: 'New York', data: 'NY' },
  { display: 'North Carolina', data: 'NC' },
  { display: 'North Dakota', data: 'ND' },
  { display: 'Northern Mariana Islands', data: 'MP' },
  { display: 'Ohio', data: 'OH' },
  { display: 'Oklahoma', data: 'OK' },
  { display: 'Oregon', data: 'OR' },
  { display: 'Palau', data: 'PW' },
  { display: 'Pennsylvania', data: 'PA' },
  { display: 'Puerto Rico', data: 'PR' },
  { display: 'Rhode Island', data: 'RI' },
  { display: 'South Carolina', data: 'SC' },
  { display: 'South Dakota', data: 'SD' },
  { display: 'Tennessee', data: 'TN' },
  { display: 'Texas', data: 'TX' },
  { display: 'Utah', data: 'UT' },
  { display: 'Vermont', data: 'VT' },
  { display: 'Virgin Islands', data: 'VI' },
  { display: 'Virginia', data: 'VA' },
  { display: 'Washington', data: 'WA' },
  { display: 'West Virginia', data: 'WV' },
  { display: 'Wisconsin', data: 'WI' },
  { display: 'Wyoming', data: 'WY' },
];
