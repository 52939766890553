import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cookieCutter from 'cookie-cutter';
import { CgClose } from 'react-icons/cg';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { smallMobile, tablet, desktop } from 'helpers/utils/screensizes';
import Image from 'frontastic/lib/image';

export default function CompareProducts({ compareProducts, setCompareProducts }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [skuIds, setSkuIds] = useState([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const router = useRouter();
  const [isdesktop] = useMediaQuery(tablet);
  const [isSmallMobile] = useMediaQuery(smallMobile);
  const compareArea = useRef(null);

  const removeProduct = (skuId) => {
    const updatedArray = compareProducts?.filter((item: { skuId: any }) => item?.skuId !== skuId);
    setCompareProducts(updatedArray);
    cookieCutter.set('compareProducts', JSON.stringify(updatedArray));
  };

  const handleAppendURL = () => {
    setIsLoad(true);
    router
      .push(`/compare?skus=${encodeURIComponent(JSON.stringify(skuIds))}`)
      .then(() => {
        setIsLoad(false);
      })
      .catch(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    const extractedSkuIds = compareProducts?.map((product) => product?.skuId);
    setSkuIds(extractedSkuIds);
  }, [compareProducts]);

  useEffect(() => {
    const compareProductsElement = compareArea?.current;
    const handleScroll = () => {
      const buttonElement = document.getElementById('back-to-top');
      const isBackToTopPresent = !!buttonElement;
      const adjustButtonPosition = () => {
        let compareProductsHeight = compareProductsElement.offsetHeight + 5;
        buttonElement.style.bottom = `${compareProductsHeight}px`;
        compareProductsHeight = 0;
      };
      if (compareProducts?.length > 0 && isBackToTopPresent && window.innerWidth <= desktop) {
        return adjustButtonPosition();
      } else if (isBackToTopPresent) buttonElement.style.bottom = '';
    };
    const delayedHandleScroll = () => {
      setTimeout(() => {
        handleScroll();
      }, 0);
    };
    delayedHandleScroll();
    window.addEventListener('scroll', delayedHandleScroll);
    return () => {
      window.removeEventListener('scroll', delayedHandleScroll);
    };
  }, [compareProducts]);

  return (
    <>
      {compareProducts?.length > 0 && (
        <div ref={compareArea} className="flex h-full w-full flex-wrap items-center justify-center gap-x-4 gap-y-2 p-2">
          <p className="text-sm leading-4 text-gray-900">
            {formatMessage({ id: 'compare', defaultMessage: 'Select 2 to 4 to compare' })}
          </p>
          <div className="flex w-fit items-center justify-center gap-x-4">
            <div className="my-2 flex items-center justify-center gap-x-4">
              {compareProducts?.map((product: { mediumImage: string; displayName: string; skuId: any }, index) => {
                return (
                  <div key={index} className="relative h-full">
                    <Image
                      className={`${
                        isdesktop ? 'h-16 w-16' : isSmallMobile ? 'h-12 w-12' : 'h-auto w-auto'
                      } cursor-pointer md:mx-auto`}
                      src={product?.mediumImage}
                      alt={product?.displayName}
                    />
                    <CgClose
                      onClick={() => {
                        removeProduct(product?.skuId);
                      }}
                      className="absolute -top-2 -right-2 h-5 w-5 cursor-pointer text-gray-900"
                    />
                  </div>
                );
              })}
            </div>
            <button
              onClick={() => {
                handleAppendURL();
              }}
              className={`h-9 w-[131px] min-w-[102px] rounded text-white ${
                compareProducts?.length <= 1 ? 'bg-blue-200' : 'bg-blue-900'
              }`}
              disabled={compareProducts?.length <= 1}
            >
              {formatMessage({ id: 'compareProduct', defaultMessage: 'Compare' })}
            </button>
          </div>
        </div>
      )}
      {isLoad && <Loader />}
    </>
  );
}
