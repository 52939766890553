import React from 'react';
import ConfirmDelete from 'components/commercetools-ui/aafes/confirm-delete';

const ConfirmDeleteTastic = ({ data }) => {
  return (
    <ConfirmDelete
      description={data?.description}
      buttonText={data?.buttonText}
      successMessageTitle={data?.successMessageTitle}
      successMessageNote={data?.successMessageNote}
      errorMessage={data?.errorMessage}
    />
  );
};

export default ConfirmDeleteTastic;
