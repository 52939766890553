import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="10" height="16" viewBox="0 0 10 16" fill="none">
    <path
      d="M2.01875 15.5L0.6875 14.1688L6.85625 8L0.6875 1.83125L2.01875 0.5L9.51875 8L2.01875 15.5Z"
      fill="#333333"
    />
  </svg>
);

export default Icon;
