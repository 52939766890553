import React, { useEffect } from 'react';
import { useAccount } from 'frontastic';

function MonetateProfileData() {
  const { account } = useAccount();

  useEffect(() => {
    if (!account) {
      return;
    }
    const scriptId = 'customerIdScript';
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.id = scriptId;
    scriptElement.textContent = `var customerId = "${account.cid}"; 
                                 var userName = "${account.userName}";`;
    document.head.appendChild(scriptElement);
  }, [account?.cid]);

  if (!account) {
    return <></>;
  }

  return (
    <div id="profileData">
      <div id="customerId" style={{ display: 'none' }}>
        {account.cid}
      </div>
    </div>
  );
}

export default MonetateProfileData;
