import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import Breadcrumb from '../bundle-select/breadcrumb';

function UniformBuilderLanding() {
  const [isDesktop] = useMediaQuery(tablet);
  return <div>{isDesktop && <Breadcrumb />}</div>;
}

export default UniformBuilderLanding;
