import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HiOutlineCheckCircle } from 'react-icons/hi';

function UpdateProfileConfirmation({ closeModal, updatedProfile, message }) {
  return (
    <>
      <Transition appear show={updatedProfile} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child>
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[50%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] py-8 dark:bg-primary-200 md:mt-9 lg:mt-3 "
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="mt-1 flex items-center  text-affes-xl font-semibold text-gray-900 dark:text-white">
                          <HiOutlineCheckCircle className="mr-2 text-xl font-semibold text-green-800" />
                          {message}
                        </h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>
                      <section className="flex justify-center">
                        <button className="mt-3 rounded bg-blue-900 px-3 py-1 text-white " onClick={closeModal}>
                          Ok
                        </button>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default UpdateProfileConfirmation;
