import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { Body, Title } from './Typography';

function RetrunInfo() {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const returnPolicies = `Exchange Plus product returns vary by supplier, visit our return policies for more information.`;
  const { studioConfig: projectConfiguration } = useCart();
  const domainURL = projectConfiguration?.aafesDomain;
  return (
    <>
      <section>
        <Title title={formatProductMessage({ id: 'returns', defaultMessage: 'Returns' })} />
        <div className="flex  flex-col pt-2 text-sm leading-5 ">
          {/* //TODO comment saying static and will be replaced with dynamic data after */}
          <p className="pb-2 font-normal leading-5 text-[#333333]">
            Most products in new condition may be returned within 90 days either to a store or by mail, except as
            detailed in the
            <a
              href={`${domainURL}customer-service/returns-exchanges`}
              aria-label={'returns-exchanges'}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#043C63]"
            >
              <span>&nbsp;Online Return Policy</span>
            </a>
            . Patrons of shopmyexchange.com who shop via the Veteran&apos;s Online Shopping Benefit can return
            shopmyexchange by mail.
          </p>
          <Body body={returnPolicies} />
        </div>
      </section>
    </>
  );
}

export default RetrunInfo;
