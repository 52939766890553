import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Address } from '@Types/account/Address';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { FaChevronDown } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import * as Yup from 'yup';
import Loader from 'components/commercetools-ui/loader/Loader';
import { stateOptions } from 'helpers/constants/stateList';
import { useFormat } from 'helpers/hooks/useFormat';
import { usePreventClickAfterDrag } from 'helpers/hooks/usePreventClickAfterDrag';
import { validateInput } from 'helpers/keyPressValidation';
import { validateAddress } from 'frontastic/actions/account';
import { useAccount, useCart } from 'frontastic/provider';
interface AddAddressProp {
  afterSavingAddress: (data) => void;
  withoutSavingAddress: (address) => void;
  closeAddAddressModal: () => void;
  parentLoaderState: boolean;
  defaultValues: Partial<Address>;
  alwaysSave: boolean;
  editAddress: boolean;
  isCart: boolean;
  restrictedZips?: string[];
  shippableZips?: string[];
  existingAddresses: Address[];
  getAllCustomMessages?: any;
  addressType?: string;
}
function AddAddressModal({
  afterSavingAddress,
  withoutSavingAddress,
  closeAddAddressModal,
  parentLoaderState,
  alwaysSave = false,
  editAddress,
  defaultValues,
  isCart,
  restrictedZips = [],
  shippableZips = [],
  existingAddresses = [],
  getAllCustomMessages,
  addressType,
}: AddAddressProp) {
  const badUspsResponseCodes = ['F', 'I', 'K', 'R', 'V', 'W', 'X'];
  const { addAddress, account, loggedIn } = useAccount();
  const [isBadAddress, setBadAddress] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<Address>({});
  const [userFilledAddress, setUserFilledAddress] = useState<any>({});
  const [showAddNewAddress, setShowAddNewAddress] = useState(true);
  const [showVerifyShipping, setShowVerifyShipping] = useState(false);
  const [addressChoice, setAddressChoice] = useState('suggested');
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { updateAddress } = useAccount();
  const [errorMessage, setErrorMessage] = useState('');

  const cantShip = getAllCustomMessages?.cantShip;
  const maxAddressError = getAllCustomMessages?.addressesLimit;
  const { studioConfig } = useCart();
  const [maxAddresses, setMaxAddresses] = useState(20);
  const shouldPreventClickOnDrag = usePreventClickAfterDrag();
  useEffect(() => {
    setShowLoader(true);
    if (studioConfig?.addressesMaxQty) {
      setMaxAddresses(studioConfig?.addressesMaxQty);
      if (loggedIn && existingAddresses?.length < studioConfig?.addressesMaxQty) {
        setShouldSaveAddress(true);
      }
    }
    setShowLoader(false);
  }, [studioConfig?.addressesMaxQty]);

  const shippingAddressFormSchema = Yup.object().shape({
    additionalAddressInfo: Yup.string(),
    firstName: Yup.string()
      .required(formatCommonMessage({ id: 'fNameRequired', defaultMessage: 'First name is required.' }))
      .transform((value) => value.replace(/\s+/g, ' ').trim())
      .matches(
        /^\s*\p{L}[-'\\. \p{L}]*\s*$/u,
        formatCommonMessage({ id: 'validFirstName', defaultMessage: 'Enter a valid first name.' }),
      ),
    lastName: Yup.string()
      .required(formatCommonMessage({ id: 'lNameRequired', defaultMessage: 'Last name is required.' }))
      .transform((value) => value.replace(/\s+/g, ' ').trim())
      .matches(
        /^\s*\p{L}[-'\\. \p{L}]*\s*$/u,
        formatCommonMessage({ id: 'validLastName', defaultMessage: 'Enter a valid last name.' }),
      ),
    streetName: Yup.string()
      .required(formatCommonMessage({ id: 'address1Required', defaultMessage: 'Address line 1 is required.' }))
      .matches(
        /^[A-Za-z0-9\s\-]+$/,
        formatCommonMessage({ id: 'validStreetName', defaultMessage: 'Enter a valid street name.' }),
      ),
    streetNumber: Yup.string().matches(
      /^[A-Za-z0-9\s\-]*$/,
      formatCommonMessage({ id: 'validStreetName', defaultMessage: 'Enter a valid street name.' }),
    ),
    city: Yup.string()
      .required(formatCommonMessage({ id: 'cityRequired', defaultMessage: 'City is required.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validCity', defaultMessage: 'Enter a valid city name.' })),
    state: Yup.string()
      .required(formatCommonMessage({ id: 'stateRequired', defaultMessage: 'Please select state.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validState', defaultMessage: 'Enter a valid state name.' })),
    postalCode: Yup.string()
      .required(formatCommonMessage({ id: 'zipRequired', defaultMessage: 'Zip code is required.' }))
      .test(
        'testOnlyNum',
        formatCommonMessage({ id: 'enterValidZip', defaultMessage: 'Please enter a valid zip code' }),
        (val) => {
          val = val ? val : '';
          const regExp = new RegExp('^[\\d-]+$');
          return regExp.test(val);
        },
      )
      .max(10, formatCommonMessage({ id: 'zipShouldBe10', defaultMessage: 'Zip code should be 5-10 digits.' }))
      .min(5, formatCommonMessage({ id: 'zipShouldBe5', defaultMessage: 'Zip code should be 5 digits.' }))
      .test('testRestrictedZips', cantShip, (val) => {
        val = val ? val : '';
        if (shippableZips.length > 0 && shippableZips.indexOf(val) == -1) {
          return false;
        } else if (restrictedZips?.length > 0 && restrictedZips.indexOf(val) > -1) {
          return false;
        }
        return true;
      }),
    phone: Yup.string()
      .required(formatCommonMessage({ id: 'phoneRequired', defaultMessage: 'Phone Number is required.' }))
      .matches(
        /^(?:\+?\d{10,14}|\d{10,15})$/,
        formatCommonMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid Phone Number.' }),
      )
      .min(10, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 15 digits.' }))
      .max(15, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 15 digits.' })),
  });

  const shippingAddressForm: any = {
    resolver: yupResolver(shippingAddressFormSchema),
    mode: 'onBlur',
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(shippingAddressForm);

  useEffect(() => {
    if (editAddress) {
      reset(defaultValues);
    } else {
      reset();
    }
  }, [editAddress, defaultValues, reset]);

  const ShippingAddressFormHandler = async (values: any) => {
    const payload = {
      streetName: values.streetName,
      streetNumber: values.streetNumber,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values?.phone,
    };
    setShowLoader(true);
    setUserFilledAddress(values);
    await validateAddress(payload)
      .then((response) => {
        if (!response.validAddress) {
          setBadAddress(true);
          setAddressChoice('entered');
        } else {
          const formattedPostalCode =
            response?.postalCode.slice(0, 5) +
            (response?.postalCode.length > 5 ? '-' : '') +
            response?.postalCode.slice(5);
          const formattedSuggestedAddress = {
            additionalAddressInfo: values.additionalAddressInfo,
            firstName: values.firstName,
            lastName: values.lastName,
            streetName: response?.streetName,
            streetNumber: response?.streetNumber,
            postalCode: formattedPostalCode,
            city: response?.city,
            state: response?.state,
            phone: values.phone,
          };
          setSuggestedAddress(formattedSuggestedAddress);
          setBadAddress(false);
          setAddressChoice('suggested');
        }
        setShowVerifyShipping(true);
        setShowAddNewAddress(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setShowLoader(false));
  };

  const blockSpecialChar = (e) => {
    const key = e.key || String.fromCharCode(e.which || e.keyCode);
    return (
      (key >= 'A' && key <= 'Z') ||
      (key >= 'a' && key <= 'z') ||
      key === 'Backspace' ||
      key === 'Tab' ||
      key === 'Enter' ||
      key === ' ' ||
      key === '.' ||
      key === ':' ||
      key === '\\' ||
      key === "'" ||
      key === '-' ||
      (key >= '0' && key <= '9')
    );
  };

  const handleKeyPress = (event) => {
    if (!blockSpecialChar(event) && event.key !== '+') {
      event.preventDefault();
    }
  };

  const preventPaste = (e) => {
    e.preventDefault();
  };

  const addAddressToAccount = () => {
    setShowLoader(true);
    let payload;
    if (addressChoice === 'suggested') {
      payload = suggestedAddress;
    } else {
      payload = userFilledAddress;
    }
    payload = {
      ...payload,
      country: 'US',
      isDefaultBillingAddress: account?.addresses?.length == 0,
      isDefaultShippingAddress: account?.addresses?.length == 0,
    };
    addAddress(payload)
      .then((data) => {
        if (data.errorCode === 500) {
          setErrorMessage(data.body);
          setShowVerifyShipping(false);
          setShowAddNewAddress(true);
        } else {
          afterSavingAddress(data);
          closeAddAddressModal();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoader(false));
  };

  const proceedWithoutAddingAddress = () => {
    let payload;
    if (addressChoice === 'suggested') {
      payload = suggestedAddress;
    } else {
      payload = userFilledAddress;
    }
    withoutSavingAddress(payload);
  };
  const verifyAddressFormHandler = () => {
    reset();
    if (alwaysSave || shouldSaveAddress) {
      addAddressToAccount();
    } else {
      proceedWithoutAddingAddress();
    }
  };
  const addAddressSubmit = debounce(() => verifyAddressFormHandler(), 2000);

  const UpdateAddressSubmit = (data) => {
    updateAddress(data)
      .then((data) => {
        if (data?.errorCode) {
          setErrorMessage(data.body);
        } else {
          closeAddAddressModal();
        }
      })
      .catch((error) => {
        console.error('Failed to update address:', error);
      });
  };

  const updateAddressChoice = (e) => {
    if (e.target.checked) {
      setAddressChoice(e.target.value);
    }
  };

  const handleSaveAddressCheckbox = (e) => {
    setShouldSaveAddress(e.target.checked);
    if (e.target.checked && existingAddresses?.length >= maxAddresses) {
      setErrorMessage(maxAddressError);
    } else {
      setErrorMessage('');
    }
  };
  const handlePostalCodeInput = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    const formattedValue =
      sanitizedValue.slice(0, 5) + (sanitizedValue.length > 5 ? '-' : '') + sanitizedValue.slice(5, 10);
    const truncatedValue = formattedValue.slice(0, 10);
    e.target.value = truncatedValue;
  };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeAddAddressModal}>
          <Transition.Child
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={() => {
                    if (!shouldPreventClickOnDrag()) {
                      closeAddAddressModal();
                    }
                  }}
                >
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left" aria-labelledby="addressModalLabel">
                      <section>
                        <section>
                          {showAddNewAddress && (
                            <section className="">
                              <section
                                className="mb-6 flex justify-between text-lg font-bold "
                                aria-label={editAddress ? 'Edit Address Modal' : 'Add New Address Modal'}
                                aria-labelledby="addressModalLabel"
                              >
                                {editAddress ? (
                                  <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                    {' '}
                                    {formatAccountMessage({ id: 'edit.add', defaultMessage: 'Edit Address' })}
                                  </h3>
                                ) : (
                                  <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                    {' '}
                                    {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add New Address' })}
                                  </h3>
                                )}
                                <section className="cursor-pointer">
                                  <GrClose
                                    onClick={() => {
                                      closeAddAddressModal();
                                    }}
                                    role="button"
                                    aria-label="Close Address Modal"
                                  />
                                </section>
                              </section>
                              {errorMessage && (
                                <div>
                                  <p className="mb-4 text-red-500" role="alert">
                                    {errorMessage}
                                  </p>
                                </div>
                              )}
                              <form onSubmit={handleSubmit(ShippingAddressFormHandler)}>
                                <section className=" ">
                                  <section className="mb-[15px] w-full xl:w-1/2">
                                    <label
                                      htmlFor="additionalAddressInfo"
                                      className=" mb-[10px] block w-full text-[12px] font-bold text-gray-800 "
                                    >
                                      {formatCommonMessage({ id: 'nickName', defaultMessage: 'Nick Name' })}
                                    </label>
                                    <input
                                      type="text"
                                      maxLength={15}
                                      id="additionalAddressInfo"
                                      {...register('additionalAddressInfo')}
                                      className=" block w-full rounded py-[6px] px-[10px] xl:w-[96%]"
                                    />
                                  </section>

                                  <section className="gap-6 xl:flex xl:justify-between">
                                    <section className="mb-[15px] w-full">
                                      <label
                                        htmlFor="firstName"
                                        className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                      >
                                        {formatCommonMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                                        <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        id="firstName"
                                        maxLength={19}
                                        onKeyDown={handleKeyPress}
                                        onPaste={preventPaste}
                                        onKeyPress={validateInput}
                                        {...register('firstName')}
                                        className={`block w-full rounded py-[6px] px-[10px] ${
                                          errors?.firstName?.message ? 'border-red-500' : ''
                                        }`}
                                      />
                                      {errors?.firstName?.message && (
                                        <span className="inline-flex text-sm text-red-500" role="alert">
                                          {errors?.firstName?.message}
                                        </span>
                                      )}
                                    </section>
                                    <section className="mb-[15px] w-full">
                                      <label
                                        htmlFor="lastName"
                                        className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                      >
                                        {formatCommonMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                                        <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        id="lastName"
                                        onKeyDown={handleKeyPress}
                                        onPaste={preventPaste}
                                        maxLength={20}
                                        onKeyPress={validateInput}
                                        {...register('lastName')}
                                        className={`block w-full rounded py-[6px] px-[10px] ${
                                          errors?.lastName?.message ? 'border-red-500' : ''
                                        }`}
                                      />
                                      {errors?.lastName?.message && (
                                        <span className="inline-flex text-sm text-red-500" role="alert">
                                          {errors?.lastName?.message}
                                        </span>
                                      )}
                                    </section>
                                  </section>

                                  <section className="mb-[15px]">
                                    <label
                                      htmlFor="addressLine1"
                                      className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                    >
                                      {formatCommonMessage({ id: 'addressLine1', defaultMessage: 'Address line 1' })}
                                      <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      id="addressLine1"
                                      onKeyDown={handleKeyPress}
                                      onPaste={preventPaste}
                                      maxLength={40}
                                      {...register('streetName')}
                                      className={`block w-full rounded py-[6px] px-[10px] ${
                                        errors?.streetName?.message ? 'border-red-500' : ''
                                      }`}
                                    />
                                    {errors?.streetName?.message && (
                                      <span className="inline-flex text-sm text-red-500" role="alert">
                                        {errors?.streetName?.message}
                                      </span>
                                    )}
                                  </section>
                                  <section className="mb-[15px]">
                                    <label
                                      htmlFor="addressLine2"
                                      className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                    >
                                      {formatCommonMessage({ id: 'addressLine2', defaultMessage: 'Address line 2' })}
                                    </label>
                                    <input
                                      type="text"
                                      id="addressLine2"
                                      onKeyDown={handleKeyPress}
                                      onPaste={preventPaste}
                                      maxLength={40}
                                      {...register('streetNumber')}
                                      className={`block w-full rounded py-[6px] px-[10px] ${
                                        errors?.streetNumber?.message ? 'border-red-500' : ''
                                      }`}
                                    />
                                    {errors?.streetNumber?.message && (
                                      <span className="inline-flex text-sm text-red-500" role="alert">
                                        {errors?.streetNumber?.message}
                                      </span>
                                    )}
                                  </section>
                                  <section className="gap-6 xl:flex xl:justify-between" aria-labelledby="cityInfo">
                                    <section className="mb-[15px] w-full xl:w-1/2">
                                      <section className="flex justify-between">
                                        <label htmlFor="city" className="mb-[10px] text-[12px] font-bold text-gray-800">
                                          {formatCommonMessage({ id: 'city', defaultMessage: 'City' })}
                                          <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                            *
                                          </span>
                                        </label>
                                        <p id="cityInfo" className=" text-[12px] font-bold text-gray-800">
                                          {' '}
                                          {formatCommonMessage({
                                            id: 'apoFpo.accepted',
                                            defaultMessage: 'APO/FPO also accepted',
                                          })}{' '}
                                        </p>
                                      </section>

                                      <input
                                        type="text"
                                        id="city"
                                        onKeyDown={handleKeyPress}
                                        onPaste={preventPaste}
                                        maxLength={30}
                                        aria-labelledby="cityInfo"
                                        aria-required="true"
                                        {...register('city')}
                                        className={`block w-full rounded py-[6px] px-[10px] ${
                                          errors?.city?.message ? 'border-red-500' : ''
                                        }`}
                                      />
                                      {errors?.city?.message && (
                                        <span className="inline-flex text-sm text-red-500" role="alert">
                                          {errors?.city?.message}
                                        </span>
                                      )}
                                    </section>

                                    <section className="relative mb-[15px] w-full xl:w-1/2">
                                      <label
                                        htmlFor="state"
                                        className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                      >
                                        {formatCommonMessage({
                                          id: 'state.province.region',
                                          defaultMessage: 'State/Province/Region',
                                        })}
                                        <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                          *
                                        </span>
                                      </label>
                                      <div className="relative">
                                        <select
                                          name="State/Province/Region"
                                          id="state"
                                          {...register('state')}
                                          aria-required="true"
                                          className={`block w-full rounded py-[6px] px-[10px] ${
                                            errors?.state?.message ? 'border-red-500' : ''
                                          }`}
                                        >
                                          <option value=""></option>
                                          {stateOptions.map((state) => (
                                            <option key={state.data} value={state.data}>
                                              {state.display}
                                            </option>
                                          ))}
                                        </select>
                                        <FaChevronDown
                                          aria-hidden="true"
                                          className="pointer-events-none absolute top-1/2 right-3 -translate-y-1/2 text-xs text-black-500"
                                        />
                                      </div>
                                      {errors?.state?.message && (
                                        <span className="inline-flex text-sm text-red-500" role="alert">
                                          {errors?.state?.message}
                                        </span>
                                      )}
                                    </section>
                                  </section>

                                  <section className="gap-6 xl:flex xl:justify-between">
                                    <section className="mb-[15px] w-full xl:w-1/2">
                                      <label
                                        htmlFor="zipcode"
                                        className="mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                      >
                                        {formatCommonMessage({ id: 'postalCode', defaultMessage: 'Zip/Postal Code' })}
                                        <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        id="zipcode"
                                        onInput={handlePostalCodeInput}
                                        maxLength={10}
                                        {...register('postalCode')}
                                        aria-labelledby="zipcodeLabel"
                                        aria-required="true"
                                        className={`block w-full rounded py-[6px] px-[10px] ${
                                          errors?.postalCode?.message ? 'border-red-500' : ''
                                        }`}
                                      />
                                      {errors?.postalCode?.message && (
                                        <span id="zipcodeError" className="inline-flex text-sm text-red-500">
                                          {errors?.postalCode?.message}
                                        </span>
                                      )}
                                    </section>
                                    <section className="mb-[15px] w-full xl:w-1/2">
                                      <label
                                        htmlFor="phoneNumber"
                                        className=" mb-[10px] block w-full text-[12px] font-bold text-gray-800"
                                      >
                                        {formatCommonMessage({ id: 'phoneNumber', defaultMessage: 'Phone number' })}
                                        <span className="ml-2 text-affes-sm font-bold leading-[14.4px] text-red-500">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        id="phoneNumber"
                                        onKeyDown={handleKeyPress}
                                        onPaste={preventPaste}
                                        maxLength={15}
                                        {...register('phone')}
                                        aria-labelledby="phoneNumberLabel"
                                        aria-required="true"
                                        className={`block w-full rounded py-[6px] px-[10px] ${
                                          errors?.phone?.message ? 'border-red-500' : ''
                                        }`}
                                      />
                                      {errors?.phone?.message && (
                                        <span
                                          id="phoneNumberError"
                                          className="inline-flex text-sm text-red-500"
                                          role="alert"
                                        >
                                          {errors?.phone?.message}
                                        </span>
                                      )}
                                    </section>
                                  </section>
                                  {!alwaysSave && !editAddress && isCart && loggedIn && (
                                    <section
                                      className="mb-[15px] flex w-full pb-2"
                                      role="group"
                                      aria-labelledby="saveAddressLabel"
                                    >
                                      <input
                                        type="checkbox"
                                        id="saveAddressCheckbox"
                                        className="my-auto mr-2 rounded-sm"
                                        onChange={(e) => handleSaveAddressCheckbox(e)}
                                        checked={shouldSaveAddress}
                                        aria-labelledby="saveAddressLabel"
                                      />
                                      <p
                                        id="saveAddressLabel"
                                        className="text-affes-sm leading-[20px] text-gray-900"
                                        role="checkbox"
                                        aria-checked={shouldSaveAddress}
                                      >
                                        {' '}
                                        {formatCommonMessage({
                                          id: 'save.address.nextTime',
                                          defaultMessage: 'Save the address for faster checkout next time.',
                                        })}
                                      </p>
                                    </section>
                                  )}

                                  <section className=" md:flex" role="group" aria-labelledby="addressButtonLabel">
                                    {editAddress && (
                                      <button
                                        type="submit"
                                        className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                                        onClick={handleSubmit(UpdateAddressSubmit)}
                                      >
                                        {formatAccountMessage({
                                          id: 'updateAddress',
                                          defaultMessage: 'Update Address',
                                        })}
                                      </button>
                                    )}

                                    {!alwaysSave && !editAddress && isCart && loggedIn ? (
                                      <button
                                        type="submit"
                                        className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                                      >
                                        {formatAccountMessage({
                                          id: 'useAddress',
                                          defaultMessage: 'Use Address',
                                        })}
                                      </button>
                                    ) : (
                                      !editAddress && (
                                        <button
                                          type="submit"
                                          className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                                        >
                                          {formatAccountMessage({
                                            id: 'saveAddress',
                                            defaultMessage: 'Save Address',
                                          })}
                                        </button>
                                      )
                                    )}

                                    <button
                                      type="button"
                                      className=" tm-2 block w-full  text-center text-affes-xl font-bold leading-[19.2px] text-blue-900 md:m-0 md:w-32"
                                      onClick={() => {
                                        closeAddAddressModal();
                                      }}
                                    >
                                      {formatCommonMessage({
                                        id: 'cancel',
                                        defaultMessage: 'Cancel',
                                      })}
                                    </button>
                                  </section>
                                </section>
                              </form>
                            </section>
                          )}

                          {showVerifyShipping && (
                            <section role="region" aria-label="Address Verification Section">
                              <section className="mb-[26px] flex justify-between">
                                {addressType ? (
                                  <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                    {`Verify Your ${addressType} Address`}
                                  </h3>
                                ) : (
                                  <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                    {formatCheckoutMessage({
                                      id: 'verify.shipping.address',
                                      defaultMessage: 'Verify Your Shipping Address',
                                    })}
                                  </h3>
                                )}
                              </section>
                              {isBadAddress ? (
                                <p role="alert">
                                  {formatCommonMessage({
                                    id: 'noSuggestionText',
                                    defaultMessage:
                                      'The address you entered may be incorrect or inaccurate. Please go back and update the information. You may also use the address as it appears but it may cause delivery issues.',
                                  })}
                                </p>
                              ) : (
                                <p>
                                  {formatCommonMessage({
                                    id: 'suggestionText',
                                    defaultMessage:
                                      'Based on the info you entered for address, we recommend the following revised address:',
                                  })}
                                </p>
                              )}
                              <section className="my-2 gap-6 xl:flex xl:justify-between">
                                {!isBadAddress && (
                                  <section className="w-full md:w-1/2">
                                    <section className="my-4">
                                      <input
                                        type="radio"
                                        id="suggestedAddress"
                                        className="mr-3"
                                        name="addressChoice"
                                        value="suggested"
                                        checked={addressChoice == 'suggested'}
                                        onChange={updateAddressChoice}
                                        aria-labelledby="suggestedAddressLabel"
                                      />
                                      <label
                                        id="suggestedAddressLabel"
                                        htmlFor="suggestedAddress"
                                        className="text-affes-sm font-bold leading-[16.8px] text-gray-900"
                                      >
                                        {' '}
                                        {formatCommonMessage({
                                          id: 'suggested.address',
                                          defaultMessage: 'Use suggested address',
                                        })}
                                      </label>
                                    </section>
                                    <section className="ml-8">
                                      <p className="mb-[10px] w-full text-affes-sm font-bold leading-[16.8px] text-gray-900">
                                        {userFilledAddress.firstName + ' ' + userFilledAddress.lastName}
                                      </p>
                                      <p className="w-full text-affes-sm leading-[20px] text-gray-900">
                                        {(suggestedAddress.streetName ? suggestedAddress.streetName + ' ' : '') +
                                          (suggestedAddress.streetNumber ? suggestedAddress.streetNumber : '')}
                                      </p>
                                      <p className="w-full text-affes-sm leading-[20px] text-gray-900">
                                        {suggestedAddress.city +
                                          ', ' +
                                          suggestedAddress.state +
                                          ' ' +
                                          suggestedAddress.postalCode}
                                      </p>
                                    </section>
                                  </section>
                                )}
                                <section className="w-full md:w-1/2">
                                  <section className="my-4">
                                    <input
                                      type="radio"
                                      id="typedAdress"
                                      className="mr-3"
                                      name="addressChoice"
                                      value="entered"
                                      checked={addressChoice == 'entered'}
                                      onChange={updateAddressChoice}
                                      aria-labelledby="typedAddressLabel"
                                    />
                                    <label
                                      id="typedAddressLabel"
                                      htmlFor="typedAddress"
                                      className="text-affes-sm font-bold leading-[16.8px] text-gray-900"
                                    >
                                      {' '}
                                      {formatCommonMessage({
                                        id: 'use.entered.address',
                                        defaultMessage: 'Use address you entered',
                                      })}
                                    </label>
                                  </section>
                                  <section className="ml-8">
                                    <p className="mb-[10px] w-full text-affes-sm font-bold leading-[16.8px] text-gray-900">
                                      {userFilledAddress.firstName + ' ' + userFilledAddress.lastName}
                                    </p>
                                    <p className="w-full text-affes-sm leading-[20px] text-gray-900">
                                      {(userFilledAddress.streetName ? userFilledAddress.streetName + ' ' : '') +
                                        (userFilledAddress.streetNumber ? userFilledAddress.streetNumber : '')}
                                    </p>
                                    <p className="w-full text-affes-sm leading-[20px] text-gray-900">
                                      {userFilledAddress.city +
                                        ', ' +
                                        userFilledAddress.state +
                                        ' ' +
                                        userFilledAddress.postalCode}
                                    </p>
                                  </section>
                                </section>
                              </section>
                              <section className={`mt-16 md:flex ${isBadAddress ? '' : 'justify-center'}`}>
                                <button
                                  type="button"
                                  className={`mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 ${
                                    isBadAddress ? 'md:w-[136px]' : 'md:w-[200px]'
                                  }`}
                                  onClick={addAddressSubmit}
                                >
                                  {isBadAddress
                                    ? formatAccountMessage({
                                        id: 'continue',
                                        defaultMessage: 'Continue',
                                      })
                                    : formatAccountMessage({
                                        id: 'continueToCheckoutmsg',
                                        defaultMessage: 'Continue',
                                      })}
                                </button>
                                {isBadAddress && (
                                  <button
                                    type="button"
                                    className=" mt-2 block w-full  text-center text-affes-xl font-bold leading-[19.2px] text-blue-900 md:m-0 md:w-32"
                                    onClick={() => {
                                      setShowVerifyShipping(false);
                                      setShowAddNewAddress(true);
                                    }}
                                  >
                                    {formatCommonMessage({
                                      id: 'cancel',
                                      defaultMessage: 'Cancel',
                                    })}
                                  </button>
                                )}
                              </section>
                            </section>
                          )}
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {(showLoader || parentLoaderState) && (
        <section className="z-[999]">
          <Loader></Loader>
        </section>
      )}
    </>
  );
}

export default AddAddressModal;
