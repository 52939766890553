import React, { useEffect, useState } from 'react';
import { Product } from '@Types/product/Product';
import Loader from 'components/commercetools-ui/loader/Loader';
import { UB_RANKS } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProduct } from 'frontastic';

type Props = {
  setRank?: React.Dispatch<React.SetStateAction<string>>;
  rank?: string;
  gender?: string;
  branchOfServiceDescription?: string;
  setFetchedUniforms?: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function SelectRank({ rank, gender, setRank, setFetchedUniforms, branchOfServiceDescription }: Props) {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const { selectAvailableUniforms } = useProduct();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [availableRanks, setAvailableRanks] = useState([]);
  const [isNoRank, setIsNoRank] = useState<boolean>(false);
  const getBorderStyle = (selectedGender: string) => {
    if (selectedGender === rank) {
      return 'border-blue-900 bg-blue-900 text-white';
    }
    return 'border-[#00000033] text-gray-900';
  };

  const selectUniforms = async () => {
    setIsLoad(true);
    const response = await selectAvailableUniforms(gender, branchOfServiceDescription);
    setIsLoad(false);
    if (response?.errorCode || response?.items?.length < 1) {
      setIsNoRank(true);
    } else {
      const filterUniforms = response?.items?.filter((uniform: Product) => {
        return uniform?.variants?.[0]?.isOnStock && uniform?.variants?.[0]?.attributes?.isProductShowOnSite !== false;
      });
      setFetchedUniforms(filterUniforms);
      const filterRanks = [];
      for (const item of UB_RANKS) {
        for (const res of filterUniforms) {
          if (res?.variants[0]?.attributes?.configuratorRank === item) {
            filterRanks.push(item);
            break;
          }
        }
      }
      filterRanks.length ? setAvailableRanks(filterRanks) : setIsNoRank(true);
    }
  };

  useEffect(() => {
    setRank('');
    selectUniforms();
  }, []);

  return (
    <div className="mb-5 flex h-full w-full justify-center">
      {isLoad && <Loader />}
      <div>
        <div className="flex justify-center">
          <span className="font-lato mt-[35px] mb-[17px] h-[19px] min-w-[119px] text-base font-bold leading-[19.2px] text-gray-900 md:mb-[23px] lg:mb-[23px]">
            {formatMessage({ id: 'selectYourRank', defaultMessage: 'Select Your Rank' })}{' '}
          </span>
        </div>
        {isNoRank ? (
          <p> {formatMessage({ id: 'noRankFound', defaultMessage: 'No rank found' })}</p>
        ) : (
          availableRanks?.map((item, index) => {
            return (
              <div key={index} className="mb-[15px] min-w-[174px]">
                <button
                  className={`h-[36px] w-full cursor-pointer rounded border ${getBorderStyle(item)}`}
                  onClick={() => {
                    setRank(item);
                  }}
                >
                  {item}
                </button>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
