import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount, useProduct } from 'frontastic';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { Title } from './Typography';

const Reviews = () => {
  const { formatMessage } = useFormat({ name: 'product' });
  const [isDesktop] = useMediaQuery(tablet);
  const { expandRatingReviews: expand, setExpandingRatingReviews: setExpand } = useProduct();
  const { loggedIn } = useAccount();
  return (
    <>
      <section id="reviews" data-loggedin={loggedIn}>
        {isDesktop ? (
          <>
            <div className="mt-[22px] mb-[33px]">
              <Title
                title={formatMessage({
                  id: 'product.reviews',
                  defaultMessage: 'Ratings & Reviews',
                })}
              />
              <div id="BVRRContainer"></div>
            </div>
          </>
        ) : (
          <>
            <div
              className="relative flex w-full cursor-pointer items-center justify-between text-left"
              data-expanded={expand}
              onClick={() => setExpand(!expand)}
            >
              <Title
                title={formatMessage({
                  id: 'product.reviews',
                  defaultMessage: 'Ratings & Reviews',
                })}
              />
              <span className="ml-6 flex items-center">
                {expand ? (
                  <ChevronUpIcon className="block h-6 w-6 text-gray-500 group-hover:text-gray-500" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon
                    className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                )}
              </span>
            </div>
            <div className={` ${expand ? 'mb-2 block' : 'hidden'}`}>
              <div id="BVRRContainer"></div>
            </div>
          </>
        )}
        <hr className="border border-gray-300" />
      </section>
    </>
  );
};

export default Reviews;
