import React from 'react';
import MiniFooter from 'components/commercetools-ui/aafes/miniFooter';

function guestFooterTastic({ data }) {
  return (
    <>
      <MiniFooter data={data} />
    </>
  );
}

export default guestFooterTastic;
