import React, { useEffect } from 'react';
import { useProduct } from 'frontastic';

declare global {
  interface Window {
    $BV: any;
  }
}
const RatingStars = ({ productId }) => {
  const { setExpandingRatingReviews } = useProduct();
  useEffect(() => {
    // Create a MutationObserver instance to watch for changes to window.$BV
    const observer = new MutationObserver(() => {
      if (window.$BV) {
        if (window.$BV) {
          window.$BV.configure('global', { productId });
          window.$BV.ui('rr', 'show_reviews', {
            doShowContent: function () {
              setExpandingRatingReviews(true);
            },
          });
        }
        observer.disconnect();
      }
    });
    observer.observe(document, { attributes: true, childList: true, subtree: true });

    return () => observer.disconnect();
  }, [productId]);

  return <div id="BVRRSummaryContainer"></div>;
};

export default RatingStars;
