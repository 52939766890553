import React from 'react';
import { Disclosure } from '@headlessui/react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Accordion from './accordion';
import BrandsList from './brandsList';
import Breadcrumb from './breadcrumb';
import Highlighter from './Highlighter';

const NavItem = ({ brands, handleCategorySelect, categories, currentCategory }) => {
  const { formatMessage: formatBrandsListingMessage } = useFormat({ name: 'brands-listing' });
  const { studioConfig } = useCart();
  const [isDesktop] = useMediaQuery(screenSizes.tablet);
  return (
    <div className="mx-auto w-full rounded-2xl bg-white p-2">
      <Breadcrumb
        currentCategory={currentCategory}
        categories={categories}
        handleCategorySelect={handleCategorySelect}
        formatBrandsListingMessage={formatBrandsListingMessage}
      />
      <div className="flex flex-wrap gap-8 md:flex-nowrap">
        <div className="w-full lg:w-[213px] lg:shrink-0">
          {isDesktop ? (
            <section id="brands">
              <h2 className="border-b-2 border-gray-300 pb-4 text-xl font-bold">
                {formatBrandsListingMessage({ id: 'filters.title', defaultMessage: 'Filters' })}
              </h2>
              <Accordion
                categories={categories}
                onCategorySelect={handleCategorySelect}
                currentCategory={currentCategory}
                isDesktop={isDesktop}
                close={close}
              />
            </section>
          ) : (
            <>
              <Disclosure>
                {({ open, close }) => (
                  <>
                    <h3>
                      <Disclosure.Button className="group relative flex w-full items-center justify-between rounded border-1 border-[#043C63] py-2 text-left">
                        <h2 className="px-2 text-[16px] font-medium text-gray-900">Refine by Category</h2>
                        <span className="self-center px-6 text-xl">
                          {open ? (
                            <BsChevronDown className="text-sm text-gray-900" />
                          ) : (
                            <BsChevronUp className="text-sm text-gray-900" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel>
                      <Accordion
                        categories={categories}
                        onCategorySelect={handleCategorySelect}
                        currentCategory={currentCategory}
                        isDesktop={isDesktop}
                        close={close}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </>
          )}
        </div>
        <div className="mt-5 flex flex-col md:grow lg:mt-0">
          {studioConfig?.blpFeaturedAssetId && (
            <TemplateCMS cmsContent={{ assetID: studioConfig?.blpFeaturedAssetId }} />
          )}

          <h2 className="text-lg font-bold leading-[1.375rem] lg:ml-4">
            {formatBrandsListingMessage({ id: 'brands.highlight.title', defaultMessage: 'A-Z Brands' })}
          </h2>
          <Highlighter brands={brands} />
          <BrandsList brands={brands} />
        </div>
      </div>
    </div>
  );
};

export default NavItem;
