import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';
import { validateEmail } from 'helpers/utils/emailValidator';
import { useAccount, useCart } from 'frontastic';

function ShareEmailModal({ shareByEmail, closeModal }) {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { sendWishlistTemplate } = useAccount();
  const { studioConfig } = useCart();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();

  const [count, setCount] = useState(500);
  const [typeCount, setTypeCount] = useState(0);
  const [emailCount, setEmailCount] = useState(1);
  const [recipientsEmail, setRecipientsEmail] = useState([{ email: '' }]);
  const [emailError, setEmailError] = useState([null]);
  const [yourEmail, setYourEmail] = useState('');
  const [yourEmailError, setYourEmailError] = useState(null);
  const [yourName, setYourName] = useState('');
  const [nameError, setNameError] = useState(null);

  const handleMessageChange = (event) => {
    const message = event.target.value;
    const currentLength = message.length;
    if (currentLength <= 500) {
      setTypeCount(currentLength);
    } else {
      setTypeCount(500);
      event.preventDefault();
    }
  };

  const emailCountHandler = (event) => {
    event.preventDefault();
    setEmailCount(emailCount + 1);
  };

  const handleDelete = (index) => {
    const emailList = [...recipientsEmail];
    const errorList = [...emailError];
    emailList.splice(index, 1);
    errorList.splice(index, 1);
    setRecipientsEmail(emailList);
    setEmailError(errorList);
    setEmailCount(emailCount - 1);
  };

  const errorMessages = {
    emailRequired: 'Email is required',
    emailInvalid: 'Invalid Email',
    nameRequired: 'Name is required',
  };

  const validateForm = () => {
    let isValid = true;
    setEmailError([]);
    recipientsEmail.map((email, index) => {
      if (!email.email || email.email == null || email.email == '') {
        setEmailError((error) => [...error, errorMessages.emailRequired]);
        isValid = false;
      } else if (!validateEmail(email.email)) {
        setEmailError((error) => [...error, errorMessages.emailInvalid]);
        isValid = false;
      } else {
        setEmailError((error) => [...error, '']);
      }
    });

    if (!yourEmail || yourEmail == null || yourEmail == '') {
      setYourEmailError(errorMessages.emailRequired);
      isValid = false;
    } else if (!validateEmail(yourEmail)) {
      setYourEmailError(errorMessages.emailInvalid);
      isValid = false;
    } else {
      setYourEmailError('');
    }

    if (!yourName || yourName == null || yourName == '') {
      setNameError(errorMessages.nameRequired);
      isValid = false;
    } else {
      setNameError('');
    }

    return isValid;
  };

  const shareByEmailPayload = async (data, email) => {
    await sendWishlistTemplate(data, email).then(() => {
      closeModal();
    });
  };

  const onSubmit = (data) => {
    data.recipientsEmail.map((email, index) => {
      shareByEmailPayload(data, email);
    });

    reset();
    closeModal();
  };
  const closeModalAndReset = () => {
    closeModal();
    reset();
    setTypeCount(0);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      closeModal();
    }
  };
  const addFormFields = (e) => {
    setRecipientsEmail([...recipientsEmail, { email: '' }]);
    emailCountHandler(e);
  };

  return (
    <>
      <Transition appear show={shareByEmail} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={closeModalAndReset}
                  aria-modal="true"
                  aria-labelledby="emailWishlistModalLabel"
                >
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[550px] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto overflow-x-hidden rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[480px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={handleKeyDown}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section className="modalHeader relative mb-[22px]">
                        <button
                          aria-label="Close Modal"
                          type="button"
                          className="absolute right-0 top-0"
                          onClick={closeModalAndReset}
                        >
                          <GrClose />
                        </button>
                        <h3
                          id="emailWishlistModalLabel"
                          className="text-[18px] font-bold leading-[21.6px] text-gray-900"
                        >
                          {formatAccountMessage({
                            id: 'email.your.wishlist',
                            defaultMessage: 'Email Your Wishlist',
                          })}
                        </h3>
                      </section>
                      <section className="md:w-[24rem]">
                        <form onSubmit={handleSubmit(onSubmit)} aria-labelledby="emailWishlistFormLabel">
                          <ul>
                            {recipientsEmail.map((element, i) => {
                              return (
                                <li key={i} className="mb-2">
                                  <label htmlFor="email" className="mb-1 block text-affes-base font-bold text-gray-800">
                                    {formatAccountMessage({
                                      id: 'recipients.email',
                                      defaultMessage: "Recipients's Email Address",
                                    })}{' '}
                                    {i === 0 && (
                                      <sup className="relative top-0 mb-[10px] inline-block text-[12px] font-bold text-red-900">
                                        {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                                      </sup>
                                    )}
                                  </label>
                                  <input
                                    type="email"
                                    id="email"
                                    name={`recipientsEmail[${i}].email`}
                                    defaultValue={element.email || ''}
                                    maxLength={40}
                                    {...register(`recipientsEmail[${i}].email`, {
                                      required: formatAccountMessage({
                                        id: 'emailRequired',
                                        defaultMessage: 'Email is required',
                                      }),
                                      validate: (value) => {
                                        if (validateEmail(value)) {
                                          return true;
                                        }
                                        return formatAccountMessage({
                                          id: 'invalidEmail',
                                          defaultMessage: 'Invalid Email',
                                        });
                                      },
                                    })}
                                    className={`mb-2 w-full border  border-gray-600 md:mb-0 md:w-[17rem] ${
                                      errors.recipientsEmail && errors.recipientsEmail[i]?.email
                                        ? 'border-red-500 text-red-500   focus:ring-0'
                                        : 'border-gray-600 text-black-500'
                                    }`}
                                    onChange={() => clearErrors('recipientsEmail')}
                                    aria-describedby={`emailValidationError-${i}`}
                                  />
                                  {i !== 0 && (
                                    <button
                                      onClick={() => handleDelete(i)}
                                      className="text-affes-sm text-blue-900 md:ml-2"
                                      type="button"
                                    >
                                      {formatAccountMessage({ id: 'remove', defaultMessage: 'Remove' })}
                                    </button>
                                  )}
                                  {errors.recipientsEmail && errors.recipientsEmail[i]?.email && (
                                    <p id={`emailValidationError-${i}`} className="text-[12px] text-red-900">
                                      {errors.recipientsEmail[i].email.message}
                                    </p>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                          {studioConfig?.emailCountLimit && emailCount < studioConfig?.emailCountLimit && (
                            <p className="text-right md:w-[17rem]">
                              <button
                                type="button"
                                onClick={addFormFields}
                                name=""
                                className="text-affes-sm text-blue-900"
                              >
                                {formatAccountMessage({
                                  id: 'add.another.email',
                                  defaultMessage: '+ Add another email address',
                                })}
                              </button>
                            </p>
                          )}
                          <p className="mb-2">
                            <label htmlFor="yourName" className="mb-1 block text-affes-base font-bold text-gray-800">
                              {formatAccountMessage({ id: 'your.name', defaultMessage: 'Your Name:' })}{' '}
                              <sup
                                className="relative top-0 mb-[10px] inline-block text-[12px] font-bold text-red-900"
                                aria-hidden="true"
                              >
                                {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                              </sup>
                            </label>
                            <input
                              type="text"
                              id="yourName"
                              name="yourName"
                              defaultValue={yourName}
                              {...register('yourName', {
                                required: formatAccountMessage({
                                  id: 'nameRequired',
                                  defaultMessage: 'Name is required.',
                                }),
                                pattern: {
                                  value: /^[A-Za-z]+$/,
                                  message: formatAccountMessage({
                                    id: 'onlyAlphabets',
                                    defaultMessage: 'Only alphabets are allowed.',
                                  }),
                                },
                              })}
                              className={`w-full border border-gray-600 md:w-[17rem] ${
                                errors?.yourName
                                  ? 'border-red-500 text-red-500   focus:ring-0'
                                  : 'border-gray-600 text-black-500'
                              }`}
                              onChange={() => clearErrors('yourName')}
                              maxLength={50}
                              aria-invalid={errors?.yourName ? 'true' : 'false'}
                              aria-describedby="yourNameError"
                            />
                            {errors.yourName && (
                              <p id="yourNameError" className="text-[12px] text-red-900">
                                {errors.yourName.message}
                              </p>
                            )}
                          </p>
                          <p className="mb-2">
                            <label htmlFor="yourEmail" className="mb-1 block text-affes-base font-bold text-gray-800">
                              {formatAccountMessage({ id: 'your.email', defaultMessage: 'Your Email Address:' })}{' '}
                              <sup
                                className="relative top-0 mb-[10px] inline-block text-[12px] font-bold text-red-900"
                                aria-hidden="true"
                              >
                                {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                              </sup>
                            </label>
                            <input
                              type="email"
                              id="yourEmail"
                              name="yourEmail"
                              defaultValue={yourEmail}
                              maxLength={40}
                              {...register('yourEmail', {
                                required: formatAccountMessage({
                                  id: 'emailRequired',
                                  defaultMessage: 'Email is required',
                                }),
                                validate: (value) => {
                                  if (validateEmail(value)) {
                                    return true;
                                  }
                                  return formatAccountMessage({
                                    id: 'invalidEmail',
                                    defaultMessage: 'Invalid Email',
                                  });
                                },
                              })}
                              className={`w-full border border-gray-600 md:w-[17rem] ${
                                errors.yourEmail
                                  ? 'border-red-500 text-red-500   focus:ring-0'
                                  : 'border-gray-600 text-black-500'
                              }`}
                              onChange={() => clearErrors('yourEmail')}
                              aria-invalid={errors?.yourEmail ? 'true' : 'false'}
                              aria-describedby="yourEmailError"
                            />
                            {errors.yourEmail && (
                              <p id="yourEmailError" className="text-[12px] text-red-900">
                                {errors.yourEmail.message}
                              </p>
                            )}
                          </p>
                          <p className="mb-2">
                            <label htmlFor="message" className="mb-1 block text-affes-base font-bold text-gray-800">
                              {formatAccountMessage({ id: 'message', defaultMessage: 'Message:' })}
                            </label>
                            <textarea
                              id="message"
                              name="message"
                              rows={5}
                              {...register('message', { maxLength: 500 })}
                              className="w-full border border-gray-600 md:w-[17rem]"
                              onChange={(e) => {
                                handleMessageChange(e);
                                const inputValue = e.target.value;
                                if (inputValue.length > 500) {
                                  e.target.value = inputValue.slice(0, 500);
                                }
                              }}
                              aria-describedby="messageError"
                            ></textarea>
                          </p>
                          <p className="mb-[10px] block w-full text-right text-[12px] font-medium text-gray-800 md:w-[17rem]">
                            {count - typeCount}{' '}
                            {formatAccountMessage({ id: 'character.left', defaultMessage: 'Characters Left' })}
                          </p>
                          <section className="md:flex">
                            <button
                              type="submit"
                              className="mr-10 mb-[10px] w-full rounded-sm bg-blue-900 py-3 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                            >
                              {formatAccountMessage({ id: 'send', defaultMessage: 'Send' })}
                            </button>
                            <button
                              type="button"
                              onClick={closeModalAndReset}
                              className="w-full py-3 text-affes-xl font-bold leading-[19.2px] text-blue-900 md:w-auto"
                            >
                              {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                            </button>
                          </section>
                        </form>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ShareEmailModal;
