import React, { useMemo } from 'react';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

export default function PriceDetails({ product }) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const sku = product?.variants?.[0];
  const listPrice = sku?.listPrice;
  const salePrice = sku?.salePrice;
  const msrp = sku?.msrp;
  const activePrice = sku?.activePrice;

  const msrpValues = product?.variants?.map((variant) => variant?.msrp || null);
  const filteredMsrpValues = msrpValues?.filter((value) => typeof value === 'number');
  const uniqueMsrpValues = Array.from(new Set(filteredMsrpValues)) as number[];

  const listPriceValues = product?.variants?.map((variant) => variant?.listPrice || null);
  const filteredListPriceValues = listPriceValues?.filter((value) => typeof value === 'number');
  const uniqueListPriceValues = Array.from(new Set(filteredListPriceValues)) as number[];

  const salePriceValues = product?.variants?.map((variant) => variant?.salePrice || null);
  const filteredSalePriceValues = salePriceValues?.filter((value) => typeof value === 'number');
  const uniqueSalePriceValues = Array.from(new Set(filteredSalePriceValues)) as number[];

  const priceRangeMaxMSRP =
    product?.variants?.length === 1
      ? msrp ?? undefined
      : uniqueMsrpValues?.length > 0
      ? Math.max(...uniqueMsrpValues)
      : undefined;
  const priceRangeMaxListPrice =
    product?.variants?.length === 1
      ? listPrice ?? undefined
      : uniqueListPriceValues?.length > 0
      ? Math.max(...uniqueListPriceValues)
      : undefined;
  const priceRangeMaxSalePrice =
    product?.variants?.length === 1
      ? salePrice ?? undefined
      : uniqueSalePriceValues?.length > 0
      ? uniqueListPriceValues?.length === 1 &&
        uniqueListPriceValues?.[0] > Math.min(...uniqueSalePriceValues) &&
        uniqueListPriceValues?.[0] <= Math.max(...uniqueSalePriceValues) &&
        !priceRangeMaxMSRP
        ? Math.min(...uniqueSalePriceValues)
        : Math.max(...uniqueSalePriceValues)
      : undefined;

  const isListPriceLarger = useMemo(() => {
    if (!priceRangeMaxSalePrice) return true;
    if (product?.variants?.length === 1) return listPrice > salePrice;
    if (
      uniqueListPriceValues?.length === 1 &&
      uniqueSalePriceValues?.length > 1 &&
      uniqueListPriceValues?.[0] >= Math.max(...uniqueSalePriceValues)
    )
      return uniqueListPriceValues?.[0] > Math.min(...uniqueSalePriceValues);
    else {
      return priceRangeMaxListPrice > priceRangeMaxSalePrice;
    }
  }, [listPrice, salePrice]);

  const calculateMoneyStats = (moneyArray) => {
    // Filter out undefined values and sort the array
    const filteredMoneyArray = moneyArray?.filter((money) => money !== undefined)?.sort((a, b) => (a || 0) - (b || 0));

    // Get the lowest and highest values
    const lowest = filteredMoneyArray?.[0];
    const highest = filteredMoneyArray?.[filteredMoneyArray?.length - 1];

    // Calculate the difference in value
    const difference =
      lowest && highest
        ? {
            centAmount: (highest || 0) - (lowest || 0),
          }
        : undefined;

    // Calculate the percentage difference
    const percentageDifference = difference ? (difference?.centAmount / (highest || 1)) * 100 : 0;

    return {
      difference,
      percentageDifference: Math.round(percentageDifference),
      filteredMoneyArray,
    };
  };

  const displayMsrpRange = useMemo(() => {
    if (product?.variants?.length > 1) {
      if (uniqueMsrpValues?.length > 1) {
        const minMsrp = Math.min(...uniqueMsrpValues) as number;
        const maxMsrp = Math.max(...uniqueMsrpValues) as number;
        return `${CurrencyHelpers.formatForCurrency(minMsrp * 100)} - ${CurrencyHelpers.formatForCurrency(
          maxMsrp * 100,
        )}`;
      } else if (uniqueMsrpValues?.length === 1) {
        const uniqueMsrpPrice = uniqueMsrpValues?.[0];
        return uniqueMsrpPrice !== undefined ? `${CurrencyHelpers.formatForCurrency(uniqueMsrpPrice * 100)}` : null;
      }
    }

    // Default format when there is only one unique MSRP value or no variants
    return msrp !== undefined ? `${CurrencyHelpers.formatForCurrency(msrp * 100)}` : null;
  }, [product?.variants]);

  const displayListPriceRange = useMemo(() => {
    if (product?.variants?.length > 1) {
      if (uniqueListPriceValues?.length > 1) {
        const minListPrice = Math.min(...uniqueListPriceValues) as number;
        const maxListPrice = Math.max(...uniqueListPriceValues) as number;
        return `${CurrencyHelpers.formatForCurrency(minListPrice * 100)} - ${CurrencyHelpers.formatForCurrency(
          maxListPrice * 100,
        )}`;
      } else if (uniqueListPriceValues?.length === 1) {
        const uniqueListPrice = uniqueListPriceValues?.[0];
        return uniqueListPrice !== undefined ? CurrencyHelpers.formatForCurrency(uniqueListPrice * 100) : null;
      }
    }

    // Default format when there is only one unique listPrice value or no variants
    return listPrice !== undefined ? CurrencyHelpers.formatForCurrency(listPrice * 100) : null;
  }, [product?.variants]);

  const displaySalePriceRange = useMemo(() => {
    if (product?.variants?.length > 1) {
      if (uniqueSalePriceValues?.length > 1) {
        const minSalePrice = Math.min(...uniqueSalePriceValues) as number;
        const maxSalePrice = Math.max(...uniqueSalePriceValues) as number;
        return (
          isListPriceLarger &&
          `${CurrencyHelpers.formatForCurrency(minSalePrice * 100)} - ${CurrencyHelpers.formatForCurrency(
            maxSalePrice * 100,
          )} Sale`
        );
      } else if (uniqueSalePriceValues?.length === 1) {
        const uniqueSalePrice = uniqueSalePriceValues?.[0];
        return isListPriceLarger && uniqueSalePrice
          ? `${CurrencyHelpers.formatForCurrency(uniqueSalePrice * 100)} Sale`
          : null;
      }
    }
    // Default format when there is only one unique salePrice value or no variants
    return isListPriceLarger && salePrice ? `${CurrencyHelpers.formatForCurrency(salePrice * 100)} Sale` : null;
  }, [product?.variants]);

  const moneyArray = [priceRangeMaxMSRP, priceRangeMaxListPrice, priceRangeMaxSalePrice];
  const moneyStats = useMemo(() => {
    return calculateMoneyStats(moneyArray);
  }, [moneyArray]);

  const isOnlylistPrice = useMemo(() => {
    return msrp === undefined && !isListPriceLarger && moneyStats?.difference?.centAmount <= 0;
  }, [msrp, isListPriceLarger, moneyStats?.difference?.centAmount]);

  const saveUpto = useMemo(() => {
    return uniqueListPriceValues?.length > 1 || uniqueMsrpValues?.length > 1 || uniqueSalePriceValues?.length > 1;
  }, [priceRangeMaxMSRP, priceRangeMaxListPrice, priceRangeMaxSalePrice]);

  return (
    <section className="flex flex-col gap-y-2">
      {displayMsrpRange && (
        <p className="text-sm leading-5 text-gray-900">
          {formatProductMessage({ id: 'compare.at', defaultMessage: 'Compare at:' })}{' '}
          <span className="line-through">{displayMsrpRange}</span>
        </p>
      )}

      <div className="flex flex-wrap gap-2">
        <span className={`text-sm leading-5 text-gray-900 ${isOnlylistPrice && 'font-bold'}`}>
          {displayListPriceRange}
        </span>

        {displaySalePriceRange && <p className="text-sm font-bold leading-5 text-[#DA0F0F]">{displaySalePriceRange}</p>}
      </div>
      {moneyStats?.difference?.centAmount > 0 && (
        <section className="relative w-max">
          <p className={`font-lato flex items-center text-sm font-normal leading-[16.8px] text-[#666666]`}>
            <span>{saveUpto ? 'Save up to' : 'You Save:'}</span>
            <section className={`ml-1`}>
              {CurrencyHelpers.formatForCurrency(moneyStats?.difference?.centAmount * 100)}
              <span className={`ml-1`}>({moneyStats?.percentageDifference}%)</span>
            </section>
          </p>
        </section>
      )}
    </section>
  );
}
