import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import ImageMagnifier from 'components/commercetools-ui/aafes/ImageMagnifier';
import { UB_REQUIRED_FIELD_TEXT, UB_OPTIONAL_FIELD_TEXT, UB_APPAREL_IMAGE_ZOOM_PROPS } from 'helpers/constants/aafes';
interface ButtonOption {
  value: string;
  label: string;
  isOnStock: boolean;
}

interface UBApparelSelectorProps {
  defaultImageApparel?: string;
  title: string;
  sizeText: string;
  selectedSku: any;
  buttonOptions: ButtonOption[];
  maxButtonsPerRow: number;
  buttonWidth: number;
  gap: number;
  onSelect: (value: any) => void;
  isMandatory?: boolean;
}

const UBApparelSelector: React.FC<UBApparelSelectorProps> = ({
  defaultImageApparel,
  title,
  sizeText,
  buttonOptions,
  maxButtonsPerRow,
  buttonWidth,
  gap,
  onSelect,
  isMandatory,
  selectedSku,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const myLoader = ({ src }: { src: string }) => {
    return src || '';
  };
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
  return (
    <div className="border-b border-[#00000033] pb-7 text-[#333]">
      <div className="mb-8 flex items-start">
        <ImageMagnifier zoomProps={UB_APPAREL_IMAGE_ZOOM_PROPS}>
          <Image
            src={selectedSku?.image || defaultImageApparel}
            width={64}
            height={64}
            alt={title}
            loader={myLoader}
            id={`key_${selectedSku?.image}`}
          />
        </ImageMagnifier>
        <h2 className="ml-3.5 self-start leading-5">
          <span className="inline-block text-base font-semibold leading-4">{title}</span>
          {isMandatory ? (
            <span className="ml-1 inline-block text-sm font-normal not-italic leading-4 text-red-700">
              {UB_REQUIRED_FIELD_TEXT}
            </span>
          ) : (
            <span className="ml-0 inline-block text-sm font-normal not-italic leading-4 sm:ml-2.5">
              {UB_OPTIONAL_FIELD_TEXT}
            </span>
          )}
        </h2>
      </div>
      <p className="mb-3 text-sm font-bold leading-affes-7.5">
        <span className="inline-block">{sizeText}</span>
        <span className="ml-2 inline-block text-sm font-normal leading-affes-7.5">{selectedSku?.label}</span>
      </p>
      <div className="flex gap-2.5">
        <div
          className="flex flex-wrap"
          style={{ gap: `${gap}px`, maxWidth: `${(buttonWidth + gap) * maxButtonsPerRow - gap}px` }}
        >
          {buttonOptions.slice(0, isOpen ? buttonOptions.length : maxButtonsPerRow).map((option) => (
            <button
              key={option.value}
              onClick={() => onSelect(option)}
              className={`h-[36px] w-[${buttonWidth}px] rounded-[4px] text-sm font-normal ${
                option?.isOnStock
                  ? selectedSku?.value === option?.value
                    ? 'border-blue-900 bg-blue-900 text-white'
                    : 'border border-[#00000033] bg-white'
                  : 'border-2 border-dashed border-[#bebebe] bg-[#f9f9f9]'
              }`}
              disabled={!option?.isOnStock}
            >
              {option.label}
            </button>
          ))}
        </div>
        {buttonOptions.length > maxButtonsPerRow &&
          (isMobile ? (
            <div className="ml-auto">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`h-[36px] w-[40px] rounded-[4px] border border-[#00000033] bg-white text-xl font-normal text-blue-900`}
              >
                {isOpen ? '-' : '+'}
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={`h-[36px] w-[40px] rounded-[4px] border border-[#00000033] bg-white text-xl font-normal text-blue-900`}
              >
                {isOpen ? '-' : '+'}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UBApparelSelector;
