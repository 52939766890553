import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { Body, Title } from './Typography';

function WarrantyInfo({ warrantyData }) {
  const [isDesktop] = useMediaQuery(tablet);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const warrantyTitle = formatProductMessage({ id: 'warrantyInfo', defaultMessage: 'Warranty' });
  return (
    <>
      {isDesktop ? (
        <section id="warranty">
          <Title title={warrantyTitle} />
          <Body body={warrantyData} />
        </section>
      ) : (
        <>
          <Disclosure>
            {({ open }) => (
              <>
                <h3>
                  <Disclosure.Button className="group relative flex w-full items-center justify-between text-left">
                    <Title title={warrantyTitle} />
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <ChevronUpIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel>
                  <Body body={warrantyData} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </>
      )}

      <hr className="border border-gray-300" />
    </>
  );
}

export default WarrantyInfo;
