import React from 'react';
import Checkout from 'components/commercetools-ui/aafes/checkout';
import { useCart } from 'frontastic/provider';
import Head from 'next/head';
import { useFormat } from 'helpers/hooks/useFormat';

const CheckoutTastic = ({ onSubmit, data }) => {
  const { data: cartList } = useCart();
  const { formatMessage } = useFormat({ name: 'cart' });
  return (
    <>
      <Head>
        <meta name="description" content={formatMessage({ id: 'checkout', defaultMessage: 'checkout' })} />
      </Head>
      <Checkout cartList={cartList} onSubmit={onSubmit} giftCard={data} />
    </>
  );
};

export default CheckoutTastic;
