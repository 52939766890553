import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { Title } from './Typography';

export default function SpecialOffers({ variantPromotion }) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isDesktopSize] = useMediaQuery(tablet);

  const renderPromotionItem = (promotion) => (
    <div className="mb-10 flex" key={`key_${promotion?.name}`}>
      <div className="w-[71px] shrink-0">
        <img alt={`Image_${promotion?.name}`} width="71" height="62" src={promotion?.imageUrl} />
      </div>
      <div className="p-5 lg:p-0 lg:pl-5">
        <p className="mb-3 text-sm font-bold">{promotion?.name}</p>
        <div dangerouslySetInnerHTML={{ __html: promotion?.description }}></div>
      </div>
    </div>
  );

  return (
    <section id="specialOffers">
      {isDesktopSize ? (
        <>
          <Title title={formatProductMessage({ id: 'specialOffers', defaultMessage: 'Special Offers' })} />
          <div className="grid md:gap-x-5 lg:grid-cols-2">{variantPromotion.map(renderPromotionItem)}</div>
        </>
      ) : (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="SpecialOffersBtn flex w-full items-center justify-between text-left">
                <Title title={formatProductMessage({ id: 'specialOffers', defaultMessage: 'Special Offers' })} />
                {open ? (
                  <ChevronUpIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                )}
              </Disclosure.Button>

              <Disclosure.Panel>{variantPromotion.map(renderPromotionItem)}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      <hr className="border border-gray-300" />
    </section>
  );
}
