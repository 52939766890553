import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import cookieCutter from 'cookie-cutter';
import { CgClose } from 'react-icons/cg';
import { MARKETING_BUG_NAMES } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
const LoginModal = dynamic(() => import('../Modals/Login/login'));
import PriceDetails from './priceDetails';
export default function ProductInfo({
  matchingVariants,
  isMarketPlaceItem,
  isUseFacilityBasedPrice,
  product,
  setIsLoad,
}) {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const [loginModal, setLoginModal] = useState(false);
  const handleAppendURL = (skuToRemove) => {
    setIsLoad(true);
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    const currentSkus = url?.searchParams?.get('skus');
    const storedCompareProducts = JSON.parse(cookieCutter.get('compareProducts') || '[]');
    if (currentSkus) {
      const skusArray = JSON?.parse(currentSkus);
      const updatedSkus = skusArray?.filter((sku) => sku !== skuToRemove);
      url?.searchParams?.set('skus', JSON?.stringify(updatedSkus));
      const updatedCompareProducts = storedCompareProducts?.filter((product) => product?.skuId !== skuToRemove);
      cookieCutter.set('compareProducts', JSON.stringify(updatedCompareProducts));
      router
        .push(url?.toString())
        .then(() => {
          setIsLoad(false);
        })
        .catch(() => setIsLoad(false));
    } else {
      setIsLoad(false);
    }
  };
  function closeLoginModal() {
    setLoginModal(false);
  }

  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) return;
    setIsLoad(true);
  };

  return (
    <>
      <div className="relative h-full w-full border-b border-r border-[#00000033] px-2 pt-6">
        {!isMarketPlaceItem && (
          <div
            className={`item-tag absolute top-0.5 left-3 ${matchingVariants?.marketingBug} rounded-[3px] px-2 py-0.5 text-xs font-bold leading-5 text-white`}
          >
            {MARKETING_BUG_NAMES?.[matchingVariants?.marketingBug]}
          </div>
        )}
        <div className="flex h-full gap-x-3 py-2">
          <div className="h-[75px] min-w-[75px]">
            <NextLink href={product?._url}>
              <a aria-label="Go to PDP page">
                <Image
                  onClick={(event) => {
                    handleClick(event);
                  }}
                  loader={({ src }) => src}
                  className="h-full w-full cursor-pointer"
                  src={matchingVariants[0]?.images?.[0]}
                  alt={product?.name}
                />
              </a>
            </NextLink>
          </div>
          <div className="flex flex-col place-content-between gap-y-3">
            <div className="flex flex-col gap-y-3">
              <NextLink href={product?._url}>
                <a aria-label="Go to PDP page">
                  <span
                    onClick={(event) => {
                      handleClick(event);
                    }}
                    className="cursor-pointer text-sm font-bold leading-5 text-blue-900 hover:underline"
                  >
                    {product?.name}
                  </span>
                </a>
              </NextLink>
              {!isUseFacilityBasedPrice && !isMarketPlaceItem && (
                <PriceDetails product={product} setLoginModal={setLoginModal} />
              )}
            </div>
            {!isMarketPlaceItem && <div id={`BVRRInlineRating-${product?.productId}`}></div>}
            <NextLink href={product?._url}>
              <button
                onClick={() => {
                  setIsLoad(true);
                }}
                className="h-9 w-[102px] rounded bg-blue-900 leading-4 text-white"
              >
                {formatMessage({ id: 'select', defaultMessage: 'Select' })}
              </button>
            </NextLink>
          </div>
        </div>
        <CgClose
          onClick={() => {
            handleAppendURL(matchingVariants?.[0]?.sku);
          }}
          className="absolute top-0.5 right-1 h-5 w-5 cursor-pointer text-gray-900"
        />
      </div>
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        closeAccountFlyout={closeLoginModal}
        loginCallBack={() => router.push(router.asPath)}
      />
    </>
  );
}
