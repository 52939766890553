import React from 'react';

type Props = { quantity: number; setQuantity: React.Dispatch<React.SetStateAction<number>>; maxQty?: number };

const PdpQuantityDropdown = ({ quantity, setQuantity, maxQty = 20 }: Props) => {
  const maxCountList = () => {
    const content = [];
    for (let i = 1; i <= maxQty; i++) {
      content.push(
        <option value={i} defaultValue={quantity} key={i}>
          {i}
        </option>,
      );
    }
    return content;
  };

  const handleQuantityUpdate = (e) => {
    const value = parseInt(e?.target?.value);
    setQuantity(value);
  };
  return (
    <>
      <section className="text-center">
        <select
          value={quantity}
          onChange={handleQuantityUpdate}
          className="select cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900"
        >
          {maxCountList()}
        </select>
      </section>
    </>
  );
};

export default PdpQuantityDropdown;
