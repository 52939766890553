import { GA_REMOVE_FROM_CART, GA_SERVER_URL } from 'helpers/constants/aafes';
import { useCallback } from 'react';
import useGATrackUserAction from './useGATrackUserAction';
import { getUserProperties } from './useGATrackViewActions';

const useGATrackRemoveAction = () => {
  const { calculateAge } = useGATrackUserAction();
  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const trackRemoveFromCart = useCallback((trackRemoveFromCartObj: any) => {
    const { item, account } = trackRemoveFromCartObj;
    const gtag = window?.gtag;
    try {
      const removeFromCart = {
        currency: item?.totalPrice?.currencyCode,
        value: item?.totalPrice?.centAmount / 10 ** item?.totalPrice?.fractionDigits,
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: {
          item_id: item?.variant?.sku,
          item_name: item?.name,
          product_id: item?.variant?.productKey,
          discount: 0,
          item_brand: item?.variant?.attributes?.brand || '',
          size: item?.variant?.picker?.Size || '',
          color: item?.variant?.picker?.Color || '',
          price: item?.price?.centAmount / 10 ** item?.price?.fractionDigits,
          quantity: item?.count,
        },
      };
      gtag('event', GA_REMOVE_FROM_CART, removeFromCart);
    } catch (error) {
      console.error('ga4 remove from cart event fail', error);
    }
  }, []);

  return { trackRemoveFromCart };
};

export default useGATrackRemoveAction;
