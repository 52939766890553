import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import {
  NestedUpdateOrInsert,
  GetOrdinalSuffix,
  GetAddBundleJson,
  ValidateAddBundle,
  GetBundlePrice,
} from 'helpers/utils/ub-utils';
import { useRouter } from 'next/router';
import { UB_PATCH_OPTIONS } from 'helpers/constants/aafes';
const UniformBuilderContext = createContext(null);
import { getCartItems } from 'frontastic/actions/cart';
function UniformBuilderProvider({ children, initialState }) {
  const router = useRouter();
  const { patchSelectedOnLoad, userSwitchedPatch } = initialState;
  const [ubState, setUbState] = useState(initialState);
  const structureUbData = useCallback((uniformInCart?: any) => {
    const {
      patchTypeOptions,
      selectedPatchType,
      setSelectedPatchType,
      setUserSwitchedPatch,
      sewOnProduct,
      product: {
        variants,
        variants: [{ sku: bundleSku = '', attributes: { configurableProperties = [] } = {} } = {}] = [],
        name = '',
      } = {},
    } = ubState || {};
    const bundleConfiguration = uniformInCart?.bundleConfiguration || [];
    let configurablePropertiesTransformed = [];
    if (uniformInCart) {
      configurablePropertiesTransformed = configurableProperties.map(
        ({
          id = '',
          isMandatory = false,
          configurationOption: { product: { variants = [], name = '' } = {}, productGroups = [] } = {},
        }) => {
          const bundledItem = bundleConfiguration.find((item: any) => item.id === id);
          const filteredProductGroups = productGroups
            .map((group) => {
              const matchingGroups = bundledItem?.productGroups?.filter(
                (bundledGroup: any) => bundledGroup.key === group.key,
              );
              if (matchingGroups?.length) {
                return {
                  ...group,
                  product: matchingGroups.map((matchingGroup: any) => ({
                    ...matchingGroup.product,
                    image: matchingGroup.product?.image || 'Unavailable',
                    imageAltTxt: matchingGroup.product?.imageAltText || 'Unavailble',
                    price: matchingGroup.product?.price || null,
                  })),
                };
              }
              return {
                ...group,
                product: [],
              };
            })
            .filter(Boolean);

          return {
            id,
            name,
            productSku: bundledItem?.product?.sku || null, // Using optional chaining
            variants,
            productGroups: filteredProductGroups,
            isMandatory,
          };
        },
      );
    } else {
      configurablePropertiesTransformed = configurableProperties.map(
        ({
          id = '',
          isMandatory = false,
          configurationOption: { product: { variants = [], name = '' } = {}, productGroups = [] } = {},
        }) => ({
          id,
          name,
          productSku: null,
          variants,
          productGroups: productGroups.map((group) => ({ ...group, product: [] })),
          isMandatory,
        }),
      );
    }
    const uniformId = router.query.uniformId;
    const newObject = {
      bundleSku,
      name,
      quantity: uniformInCart?.count || 1,
      maxQuantity: variants?.[0]?.attributes?.maxQty,
      configurableProperties: configurablePropertiesTransformed,
      images: [variants?.[0]?.imageSet?.find((innerItem: any) => innerItem?.type === 'xlarge')?.src?.[0]?.url],
      patchTypeOptions,
      selectedPatchType,
      setSelectedPatchType,
      userSwitchedPatch,
      setUserSwitchedPatch,
      sewOnProduct,
      skuPrices: initialState?.skuPrices,
      ...(uniformId ? { uniformId: uniformId } : {}),
    };
    if (!patchSelectedOnLoad?.current) {
      if (uniformInCart?.isSewOn || !uniformInCart) {
        setSelectedPatchType(UB_PATCH_OPTIONS?.[0]);
      } else {
        setSelectedPatchType(UB_PATCH_OPTIONS?.[1]);
      }
      patchSelectedOnLoad.current = true;
    }
    setUbState(newObject);
  }, []);

  const prepareUniformData = useCallback(async () => {
    const uniformId = router.query.uniformId;
    if (uniformId) {
      const cartItems = await getCartItems();
      const uniformInCart = cartItems?.lineItems?.find((item: any) => item?.lineItemId === uniformId);

      if (uniformInCart) {
        structureUbData(uniformInCart);
      } else {
        structureUbData();
      }
    } else {
      structureUbData();
    }
  }, []);
  useEffect(() => {
    if (userSwitchedPatch) {
      structureUbData();
    } else {
      prepareUniformData();
    }
  }, []); //this needs to only run on the page load

  const value = useMemo(
    () => [
      ubState,
      setUbState,
      NestedUpdateOrInsert,
      GetOrdinalSuffix,
      GetAddBundleJson,
      ValidateAddBundle,
      GetBundlePrice,
    ],
    [ubState, setUbState],
  );

  return <UniformBuilderContext.Provider value={value}>{children}</UniformBuilderContext.Provider>;
}

export { UniformBuilderContext, UniformBuilderProvider };
