import React, { Fragment } from 'react';
import Link from 'next/link';
import { Dialog, Transition } from '@headlessui/react';
import { PIA } from 'helpers/constants/aafes';

function PrivayAct({ closeModal, siteTermsModal }) {
  return (
    <>
      <Transition appear show={siteTermsModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="fixed inset-0" onClick={closeModal}>
                  <section
                    className="fixed top-1/2 left-1/2 max-h-[700px] w-[300px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[90%] lg:mt-3 xl:w-[1000px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="text-xl  font-semibold text-gray-900 dark:text-white">Terms & Conditions</h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>
                      <div className="space-y-4 overflow-y-auto py-6">
                        <section>
                          <p className="text-lg font-bold">Legal Disclaimer</p>
                          <p>
                            The Exchange reserves the right to correct any errors or omissions (including after an order
                            has been submitted) and to change or update information at any time without prior notice.
                            Please note that such errors or omissions may relate to product descriptions, pricing and
                            availability, and we reserve the right to cancel or refuse to accept any order placed based
                            on incorrect pricing or availability information. We apologize for any inconvenience.
                          </p>
                        </section>

                        <section>
                          <p className="text-lg font-bold">Authorized Exchange Customers</p>
                          <p>
                            The Exchange reserves the right to correct any errors or omissions (including after an order
                            has been submitted) and to change or update information at any time without prior notice.
                            Please note that such errors or omissions may relate to product descriptions, pricing and
                            availability, and we reserve the right to cancel or refuse to accept any order placed based
                            on incorrect pricing or availability information. We apologize for any inconvenience.
                          </p>
                          <ul className="my-3 ml-5 list-disc">
                            <p>This includes:</p>
                            <li className="list-disc">Active Duty Military</li>
                            <li>100% Disabled</li>
                            <li>Retirees</li>
                            <li>Reserves</li>
                            <li>National Guard personnel</li>
                            <li>Ready Reserve</li>
                            <li>US Civilian DoD employees, when stationed outside of the US</li>
                            <li>Department of State Officials serving in foreign countries</li>
                            <li>and dependents of the above</li>
                            <li>Honorably Discharged Veterans*</li>
                          </ul>
                          <p>
                            If you can shop for retail merchandise at your local Exchange, you are authorized to order
                            from the Catalog and Exchange Online Store.
                          </p>
                          <p>
                            If you have any questions about your eligibility, call toll free in the U.S. 1-800-527-2345.
                          </p>
                          <p>
                            *Honorably discharged Veterans of all United States Armed Forces and Reserves are authorized
                            patrons of the Exchange online store. Veterans shopping in foreign countries need to have a
                            valid APO/FPO/MPO/DPO address.
                          </p>
                        </section>

                        <section>
                          <p className="my-2 text-lg font-bold">Online Safety Guarantee</p>
                          <p>
                            WE GUARANTEE YOUR SECURITY ON OUR WEBSITE: This site is using secure technology to safeguard
                            the use of your credit card information online. We re so confident about our safety measures
                            that were offering you our Exchange Online Safety Guarantee. Heres our Exchange Online
                            Safety Guarantee
                            <strong>
                              : WE GUARANTEE YOUR SECURITY ON OUR WEBSITE: This site is using secure technology to
                              safeguard the use of your credit card information online. We re so confident about our
                              safety measures that we re offering you our Exchange Online Safety Guarantee. Here s our
                              Exchange Online Safety Guarantee
                            </strong>
                          </p>
                          <p>
                            We can offer this Exchange Online Safety Guarantee, because we&apos;re certain about our
                            site security. We know that your credit card information is secure because it is encrypted.
                            The use of Secure Sockets Layer (SSL) encryption on our website is the best global secure
                            transaction technology available today. You should make sure your browser is capable of SSL
                            in order to take advantage of the benefits of SSL secure technology while shopping at The
                            Exchange Online Store. Doing so ensures that all sensitive information like Credit Card
                            Information and Social Security numbers are encrypted before being sent over the internet.
                          </p>
                          <p>
                            We&apos;re dedicated to maintaining security for your Credit Card information and we believe
                            our Exchange Online Safety Guarantee will ease your concerns about using your credit card
                            while shopping in The Exchange Online Store. If you still feel uncomfortable transmitting
                            your credit card information over the internet, you may also place your order at one of the
                            numbers listed below. Our Exchange Online Store Entry Associates will be glad to assist you
                            in completing your order.
                          </p>
                          <p>Customers in the 50 United States and Puerto Rico:</p>
                          <p>Toll Free Phone 1-800-527-2345</p>
                          <p>Toll Free TDD 1-800-423-2011</p>
                          <p>TOLL FREE telephone numbers for Customers outside the United States & Puerto Rico:</p>
                          <p>Germany 0800-82-16500</p>
                          <p>Japan/Okinawa 0031-11-4132</p>
                          <p>Korea 00308-13-0664</p>
                          <p>Italy 8008-71227</p>
                          <p>Guam 01800-636-3297</p>
                          <p>Belgium 0800-7-2432</p>
                          <p>Netherlands 0800-022-7129</p>
                          <p>United Kingdom 0800-96-8101</p>
                          <p>Spain 900-971-391</p>
                        </section>
                        <section>
                          <p className="my-2 text-lg font-bold">Disclaimer of Responsibility</p>
                          <p>
                            Military sponsors can disclaim responsibility of a current or former family member by
                            providing the Exchange a Disclaimer of Responsibility. The sponsor and family member social
                            security numbers will be coded into the computer so that no Exchange will cash the family
                            member&apos;s or former family member&apos;s check. The disclaimed individual can still use
                            the Exchange as an authorized patron, but cannot cash checks.
                          </p>
                          <p>See a Customer Service Representative to request Exchange Form 7200-30.</p>
                        </section>
                        <section>
                          <p className="text-lg font-bold">Notice of Intent to Resell</p>
                          <p>
                            Exchange patrons are prohibited from the following: (1) Making (or purchasing for the
                            purpose of making) a sale, exchange, transfer, or other disposition of exchange merchandise
                            or services to unauthorized patrons (customary gifts of a personal nature are permissible).
                            (2) Using exchange merchandise or services to produce income. (3) Making purchases for the
                            purpose of resale by, or on behalf of, an installation private organization or other
                            non-government entity.
                          </p>
                        </section>
                        <section>
                          <p className="my-2 text-lg font-bold">Privacy Act Statement</p>
                          <p>
                            <strong>AUTHORITY:</strong> 10 U.S.C. 7013, Secretary of the Army; 10 U.S.C. 9013, Secretary
                            of the Air Force; 10 U.S.C. 2481, Defense Commissary and Exchange Systems: Existence and
                            Purpose; Title 10 §2488. Combined Exchange and Commissary Stores; DoDI 1330.09, Armed
                            Services Exchange Policy; DoDI 1330.21, Armed Services Exchange Regulations; Army Regulation
                            215-8/Air Force Instruction 34-211(I), Army and Air Force Exchange Service Operations; and
                            E.O. 9397 (SSN), as amended.
                          </p>
                          <p>
                            <strong>PRINCIPAL PURPOSE(S):</strong> Information is collected to identify patron
                            eligibility and used to enable and assist military exchanges, MWR, and DeCA in their efforts
                            of enhancing the quality of life for authorized patrons. Information is used to obtain
                            aggregate patron demographics of patron&apos;s desires to improve shopping experiences.
                          </p>
                          <p>
                            <strong>ROUTINE USE(S):</strong> Records may be disclosed outside of DoD pursuant to Title 5
                            U.S.C. §552a(b)(3) regarding DoD “Blanket Routine Uses” published at dpcld.defense.gov. This
                            includes disclosure to contractors for improvement of service exchange programs, to
                            incentive programs, and pursuant to 5 U.S.C. 552a (b)(12) to a consumer reporting agency. In
                            addition, records may be disclosed to Federal agencies, and state, local and territorial
                            governments, to U.S. Postal Service in order to provide claim and postal authorities with
                            confirmation/certification of shipment for customer claims for damage or lost shipments and
                            to audit firms under contract with the service exchange to collect delinquency accounts.
                          </p>
                          <p>
                            <strong>DISCLOSURE:</strong> Voluntary. However, failure to provide all requested
                            information may result in denial of access to services or to specific shopping websites.
                          </p>
                          <section className="flex ">
                            A copy of the Privacy Impact Assessment (PIA) for the collection of information may be
                            located
                            <Link href={PIA.link}>
                              <p className="ml-1 cursor-pointer text-blue-900 hover:underline"> here.</p>
                            </Link>
                          </section>
                          <section className="flex ">
                            <p>
                              <strong>SYSTEM OF RECORD NOTICE (SORN):</strong> AAFES 0207.02 &quot;Exchange Retail Sales
                              Transaction Data &quot; may be viewed at
                            </p>
                            <Link href={PIA.defense}>
                              <p className="ml-1 cursor-pointer text-blue-900 hover:underline"> dpcld.defense.gov</p>
                            </Link>
                            .
                          </section>
                        </section>

                        <section>
                          <p className="my-2 text-lg font-bold">privacy policy</p>
                          <p>
                            We understand that you care how information about you is used, and we want you to know with
                            confidence that we aggressively protect any sensitive/personal information that we may
                            obtain and any use of such information will be done sensibly in accordance with government
                            regulations. The following information describes our Privacy Policy. By visiting the
                            Exchange web sites (not including the Exchange Online Mall), you are accepting the practices
                            described in this Privacy Policy.
                          </p>
                          <ul className="ml-5 list-disc">
                            <li>What Personal Information About Customers Does the Exchange Gather?</li>
                            <li>What Are Cookies (and What Are Your Options)?</li>
                            <li>What Web Analytics Service is Used (and What Are Your Options)?</li>
                            <li>What Choices Do You Have?</li>
                            <li>Does the Exchange Share the Information We Receive?</li>
                            <li>How Secure Is Information About Me?</li>
                            <li>Which Information Can I Access?</li>
                            <li>Are Children Allowed to Use the Exchange web sites?</li>
                            <li>Conditions of Use, Notices, and Revisions</li>
                            <li>MILITARY STAR Card Privacy Notice</li>
                            <li>
                              Privacy Act Statement (Authority, Principal Purpose[s], Routine Use[s], & Disclosure)
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">
                            Privacy and Security Policy for Exchange Online (General Terms)
                          </h3>
                          <ul className="ml-5 list-disc">
                            <li>
                              The Army & Air Force Exchange Service (hereinafter referred to as the Exchange) web site,
                              shopmyexchange.com, is an unofficial Department of Defense (DoD) site, published and
                              maintained by the Exchange with non-appropriated funds. The Exchange operates the Exchange
                              Online Store and facilitates access to Exchange Online Mall shopping web sites on behalf
                              of all the military exchanges.
                            </li>
                            <li>
                              For site security and to ensure that this service remains available to all users, the
                              Exchange computer system employs software programs to monitor network traffic for
                              identifying unauthorized attempts to upload or change information, and otherwise cause
                              damage. Unauthorized attempts to upload or change information on this service are strictly
                              prohibited and may be punishable under applicable federal law.
                            </li>
                            <li>
                              Portions of the Exchange web site are accessible only to authorized patrons and the
                              Exchange is required to ensure that only those with authorization can have access to these
                              portions of the web site. When you log-in your computer will be asked to accept
                              &quot;cookies &quot;. These cookies may be temporary or persistent (temporary cookies will
                              expire at the end of your session). We collect e-mail addresses and volunteered
                              information such as surveys and sweepstakes registration information from visitors to our
                              site. Our web site also automatically recognizes your internet service provider. We do not
                              sell or exchange names or any other information about our online customers with third
                              parties. We are committed to ensuring your privacy as a customer and will only use this
                              information to improve your overall shopmyexchange.com, Exchange Online Store, and
                              Exchange Online Mall shopping experience.
                            </li>
                            <li>
                              With your permission, we would like to contact you with news about products, services and
                              events. To voluntarily sign up to receive our periodic email newsletter. If you change
                              your mind and would like to be removed from the email newsletter list, you can also
                              Unsubscribe on the same page.
                            </li>
                            <li>
                              In connection with authorized law enforcement investigations, the Exchange may be required
                              to identify individual users or their usage habits. Raw data logs are scheduled for
                              destruction in accordance with Exchange internal guidelines.
                            </li>
                            <li>
                              If you have any questions or comments about the information presented here, please Contact
                              the Exchange.
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">
                            What Personal Information About Customers Does the Exchange Gather?
                          </h3>
                          <p>
                            The information we learn from customers helps us personalize and continually improve your
                            shopping experience on the Exchange web sites. Here are the types of information we gather:
                          </p>
                          <ul className="ml-5 list-disc">
                            <li>
                              <strong>Information You Provide Us:</strong> Aside from the information available through
                              the official DEERS database, we receive and store (in many cases just temporarily) any
                              information you may enter on the Exchange web sites or provide us in other ways
                              electronically. You can choose not to provide certain information, but then you might not
                              be able to take advantage of many of our features/offers online. We may use the
                              information that you provide for such purposes as responding to your requests, customizing
                              future shopping for you, improving our stores, and communicating with you.
                            </li>
                            <li>
                              <strong>Automatic Information:</strong> We receive and store certain types of information
                              during some of your online interactions with us. For example, like most Web sites, we use
                              session and/or persistent &quot;cookies, &quot; and we obtain certain types of information
                              when your Web browser accesses the Exchange web sites, advertisements and other content
                              served by us or on our behalf on other Web sites.
                            </li>
                            <li>
                              <strong>E-mail/Marketing Communications:</strong> To help us make e-mails more useful and
                              interesting, we often receive a confirmation when you subscribe to our e-mails if your
                              computer supports such capabilities. If you do not want to receive e-mails or other mail
                              from us, please follow the Unsubscribe instructions at the bottom or back of each of those
                              materials.
                            </li>
                            <li>
                              <strong>Mobile Apps:</strong> Using the Store Locator feature on one of our Exchange
                              mobile apps you can always find the nearest Exchange store location(s). Our Exchange
                              mobile app is capable of using your mobile device&apos;s GPS or, if you&apos;d rather, you
                              can enter a city and zip code to immediately locate the nearest Exchange store(s)
                              including directions, store name, store address, store phone number, and number of miles
                              from your designated location. This information is not stored or shared.
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">What Are Cookies (and What Are Your Options)</h3>
                          <ul className="ml-5 list-disc">
                            <li>
                              Cookies are alphanumeric identifiers that we may transfer to your computer&apos;s hard
                              drive in the form of text files through your Web browser to enable our systems to
                              recognize your browser and in some instances to provide personalized features/offers
                              online. As for the difference between session cookies and persistent cookies:
                            </li>
                            <ul className="ml-3 list-disc">
                              <li>
                                <strong>Session Cookie:</strong> A cookie that is erased when the user closes the Web
                                browser. The session cookie is stored in temporary memory and is not retained after the
                                browser is closed. Session cookies do not collect information from the user&apos;s
                                computer. They typically will store information in the form of a session identification
                                that does not identify the user. Session cookies are also referred to as transient
                                cookies or temporary cookies.
                              </li>
                              <li>
                                <strong>Persistent Cookie:</strong> A cookie that is stored on a user&apos;s hard drive
                                until it expires (persistent cookies are set with expiration dates) or until the user
                                deletes the cookie. Persistent cookies are used to collect information about the user,
                                such as Web surfing behavior or user preferences for a specific Web site. Persistent
                                cookies are also referred to as permanent cookies or stored cookies.
                              </li>
                            </ul>
                            <li>
                              The Help and/or Tools/Options menu(s) on the toolbar of most browsers will tell you how to
                              prevent your browser from accepting new cookies, how to have the browser notify you when
                              you receive a new cookie, or how to disable cookies altogether. Additionally, you can
                              disable or delete similar data used by browser add-ons, such as Flash cookies, by changing
                              the add-on&apos;s settings or visiting the Web site of its manufacturer. However, because
                              cookies may allow you to take advantage of some of our features/offers online, we
                              recommend that you leave cookies turned on.
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">
                            What Web Analytics Service is Used (and What Are Your Options)?
                          </h3>
                          <p>
                            Like many other online retailers, the Exchange web sites use Google Analytics, a web
                            analytics service provided by Google, Inc. (&apos;Google&apos;). Google Analytics uses
                            &apos;cookies&apos;, which are text files placed on your computer, to help the web site
                            analyze how users use the site. The information generated by the cookie about your use of
                            the web site (including your IP address) will be transmitted to and stored by Google on
                            servers in the United States. Google will use this information for the purpose of evaluating
                            your use of the web site, compiling reports on website activity for website operators and
                            providing other services relating to website activity and internet usage. Google may also
                            transfer this information to third parties where required to do so by law, or where such
                            third parties process the information on Google&apos;s behalf. Google will not associate
                            your IP address with any other data held by Google. You may refuse the use of cookies by
                            selecting the appropriate settings on your browser, however please note that if you do this
                            you may not be able to use the full functionality of this web site. By using this web site,
                            you consent to the processing of data about you by Google in the manner and for the purposes
                            set out above.
                          </p>
                          <p>
                            Due to user privacy concerns, Google Analytics doesn&apos;t report on personally
                            identifiable information, including a visitor&apos;s IP address (IP address is a unique
                            identifier for a computer or device on a TCP/IP network such as the Internet, an IP address
                            is written as four numbers each separated by periods and each number ranges from 0 to 255
                            [for example, 192.168.0.1]). Instead, Google Analytics provides aggregated data to help
                            participating web sites make informed business decisions.
                          </p>
                          <h3 className="text-lg font-bold">What Choices Do You Have?</h3>
                          <ul className="ml-5 list-disc">
                            <li>
                              As discussed above, you can always choose not to provide information, even though it might
                              be needed to make a purchase or to take advantage of various features/offers online.
                              Again, the Help and/or Tools/Options menu(s) on the toolbar of most browsers will tell you
                              how to prevent your browser from accepting new cookies, how to have the browser notify you
                              when you receive a new cookie, or how to disable cookies altogether. Additionally, you can
                              disable or delete similar data used by browser add-ons, such as Flash cookies, by changing
                              the add-on&apos;s settings or visiting the Web site of its manufacturer. However, because
                              cookies may allow you to take advantage of some of our features/offers online, we
                              recommend that you leave cookies turned on.
                            </li>
                            <li>
                              As mentioned above, if you do not want to receive e-mail or other mail from us, please
                              follow the Unsubscribe instructions at the bottom or back of each of those materials.
                            </li>
                            <li>
                              You can add or update certain information (such as in the &apos;My Account&apos; area of
                              the Exchange Online Store).
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">Does the Exchange Share the Information We Receive?</h3>
                          <p>
                            Information about our customers is an important part of our business. We at the Exchange
                            greatly respect our customers privacy and we do not sell or exchange names or any other
                            information about our customers with third parties.
                          </p>
                          <h3 className="text-lg font-bold">How Secure Is Information About Me?</h3>
                          <ul className="ml-5 list-disc">
                            <li>
                              We work to protect the security of your information during transmission by using Secure
                              Sockets Layer (SSL) software, which encrypts information you input.
                            </li>
                            <li>
                              Making a purchase from the Exchange Online Store generates an order confirmation e-mail
                              which lists your payment method (such as MILITARY STAR card, Visa, Discover, etc.) but
                              does not list the related credit card number or even a partial set of its digits. Of
                              course, we transmit the entire credit card number to the appropriate credit card company
                              during order processing.
                            </li>
                            <li>
                              It is important for you to protect against unauthorized access to your username, password
                              and to your computer. Be sure to sign off when finished using a shared computer.
                            </li>
                          </ul>
                          <h3 className="text-base font-bold">Which Information Can I Access?</h3>
                          <p>
                            We offer you access to a broad range of information about your account and your interactions
                            with the Exchange Online for the limited purpose of viewing and, in certain cases, updating
                            that information (such as inside the &apos;My Account&apos; area of the Exchange Online
                            Store).
                          </p>
                          <h3 className="text-base font-bold">Are Children Allowed to Use the Exchange web sites?</h3>
                          <p>
                            The Exchange does sell children&apos;s products for purchase by adults. If you are under 18,
                            you may use the Exchange web sites only with the involvement of a parent or guardian.
                          </p>
                          <h3 className="text-base font-bold">Conditions of Use, Notices, and Revisions</h3>
                          <p>
                            If you choose to visit the Exchange web sites, your visit and any dispute over privacy is
                            subject to this Policy, including limitations on damages, resolution of disputes, and
                            application of the law. If you have any concern about privacy at the Exchange web sites,
                            please see the &apos;Customer Service&apos; area of the Exchange Online Store and send us a
                            thorough description, and we will try to resolve it. Our business changes constantly, and
                            our Privacy Policy is subject to change at any time also. We may e-mail periodic reminders
                            of our privacy policies, unless you have instructed us not to, but you should check our Web
                            site frequently to see any updates in our latest version. Unless stated otherwise, our
                            current Privacy Policy applies to all information that we have about you and your account.
                            We stand behind the promises we make, however, and will never materially change our policies
                            and practices to make them less protective of customer information collected in the past.
                          </p>
                          <h3 className="text-base font-bold">MILITARY STAR Card Privacy Notice</h3>
                          <p>Read the MILITARY STAR Card Privacy Notice.</p>
                          <h3 className="text-base font-bold">
                            Privacy Act Statement (Authority, Principal Purpose[s], Routine Use[s], & Disclosure)
                          </h3>
                          <p>
                            <strong>AUTHORITY:</strong> 10 U.S.C. 7013, Secretary of the Army; 10 U.S.C. 9013, Secretary
                            of the Air Force; 10 U.S.C. 2481, Defense Commissary and Exchange Systems: Existence and
                            Purpose; Title 10 §2488. Combined Exchange and Commissary Stores; DoDI 1330.09, Armed
                            Services Exchange Policy; DoDI 1330.21, Armed Services Exchange Regulations; Army Regulation
                            215-8/Air Force Instruction 34-211(I), Army and Air Force Exchange Service Operations; and
                            E.O. 9397 (SSN), as amended.
                          </p>
                          <p>
                            <strong>PRINCIPAL PURPOSE(S):</strong> Information is collected to identify patron
                            eligibility and used to enable and assist military exchanges, MWR, and DeCA in their efforts
                            of enhancing the quality of life for authorized patrons. Information is used to obtain
                            aggregate patron demographics of patron&apos;s desires to improve shopping experiences.
                          </p>
                          <p>
                            <strong>ROUTINE USE(S):</strong> Records may be disclosed outside of DoD pursuant to Title 5
                            U.S.C. §552a(b)(3) regarding DoD “Blanket Routine Uses” published at dpcld.defense.gov. This
                            includes disclosure to contractors for improvement of service exchange programs, to
                            incentive programs, and pursuant to 5 U.S.C. 552a (b)(12) to a consumer reporting agency. In
                            addition, records may be disclosed to Federal agencies, and state, local and territorial
                            governments, to U.S. Postal Service in order to provide claim and postal authorities with
                            confirmation/certification of shipment for customer claims for damage or lost shipments and
                            to audit firms under contract with the service exchange to collect delinquency accounts.
                          </p>
                          <p>
                            <strong>DISCLOSURE:</strong> Voluntary. However, failure to provide all requested
                            information may result in denial of access to services or to specific shopping websites.
                          </p>
                          <h3 className="my-2 text-lg font-bold">Gift Cards</h3>
                          <p className="text-base font-bold">What are exchange privileges?</p>
                          <p>
                            Exchange privileges are granted to authorized customers from all branches of the military
                            service and other authorized federal departments or agencies serving with or on temporary
                            duty with the U.S. Forces throughout the world. Anyone with exchange privileges may receive
                            an Exchange Gift Card to include Active Duty, Retirees, Reservists and National Guard
                            personnel and their dependents.
                          </p>
                          <p className="text-base font-bold">Who are authorized Exchange customers?</p>
                          <p>
                            The Exchange Online Store is for the exclusive use of authorized Exchange customers
                            throughout the world. This includes:
                          </p>
                          <ul className="my-3 ml-5 list-disc">
                            <p>This includes:</p>
                            <li className="list-disc">Active Duty Military</li>
                            <li>100% Disabled</li>
                            <li>Retirees</li>
                            <li>Reserves</li>
                            <li>National Guard personnel</li>
                            <li>Ready Reserve</li>
                            <li>US Civilian DoD employees, when stationed outside of the US</li>
                            <li>Department of State Officials serving in foreign countries</li>
                            <li>and dependents of the above</li>
                            <li>Honorably Discharged Veterans*</li>
                          </ul>
                          <p>
                            If you can shop for retail merchandise at your local Exchange, you are authorized to order
                            from the Exchange Online Store. If you have any questions about your eligibility, call toll
                            free in the U.S., 1-800-527-2345.
                          </p>
                          <p className="text-base font-bold">Where can they use the Exchange eGift Card?</p>
                          <p>The Exchange eGift Card can be redeemed only at shopmyexchange.com.</p>
                          <p className="text-base font-bold">Where can they use the Exchange Gift Card?</p>
                          <p>
                            The Exchange Gift Card can be redeemed in the Exchange retail and food facilities, to
                            include contingency locations. The Exchange Gift Card can also be redeemed at Marine, Navy,
                            and Coast Guard retail locations. The Exchange Gift Card and the Exchange eGfit card can be
                            redeemed online at shopmyexchange.com.
                          </p>

                          <h3 className="my-2 text-lg font-bold ">Promotional Text Messaging</h3>
                          <p className="my-2">
                            You may opt-in to receive SMS messaging under the communication preferences in your
                            shopmyexchange.com account profile. By opting in for SMS messaging, you are requesting,
                            agreeing to, enrolling in or otherwise consenting to receiving promotional or purchase
                            status text messages through the Exchange SMS program. Message frequency may vary. When you
                            opt-in, you will receive a text message from to confirm your selection. To confirm your
                            opt-in to this program, please reply with Y as instructed.
                          </p>
                          <p className="my-2">
                            If you respond with Y, you will receive a message from {`<short code>`} &quot;The Exchange:
                            Thanks for subscribing to promo texts from the Exchange. Msg freq may vary. Reply HELP for
                            help, STOP to cancel. Msg&data rates may apply.”
                          </p>
                          <p className="my-2">
                            To discontinue receiving SMS messages from the Exchange, text STOP to 35608. For additional
                            help text HELP to 35608 or contact 1-800-527-2345.
                          </p>
                          <p className="my-2">
                            Compatible carriers include: AT&T, T-Mobile® T-Mobile is not liable for delayed or
                            undelivered messages, Verizon Wireless, Sprint, Boost, Alltel (Verizon Wireless), U.S.
                            Cellular, Cellular One, MetroPCS, ACS/Alaska, Bluegrass Cellular, Cellular One of East
                            Central Illinois, Centennial Wireless, Cox Communications, EKN/Appalachian Wireless, GCI,
                            Illinois Valley Cellular, Immix/Keystone Wireless, Inland Cellular, Nex-Tech Wireless, Rural
                            Cellular Corporation, Thumb Cellular, United Wireless, West Central (WCC), Cellcom, C Spire
                            Wireless CellSouth, Cricket, Cincinnati Bell and Virgin Mobile®
                          </p>
                          <p className="my-2">
                            You authorize the Exchange to send text messages to the cell phone number associated with
                            your opt-in (i.e. the number listed when you select your communication preferences) on your
                            account. You confirm that you are a subscriber to the relevant phone number and that you are
                            the customary user of that number and that you have the authority to opt-in. You understand
                            and agree that message and data rates may apply to each text message sent or received in
                            connection with the Exchange SMS messaging, and you are responsible for such charges, in
                            addition to any applicable roaming or international charges. The Exchange does not impose a
                            separate fee for sending text messages. By opting in, you consent to the use of an
                            electronic record to document your opt-in preference. The Exchange will not be liable for
                            any delays in the receipt of any text messages. Delivery is subject to effective
                            transmission from your network operation.
                          </p>
                          <p className="my-2">
                            You agree to indemnify and hold harmless the Exchange, the United States, and all other U.S.
                            agencies and instrumentalities, their agents, representatives, and employees (“Indemnitees”)
                            in full for all claims, expenses, and damages (i) related to or caused in whole or in part
                            by your failure to notify the Exchange if you change your telephone number; (ii) related to
                            or in connection with a claim, suit or proceeding brought by a third party arising from your
                            actions, omissions or misconduct including, but not limited to, all claims, expenses, and
                            damages related to or arising under the Telephone Consumer Protection Act. You further agree
                            to indemnify and hold the Exchange and Indemnitees harmless from and against any claim or
                            demand, including reasonable attorneys&apos; fees, made by any third party resulting from or
                            arising out of your use of the text messages or your violation of this Agreement. We respect
                            your privacy. You may view our privacy policy here.
                          </p>
                          <p className="my-2">
                            The Exchange may revise, modify, or amend the SMS/Text Messaging Terms & Conditions at any
                            time. Any such revision, modification, or amendment shall take effect when it is posted to
                            Exchange website. You agree to review these SMS/Text Messaging Terms & Conditions
                            periodically to ensure that you are aware of any changes. Your continued consent to receive
                            text messages will indicate your acceptance of those changes.
                          </p>
                        </section>
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PrivayAct;
