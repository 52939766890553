import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import Icon from 'components/icons/infoIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions, useCustomMessages } from 'frontastic';
import Add from '../../../../images/add-on-logo.png';
export default function AddOn() {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { getCustomMessages } = useCustomMessages();
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);
  const addonMessage = getAllCustomMessages?.addOnInfoMessage;
  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=addOnVerbiage,addOnInfoMessage`,
    fetchApiHub,
    revalidateOptions,
  );

  useEffect(() => {
    setGetCustomMessages(data);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [data]);

  return (
    <>
      <section className=" text-sm font-normal text-gray-900">
        <div className="flex">
          <img src={Add.src} alt="Add On Image" className=" mr-2 inline h-12 w-[50px]" />
          <p className="ml-1 w-[175px] pt-2">{getAllCustomMessages?.addOnVerbiage} &nbsp;</p>
          <div className="details-popover hidden" id="set"></div>
          <div className="group relative pt-2">
            <button
              className="text-primary ml-2 text-lg"
              ref={buttonRef}
              onClick={() => setPopupVisible(true)}
              onMouseEnter={(event) => {
                const popupScroll = event?.currentTarget?.nextElementSibling?.querySelector('.popup-scroll');
                if (popupScroll && popupScroll?.scrollTop !== 0) {
                  popupScroll.scrollTop = 0;
                }
              }}
            >
              <Icon className="cursor-pointer" />
            </button>
            <div
              ref={popupRef}
              className={`absolute -right-1/2 top-[40px] z-10 translate-x-1 rotate-0 ${popupVisible ? '' : 'hidden'}`}
            >
              <div className="relative rounded-md bg-white">
                <div className="text-black boxArrow absolute right-2 w-60 rounded-md border border-solid bg-white text-sm opacity-100 transition-all duration-300">
                  <div className="relative z-[1] flex rounded-t-md border-b border-solid bg-[#EDEDED] p-2 text-base font-bold after:absolute after:-top-[1px] after:right-[6px] after:inline-block after:h-[1px] after:w-[23px] after:bg-white after:content-[''] sm:after:right-[8px]">
                    {formatProductMessage({ id: 'addOn.details', defaultMessage: 'Add On Details' })}
                  </div>
                  <div className="pb-4">
                    <div
                      className="popup-scroll aafes-orderList h-60 overflow-y-auto px-4 py-2"
                      dangerouslySetInnerHTML={{ __html: addonMessage }}
                    />
                  </div>
                </div>
                <div className="absolute right-[10px] -top-[16px] z-0 h-4 w-4 -translate-x-1/2 translate-y-1/2 rotate-[225deg] bg-white drop-shadow-md md:-left-[28px] md:right-0"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
