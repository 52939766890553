import React, { useEffect, useState } from 'react';
import { useCart } from 'frontastic';
import Cnet from './Cnet';
import FlixMedia from './FlixMedia';
import Salsify from './Salsify';
import Syndigo from './Syndigo';

interface SyndicatedContentHOCProps {
  productId: string;
  model?: any;
  variant?: any;
}

const SyndicatedContentHOC: React.FC<SyndicatedContentHOCProps> = ({ productId, model, variant }) => {
  const { studioConfig } = useCart();
  const [syndicateConfig, setSyndicateConfig] = useState(null);

  useEffect(() => {
    try {
      const { salsifyConfig, syndigoConfig, flixConfig, cnetConfig } = studioConfig;
      setSyndicateConfig({
        salsifyConfig: JSON.parse(salsifyConfig || '{}'),
        syndigoConfig: JSON.parse(syndigoConfig || '{}'),
        flixConfig: JSON.parse(flixConfig || '{}'),
        cnetConfig: JSON.parse(cnetConfig || '{}'),
      });
    } catch (err) {
      console.warn(err);
    }
  }, [studioConfig]);

  if (!syndicateConfig) return null;

  return (
    <>
      <Salsify productId={productId} config={syndicateConfig.salsifyConfig} />
      <Syndigo productId={productId} config={syndicateConfig.syndigoConfig} />
      {model?.desc && <FlixMedia mpn={model.desc} config={syndicateConfig.flixConfig} />}
      <Cnet
        skuId={variant?.sku}
        brand={variant?.attributes?.brand}
        vendorProdId={variant?.attributes?.vendorProdId}
        config={syndicateConfig.cnetConfig}
      />
    </>
  );
};

export default SyndicatedContentHOC;
