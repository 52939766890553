import React from 'react';
import Header from './header';

function MiniHeaderindex({ data }) {
  return (
    <>
      <Header data={data} />
    </>
  );
}

export default MiniHeaderindex;
