import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { LineItem } from '@Types/cart/LineItem';
import { useForm } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import * as Yup from 'yup';
import { useFormat } from 'helpers/hooks/useFormat';
import { validateInput } from 'helpers/keyPressValidation';
import { useCart } from 'frontastic';

interface PickupPersonProp {
  item?: LineItem;
  pickUpDetails: any;
  closeModal?: () => void;
  editPickUpDetails: any;
}

function PickupPersonModal({ pickUpDetails, item, closeModal, editPickUpDetails }: PickupPersonProp) {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { pickUpPersonInformation } = useCart();
  const [showLoader, setShowLoader] = useState(false);

  const pickUpPersonInformationFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(formatCommonMessage({ id: 'fNameRequired', defaultMessage: 'First name is required.' }))
      .transform((value) => value.replace(/\s+/g, ' ').trim())
      .matches(
        /^\s*\p{L}[-'\\. \p{L}]*\s*$/u,
        formatCommonMessage({ id: 'validFirstName', defaultMessage: 'Enter a valid first name.' }),
      ),
    lastName: Yup.string()
      .required(formatCommonMessage({ id: 'lNameRequired', defaultMessage: 'Last name is required.' }))
      .transform((value) => value.replace(/\s+/g, ' ').trim())
      .matches(
        /^\s*\p{L}[-'\\. \p{L}]*\s*$/u,
        formatCommonMessage({ id: 'validLastName', defaultMessage: 'Enter a valid last name.' }),
      ),
    email: Yup.string()
      .required(
        formatCommonMessage({
          id: 'emailRequired',
          defaultMessage: 'Email is required.',
        }),
      )
      .email('Invalid email format'),
    phoneNumber: Yup.string()
      .required(formatCommonMessage({ id: 'phoneRequired', defaultMessage: 'Phone Number is required.' }))
      .matches(
        /^(?:\+1\s?)?(\d{10,14})$/,
        formatCommonMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid Phone Number.' }),
      )
      .min(10, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 14 digits.' }))
      .max(14, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 14 digits.' })),
  });

  const pickUpPersonInformationForm: any = {
    resolver: yupResolver(pickUpPersonInformationFormSchema),
    mode: 'onBlur',
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(pickUpPersonInformationForm);

  useEffect(() => {
    if (editPickUpDetails && item) {
      reset({
        firstName: item.pickupPersonFirstName,
        lastName: item.pickupPersonLastName,
        email: item.pickupPersonEmail,
        phoneNumber: item.pickupPersonPhone,
      });
    } else {
      reset();
    }
  }, [editPickUpDetails, item, reset]);

  const pickUpPersonDetailsHandler = async (values: any) => {
    const payload = {
      id: item?.lineItemId,
      pickupPersonFirstName: values.firstName.trim().replace(/\s+/g, ' '),
      pickupPersonLastName: values.lastName.trim().replace(/\s+/g, ' '),
      pickupPersonEmail: values.email,
      pickupPersonPhone: values.phoneNumber,
    };
    setShowLoader(true);
    await pickUpPersonInformation(payload)
      .then(() => {
        closeModal();
      })
      .finally(() => setShowLoader(false));
  };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <form onSubmit={handleSubmit(pickUpPersonDetailsHandler)}>
                  <section className="absolute inset-0" onClick={closeModal}>
                    <section
                      className="absolute top-1/2 left-1/2 max-h-[700px] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto overflow-x-hidden rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                      onClick={(e) => e.stopPropagation()}
                      aria-labelledby="pickupPersonTitle"
                      aria-modal="true"
                    >
                      <section className="absolute top-3 right-3"></section>
                      <section className="text-left">
                        <section className="modalHeader relative mb-[22px]">
                          <button
                            type="button"
                            aria-label="Close Modal"
                            className="absolute right-0 top-0"
                            onClick={closeModal}
                          >
                            <GrClose />
                          </button>
                          <h3 id="pickupPersonTitle" className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                            {formatCheckoutMessage({ id: 'pickup.person', defaultMessage: 'Pick Up Person' })}{' '}
                          </h3>
                        </section>
                        <section>
                          <section className="mb-3 grid grid-cols-1 gap-y-6 gap-x-10 md:grid-cols-2">
                            <div>
                              <label
                                htmlFor="firstName"
                                className="mb-[9px] block text-[12px] font-bold leading-[14.4px] text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'firstName', defaultMessage: 'First name' })}{' '}
                                <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">*</sup>
                              </label>
                              <input
                                type="text"
                                name=""
                                id="firstName"
                                maxLength={19}
                                {...register('firstName', {
                                  value: pickUpDetails?.firstName,
                                  pattern: {
                                    value: /^[a-zA-Z\s]*$/,
                                    message: 'First name should only contain letters',
                                  },
                                })}
                                className={`w-full rounded-sm border  py-[5px]  md:w-[250px] ${
                                  errors?.firstName?.message ? 'border-red-500' : 'border-gray-600'
                                }`}
                                aria-invalid={errors?.firstName?.message ? 'true' : 'false'}
                                aria-describedby={errors?.firstName?.message ? 'firstNameError' : null}
                                onKeyPress={(e) => validateInput(e)}
                              />
                              {errors?.firstName?.message && (
                                <span id="firstNameError" className="inline-flex text-sm text-red-500">
                                  {errors?.firstName?.message}
                                </span>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="lastName"
                                className="mb-[9px] block text-[12px] font-bold leading-[14.4px] text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'lastName', defaultMessage: 'Last name' })}{' '}
                                <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">*</sup>
                              </label>
                              <input
                                type="text"
                                name=""
                                id="lastName"
                                maxLength={20}
                                {...register('lastName', {
                                  value: pickUpDetails?.lastName,
                                  pattern: {
                                    value: /^[a-zA-Z\s]*$/,
                                    message: 'Last name should only contain letters',
                                  },
                                })}
                                className={`w-full rounded-sm border border-gray-600 py-[5px] px-3 md:w-[250px] ${
                                  errors?.lastName?.message ? 'border-red-500' : 'border-gray-600'
                                }`}
                                aria-invalid={errors?.lastName?.message ? 'true' : 'false'}
                                aria-describedby={errors?.lastName?.message ? 'lastNameError' : null}
                                onKeyPress={(e) => validateInput(e)}
                              />
                              {errors?.lastName?.message && (
                                <span id="lastNameError" className="inline-flex text-sm text-red-500">
                                  {errors?.lastName?.message}
                                </span>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="email"
                                className="mb-[9px] block text-[12px] font-bold leading-[14.4px] text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'email', defaultMessage: 'Email' })}{' '}
                                <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">*</sup>
                              </label>
                              <input
                                type="text"
                                name=""
                                id="email"
                                {...register('email', { value: pickUpDetails?.email })}
                                className={`w-full rounded-sm border  py-[5px] px-3 md:w-[250px] ${
                                  errors?.email?.message ? 'border-red-500' : 'border-gray-600'
                                }`}
                                aria-invalid={errors?.email?.message ? 'true' : 'false'}
                                aria-describedby={errors?.email?.message ? 'emailError' : null}
                              />
                              {errors?.email?.message && (
                                <span id="emailError" className="inline-flex text-sm text-red-500">
                                  {errors?.email?.message}
                                </span>
                              )}
                            </div>
                            <div>
                              <label
                                htmlFor="phoneNumber"
                                className="mb-[9px] block text-[12px] font-bold leading-[14.4px] text-gray-800"
                              >
                                {formatCheckoutMessage({ id: 'phoneNumber', defaultMessage: 'Phone number' })}{' '}
                                <sup className="ml-1 text-[12px] font-bold leading-[14.4px] text-[#DA0F0F]">*</sup>
                              </label>
                              <input
                                type="text"
                                name=""
                                id="phoneNumber"
                                maxLength={14}
                                {...register('phoneNumber', { value: pickUpDetails?.phoneNumber })}
                                className={`w-full rounded-sm border  py-[5px] px-3 md:w-[250px] ${
                                  errors?.phoneNumber?.message ? 'border-red-500' : 'border-gray-600'
                                }`}
                                aria-invalid={errors?.phoneNumber?.message ? 'true' : 'false'}
                                aria-describedby={errors?.phoneNumber?.message ? 'phoneNumberError' : null}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors?.phoneNumber?.message && (
                                <span id="phoneNumberError" className="inline-flex text-sm text-red-500">
                                  {errors?.phoneNumber?.message}
                                </span>
                              )}
                            </div>
                          </section>
                        </section>
                        <section className="md:flex">
                          <button
                            type="submit"
                            name=""
                            className="mr-10 mb-[10px] w-full rounded-sm bg-blue-900 py-3 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                          >
                            {editPickUpDetails ? (
                              <>{formatCommonMessage({ id: 'update', defaultMessage: 'Update' })}</>
                            ) : (
                              <>{formatCheckoutMessage({ id: 'save', defaultMessage: 'Save' })}</>
                            )}
                          </button>
                          <button
                            onClick={closeModal}
                            type="button"
                            name=""
                            className="w-full py-3 text-affes-xl font-bold leading-[19.2px] text-blue-900 md:w-auto"
                          >
                            {formatCheckoutMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                          </button>
                        </section>
                      </section>
                    </section>
                  </section>
                </form>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PickupPersonModal;
