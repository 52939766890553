import React from 'react';

export const validateInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const inputElement = e.target as HTMLInputElement;
  const pattern = /^[a-zA-Z][a-zA-Z\s.:\\'-]*$/;
  const input = inputElement.value + e.key;
  if (!pattern.test(input)) {
    e.preventDefault();
  }
};
