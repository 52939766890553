import React, { useEffect, useMemo, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import Price from '../../price';

const FloatingHeader = ({ products, variants, showLogin, accountFlyout, sku, promotion }) => {
  const [isDesktopSize] = useMediaQuery(tablet);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [activeTab, setActiveTab] = useState(0);
  const { loggedIn } = useAccount();

  const tabItems = [
    { id: 'overview', title: 'Overview' },
    { id: 'specification', title: 'Specification' },
    { id: 'reviews', title: 'Ratings/Reviews' },
    { id: 'specialOffers', title: 'Special Offers' },
    { id: 'warranty', title: 'Warranty' },
    { id: 'shipping', title: 'Shipping / Returns' },
  ].filter((item) => {
    if (item.id === 'specification') {
      return variants?.specifications;
    } else if (item.id === 'specialOffers') {
      return promotion && promotion?.length > 0;
    } else if (item.id === 'warranty') {
      return variants?.warrantyDescription;
    }
    return true;
  });

  // Click event handler for tab item
  const handleTabClick = (index: any) => {
    const sectionId = tabItems?.[index]?.id;
    const section = document?.getElementById(sectionId);

    if (section) {
      const headerHeight = document?.querySelector('header')?.offsetHeight;
      const additionalOffset = 50;
      const offsetTop = section?.offsetTop;
      window.scrollTo({
        top: offsetTop - headerHeight - additionalOffset,
        behavior: 'smooth',
      });
    }
  };
  const isListPriceLarger = useMemo(() => {
    if (!products?.salePrice?.centAmount) return true;
    if (products?.listPrice?.centAmount > products?.salePrice?.centAmount) return true;
    return false;
  }, [products?.listPrice, products?.salePrice]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document?.querySelectorAll('section[id]');
      for (let i = sections?.length - 1; i >= 0; i--) {
        const condidtion = sections?.[i]?.getBoundingClientRect()?.top <= 200;
        if (condidtion) {
          const currentTab = tabItems?.findIndex((item) => item?.id === sections?.[i]?.id);
          currentTab > -1 && setActiveTab(currentTab);
          break;
        }
      }
    };
    // Add event listener to update active tab on scroll
    window?.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, [tabItems]);
  return (
    <>
      {isDesktopSize && (
        <div>
          <header className={'bg-white px-3.5 py-2.5'}>
            <div className="mx-[20px]  flex items-center">
              <div className="max-h-[75px] max-w-[75px]">
                {products?.images && products?.images?.length > 0 && (
                  <img src={products?.images?.[0].src} alt="Product Img" className="w-20" />
                )}
              </div>
              <div className="w-auto py-1.5 pl-[2.25rem]">
                <div className="flex-initial">
                  <p className="text-[17.5px] font-semibold leading-[21px] text-gray-900 ">{products?.name}</p>
                </div>
                <div className="mt-2 flex-wrap">
                  {variants?.isMSRPRestricted && !loggedIn ? (
                    <div>
                      <a className="cursor-pointer" onClick={() => showLogin()}>
                        <p className="text-base leading-[20px] text-blue-900 hover:underline">
                          {formatProductMessage({
                            id: 'exchangePricing',
                            defaultMessage: 'Log in for Exchange pricing',
                          })}
                        </p>
                      </a>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center space-x-4">
                      <div>
                        <Price price={products?.listPrice ?? products?.price} className="text-sm text-gray-900 " />
                      </div>
                      {isListPriceLarger && products?.salePrice && (
                        <div className="flex flex-row items-center gap-x-1.5 text-lg font-semibold text-red-900">
                          <Price price={products?.salePrice} className="text-lg font-semibold text-red-900" />
                          <span>
                            {formatProductMessage({
                              id: 'sale',
                              defaultMessage: 'Sale',
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>
          <section className={'w-full bg-[#ebebeb]'}>
            <div className="mx-[20px] flex items-start">
              <ul className="flex flex-row text-base font-semibold">
                {tabItems.map((tab, index) => (
                  <a
                    href={`#${tab?.id}`}
                    key={tab?.id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick(index);
                    }}
                    className="xl:mr-[4rem]"
                  >
                    <span
                      className={`inline-block py-[13.5px] text-base text-blue-900 md:px-2 lg:px-[13.5px] ${
                        activeTab === index ? 'border-b-4 border-blue-900' : ''
                      }`}
                    >
                      {tab?.title}
                    </span>
                  </a>
                ))}
              </ul>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default FloatingHeader;
