import React, { useEffect, useRef, useState } from 'react';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useCart } from 'frontastic';
import { getMarketingBugProducts } from 'frontastic/actions/product';
import BackToPLP from './backToPLP';
import ProductInfo from './productInfo';

const ProductTable = ({ products, skus }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const [processedSpecifications, setProcessedSpecifications] = useState([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [marketingBugProducts, setMarketingBugProducts] = useState([]);
  const { studioConfig, data, setCartProductDetailEventSent } = useCart();
  const [isOverflow, setIsOverflow] = useState(false);
  const divRef = useRef(null);

  const handleGridTop = () => {
    if (products?.length < 4)
      return (
        <div className="col-span-1 hidden w-full min-w-[345px] border-b border-r border-[#00000033] lg:block lg:min-w-full"></div>
      );
  };
  const handleGridBotton = () => {
    if (products?.length === 1) {
      const borderDivs = Array.from({ length: 2 }, (_, index) => (
        <div
          key={index}
          className="col-span-1 hidden w-full min-w-[345px] border-b border-[#00000033] lg:block lg:min-w-full"
        ></div>
      ));
      return borderDivs;
    } else if (products?.length === 2) {
      return (
        <div className="col-span-1 hidden w-full min-w-[345px] border-b border-[#00000033] lg:block lg:min-w-full"></div>
      );
    }
  };

  const findVariantsBySkus = (product) => {
    const variantMap = {};
    product?.variants?.forEach((variant) => {
      variantMap[variant.sku] = variant;
    });

    const matchingVariants = skus?.map((sku) => variantMap[sku]);
    const productWithMarketingBug = marketingBugProducts?.find((product) =>
      product?.variants?.find((variant) => variant?.skuId === matchingVariants?.filter(Boolean)[0]?.sku),
    );
    return { ...matchingVariants?.filter(Boolean), marketingBug: productWithMarketingBug?.marketingBug || null };
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (divRef.current) {
        setIsOverflow(divRef.current.scrollWidth > divRef.current.clientWidth);
      }
    };
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [isLoad]);

  useEffect(() => {
    const processSpecifications = async () => {
      const specificationsArray = [];

      products?.forEach(async (product, index) => {
        const matchingVariants = findVariantsBySkus(product);
        const specifications = matchingVariants?.[0]?.attributes?.specifications
          ? JSON?.parse(product?.variants?.[0]?.attributes?.specifications)
          : null;
        const description = product?.description;

        specifications &&
          specifications?.forEach((spec) => {
            const { label, desc } = spec;
            const key = label;

            const existingSpec = specificationsArray?.find((item) => item?.key === key);

            if (!existingSpec) {
              const newEntry = { key, value: [] };
              for (let i = 1; i <= products.length; i++) {
                newEntry.value.push({ [`product${i}`]: i === index + 1 ? desc : null });
              }
              specificationsArray.push(newEntry);
            } else {
              const productIndex = index + 1;
              const productEntry = existingSpec.value.find((entry) => entry.hasOwnProperty(`product${productIndex}`));

              if (productEntry) {
                productEntry[`product${productIndex}`] = desc;
              } else {
                existingSpec.value.push({ [`product${productIndex}`]: desc });
              }
            }
          });
        const descriptionKey = 'Description';
        const existingDescription = specificationsArray?.find((item) => item?.key === descriptionKey);

        if (!existingDescription) {
          specificationsArray.push({ key: descriptionKey, value: [{ [`product${index + 1}`]: description }] });
        } else {
          const productIndex = index + 1;
          const productEntry = existingDescription.value.find((entry) =>
            entry.hasOwnProperty(`product${productIndex}`),
          );

          if (productEntry) {
            productEntry[`product${productIndex}`] = description;
          } else {
            existingDescription.value.push({ [`product${productIndex}`]: description });
          }
        }
      });

      setProcessedSpecifications(specificationsArray);
    };

    processSpecifications();
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
        const formattedSkus = skus.join(',');
        const res = await getMarketingBugProducts(formattedSkus);
        setMarketingBugProducts(res?.records);
        setIsLoad(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoad(false);
      }
    };

    fetchData();
  }, [skus]);

  useEffect(() => {
    const initBazaarvoice = () => {
      if (window.$BV) {
        clearInterval(intervalId);
        const productIds = products?.map((product) => product?.productId);
        window.$BV.ui('rr', 'inline_ratings', {
          productIds: productIds,
          containerPrefix: 'BVRRInlineRating',
        });
      }
    };
    const intervalId = setInterval(() => {
      initBazaarvoice();
    }, 500);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [products]);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'compare',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (data && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };

    fetchData();
  }, [data, monetateConfig]);

  return (
    <>
      <section ref={divRef} className="mt-1 h-screen w-full overflow-x-auto lg:h-auto lg:overflow-x-visible">
        <div
          className="sticky left-0"
          onClick={() => {
            setIsLoad(true);
          }}
        >
          <BackToPLP />
        </div>
        <div className="sticky top-0 z-10 bg-white">
          <div className="flex grid-cols-4 lg:grid">
            {handleGridTop()}
            {products?.map((product, index) => {
              const matchingVariants = findVariantsBySkus(product);
              const isMarketPlaceItem = product?.variants?.[0]?.attributes?.customProductType === 'INTEGRATED_VENDOR';
              const isUseFacilityBasedPrice = product?.variants?.[0]?.attributes?.isUseFacilityBasedPrice;
              return (
                <div className="col-span-1 w-full min-w-[345px] bg-white lg:min-w-full" key={index}>
                  <ProductInfo
                    matchingVariants={matchingVariants}
                    isMarketPlaceItem={isMarketPlaceItem}
                    isUseFacilityBasedPrice={isUseFacilityBasedPrice}
                    product={product}
                    setIsLoad={setIsLoad}
                  />
                </div>
              );
            })}
            {handleGridBotton()}
          </div>
        </div>
        <div>
          <div className="text-lg font-bold leading-5 text-gray-900">
            <p className="py-4">
              {formatMessage({ id: 'productSpecification', defaultMessage: 'Product Specification' })}
            </p>
          </div>
          {processedSpecifications?.map((Specification, index) => {
            return (
              <div className={`text-gray-900 ${isOverflow ? 'w-fit' : 'w-full'} lg:w-full`} key={index}>
                <div className="flex min-h-[36px] w-full items-center bg-[#F5F5F5] px-1 text-sm font-bold leading-5">
                  {Specification?.key}
                </div>
                <div className="flex grid-cols-4 lg:grid">
                  {handleGridTop()}
                  {Specification?.value?.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="col-span-1 flex min-h-[44px] w-full min-w-[345px] flex-col items-center justify-center border-b border-r border-[#00000033] p-2 text-center text-sm leading-5 lg:min-w-full"
                        dangerouslySetInnerHTML={{ __html: val[`product${index + 1}`] }}
                      ></div>
                    );
                  })}
                  {handleGridBotton()}
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {isLoad && <Loader />}
    </>
  );
};

export default ProductTable;
