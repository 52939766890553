import React from 'react';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';

type ContentsProps = {
  contents?: Record<string, any>;
};

const Contents = (contents: ContentsProps) => {
  return (
    <>
      {contents?.contents?.topBanner && <TemplateCMS cmsContent={{ assetID: contents?.contents?.topBanner }} />}
      {contents?.contents?.featuredContent && (
        <TemplateCMS cmsContent={{ assetID: contents?.contents?.featuredContent }} />
      )}
    </>
  );
};

export default Contents;
