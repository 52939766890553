import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Loader from 'components/commercetools-ui/loader/Loader';
import CheckIcon from 'components/icons/Check-circle';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useProduct } from 'frontastic';
import Breadcrumb from '../bundle-select/breadcrumb';
import NeedAnythingElse from './needAnythingElse';

export default function ThankYou({ homeCta, lineItem }) {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const { getSkusExternalPrices } = useProduct();
  const [variant, setVariant] = useState([]);
  const [variantquantities, setVariantquantities] = useState([]);
  const [referencedProductsPrice, setReferencedProductsPrice] = useState([]);
  const referencedProducts = lineItem?.variant?.attributes?.referencedProducts;
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isDesktop] = useMediaQuery(tablet);

  const getStandAlonePriceBySkus = async (skus) => {
    setIsLoad(true);
    try {
      const fetchedPrices = await getSkusExternalPrices(skus);
      setReferencedProductsPrice(fetchedPrices);
    } catch (error) {}
    setIsLoad(false);
  };
  useEffect(() => {
    if (referencedProducts?.length > 0) {
      const skus = referencedProducts?.flatMap((product) => product?.variants?.map((variant) => variant?.sku));
      getStandAlonePriceBySkus(skus);
    }
  }, [referencedProducts]);
  const CATEGORIES = [{ name: 'Category', url: '#' }];

  return (
    <>
      <section>{isDesktop && <Breadcrumb categories={CATEGORIES} activeItem={'Uniform Bundle'} />}</section>
      <section>
        {isLoad && <Loader />}
        <div className="flex items-center gap-[18px] bg-[#f6f6f6] px-3 py-9 text-[#45A614]">
          <CheckIcon className=" h-[36px] w-[36px] fill-current" />
          <div>
            <p className="text-2xl font-semibold leading-9">
              {formatMessage({ id: 'thankyou', defaultMessage: 'Thank you!' })}
            </p>
            <p className="text-lg font-semibold	leading-6	">
              {formatMessage({ id: 'thankyouText', defaultMessage: 'Your uniform has been added to cart. ' })}(
              <span
                onClick={() => {
                  setIsLoad(true);
                }}
                className="cursor-pointer text-blue-900 underline hover:no-underline"
              >
                <Link href={lineItem?._bundleEditUrl ?? '#'}>
                  {formatMessage({ id: 'editBundle', defaultMessage: 'Edit Bundle' })}
                </Link>
              </span>
              )
            </p>
          </div>
        </div>
      </section>
      {referencedProducts && referencedProducts.length > 0 && (
        <section>
          <div className="mt-4 flex flex-col gap-y-4">
            <div className="text-2xl font-semibold leading-7 text-[#717171]">
              {formatMessage({ id: 'needAnythingElse', defaultMessage: 'Need Anything Else?' })}
            </div>
            <div className="text-base font-normal leading-6 text-gray-900">
              <span className="font-semibold">{formatMessage({ id: 'note', defaultMessage: 'NOTE: ' })}</span>
              {formatMessage({ id: 'theseItemsMayShipSeparately', defaultMessage: 'These items may ship separately' })}
            </div>
            <div className="py-5">
              {referencedProducts?.map((product, index) => {
                return (
                  <>
                    <NeedAnythingElse
                      key={index}
                      product={product}
                      variant={variant[index]}
                      setVariant={setVariant}
                      setQuantity={setVariantquantities}
                      quantity={variantquantities[index]}
                      productIndex={index}
                      referencedProductsPrice={referencedProductsPrice}
                    />
                    <hr className="border border-gray-900 opacity-10" />
                  </>
                );
              })}
            </div>
          </div>
        </section>
      )}
      <section>
        <div className="flex justify-center py-10">
          <Link href={homeCta ?? '#'}>
            <button className="h-10 w-full rounded bg-blue-900 text-lg font-normal text-[#FFFFFF] md:w-60">
              {formatMessage({ id: 'cart.start.shopping', defaultMessage: 'Continue Shopping' })}
            </button>
          </Link>
        </div>
      </section>
    </>
  );
}
