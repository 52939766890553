import React, { useEffect, useState } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { isParentBundleLineItem } from 'helpers/utils/ub-utils';
import { useCart, useAccount } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import GrDeliver from '../../../icons/delivery';
import StoreIcon from '../../../icons/storeIcon';
import Warning from '../../../icons/warning';
import CartItem from './cartItem';
import AlertMessage from './message';
const CartItems = ({
  data,
  giftCardImg,
  itemQty,
  getAllCustomMessages,
  itemInStock,
  setShowRecipientDetailsErrMsg,
  defaultShippingAddressNotAvailable,
}) => {
  const { loggedIn } = useAccount();
  useEffect(() => {
    if (data.totalLineItemCount) {
      const separatedCartList = getSeparatedDeliverySections(data.displayLineItems);
      setCartList(separatedCartList);
    }
  }, [data]);
  const pickupStore = getAllCustomMessages?.pickUpInStore;
  const curbside = getAllCustomMessages?.curbsideAvailable;
  const inventoryCantSupply = getAllCustomMessages?.inventoryCantSupply;
  const SHIP_TO_ADDRESS_ITEMS = 0;
  const PICKUP_STORE_ITEMS = 1;
  const ELECTRONIC_DELIVERY_ITEMS = 2;
  const ADDITIONAL_ITEMS = 3;
  const SHIP_TO_ADDRESS = 'Ship to Address';
  const ELECTRONIC_DELIVERY = 'Electronic Delivery';
  const [curbAvailable, setCurbAvailable] = useState([]);
  const [pickUpStoreCount, setPickupStoreCount] = useState([]);
  const getSeparatedDeliverySections = (lineItems: LineItem[]) => {
    const separatedDeliverySections: {
      [ELECTRONIC_DELIVERY]: LineItem[];
      [SHIP_TO_ADDRESS]: LineItem[];
      SHIP_TO_STORE: {
        [key: string]: LineItem[];
      };
    } = {
      [ELECTRONIC_DELIVERY]: [],
      [SHIP_TO_ADDRESS]: [],
      SHIP_TO_STORE: {},
    };

    //ShipToAddress section
    for (const lineItem in lineItems[SHIP_TO_ADDRESS_ITEMS]) {
      for (let i = 0; i < lineItems[SHIP_TO_ADDRESS_ITEMS][lineItem]?.length; i++) {
        const item = lineItems[SHIP_TO_ADDRESS_ITEMS][lineItem][i];
        if (!(item?.isAttachment || item?.isOverSizeFee)) {
          separatedDeliverySections[SHIP_TO_ADDRESS].push(item);
        }
      }
    }

    setCurbAvailable([]);
    setPickupStoreCount([]);
    //PickupStore section
    for (const lineItem in lineItems[PICKUP_STORE_ITEMS]) {
      for (let i = 0; i < lineItems[PICKUP_STORE_ITEMS][lineItem]?.length; i++) {
        const item = lineItems[PICKUP_STORE_ITEMS][lineItem][i];
        if (separatedDeliverySections.SHIP_TO_STORE[item.channel.name]?.length) {
          separatedDeliverySections.SHIP_TO_STORE[item.channel.name]?.push(item);
        } else {
          separatedDeliverySections.SHIP_TO_STORE[item.channel.name] = [item];
          setPickupStoreCount((storeCount) => [
            ...storeCount,
            data.displayLineItems?.[PICKUP_STORE_ITEMS]?.[`${lineItem}_totalCount`],
          ]);
          setCurbAvailable((curbValues) => [...curbValues, item.channel.isCurbSidePickupEligible]);
        }
      }
    }

    //Electronic Delivery section
    lineItems[ELECTRONIC_DELIVERY_ITEMS]['EGIFT_CARD']?.map((item) => {
      separatedDeliverySections[ELECTRONIC_DELIVERY].push(item);
    });

    return separatedDeliverySections;
  };

  //Delivery Item Count section
  const DeliveryItemCount = (shippingOption) => {
    let totalCount = 0;
    for (const key in data.displayLineItems[shippingOption]) {
      if (key.endsWith('_totalCount')) {
        totalCount += data.displayLineItems[shippingOption][key];
      }
    }
    return totalCount;
  };

  const [cartList, setCartList] = useState(null);

  const { updateItem, removeAdditionalItem } = useCart();

  const { formatMessage } = useFormat({ name: 'cart' });

  const removeAddonProducts = (addonItem) => {
    data.lineItems.map((item, index) => {
      item?.attachments?.map((baseItem, index) => {
        baseItem?.id === addonItem.productId &&
          removeAdditionalItem(addonItem.lineItemId, item.lineItemId, item?.attachments?.length);
      });
    });
  };
  const isItemInStock = (item) => {
    const isBundleItem = isParentBundleLineItem(item);
    if (isBundleItem) {
      const bundleItemsId: string[] =
        data?.lineItems
          ?.filter((lineItem) => lineItem?.parentBundleLineItemId === item?.lineItemId)
          ?.map((item) => item?.lineItemId) ?? [];
      return ![item?.lineItemId, ...bundleItemsId]?.some((bundleItemId) => !itemInStock?.[bundleItemId]?.isOnStock);
    }
    return itemInStock?.[item?.lineItemId]?.availability
      ? itemInStock?.[item?.lineItemId]?.availability.isOnStock
      : itemInStock?.[item?.lineItemId]?.isOnStock;
  };
  function electronicDelivery() {
    return (
      <section>
        {cartList?.['Electronic Delivery']?.map(
          (item, index) =>
            !item?.isAttachment && (
              <CartItem
                item={item}
                index={index}
                key={item.lineItemId}
                hideBottomBorder={index == cartList?.['Electronic Delivery'].length - 1}
                itemShippingAddresses={data.itemShippingAddresses}
                removeAddonProducts={removeAddonProducts}
                shippingAddresses={data.itemShippingAddresses}
                giftCardImg={giftCardImg}
                itemQty={itemQty}
                getAllCustomMessages={getAllCustomMessages}
                shippingSurcharge={undefined}
                activationFeeItem={data?.displayLineItems?.[ADDITIONAL_ITEMS]?.others?.find(
                  (lineItem) => lineItem?.productId === item?.variant?.attributes?.activationFeeItem?.id,
                )}
                itemStockAvailability={isItemInStock(item)}
                setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
                data={data}
                lineItemsStock={itemInStock}
                defaultShippingAddressNotAvailable={defaultShippingAddressNotAvailable}
              ></CartItem>
            ),
        )}
      </section>
    );
  }

  function shipToAddress() {
    return (
      <section>
        {cartList?.[SHIP_TO_ADDRESS]?.map((item, index) => (
          <CartItem
            item={item}
            index={index}
            key={item.lineItemId}
            hideBottomBorder={index == cartList?.[SHIP_TO_ADDRESS].length - 1}
            itemShippingAddresses={data.itemShippingAddresses}
            removeAddonProducts={removeAddonProducts}
            shippingAddresses={data.itemShippingAddresses}
            giftCardImg={giftCardImg}
            itemQty={itemQty}
            getAllCustomMessages={getAllCustomMessages}
            shippingSurcharge={data?.customLineItems?.find((customLineItem) => customLineItem.key === item.lineItemId)}
            activationFeeItem={data?.displayLineItems?.[ADDITIONAL_ITEMS]?.others?.find(
              (lineItem) => lineItem?.productId === item?.variant?.attributes?.activationFeeItem?.id,
            )}
            itemStockAvailability={isItemInStock(item)}
            setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
            data={data}
            lineItemsStock={itemInStock}
            defaultShippingAddressNotAvailable={defaultShippingAddressNotAvailable}
          ></CartItem>
        ))}
      </section>
    );
  }
  return (
    <>
      <section className="hidden">
        <AlertMessage
          bg="bg-red-100"
          border="border border-red-900"
          color="text-red-900"
          icon={<Warning className="w-[18.54px]" />}
          text={inventoryCantSupply}
        />
      </section>
      {!loggedIn ? (
        <section className="mb-3 rounded  lg:border lg:border-gray-700">
          <section className="lg:px-2">
            {shipToAddress()}
            <hr />
            {electronicDelivery()}
          </section>
        </section>
      ) : (
        <>
          {cartList?.[SHIP_TO_ADDRESS]?.length > 0 && (
            <section className="mb-3 rounded lg:border  lg:border-gray-700 ">
              <section className="flex items-center bg-gray-700 p-2">
                <GrDeliver className="mr-2 text-gray-900" />

                <h3 className="font-bold text-gray-900">
                  {formatMessage({ id: 'shipToAddress', defaultMessage: 'Ship to Address' })} (
                  {DeliveryItemCount(SHIP_TO_ADDRESS_ITEMS)}{' '}
                  {formatMessage({ id: 'shipToItems', defaultMessage: 'items' })}){' '}
                </h3>
              </section>
              <section className="lg:p-2">{shipToAddress()}</section>
            </section>
          )}

          {cartList?.SHIP_TO_STORE &&
            Object.keys(cartList?.SHIP_TO_STORE).length > 0 &&
            Object.keys(cartList?.SHIP_TO_STORE).map((storeName, index) => (
              <section className="mb-3 rounded lg:border  lg:border-gray-700 " key={storeName}>
                <section className="flex items-center bg-gray-700 p-2">
                  <StoreIcon className="mr-2 mb-6 text-gray-900 sm:mb-0" />
                  <h3 className="font-bold text-gray-900">
                    {pickupStore} {storeName} ({pickUpStoreCount[index]}{' '}
                    {formatMessage({ id: 'shipToItems', defaultMessage: 'items' })}){' '}
                    <span className="ml-3 text-sm font-normal">{curbAvailable[index] && curbside}</span>
                  </h3>
                </section>
                <section className="lg:p-2">
                  <section>
                    {cartList?.SHIP_TO_STORE?.[storeName]?.map((item, index) => (
                      <CartItem
                        item={item}
                        index={index}
                        key={item.lineItemId}
                        hideBottomBorder={index == cartList?.SHIP_TO_STORE?.[storeName].length - 1}
                        itemShippingAddresses={data.itemShippingAddresses}
                        removeAddonProducts={removeAddonProducts}
                        shippingAddresses={data.itemShippingAddresses}
                        giftCardImg={giftCardImg}
                        itemQty={itemQty}
                        getAllCustomMessages={getAllCustomMessages}
                        shippingSurcharge={undefined}
                        activationFeeItem={data?.displayLineItems?.[ADDITIONAL_ITEMS]?.others?.find(
                          (lineItem) => lineItem?.productId === item?.variant?.attributes?.activationFeeItem?.id,
                        )}
                        itemStockAvailability={
                          itemInStock[item?.lineItemId]?.availability
                            ? itemInStock[item?.lineItemId]?.availability.isOnStock
                            : itemInStock[item?.lineItemId]?.isOnStock
                        }
                        setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
                        data={data}
                        lineItemsStock={itemInStock}
                        defaultShippingAddressNotAvailable={defaultShippingAddressNotAvailable}
                      ></CartItem>
                    ))}
                  </section>
                </section>
              </section>
            ))}

          {cartList?.['Electronic Delivery']?.length > 0 && (
            <section className="mb-3 rounded  lg:border lg:border-gray-700">
              <section className="flex items-center bg-gray-700 p-2">
                <GrDeliver className="mr-2 text-gray-900" />

                <h3 className="font-bold text-gray-900">
                  {formatMessage({ id: 'electronicDelivery', defaultMessage: 'Electronic Delivery' })} (
                  {DeliveryItemCount(ELECTRONIC_DELIVERY_ITEMS)}{' '}
                  {formatMessage({ id: 'shipToItems', defaultMessage: 'items' })}){' '}
                </h3>
              </section>
              <section className="lg:p-2">{electronicDelivery()}</section>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default CartItems;
