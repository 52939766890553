import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { GA_SUBSCRIPTION } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';

export interface Props {
  servicesModal: any;
  setServicesModal: any;
  subscriptionServices: any;
  lineItem: any;
  selectSubscriptionTitle: any;
  orderId: any;
  isSubscription: any;
  subscriptionDefaultFrequency?: any;
}

const SubscriptionServicesModal: React.FC<Props> = ({
  servicesModal,
  setServicesModal,
  subscriptionServices,
  lineItem,
  selectSubscriptionTitle,
  orderId,
  isSubscription,
  subscriptionDefaultFrequency,
}) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { addSubscriptionService, removeSubscriptionService } = useCart();
  const [activeService, setActiveService] = useState(subscriptionDefaultFrequency);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const { getSubscriptionTemplateOrders, updateSubscriptionFrequency } = useAccount();
  const [subscriptionOrderData, setSubscriptionOrderData] = useState([]);
  const [updated, setUpdated] = useState({});
  const [removeSubscribe, setRemoveSubscribe] = useState(false);
  const { account } = useAccount();
  const { trackMyAccount } = useGATrackUserAction();

  const getActiveIndex = (items) => {
    let currentIndex = null;
    subscriptionDefaultFrequency && (currentIndex = items === subscriptionDefaultFrequency);
    return currentIndex;
  };

  const addSubscription = async (lineItem, activeService) => {
    setSaveButtonClicked(true);
    if (!isSubscription) {
      await addSubscriptionService(lineItem?.lineItemId, activeService);
    } else {
      const res = await updateSubscriptionFrequency(orderId, activeService);
      res && setSaveButtonClicked(false);
    }
    setServicesModal(false);
    trackMyAccount(GA_SUBSCRIPTION, 'subscriptions', account);
  };
  const removeSubscription = async (lineItem) => {
    removeSubscriptionService(lineItem?.lineItemId);
    setServicesModal(false);
  };

  return (
    <section>
      {servicesModal && (
        <section aria-labelledby="subscriptionModalTitle" aria-modal="true">
          <section className="fixed top-0 left-0 z-20 h-full w-full" aria-hidden="true">
            <section className="overlay z-1 fixed left-0 top-0 h-full w-full bg-black-200 opacity-50"></section>
            <section className="absolute left-1/2 top-2/4 z-30 w-4/5 -translate-x-2/4 -translate-y-2/4 rounded bg-white p-8 md:w-1/3">
              <section className="mb-[21px] flex justify-between">
                <h3 id="subscriptionModalTitle" className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                  {' '}
                  {selectSubscriptionTitle}
                </h3>
                <button type="button" aria-label="Close Modal">
                  <GrClose
                    onClick={() => {
                      setServicesModal(false);
                    }}
                  />
                </button>
              </section>
              <section className="grid grid-cols-12 gap-4">
                <section className="font- col-span-12">
                  <ul
                    className="mb-5 grid grid-cols-1 gap-4 text-sm"
                    role="radiogroup"
                    aria-labelledby="subscriptionServicesLabel"
                  >
                    {subscriptionServices?.map((item, index) => {
                      const checkedVal = getActiveIndex(item);
                      return (
                        <li key={index}>
                          <label
                            htmlFor={item.id}
                            className="flex items-start text-gray-900"
                            role="radio"
                            aria-checked={checkedVal}
                          >
                            <input
                              type="radio"
                              name="addOnServices"
                              value={item}
                              id={item.id}
                              className="mr-3 mt-1"
                              defaultChecked={checkedVal}
                              onChange={() => {
                                setActiveService(item);
                                setRemoveSubscribe(false);
                                setSaveButtonClicked(false);
                              }}
                            />
                            <section>{item}</section>
                          </label>
                        </li>
                      );
                    })}
                    {!isSubscription && (
                      <li>
                        <label
                          htmlFor="noThanks"
                          className="flex items-start text-gray-900"
                          role="radio"
                          aria-checked={removeSubscribe}
                        >
                          <input
                            type="radio"
                            name="addOnServices"
                            value="No Thanks"
                            id="noThanks"
                            className="mr-3 mt-1"
                            onChange={() => setRemoveSubscribe(true)}
                          />
                          <section>No Thanks</section>
                        </label>
                      </li>
                    )}
                  </ul>

                  {!removeSubscribe ? (
                    <button
                      type="button"
                      onClick={() => activeService && addSubscription(lineItem, activeService)}
                      className={`mt-2 rounded bg-blue-900 p-3 font-bold leading-[19.2px] text-white ${
                        subscriptionDefaultFrequency === activeService || saveButtonClicked ? 'disabled:opacity-50' : ''
                      }`}
                      disabled={subscriptionDefaultFrequency === activeService || saveButtonClicked}
                    >
                      {formatCheckoutMessage({ id: 'saveContinue', defaultMessage: 'Save' })}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => lineItem && removeSubscription(lineItem)}
                      className="mt-2 rounded bg-blue-900 p-3 font-bold leading-[19.2px] text-white"
                    >
                      {formatCheckoutMessage({ id: 'saveContinue', defaultMessage: 'Save' })}
                    </button>
                  )}
                </section>
              </section>
            </section>
          </section>
        </section>
      )}
    </section>
  );
};

export default SubscriptionServicesModal;
