import React, { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import Loader from 'components/commercetools-ui/loader/Loader';
import ArrowBackward from 'components/icons/arrowBackward';
import { useAccount } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
interface GuestProfileValidationProp {
  closeModal: () => void;
}
function GuestProfileValidation({ closeModal }: GuestProfileValidationProp) {
  const router = useRouter();
  const { logout, account } = useAccount();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'common' });
  const handleLogout = async () => {
    setIsLoad(true);
    await logout(account?.accountId);
    closeModal();
    setIsLoad(false);
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };
  const handleCancel = async () => {
    setIsLoad(true);
    await router.push('/');
    setIsLoad(false);
    closeModal();
  };
  return (
    <>
      {isLoad && <Loader />}
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0">
                  <section
                    className="absolute top-1/4 left-1/2 z-[100] h-[220px] max-h-[220px] w-11/12 max-w-[700px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto bg-white p-4 dark:bg-primary-200"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col gap-y-3">
                      <div className="bg-[#EAF0F5] py-3 px-2 text-left text-lg font-semibold leading-5 text-gray-900">
                        {formatMessage({ id: 'profileValidation', defaultMessage: 'Profile Validation' })}
                      </div>
                      <div className="text-left text-xs leading-4 text-gray-900">
                        <p className="mb-3">
                          {formatMessage({
                            id: 'profileValidationMessage1',
                            defaultMessage:
                              'Anyone can buy Exchange Gift Cards and AT&T phone cards to support the troops, including those who are not normally authorized to shop the Exchange Online Store.',
                          })}
                        </p>
                        <p>
                          {formatMessage({
                            id: 'profileValidationMessage2',
                            defaultMessage:
                              'Log-off now to purchase Exchange Gift Cards and AT&T phone cards, and then log back in to continue shopping.',
                          })}
                        </p>
                      </div>
                      <div className="flex gap-x-2 text-xs text-[#FFFFFF]">
                        <button
                          onClick={handleLogout}
                          className="flex h-[31px] w-[72px] items-center justify-center bg-blue-900"
                        >
                          {<ArrowBackward className="mr-1 h-[10px] w-[10px] rotate-180 fill-current" />}{' '}
                          {formatMessage({ id: 'logOff', defaultMessage: 'Log Off' })}
                        </button>
                        <button
                          className="flex h-[31px] w-[72px] items-center justify-center bg-blue-900"
                          onClick={handleCancel}
                        >
                          {<ArrowBackward className="mr-1 h-[10px] w-[10px] rotate-180 fill-current" />}
                          {formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}
                        </button>
                      </div>
                    </div>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default GuestProfileValidation;
