import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

function ComingSoon() {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  return (
    <>
      <div className="my-4 flex justify-center">
        <span className="font-lato h-[19px] min-w-[100px] text-center text-base font-bold leading-5 text-[#DA0F0F]">
          {formatProductMessage({ id: 'comingsoon', defaultMessage: 'Coming Soon!' })}
        </span>
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className="font-lato h-[44px]  w-[277px] rounded bg-[#EEEEEE] text-center text-base font-bold leading-5 text-[#043C6366] text-opacity-40"
          disabled
        >
          {formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to Cart' })}
        </button>
      </div>
    </>
  );
}

export default ComingSoon;
