import React, { useEffect } from 'react';

const Index = ({ data }) => {
  useEffect(() => {
    if (!data || !data?.tags) return;

    const { categories, name, slug, productKey } = data?.data?.dataSource?.product;

    const placeholderRegex = /{{(.*?)}}/g;

    const replacements = {
      category: categories && categories[0]?.parent ? categories[0].name : '',
      parentCategory:
        categories && categories[0]?.parent && categories[0]?.parent[1]?.name ? categories[0]?.parent[1]?.name : '',
      htmlEncodedBreadcrumb: categories
        ? encodeURIComponent(categories[0]?.overrideUrl.replace('/browse?pageCategories=', ''))
        : '',
      productName: name,
      productSlug: slug,
      productKey: productKey,
    };

    const manipulatedTags = data?.tags?.replace(placeholderRegex, (match, placeholder) => {
      return replacements[placeholder.trim()] !== undefined ? replacements[placeholder.trim()] : '';
    });

    const cleanedTags = manipulatedTags
      .split('|')
      .map((tag) => tag.trim())
      .filter((tag) => tag)
      .join(' | ');

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = cleanedTags;

    const addedTags = [];
    const head = document.head;

    while (tempDiv.firstChild) {
      const tag = tempDiv.firstChild;
      head.appendChild(tag);
      addedTags.push(tag);
    }

    return () => {
      addedTags.forEach((tag) => {
        if (tag.parentNode) {
          tag.parentNode.removeChild(tag);
        }
      });
    };
  }, [data?.data?.dataSource?.product]);

  return null;
};

export default Index;
