import React from 'react';
import Adsense from 'components/commercetools-ui/aafes/adsense';

function index({ data }) {
  return (
    <section>
      <Adsense slot={data.clientid} />
    </section>
  );
}

export default index;
