import React, { useEffect } from 'react';

const Seo = ({ data }) => {
  useEffect(() => {
    const tempDiv = document.createElement('div');
    const addedTags = [];

    if (data && data.tags) {
      tempDiv.innerHTML = data.tags;
      const head = document.head;
      while (tempDiv.firstChild) {
        const tag = tempDiv.firstChild;
        head.appendChild(tag);
        addedTags.push(tag);
      }
    }

    return () => {
      addedTags.forEach((tag) => {
        if (tag.parentNode) {
          tag.parentNode.removeChild(tag);
        }
      });
    };
  }, [data.tags]);

  return null;
};

export default Seo;
