import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';

export default function Clearance({ res, handleRefinementClick, handleDisplayFilter, selectedRefinements }) {
  const { studioConfig } = useCart();
  const FILTER_ATTRIBUTE_CLEARANCE = studioConfig?.filterAttributeClearance;
  const [isDesktop] = useMediaQuery(tablet);
  const hasClearanceSelected = selectedRefinements[FILTER_ATTRIBUTE_CLEARANCE]?.includes('true');

  return (
    <>
      {!hasClearanceSelected && res?.refinements?.some((refinement) => refinement?.name === 'true') && (
        <div className="order-last">
          <p
            onClick={() => {
              const trueRefinement = res?.refinements?.find((refinement) => refinement?.name === 'true');
              handleRefinementClick(trueRefinement, res?.name);
              handleDisplayFilter();
            }}
            className="py-2 pb-4 text-sm font-bold leading-4 text-red-900"
          >
            <span className="cursor-pointer">{res?.displayName}</span>
          </p>
          <hr className="border border-[#00000033] opacity-40" />
        </div>
      )}
    </>
  );
}
