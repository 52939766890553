import React, { useMemo } from 'react';
import { UIProduct } from 'components/commercetools-ui/aafes/pdp';
import { useFormat } from 'helpers/hooks/useFormat';
import { calculateMoneyStats } from 'helpers/utils/calculateMoneyStats';
import { useAccount } from 'frontastic';
import Price from '../../price';

type Props = {
  product: UIProduct;
  isMSRPRestricted: boolean;
  showLogin: any;
  variantStoreIsOnStock?: boolean;
  variantIsPickupInStore?: boolean;
  isPickUp?: boolean;
  isUseFacilityBasedPrice?: boolean;
};

const PriceDetails = ({
  product,
  isMSRPRestricted,
  showLogin,
  variantStoreIsOnStock,
  variantIsPickupInStore,
  isPickUp,
  isUseFacilityBasedPrice,
}: Props) => {
  const { loggedIn } = useAccount();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const moneyArray = [product?.salePrice, product?.listPrice, product?.msrpPrice];
  const productStorePrices = product?.storePrices;
  const moneyArrayForPuis = [
    productStorePrices?.salePrice,
    productStorePrices?.listPrice,
    productStorePrices?.msrpPrice,
  ];
  const formattedLogInText = formatProductMessage({
    id: 'exchangePricing',
    defaultMessage: 'Log in for Exchange pricing',
  });
  const moneyStats = useMemo(() => {
    return calculateMoneyStats(moneyArray);
  }, moneyArray);

  const moneyStatsForPuis = useMemo(() => {
    return calculateMoneyStats(moneyArrayForPuis);
  }, moneyArray);

  const isListPriceLarger = useMemo(() => {
    if (!product?.salePrice?.centAmount) return true;
    if (product?.listPrice?.centAmount > product?.salePrice?.centAmount) return true;
    return false;
  }, [product?.listPrice, product?.salePrice]);

  const isListPriceLargerForPuis = useMemo(() => {
    if (!productStorePrices?.salePrice?.centAmount) return true;
    if (productStorePrices?.listPrice?.centAmount > productStorePrices?.salePrice?.centAmount) return true;
    return false;
  }, [productStorePrices?.listPrice, productStorePrices?.salePrice]);

  // Component breakdown for better code readability
  const PriceDetailSection = () => {
    return (
      <div className="pb-6">
        {isPickUp && variantIsPickupInStore ? (
          isUseFacilityBasedPrice && variantStoreIsOnStock ? (
            <h4 className={`font-lato text-[18px] font-bold leading-[30px] text-[#333333]`}>
              <Price price={product?.storePrice} className="inline-block" />
            </h4>
          ) : (
            <STAPriceDetail
              formatProductMessage={formatProductMessage}
              isListPriceLarger={isListPriceLarger}
              listPrice={product?.listPrice}
              msrpPrice={product?.msrpPrice}
              price={product?.price}
              salePrice={product?.salePrice}
              moneyStats={moneyStats}
            />
          )
        ) : (
          <STAPriceDetail
            formatProductMessage={formatProductMessage}
            isListPriceLarger={isListPriceLarger}
            listPrice={product?.listPrice}
            msrpPrice={product?.msrpPrice}
            price={product?.price}
            salePrice={product?.salePrice}
            moneyStats={moneyStats}
          />
        )}
      </div>
    );
  };

  if (isMSRPRestricted) {
    return loggedIn ? (
      <PriceDetailSection />
    ) : (
      <LoginForPriceSection formattedLogInText={formattedLogInText} showLogin={showLogin} />
    );
  }

  return <PriceDetailSection />;
};

export default React.memo(PriceDetails);

const LoginForPriceSection = ({ showLogin, formattedLogInText }) => {
  return (
    <div>
      <button className="cursor-pointer" onClick={() => showLogin()}>
        <p className="py-4 text-base font-semibold leading-[24px] text-blue-900 hover:underline">
          {formattedLogInText}
        </p>
      </button>
    </div>
  );
};

const STAPriceDetail = ({
  formatProductMessage,
  msrpPrice,
  listPrice,
  price,
  isListPriceLarger,
  salePrice,
  moneyStats,
}) => (
  <>
    {msrpPrice && (
      <section className="line-through">
        <p className={`font-lato flex items-center text-base font-normal leading-[30px] text-[#666666]`}>
          <span>{formatProductMessage({ id: 'compare.at', defaultMessage: 'Compare at:' })} </span>
          <section className={`ml-1 flex min-h-[30px] items-center`}>
            <Price price={msrpPrice} className="inline-block" />
          </section>
        </p>
      </section>
    )}
    <h4 className={`font-lato text-[18px] font-bold leading-[30px] text-[#333333]`}>
      <Price price={listPrice ?? price} className="inline-block" />
    </h4>
    {isListPriceLarger && salePrice && (
      <p className={`font-lato flex items-center text-base font-normal leading-[30px] text-red-300`}>
        <h4 className={`font-lato text-[18px] font-bold leading-[30px]`}>
          <Price price={salePrice} className="inline-block" />
          <span className="ml-2">{formatProductMessage({ id: 'sale', defaultMessage: 'Sale:' })} </span>
        </h4>
      </p>
    )}
    {moneyStats?.difference?.centAmount > 0 && (
      <section className="relative w-max">
        <p className={`font-lato flex items-center text-sm font-normal leading-[16.8px] text-[#666666]`}>
          <span>{formatProductMessage({ id: 'you.save', defaultMessage: 'You Save:' })}</span>
          <section className={`ml-1`}>
            <Price price={moneyStats?.difference} className="inline-block" />
            <span className={`ml-1`}>({moneyStats?.percentageDifference}%)</span>
          </section>
        </p>
      </section>
    )}
  </>
);
