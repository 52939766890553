import React, { useState } from 'react';
import Link from 'next/link';
import SecureCheckout from '../Modals/secureCheckout';

function MiniHeader({ data }) {
  const [secureCheckoutModal, setSecureCheckoutModal] = useState(false);

  function closeSecureCheckout() {
    setSecureCheckoutModal(false);
  }
  return (
    <>
      <section className="-mx-[15px] lg:-mx-0">
        <section className="flex justify-between bg-[#EDEDED] py-[22px] px-[15px] md:px-[30px]">
          <Link href={data?.linksCol1}>
            <a className="flex cursor-pointer items-center text-affes-sm leading-[16.8px] text-gray-900">
              <img
                src={data?.iconCol1?.media?.file}
                alt={`${data?.headerCol1} Icon `}
                className="relative top-[2px] mr-1"
              />{' '}
              {data?.headerCol1}
            </a>
          </Link>
          <section className="hidden md:block">
            <img src={data?.iconCol3?.media?.file} alt={`${data?.iconCol3?.media?.name} Icon `} />
          </section>
          <section className="flex md:hidden">
            <img src={data?.iconColM3?.media?.file} alt={`${data?.iconColM3?.media?.name} Icon `} />
          </section>
          <button
            className="flex items-center text-affes-xl font-bold leading-[19.2px] text-blue-900"
            onClick={() => setSecureCheckoutModal(true)}
          >
            <img src={data?.iconCol2?.media?.file} alt={`${data?.headerCol2} Icon `} className="mr-3" />{' '}
            {data?.headerCol2}
          </button>
        </section>
      </section>
      <SecureCheckout secureCheckoutModal={secureCheckoutModal} closeModal={closeSecureCheckout} />
    </>
  );
}

export default MiniHeader;
