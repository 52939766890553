import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import { validateEmail } from 'helpers/utils/fieldValidators';
import { useAccount } from 'frontastic';
import UpdateProfileModal from '../../../commercetools-ui/aafes/Modals/updateProfileConfirmation';
import StarIcon from '../../../icons/star-icon';
import footerMailIcon from '../../../images/mail.png';
const LoginModal = dynamic(() => import('../Modals/Login/login'));
function NewsLetter({ otherLinks }) {
  const { formatMessage } = useFormat({ name: 'newsletter' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const confirmationMessage = formatAccountMessage({
    id: 'savedMsg',
    defaultMessage: 'Your preferences were successfully saved.',
  });
  const [loginModal, setLoginModal] = useState(false);
  const { login, loggedIn, updateCustomerPreferences, account } = useAccount();
  const [inputMail, setInputMail] = useState('');
  const [inputMailError, setInputMailError] = useState('');
  const router = useRouter();
  const [seasonalMailChannelVal, setSeasonalMailChannelVal] = useState(
    account?.addressForSeasonalAd || account?.addresses?.[0]?.addressId,
  );
  const [alertMessage, setAlertMessage] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(' ');
  const [loading, setLoading] = useState(false);
  function showLogin() {
    setLoginModal(true);
  }

  const [subscribeClicked, setSubscribeClicked] = useState(false);
  function closeLoginModal() {
    setLoginModal(false);
    setSubscribeClicked(false);
    setRedirectUrl('');
  }

  const handleEmailChange = (e) => {
    setInputMail(e.target.value);
    setInputMailError('');
  };

  const handleEmailBlur = (e) => {
    e.target.placeholder = 'Enter Email Address';
    setInputMailError('');
  };
  const navigationPage = {
    name: formatMessage({ id: 'CommunicationPreferences', defaultMessage: 'Communication Preferences' }),
    href: '/account#communicationPreference',
  };
  async function handleSubscribe() {
    setLoading(true);
    if (validateEmail(inputMail)) {
      try {
        const res = await updateCustomerPreferences(
          'promoByEmailSubscriptionType',
          'opted_in',
          'promoSubscriptionEmail',
          inputMail,
        );
        const responseTyped = res as any;
        if ('errorCode' in responseTyped && 'body' in responseTyped) {
          setInputMailError(responseTyped?.body);
        } else {
          async function updatingMail() {
            try {
              setRedirectUrl(navigationPage.href);

              await updateCustomerPreferences(
                'seasonalAdByMailSubscriptionType',
                'subscribed',
                'addressForSeasonalAd',
                seasonalMailChannelVal,
              );
              router.push(navigationPage.href);
              setAlertMessage(true);
              setInputMail('');
            } catch (err) {
              setInputMailError(
                formatMessage({
                  id: 'seasonalMailError',
                  defaultMessage: `Couldn't Update Seasonal Mail Subscriptions`,
                }),
              );
            }
          }
          updatingMail();
          setLoading(false);
        }
      } catch (err) {
        setInputMailError(err);
      } finally {
        setLoading(false);
      }
    } else {
      setInputMailError(' *Please enter a valid email address');
    }
    setLoading(false);
  }
  function closeConfirmationModal() {
    setAlertMessage(false);
  }

  const renderSweepstakesSection = () => (
    <section className="  mt-2 cursor-pointer bg-black-100  text-white  lg:mt-0  lg:mr-3  lg:w-[330px] ">
      <section className="mx-auto  text-center ">
        <p className="py-2 text-[12px] font-bold">
          {formatMessage({ id: 'enterToWin', defaultMessage: 'ENTER TO WIN OUR' })}
        </p>
        <section className="m-auto  flex w-60 border-y border-cyan-100">
          <StarIcon aria-hidden="true" className="  h-8 w-10 p-1" />
          <section className="border-r"></section>
          <p className="my-auto w-[158px] px-4 py-1 text-affes-sm font-bold tracking-wide">
            {formatMessage({ id: 'sweepstakes', defaultMessage: 'SWEEPSTAKES!' })}
          </p>
          <section className="border-r"></section>
          <StarIcon aria-hidden="true" className="  h-8 w-10 p-1" />
        </section>
        <p className="items-center py-2 text-xs text-[rgba(139,162,177,1)]">
          {formatMessage({
            id: 'seeDetails',
            defaultMessage: 'See details and enter for your chance to win!',
          })}
        </p>
      </section>
    </section>
  );

  return (
    <>
      <section className="bg-blue-900">
        <section className="">
          <section className="  block  md:px-6 lg:flex ">
            <section className="my-auto  w-full py-3 px-4  text-white  lg:flex lg:w-4/5" aria-label="News letter">
              <section className="flex lg:w-[56%]">
                <img
                  src={footerMailIcon.src}
                  className="mr-3 mt-[5px] h-8 w-10 md:mt-2"
                  alt={formatMessage({ id: 'signup', defaultMessage: 'SIGN UP FOR EMAILS!' })}
                />
                <section className="md:pt-0.5">
                  <p className="text-affes-xl font-bold">
                    {formatMessage({ id: 'signup', defaultMessage: 'SIGN UP FOR EMAILS!' })}{' '}
                  </p>
                  <p className="text-affes-sm">
                    {formatMessage({
                      id: 'description',
                      defaultMessage: 'Get exclusive email-only offers and advance notice about sales & events',
                    })}
                  </p>
                </section>
              </section>

              <section className=" relative  mt-2 md:my-auto lg:w-[40%] ">
                <label htmlFor="signupNewsletter" className="sr-only">
                  Enter Email Address
                </label>
                <input
                  type="email"
                  className="h-9 w-full rounded text-sm text-[rgba(102,102,102,1)]"
                  placeholder={formatMessage({ id: 'newsLetterPlacaHolder', defaultMessage: 'Enter Email Address' })}
                  onFocus={(e) => (e.target.placeholder = 'Email Address')}
                  onBlur={handleEmailBlur}
                  onChange={(e) => handleEmailChange(e)}
                  value={inputMail}
                  id="signupNewsletter"
                  aria-labelledby="singupForm"
                  autoComplete="new-email"
                />
                {loggedIn ? (
                  <button
                    className="absolute right-0.5 top-0.5 rounded bg-[rgba(4,60,99,1)] px-3 py-2 text-xs font-bold text-white "
                    onClick={handleSubscribe}
                    id="singupForm"
                  >
                    {formatMessage({ id: 'subscribe', defaultMessage: 'SUBSCRIBE' })}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setSubscribeClicked(true);
                      setRedirectUrl('/');
                      showLogin();
                    }}
                    className="absolute right-0.5 top-0.5 rounded bg-[rgba(4,60,99,1)] px-3 py-2 text-xs font-bold text-white "
                    id="singupForm"
                  >
                    {formatMessage({ id: 'subscribe', defaultMessage: 'SUBSCRIBE' })}
                  </button>
                )}
                <p className="pt-0.5 text-sm">{inputMailError}</p>
              </section>
            </section>
            {loggedIn ? (
              <Link href="/sweepstakes">{renderSweepstakesSection()}</Link>
            ) : (
              <button
                onClick={() => {
                  showLogin();
                  setRedirectUrl('/sweepstakes');
                }}
                className="mt-2 w-full cursor-pointer bg-black-100  text-white  lg:mt-0  lg:mr-3 lg:w-[330px]"
              >
                {renderSweepstakesSection()}
              </button>
            )}
          </section>
        </section>
      </section>
      <LoginModal
        loginModal={loginModal}
        closeAccountFlyout={closeLoginModal}
        closeModal={closeLoginModal}
        loginCallBack={() => {
          if (subscribeClicked) {
            handleSubscribe();
          } else {
            router.push(redirectUrl);
            setLoading(false);
          }
        }}
      />
      {loading && <Loader />}

      {alertMessage && (
        <>
          <UpdateProfileModal closeModal={closeConfirmationModal} updatedProfile={true} message={confirmationMessage} />
        </>
      )}
    </>
  );
}

export default NewsLetter;
