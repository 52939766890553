import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import AdditionalServices from '../aafes/cart/additionalServices';
function AdditionalServicesModal({ lineItem, cartListItems, isSFL, setAddonItemId, closeModal, saveForLaterPrices }) {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-black fixed inset-0 bg-opacity-25" />
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <section className="absolute inset-0" onClick={closeModal}>
                <section
                  className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-2 pb-4 dark:bg-primary-200 md:mt-9 lg:mt-3 xl:w-[544px]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="absolute top-3 right-3"></section>
                  <section className="w-[100%] text-left">
                    <section className="modalHeader relative mb-5">
                      <button
                        className="absolute right-0 top-0"
                        onClick={() => {
                          closeModal(false);
                        }}
                        aria-label="Close Modal"
                      >
                        <GrClose />
                      </button>
                    </section>
                    <AdditionalServices
                      lineItem={lineItem}
                      cartListItems={cartListItems}
                      setAddonItemId={setAddonItemId}
                      isSFL={isSFL}
                      saveForLaterPrices={saveForLaterPrices}
                    />
                  </section>
                </section>
              </section>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AdditionalServicesModal;
