import React from 'react';
import Price from 'components/commercetools-ui/price';
import {
  UB_PRODUCT_TYPE_PERSONALIZE,
  UB_PRODUCT_TYPE_MULTI,
  UB_PERSONALIZE_INPUT_UPDATE_TEXT,
  UB_APPAREL_CONFIG_IMAGE_PROPS,
} from 'helpers/constants/aafes';
import { GetOrdinalSuffix } from 'helpers/utils/ub-utils';
import UBImageSection from './UBImageSection';
interface ProductItem {
  sku: string;
  image: string;
  imageAltTxt: string;
  name: string;
  personalizationTextLines?: string[];
  price: any;
}

interface UBConfigItemListDetailsProps {
  updateMode: boolean;
  product: ProductItem[];
  productType: string;
  onItemRemove: (item: ProductItem) => void;
  setUpdateMode: (value: boolean) => void;
  displayName: string;
}

const UBConfigItemListDetails: React.FC<UBConfigItemListDetailsProps> = ({
  updateMode,
  product,
  productType,
  onItemRemove,
  setUpdateMode,
  displayName,
}) => {
  return (
    <div>
      {!updateMode &&
        product?.length > 0 &&
        product.map((item, index) => (
          <React.Fragment key={`key_${item.sku}`}>
            <div className="mt-5 flex items-center">
              <UBImageSection image={item.image} altText={item.imageAltTxt} zoomProps={UB_APPAREL_CONFIG_IMAGE_PROPS} />
              <div className="ml-5">
                {productType === UB_PRODUCT_TYPE_MULTI && (
                  <p className="mb-2.5 text-sm font-semibold not-italic leading-normal">
                    {`${GetOrdinalSuffix(index + 1)} ${displayName}`}
                  </p>
                )}
                <p className="mb-2.5 text-sm font-normal not-italic leading-normal">{item.name}</p>
                {productType === UB_PRODUCT_TYPE_PERSONALIZE && (
                  <p className="mb-2.5 text-sm font-normal not-italic leading-normal">
                    {item.personalizationTextLines?.[0]}
                  </p>
                )}
                <p className="mb-2.5">
                  <Price price={item.price} className="text-sm font-semibold not-italic leading-normal" />
                </p>
                <div>
                  {productType !== UB_PRODUCT_TYPE_PERSONALIZE && (
                    <button
                      className="mr-3 text-sm font-normal not-italic leading-normal text-blue-900"
                      onClick={() => onItemRemove(item)}
                    >
                      Remove
                    </button>
                  )}
                  {productType === UB_PRODUCT_TYPE_PERSONALIZE && (
                    <button
                      className="text-sm font-normal not-italic leading-normal text-blue-900"
                      onClick={() => setUpdateMode(true)}
                    >
                      {UB_PERSONALIZE_INPUT_UPDATE_TEXT}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default UBConfigItemListDetails;
