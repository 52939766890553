import React, { useState } from 'react';
import Link from 'next/link';
import { NETWORKS } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import TrustedSite from '../../../icons/trustedSite';
import { InlineFollowButtons } from '../sharethis';
import TrustedSiteModal from './trustedSite';

function CopyrightSection({ otherLinks, dgDetails }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const year = new Date().getFullYear();
  const [showTrustedSiteModal, setTrustedSiteModal] = useState(false);
  return (
    <>
      <section className="mb-[100px] w-full px-[15px] md:mb-0 md:px-[30px]">
        <section className="md:border-t md:border-[rgba(0,0,0,0.19)]">
          <section className="block md:my-auto  xl:flex xl:justify-between ">
            {' '}
            <section className="mb-4">
              <ul className="block items-end md:flex">
                <li className="order-1 mt-[19px] mb-9 flex border-t-2 pt-4 text-sm text-[#333333] md:order-2 md:border-0">
                  <div className="mx-auto cursor-pointer hover:underline md:ml-6">
                    <Link href={otherLinks?.termsConditions}>
                      {formatMessage({ id: 'siteTerms', defaultMessage: 'Site Terms & Conditions' })}
                    </Link>
                  </div>
                  <div className="mx-3 border md:hidden"></div>
                  <div className="mx-auto cursor-pointer hover:underline md:ml-6">
                    <Link href={otherLinks?.privacyAct}>
                      {formatMessage({ id: 'privacyAct', defaultMessage: 'Privacy Act Statement' })}
                    </Link>
                  </div>
                </li>

                <li className="order-2 text-center md:order-1 md:mt-[19px] md:mb-9 md:ml-6">
                  <button className="text-affes-sm text-gray-900">
                    &#169;
                    {year}
                    {formatMessage({ id: 'copyRights', defaultMessage: ' The EXCHANGE' })}
                    <sup>TM</sup> {formatMessage({ id: 'allRights', defaultMessage: 'All Rights Reserved.' })}
                  </button>
                </li>
              </ul>
            </section>
            <section className=" relative  right-16 mb-4 ">
              <section className=" ml-16 flex items-center justify-between gap-3 md:mt-4 md:justify-end md:gap-4 ">
                <section className="z-[8] flex">
                  <Link href={dgDetails?.dgLink}>
                    <a>
                      <img src={dgDetails?.dgIcon?.media?.file} alt={dgDetails?.dgIcon?.media?.name} />
                    </a>
                  </Link>
                  <InlineFollowButtons
                    config={{
                      alignment: 'center', // alignment of buttons (left, center, right)
                      color: 'grey', // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)
                      labels: null, // button labels (cta, counts, null)
                      language: 'en', // which language to use (see LANGUAGES)
                      networks: NETWORKS,
                      padding: 0,
                    }}
                  />
                </section>
                <section className="cursor-pointer">
                  <button onClick={() => setTrustedSiteModal(true)} aria-label="Open Trusted Site Modal">
                    <TrustedSite />
                  </button>
                  {showTrustedSiteModal && (
                    <TrustedSiteModal
                      showTrustedSiteModal={showTrustedSiteModal}
                      setTrustedSiteModal={setTrustedSiteModal}
                    />
                  )}
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </>
  );
}

export default CopyrightSection;
