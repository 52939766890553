import React, { Fragment } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import Spinner from 'components/commercetools-ui/spinner';
import MilitaryStarCard from 'components/images/military-star-cardholders.png';
import PayYourWayImage from 'components/images/pay-your-way.png';
import { useCart } from 'frontastic';
function PayYourWay({ closeModal, secureCheckoutModal, studioConfig = {} }: any) {
  const { studioConfig: fallbackStudioConfig } = useCart();
  const pdpPlanYourWayDetails = studioConfig?.pdpPlanYourWayDetails || fallbackStudioConfig?.pdpPlanYourWayDetails;
  return (
    <>
      {/* TODO: static will be replaced with dynamic data*/}
      <Transition appear show={secureCheckoutModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[80%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto  rounded bg-white pb-4 dark:bg-primary-200 md:mt-9 lg:mt-3 xl:w-[700px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="absolute top-0 right-0">
                      <button
                        type="button"
                        className="ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-2xl text-gray-900 outline-none focus:outline-none  lg:hover:bg-gray-200 lg:hover:text-gray-900"
                        onClick={closeModal}
                      >
                        <GrClose />
                      </button>
                    </div>
                    <section className="w-[100%] text-left">
                      <div className="grid grid-cols-9 grid-rows-1 pt-6">
                        <div className="col-span-2">
                          <div className="ml-5 pt-[13px] pb-[26px]">
                            <Image
                              loader={({ src }) => src}
                              src={PayYourWayImage}
                              width={147}
                              height={44}
                              alt="Pay your way"
                            />
                          </div>
                        </div>
                        <div className="static col-span-7 pl-[5%] pt-[1%] pb-[3%] pr-1 text-sm">
                          <span className="w-full text-base text-[#333333]">
                            <p className="text-lg font-semibold">It&apos;s Easy</p>
                            <span className="font-semibold">Fixed Payment Plans That Fit Your Budget.&nbsp;</span>
                            Choose your payment during checkout. Get a reduced interest rate. Earn rewards
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-rows-3">
                        <div className="mt-2 bg-[#a9a9a9] py-2 pl-5">
                          <strong className="text-base text-[#333333] sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">
                            36 Month Financing
                          </strong>
                          <br></br>
                          Available on a minimum purchase of $300.00
                        </div>
                        <div className=" bg-[#dcdcdc] py-2 pl-5">
                          <strong className="text-base text-[#333333] sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">
                            48 Month Financing
                          </strong>
                          <br></br>
                          Available on a minimum purchase of $500.00
                        </div>
                        <div className=" bg-[#f8f8ff] py-2 pl-5">
                          <strong className="text-base text-[#333333] sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">
                            60 Month Financing
                          </strong>
                          <br></br>
                          Available on a minimum purchase of $1000.00
                        </div>
                      </div>

                      <div className="mt-4 pl-5">
                        <strong className="font-semibold text-[#333333] sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">
                          Don&apos;t have a MILITARY STAR® Card?
                        </strong>
                        <div className="mt-3 flex items-center justify-center">
                          <Link href="https://www.myecp.com/CardApply/GetCardApplyPrivacyTerms">
                            <a
                              className="pr-2 text-base font-semibold text-blue-900 hover:underline"
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="apply now"
                            >
                              Apply Now
                            </a>
                          </Link>
                          <div className="h-[73px] w-[104px]">
                            <Image
                              loader={({ src }) => src}
                              src={MilitaryStarCard}
                              alt="Pay your way"
                              width={104}
                              height={73}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-8 pb-10 text-xs">
                        <strong>Financing Details:</strong>
                        <br />
                        {!pdpPlanYourWayDetails ? (
                          <div className="my-3">
                            <Spinner />
                          </div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: pdpPlanYourWayDetails }} />
                        )}
                        <div className="mt-2">
                          <strong>Suggested Monthly Payment:</strong>
                          <br />
                          Estimate the monthly payment amount of a purchase using our easy&nbsp;
                          <Link href="https://www.myecp.com/Calculator">
                            <a
                              className="text-blue-900 hover:underline"
                              target="_blank"
                              rel="noreferrer"
                              aria-label="payment calculator"
                            >
                              Payment Calculator.
                            </a>
                          </Link>
                          &nbsp;Simply enter the purchase amount, select the desired period, then calculate.
                        </div>
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PayYourWay;
