import React, { useEffect, useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
import EditIcon from '../../../icons/editPencil';
import GiftDetails from '../Modals/giftDetails';
function GiftBox({ keyVal, lineItem, showEditIcon }) {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [showInfo, setShowInfo] = useState(false);
  const [showGiftModal, setShowGiftModal] = useState(false);
  const { loggedIn } = useAccount();
  useEffect(() => {
    if (lineItem?.isGift === true) {
      setIsCheck(true);
      setShowInfo(true);
    } else {
      setIsCheck(false);
      setShowInfo(false);
    }
  }, []);
  const { markAsGift } = useCart();
  const [isCheck, setIsCheck] = useState(false);
  const handleClick = (e) => {
    if (e.target.checked) {
      setShowInfo(true);
      setIsCheck(true);
      setShowGiftModal(true);
    } else {
      setIsCheck(false);
      const payload = {
        lineItem: {
          id: lineItem.lineItemId,
          isGift: false,
          giftEmailAddress: '',
          giftEmailMessage: '',
        },
        isGuestUser: !loggedIn,
      };
      markAsGift(payload);
    }
  };
  function showGift() {
    setShowGiftModal(true);
  }
  function closeGiftModal() {
    setShowGiftModal(false);
  }
  const [isCheckout, setIsCheckout] = useState(false);
  useEffect(() => {
    const location = window ? window.location.pathname : '';
    if (location.indexOf('checkout') > -1) {
      setIsCheckout(true);
    }
    if (location.indexOf('order-confirmation') > -1) {
      setIsCheckout(true);
    }
  }, []);
  return (
    <>
      <section>
        <section
          className={`border-[rgba(153, 153, 153, 0.3)] mt-3 flex items-center rounded border py-[11px] px-4 ${
            isCheckout ? ' xl:w-[657px]' : ''
          }`}
        >
          <section className="grid">
            <div className="flex items-center gap-[13px]">
              <label htmlFor={`gift${keyVal}`} className="flex">
                {showEditIcon && (
                  <input
                    type="checkbox"
                    name="gift"
                    id={`gift${keyVal}`}
                    className="mr-2 rounded"
                    onChange={handleClick}
                    checked={isCheck}
                  />
                )}
                <p className=" text-affes-base font-bold text-gray-900">
                  {formatCartMessage({ id: 'thisIsGift', defaultMessage: 'This is a gift' })}
                </p>
              </label>
              {showInfo && isCheck && showEditIcon && (
                <span onClick={() => setShowGiftModal(true)} aria-label="Edit gift information">
                  <EditIcon className="text-blue-900" />
                </span>
              )}
            </div>
            <section className="mt-0">
              {showInfo && isCheck && (
                <section className="mt-3">
                  <p className="text-affes-base text-gray-900">
                    <strong className="font-semibold text-[#666666]">
                      {' '}
                      {formatCommonMessage({ id: 'email', defaultMessage: 'Email :' })}
                    </strong>{' '}
                    {lineItem.giftEmailAddress}
                  </p>
                  <p className="pt-3 text-affes-base text-gray-900">
                    <strong className="font-semibold text-[#666666]">
                      {formatCommonMessage({ id: 'message', defaultMessage: 'Message :' })}
                    </strong>{' '}
                    {lineItem.giftEmailMessage}
                  </p>
                </section>
              )}
            </section>
          </section>
        </section>
      </section>
      <GiftDetails showGiftModal={showGiftModal} closeModal={closeGiftModal} item={lineItem} />
    </>
  );
}
export default GiftBox;
