import AmexIcon from '../../../frontend/components/images/amex.png';
import DiscoverIcon from '../../../frontend/components/images/discover-card.png';
import MasterCardIcon from '../../../frontend/components/images/mastercard.png';
import MilitaryStarIcon from '../../../frontend/components/images/military-star-cardholders.png';
import MilstarPlus from '../../../frontend/components/images/milstar-plus.png';
import VisaIcon from '../../../frontend/components/images/visa.png';

export const getCreditCardInfo = (payment) => {
  const cardInfo = {
    icon: null,
    text: null,
  };

  switch (payment.cardType) {
    case 'Visa':
      cardInfo.icon = <img src={VisaIcon.src} alt="VisaIcon" className="mr-[7.48px] h-6 w-[36.52px]" />;
      cardInfo.text = `Visa Ending ${payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)}`;
      break;
    case 'Mastercard':
      cardInfo.icon = <img src={MasterCardIcon.src} alt="MasterCardIcon" className="mr-[5.43px] h-6 w-[37.57px]" />;
      cardInfo.text = `Master Ending ${payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)}`;
      break;
    case 'Amex':
      cardInfo.icon = <img src={AmexIcon.src} alt="AmexIcon" className="mr-[5.48px] h-6 w-[36.52px]" />;
      cardInfo.text = `Amex Ending ${payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)}`;
      break;
    case 'Discover':
      cardInfo.icon = <img src={DiscoverIcon.src} alt="DiscoverIcon" className="mr-[6.52px] h-6 w-[35.49px]" />;
      cardInfo.text = `Discover Ending ${payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)}`;
      break;
    case 'Milstar':
      cardInfo.icon = <img src={MilitaryStarIcon.src} alt="MilitaryStarIcon" className="h-6 w-[38.09px]" />;
      cardInfo.text = `Military Star Ending ${
        payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)
      }`;
      break;
    case 'MilstarPlus':
      cardInfo.icon = <img src={MilstarPlus.src} alt="MilitaryStarIcon" className="h-6 w-[38.09px]" />;
      cardInfo.text = `Military Star Ending ${
        payment?.token ? payment?.token?.slice(-4) : payment?.cardNumber?.slice(-4)
      }`;
      break;
    default:
      break;
  }

  return cardInfo;
};
