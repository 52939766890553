import {
  GA_ADD_PAYMENT_INFO,
  GA_ADD_SHIPPING_INFO,
  GA_ADD_TO_CART,
  GA_ADD_TO_WISHLIST,
  GA_SERVER_URL,
} from 'helpers/constants/aafes';
import { useCallback } from 'react';
import useGATrackUserAction from './useGATrackUserAction';
import { getUserProperties, setCategoryValues } from './useGATrackViewActions';

const useGATrackAddActions = () => {
  const getCurrentUrl = (): string => {
    return window.location.href;
  };
  const { calculateAge } = useGATrackUserAction();

  const trackAddToCart = useCallback((trackAddToCartObj: any) => {
    const { product, variant, quantity, account } = trackAddToCartObj;
    const gtag = window?.gtag;
    try {
      const categories = setCategoryValues(product);
      const addToCart = {
        currency: variant?.price?.currencyCode,
        value: (variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits) * quantity,
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: [
          {
            item_id: variant?.sku,
            item_name: product?.name,
            product_id: variant?.productKey,
            item_brand: variant?.attributes?.brand || '',
            discount: 0,
            item_category: categories?.[0]?.name || '',
            item_category2: categories?.[1]?.name || '',
            item_category3: categories?.[2]?.name || '',
            item_category4: categories?.[3]?.name || '',
            size: product?.variants?.[0]?.picker?.Size || '',
            color: product?.variants?.[0]?.picker?.Color || '',
            price: variant?.price?.centAmount / 10 ** variant?.price?.fractionDigits,
            quantity: quantity,
          },
        ],
      };
      gtag('event', GA_ADD_TO_CART, addToCart);
    } catch (error) {
      console.error('ga4 add to cart event fail', error);
    }
  }, []);

  const trackAddToWishList = useCallback((trackAddToWishListObj: any) => {
    const { product, account } = trackAddToWishListObj;
    const gtag = window?.gtag;
    try {
      const categories = setCategoryValues(product);
      const addToWishList = {
        currency: product?.variants?.[0]?.price?.currencyCode,
        value: product?.variants?.[0]?.price?.centAmount / 10 ** product?.variants?.[0]?.price?.fractionDigits,
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: [
          {
            item_id: product?.variants?.[0]?.sku,
            item_name: product?.name,
            product_id: product?.variants?.[0]?.productKey,
            item_brand: product?.variants?.[0]?.attributes?.brand || '',
            discount: 0,
            item_category: categories?.[0]?.name || '',
            item_category2: categories?.[1]?.name || '',
            item_category3: categories?.[2]?.name || '',
            item_category4: categories?.[3]?.name || '',
            size: product?.variants?.[0]?.picker?.Size || '',
            color: product?.variants?.[0]?.picker?.Color || '',
            price: product?.variants?.[0]?.price?.centAmount / 10 ** product?.variants?.[0]?.price?.fractionDigits,
          },
        ],
      };
      gtag('event', GA_ADD_TO_WISHLIST, addToWishList);
    } catch (error) {
      console.error('ga4 add to wishlist event fail', error);
    }
  }, []);

  const trackAddPaymentInfo = useCallback((trackAddPaymentInfoObj: any) => {
    const { cartList, account } = trackAddPaymentInfoObj;
    const gtag = window?.gtag;
    try {
      const addPaymentInfo = {
        currency: cartList?.total?.currencyCode,
        value: cartList?.total?.centAmount / 10 ** cartList?.total?.fractionDigits,
        coupon: cartList?.discountCodes?.[0]?.code || '',
        payment_type: cartList?.payments?.[0]?.paymentMethod,
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: [
          cartList.lineItems.map((lineItems) => ({
            item_id: lineItems?.variant?.sku,
            item_name: lineItems?.name,
            discount: 0,
            product_id: lineItems?.variant?.productKey,
            item_brand: lineItems?.variant?.attributes?.brand || '',
            size: lineItems?.variant?.picker?.Size || '',
            color: lineItems?.variant?.picker?.Color || '',
            price: lineItems?.price?.centAmount / 10 ** lineItems?.price?.fractionDigits,
            quantity: lineItems?.count,
          })),
        ],
      };
      gtag('event', GA_ADD_PAYMENT_INFO, addPaymentInfo);
    } catch (error) {
      console.error('ga4 add payment info event fail', error);
    }
  }, []);

  const trackAddShippingInfo = useCallback((trackAddShippingInfoObj: any) => {
    const { cartData, account } = trackAddShippingInfoObj;
    const gtag = window?.gtag;
    try {
      const addShippingInfo = {
        currency: cartData?.total?.currencyCode,
        value: cartData?.total?.centAmount / 10 ** cartData?.total?.fractionDigits,
        shipping_tier: cartData?.shipping?.[0]?.shippingInfo?.name,
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: {
          item_id: cartData?.lineItems?.[0]?.variant?.sku,
          item_name: cartData?.lineItems?.[0]?.name,
          product_id: cartData?.lineItems?.[0]?.variant?.sku,
          item_brand: cartData?.lineItems?.[0]?.variant?.attributes?.brand || '',
          size: cartData?.lineItems?.variant?.picker?.Size || '',
          color: cartData?.lineItems?.variant?.picker?.Color || '',
          price: cartData?.lineItems?.[0]?.price?.centAmount / 10 ** cartData?.lineItems?.[0]?.price?.fractionDigits,
          quantity: cartData?.lineItems?.[0]?.count,
        },
      };
      gtag('event', GA_ADD_SHIPPING_INFO, addShippingInfo);
    } catch (error) {
      console.error('ga4 add shipping info event fail', error);
    }
  }, []);
  return { trackAddToCart, trackAddToWishList, trackAddPaymentInfo, trackAddShippingInfo };
};

export default useGATrackAddActions;
