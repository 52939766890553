import React from 'react';
function alertMsg(props) {
  return (
    <>
      <section className={`W-full mb-4 min-h-[46px] rounded border ${props.bg} ${props.color} ${props.border}`}>
        <section className="items-top flex items-start rounded-lg  p-3 text-sm md:items-center" role="alert">
          <section className="shrink-0 pt-1 md:pt-0">{props.icon}</section>
          <p className="font-lato ml-3 text-[14px] font-normal leading-[17px]">{props.text}</p>
        </section>
      </section>
    </>
  );
}

export default alertMsg;
