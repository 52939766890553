// Utility function to get day suffix
const getDaySuffix = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

// Utility function to format date and time into the desired format
const formatDateTime = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  function padHourInFormattedTime(formattedTime) {
    // Match the hour part (if it's a single digit) and pad it with a leading zero
    return formattedTime.replace(/\b(\d):(\d{2})/, '0$1:$2');
  }

  const formattedDate = date.toLocaleDateString('en-US', options);
  const day = date.getDate();
  const paddedDay = day.toString().padStart(2, '0');
  const dayWithSuffix = `${paddedDay}${getDaySuffix(day)}`;
  let formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  formattedTime = formattedTime.replace(/GMT[+-]\d{1,2}:\d{2}/, 'GMT');
  let paddedFormattedTime = padHourInFormattedTime(formattedTime);
  // Replacing the day in formattedDate with dayWithSuffix
  const formattedDateTime = `${formattedDate.replace(day.toString(), dayWithSuffix)} \u00A0${paddedFormattedTime}`;

  // Extracting components
  const [month, , year] = formattedDate.split(' ');
  const [time, period] = paddedFormattedTime.split(' ');
  const [hour, minute] = time.split(':');
  return {
    formattedDateTime,
    previewDate: {
      month: month,
      day: day.toString().padStart(2, '0'),
      year: year,
      hour: hour.padStart(2, '0'),
      minute: minute.padStart(2, '0'),
      period: period,
      timezone: paddedFormattedTime,
    },
  };
};

// Function to get the current date and time formatted
export const getFormattedDateTime = () => {
  const date = new Date();
  return formatDateTime(date);
};

// Function to parse a UTC date string into the desired format
export const parseDateTime = (utcDateStr: string) => {
  const date = new Date(utcDateStr);
  return formatDateTime(date).previewDate;
};
