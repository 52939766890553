import React from 'react';
import Order from 'components/commercetools-ui/aafes/order-confirmation';
import { useCart } from 'frontastic/provider';
import Head from 'next/head';
import { useFormat } from 'helpers/hooks/useFormat';

const OrderConfirmationTastic = ({ onSubmit, data }) => {
  const { data: cartList } = useCart();

  const { formatMessage } = useFormat({ name: 'cart' });
  return (
    <>
      <Order cartList={cartList} onSubmit={onSubmit} giftCard={data} />
    </>
  );
};

export default OrderConfirmationTastic;
