import { useState } from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Wishlist } from '@Types/wishlist/Wishlist';
//import Slider, { SliderProps } from 'components/commercetools-ui/slider';
import useSWR from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet, desktop } from 'helpers/utils/screensizes';
import { fetchApiHub, revalidateOptions, useCart, useCustomMessages, useSaveForLater } from 'frontastic';
import AafesSlider from '../aafes/aafes-slider';
import Item from './item';

interface Props {
  readonly saveForLater: Wishlist;
  readonly goToProductPage: (_url: string) => void;
  readonly removeLineItem: (lineItemId: string) => void;
  readonly moveToWishlist: (lineItem: LineItem) => void;
  readonly moveToCart: (lineItem: LineItem) => void;
  SFLPrices?: object;
}

const ItemList = ({ saveForLater, goToProductPage, removeLineItem, moveToWishlist, moveToCart, SFLPrices }: Props) => {
  const MoreThanProductMaxQty = () => {
    const queryParams = new URLSearchParams({
      messageKey: 'moreThanProductMaxQty',
    });
    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return (
      <>
        <p className="mb-3 text-[#cd0808]">{data}</p>
      </>
    );
  };

  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const [isMobileSize] = useMediaQuery(mobile);
  const [isTabletSize] = useMediaQuery(tablet);
  const [isDesktopSize] = useMediaQuery(desktop);
  const [activeItem, setActiveItem] = useState(null);
  const slidesToShow = isMobileSize ? (isTabletSize ? (isDesktopSize ? 4 : 3) : 2) : 1;
  const [errorMessage, setErrorMessage] = useState(false);

  const filteredLineItems = saveForLater?.lineItems?.filter((lineItem) => !lineItem?.isAttachment);

  const saveforlaterConfig = {
    //slidesPerView: isMobileSize ? (isTabletSize ? (isDesktopSize ? 4 : 3) : 2) : 1,
    slidesPerView: slidesToShow,
    arrows: isTabletSize ? true : false,
    dots: true,
    spaceBetween: 10,
    className: 'custom-slider save-for-later-slider',
  };
  return (
    <>
      <h2 id="cart-heading" className="sr-only">
        {formatCartMessage({ id: 'cart.shopping.items', defaultMessage: 'Items in your shopping cart' })}
      </h2>
      {errorMessage && <MoreThanProductMaxQty />}
      <AafesSlider {...saveforlaterConfig}>
        {filteredLineItems?.map((lineItem, i) => (
          <Item
            key={lineItem.lineItemId}
            lineItem={lineItem}
            lineItems={saveForLater?.lineItems}
            goToProductPage={goToProductPage}
            removeLineItem={removeLineItem}
            moveToWishlist={moveToWishlist}
            moveToCart={moveToCart}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            setQtyErrorMessage={setErrorMessage}
            saveForLaterPrices={SFLPrices[lineItem.lineItemId]}
            allsaveForLaterPrices={SFLPrices}
          />
        ))}
      </AafesSlider>
    </>
  );
};

export default ItemList;
