import React from 'react';
import Link from 'next/link';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from '../../../../helpers/hooks/useFormat';
const GuestLandingPage = ({
  topImage,
  giftCardReferenceCta,
  eGiftCardReferenceCta,
  eligibilityMessage,
  middleImage,
  armyReferenceCta,
  airForceReferenceCta,
  bottomImage,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <section aria-labelledby="guestUserSection">
      <h1 id="guestUserSection" className="sr-only">
        {' '}
      </h1>
      {topImage && (
        <div className="flex justify-center">
          <img src={topImage} alt="Top Banner Image" />
        </div>
      )}
      <div className="mx-auto mt-[18px] mb-[8px] grid max-w-[1024px] grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
        <div className="text-center md:text-right">
          <Link href={giftCardReferenceCta}>
            <button
              aria-label="give gift Card button"
              className="h-[36px] w-10/12 bg-[#C51230]  py-2 px-4 text-[11px] text-white hover:bg-red-800 md:h-[55px] md:text-[27px] lg:text-[27px] xl:w-[418px]"
            >
              <a className="mr-1">{formatMessage({ id: 'give', defaultMessage: 'GIVE' })}</a>{' '}
              <strong className="mr-1">{formatMessage({ id: 'gift', defaultMessage: 'GIFT' })}</strong>{' '}
              {formatMessage({ id: 'card', defaultMessage: 'CARD' })}
            </button>
          </Link>
        </div>
        <div className="text-center md:text-left">
          <Link href={eGiftCardReferenceCta}>
            <button
              aria-label="give egift Card button"
              className="h-[36px] w-10/12 bg-[#C51230] py-2 px-4 text-[11px] text-white hover:bg-red-800 md:h-[55px] md:text-[27px] lg:text-[27px] xl:w-[427px]"
            >
              <a className="mr-1">{formatMessage({ id: 'give', defaultMessage: 'GIVE' })} </a>{' '}
              <strong className="mr-1">{formatMessage({ id: 'eGift', defaultMessage: 'eGIFT' })}</strong>
              {formatMessage({ id: 'card', defaultMessage: 'CARD' })}
            </button>
          </Link>
        </div>
      </div>
      <div className="mx-auto mb-1.5 flex max-w-[1024px] justify-center  py-2.5">
        <strong className="bold h-[13px] text-[45%] text-[#333333] md:text-[80%] lg:text-[12px]">
          <Typography>{eligibilityMessage}</Typography>
        </strong>
      </div>
      {middleImage && (
        <div className="flex justify-center">
          <img src={middleImage} alt="Middle Banner Image" />
        </div>
      )}
      <div className="mx-auto mt-[18px] mb-[8px] grid max-w-[1024px] grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-2">
        <div className="text-center md:text-right">
          <Link href={armyReferenceCta}>
            <button
              aria-Label="army emergency relief fund button"
              className="h-[42px] w-10/12 bg-[#4C5425] py-2 px-4 text-[11px] text-white hover:bg-[#006400] md:text-[16px] xl:w-[418px] "
            >
              <a className="mr-1">
                {formatMessage({ id: 'armyEmergencyReliefFund', defaultMessage: 'ARMY EMERGENCY RELIEF FUND' })}{' '}
              </a>
            </button>
          </Link>
        </div>
        <div className="text-center md:text-left">
          <Link href={airForceReferenceCta}>
            <button
              aria-Label="air force assistance fund button"
              className="h-[42px] w-10/12 bg-[#608FB9] py-2 px-4 text-[11px] text-white hover:bg-[#00008B] md:text-[16px] xl:w-[427px] "
            >
              <a className="mr-1">
                {formatMessage({ id: 'airForceAssistanceFund', defaultMessage: 'AIR FORCE ASSISTANCE FUND' })}{' '}
              </a>
            </button>
          </Link>
        </div>
      </div>
      {bottomImage && (
        <div className="flex justify-center">
          <img src={bottomImage} alt="Bottom Banner Image" />
        </div>
      )}
    </section>
  );
};
export default GuestLandingPage;
