import React from 'react';
import PdpSeo from '../aafes-listingpages-seo/pdp';
import ListingPagesSeo from '../aafes-listingpages-seo/listingPages';
const Index = ({ data, isPdp }) => {
  if (data && data?.isPdp) {
    return <PdpSeo data={data} />;
  } else {
    return (
      <>
        <ListingPagesSeo data={data} />
      </>
    );
  }
};

export default Index;
