import React, { useState, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { StarIcon } from '@heroicons/react/solid';
import type { Address as AddressType } from '@Types/account/Address';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import { checkSessionExpiration } from 'helpers/utils/checkSessionExpiration';
import { useAccount, useCart } from 'frontastic';
import AddAddressModal from '../../../aafes/Modals/addAddressModal';
const LoginModal = dynamic(() => import('../../../aafes/Modals/Login/login'));
export interface AddressProps {
  address: AddressType;
  existingAddresses;
}

const Address: React.FC<AddressProps> = ({ address, existingAddresses }) => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { removeAddress, updateAddress, account } = useAccount();
  const { studioConfig } = useCart();
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useFormat({ name: 'common' });

  const handleDelete = () => {
    setDeleteModalOpen(false);
    setIsLoad(true);

    removeAddress(address.addressId)
      .then(() => {
        setIsLoad(false);
      })
      .catch((error) => {
        console.error('Failed to remove address:', error);
        setIsLoad(false);
      });
  };
  useEffect(() => {
    setIsLoad(false);
  }, [address]);

  const handleMakeDefault = () => {
    setIsLoad(true);
    const updatedAddress = {
      ...address,
      isDefaultBillingAddress: true,
      isDefaultShippingAddress: true,
    };

    updateAddress(updatedAddress)
      .then(() => {
        setIsLoad(false);
      })
      .catch((error) => {
        console.error('Failed to update address:', error);
        setIsLoad(false);
      });
  };

  //delete confirmation modal
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const closeDeleteModal = () => {
    setShowRemove(false);
  };

  const btnRef = useRef(null);
  const handleClickOutside = (event) => {
    if (btnRef.current && !btnRef.current.contains(event.target)) {
      setShowRemove(false);
    }
  };

  const handleWindowClick = (event) => {
    handleClickOutside(event);
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  const [loginModal, setLoginModal] = useState(false);
  const [isRememberMeSessionExpired, setRememberMeSessionExpired] = useState(false);

  const updateAddressModal = (chosedOption) => {
    const addressActions = {
      editAddress: () => {
        setShowAddAddressModal(true);
      },
      deleteAddress: () => {
        setShowRemove(true);
      },
    };

    const actionToBePerformed = addressActions[chosedOption];
    const expirationDetails = checkSessionExpiration(account?.lastActivity, studioConfig?.sessionAuthExpirationMins);
    if (expirationDetails?.checkedKeepMeSignedIn) {
      setRememberMeSessionExpired(expirationDetails?.isUserSessionExpired);
      if (expirationDetails?.isUserSessionExpired) {
        setLoginModal(true);
      } else {
        if (actionToBePerformed) {
          actionToBePerformed();
        }
      }
    } else {
      if (actionToBePerformed) {
        actionToBePerformed();
      }
    }
  };

  function closeLoginModal() {
    setLoginModal(false);
  }

  return (
    <>
      <div
        className={`${
          address?.isDefaultShippingAddress
            ? 'order-1 flex flex-col gap-4 rounded  border border-neutral-300 py-[14px] px-4 text-[#333333]'
            : 'order-2 flex flex-col gap-4 rounded  border border-neutral-300 py-[14px] px-4 text-[#333333]'
        }`}
      >
        {' '}
        {isLoad && <Loader />}
        <div className="flex-1">
          <dt className="mb-1">
            <span className="text-[14px] font-medium text-[#333333]">{address?.additionalAddressInfo}</span>
          </dt>
          <dt className="flex items-center justify-start ">
            <span className="text-[14px] font-medium text-[#333333]">
              {address?.firstName} {address?.lastName}
            </span>
            {address?.isDefaultShippingAddress && (
              <section className="ml-4 flex items-center  text-sm">
                <StarIcon className="mr-[5.21px] h-[15px] w-[15.79px]  text-blue-900 " />
                <p> {formatMessage({ id: 'defaultAddress', defaultMessage: 'Default Address' })}</p>
              </section>
            )}
          </dt>
          <dt className="mt-2 text-sm text-[#333333]">
            {address?.streetName} {','} {address?.streetNumber}
          </dt>
          <dt className="text-sm text-[#333333]">
            {address?.city}, {address?.state} {address?.postalCode}
          </dt>
          <dt className="text-md mt-2 text-sm text-[#333333]">
            <span className="font-medium">{formatMessage({ id: 'phone', defaultMessage: 'Phone' })}</span>{' '}
            {address?.phone}
          </dt>
        </div>
        <span className="  flex shrink-0 items-center space-x-2 text-sm">
          <button
            type="button"
            className="rounded-md text-sm font-medium text-[#043C63] transition  hover:underline focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
            onClick={() => {
              updateAddressModal('editAddress');
            }}
          >
            {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
          </button>
          {!address?.isDefaultShippingAddress && (
            <>
              <span className="text-[#666666]" aria-hidden="true">
                |
              </span>
              <button
                type="button"
                className="rounded-md text-sm font-medium text-[#043C63] transition hover:underline focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                ref={btnRef}
                onClick={() => {
                  updateAddressModal('deleteAddress');
                }}
              >
                {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
              </button>
              {showRemove && (
                <section className="relative">
                  <section className="absolute left-[-100px] top-[-110px] w-64 rounded-sm border border-gray-600 bg-white p-3 text-center">
                    <section className="boxArrow absolute left-14 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                      <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                    </section>
                    <p className="mb-2 font-bold text-gray-900">
                      {formatMessage({ id: 'removeItem', defaultMessage: 'Are you sure you want to delete?' })}
                    </p>
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                    >
                      {formatMessage({ id: 'yes', defaultMessage: 'Yes' })}
                    </button>
                    <button
                      type="button"
                      className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                      onClick={closeDeleteModal}
                    >
                      {formatMessage({ id: 'no', defaultMessage: 'No' })}
                    </button>
                  </section>
                </section>
              )}
              <section>
                <span className="text-[#666666]" aria-hidden="true">
                  |
                </span>

                <button
                  type="button"
                  className="ml-2 rounded-md text-sm font-medium text-[#043C63] transition hover:underline focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                  onClick={handleMakeDefault}
                >
                  {formatMessage({ id: 'makeDefault', defaultMessage: 'Make Default' })}
                </button>
              </section>
            </>
          )}
        </span>
      </div>

      {showAddAddressModal && (
        <AddAddressModal
          withoutSavingAddress={() => {}}
          afterSavingAddress={() => {}}
          closeAddAddressModal={() => setShowAddAddressModal(false)}
          parentLoaderState={isLoading}
          alwaysSave={false}
          defaultValues={address}
          editAddress={true}
          isCart={true}
          existingAddresses={existingAddresses}
        />
      )}
      {loginModal && (
        <LoginModal
          loginModal={loginModal}
          closeModal={closeLoginModal}
          closeAccountFlyout={closeLoginModal}
          loginCallBack={() => {}}
          isRememberMeSessionExpired={isRememberMeSessionExpired}
        />
      )}
    </>
  );
};

export default Address;
