import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IoClose } from 'react-icons/io5';
import Loader from 'components/commercetools-ui/loader/Loader';
import { MAX_FILTER } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { isCategory } from 'helpers/utils/findCategory';
import { useCart } from 'frontastic';

export default function FiltersApplied({ setSelectedRefinement, selectedRefinements, filter, setIsShowRefinements }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const { studioConfig } = useCart();
  const FILTER_ATTRIBUTE_CLEARANCE = studioConfig?.filterAttributeClearance;
  const FILTER_ATTRIBUTE_PRICE = studioConfig?.filterAttributePrice;
  const FILTER_ATTRIBUTE_RATING = studioConfig?.filterAttributeRating;
  const [filtersApplied, setFiltersApplied] = useState([]);
  const [filterToShow, setFilterToShow] = useState<number>(MAX_FILTER);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const router = useRouter();

  const handleURL = async (updatedRefinements) => {
    setIsLoad(true);
    setSelectedRefinement(updatedRefinements);
    setIsShowRefinements([]);
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    let facets = url?.searchParams?.get?.('facets');
    facets = updatedRefinements;
    if (Object.keys?.(updatedRefinements)?.length === 0) url?.searchParams?.delete?.('facets');
    else {
      const encodedFacets = encodeURIComponent(JSON?.stringify?.(facets));
      url?.searchParams?.set('facets', encodedFacets);
    }
    router
      ?.push(url?.toString())
      .then(() => {
        setIsLoad(false);
      })
      .catch(() => setIsLoad(false));
  };

  const removeFilter = async (removedKey, removedValue) => {
    const selectedRefinementArray = selectedRefinements?.[removedKey];
    const updatedRefinements = {
      ...selectedRefinements,
      [removedKey]: selectedRefinementArray?.filter((selectedName) => selectedName !== removedValue),
    };
    if (updatedRefinements?.[removedKey]?.length === 0) {
      delete updatedRefinements?.[removedKey];
    }
    await handleURL(updatedRefinements);
  };

  const DisplayText = (key, value) => {
    if (key === FILTER_ATTRIBUTE_PRICE) {
      const [min, max] = value.split('_');
      const formattedMin = `$${parseFloat(min).toFixed(2)}`;
      const formattedMax = `$${parseFloat(max).toFixed(2)}`;
      return `${formattedMin} - ${formattedMax}`;
    } else if (key === FILTER_ATTRIBUTE_RATING) {
      const intValue = Math.floor(parseFloat(value));
      return `${intValue} ${formatMessage({ id: 'starsAndUp', defaultMessage: 'Stars & Up' })}`;
    } else return value;
  };
  useEffect(() => {
    const sortFiltersApplied = Object.keys(selectedRefinements)
      .filter((key) => !isCategory(key))
      .reduce((result, key) => {
        result?.push({ [key]: selectedRefinements?.[key] });
        return result;
      }, []);

    const filtersApplied = sortFiltersApplied
      ?.map((filter) => {
        const [key] = Object?.keys(filter);
        const values = filter?.[key];

        const mappedValues = values?.map((value) => ({ key, value }));
        return mappedValues;
      })
      .flat();
    setFiltersApplied(filtersApplied);
  }, [selectedRefinements]);

  useEffect(() => {
    const tempShowRefinements = [];
    let getUniqueRefinements = [];
    filter?.forEach((res, index) => {
      res?.refinements?.forEach((refinement, refinementIndex) => {
        if (
          selectedRefinements[res?.name]?.includes(
            refinement?.name
              ? refinement?.name
              : res?.name === FILTER_ATTRIBUTE_RATING
              ? refinement?.low?.toFixed(1)
              : `${refinement?.low?.toFixed(2)}_${refinement?.high?.toFixed(2)}`,
          ) &&
          !isCategory(res?.name)
        ) {
          tempShowRefinements?.push({
            name: res?.name,
            isVisible: true,
          });
          getUniqueRefinements = tempShowRefinements?.filter(
            (item, index, self) => index === self?.findIndex((ref) => ref?.name === item?.name),
          );
        }
      });
    });

    // Set the state after processing the data
    setIsShowRefinements(getUniqueRefinements);
    if (filtersApplied?.length <= MAX_FILTER && filterToShow !== MAX_FILTER) setFilterToShow(MAX_FILTER);
  }, [filter, selectedRefinements]);

  if (Object.keys?.(selectedRefinements)?.length > 0 || filtersApplied?.length > 0)
    return (
      <>
        <div className="my-5 flex gap-x-1.5">
          <div className="flex flex-wrap items-center gap-3">
            {filtersApplied?.length > 0 && (
              <p className="flex h-[1.063rem] text-sm font-bold leading-4 text-gray-800">
                {formatMessage({ id: 'filtersApplied', defaultMessage: 'Filters Applied:' })}
              </p>
            )}
            {filtersApplied?.length > 0 &&
              filtersApplied?.slice(0, filterToShow)?.map((filter, index) => {
                return (
                  <div
                    key={index}
                    className="flex h-[1.5rem] gap-x-2.5 rounded bg-[#D9D9D9] px-[4px] pt-[3px] pb-[4px] text-gray-900"
                  >
                    <span className="h-[1.063rem] text-sm leading-4">
                      {filter?.key === FILTER_ATTRIBUTE_CLEARANCE
                        ? formatMessage({ id: 'clearance', defaultMessage: 'Clearance' })
                        : DisplayText(filter?.key, filter?.value)}
                    </span>
                    <button
                      onClick={() => {
                        removeFilter(filter?.key, filter?.value);
                      }}
                    >
                      <IoClose />
                    </button>
                  </div>
                );
              })}
            {filtersApplied?.length > MAX_FILTER &&
              (filterToShow === MAX_FILTER ? (
                <button
                  onClick={() => {
                    setFilterToShow(filtersApplied?.length);
                  }}
                  className="gap-x-2.5 rounded bg-[#D9D9D9] px-2 py-1.5 text-sm leading-4 text-blue-900"
                >
                  {filtersApplied?.length - MAX_FILTER}{' '}
                  {formatMessage({ id: 'moreFilters', defaultMessage: 'More Filters' })}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setFilterToShow(MAX_FILTER);
                  }}
                  className="gap-x-2.5 rounded bg-[#D9D9D9] px-2 py-1.5 text-sm leading-4 text-blue-900"
                >
                  {formatMessage({ id: 'showFewer', defaultMessage: 'Show Fewer' })}
                </button>
              ))}
            {Object.keys?.(selectedRefinements)?.length > 0 && (
              <button
                onClick={() => {
                  handleURL([]);
                  setFilterToShow(MAX_FILTER);
                }}
                className="h-[1.063rem] text-sm leading-4 text-blue-900"
              >
                {formatMessage({ id: 'clearAllFilters', defaultMessage: 'Clear All Filters' })}
              </button>
            )}
          </div>
        </div>
        {isLoad && <Loader />}
      </>
    );
  return <>{isLoad && <Loader />}</>;
}
