import React, { FC, useState } from 'react';
import classNames from 'classnames';

export interface RadioButtonOption {
  value: string;
  label: string;
}

export interface RadioButtonGroupProps {
  name: string;
  legend?: string;
  options: RadioButtonOption[];
  direction?: 'vertical' | 'horizontal';
  gap?: string;
  defaultSelected?: string;
  onValueChange?: (value: string) => void;
  shouldPrompt?: boolean;
  promptText?: string;
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  name,
  options,
  direction = 'vertical',
  gap = 'gap-x-11',
  defaultSelected,
  onValueChange,
  shouldPrompt = false,
  promptText,
}) => {
  const [selected, setSelected] = useState<any | undefined>(defaultSelected);
  const [showPromptForOption, setShowPromptForOption] = useState<any | undefined>();

  const handleChange = (option: RadioButtonOption) => {
    if (shouldPrompt) {
      setShowPromptForOption(option);
    } else {
      updateSelection(option);
    }
  };

  const updateSelection = (option: any) => {
    setSelected(option);
    onValueChange?.(option);
  };

  const confirmChange = () => {
    if (showPromptForOption) {
      updateSelection(showPromptForOption);
    }
    setShowPromptForOption(undefined);
  };

  const cancelChange = () => {
    setShowPromptForOption(undefined);
  };

  const containerClasses = classNames({
    'flex flex-col': direction === 'vertical',
    'flex flex-row': direction === 'horizontal',
    [gap]: gap,
  });

  return (
    <div>
      <fieldset className={containerClasses} aria-labelledby="fieldset-label">
        <legend id="fieldset-label" className="sr-only">
          Options
        </legend>
        {options.map((option) => (
          <div key={option.value} className="relative">
            <input
              id={option.value}
              className="h-[1.125rem] w-[1.125rem]"
              type="radio"
              name={name}
              value={option.value}
              checked={selected?.value === option.value}
              onChange={() => handleChange(option)}
              aria-labelledby={`label-${option.value}`}
              aria-describedby={`prompt-${option.value}`}
            />
            <label
              htmlFor={option.value}
              id={`label-${option.value}`}
              className="ml-2.5 text-sm font-normal leading-normal text-[#333]"
            >
              {option.label}
            </label>
            {showPromptForOption?.value === option.value && (
              <section
                className="absolute left-[-65px] top-[-105px] w-40 rounded-sm border border-gray-600 bg-white p-3 text-center"
                aria-live="assertive"
              >
                <section className="boxArrow absolute left-14 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                  <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                </section>
                <p id={`prompt-${option.value}`} className="mb-2 font-bold text-gray-900">
                  {promptText}
                </p>
                <button
                  type="button"
                  onClick={confirmChange}
                  className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={cancelChange}
                  className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                >
                  No
                </button>
              </section>
            )}
          </div>
        ))}
      </fieldset>
    </div>
  );
};
