import React from 'react';
import GuestHeader from 'components/commercetools-ui/aafes/guestUser/guestHeader';
import { useCart } from 'frontastic/provider';

function GuestHeaderTastic({ data }) {
  const { data: cartList } = useCart();
  return (
    <GuestHeader
      exchangeLogo={data.headerIcon.media.file}
      cartLogo={data.iconCol1.media.file}
      homeLink={data.homeLink}
      cartLink={data.headerCol1}
      frequentlyAskedQuestionsLink={data.frequentlyAskedQuestionsLink}
      termsAndConditionsLink={data.termsAndConditionsLink}
      cartList={cartList}
    />
  );
}

export default GuestHeaderTastic;
