import { useState } from 'react';
import { useCart } from 'frontastic';
import AafesSlider, { SliderProps } from '../aafes-slider';
import YouMayLikeTile from './you-may-like-tile';

const YouMayLikeComponent = ({
  slidesPerView,
  showArrows,
  showDots,
  showDetails,
  className = '',
  products,
  autoplayInterval = undefined,
}) => {
  let youMayLikeSliderConfig: SliderProps = {
    slidesPerView: slidesPerView,
    arrows: showArrows,
    dots: showDots,
    className: className + ' custom-slider',
  };

  if (autoplayInterval) {
    youMayLikeSliderConfig = {
      ...youMayLikeSliderConfig,
      autoplay: {
        delay: autoplayInterval,
        disableOnInteraction: false,
      },
    };
  }
  const { studioConfig } = useCart();
  const productMaxLimit = studioConfig?.productRecommendationLimit;
  const [youMayLikeComponent, setYouMayLikeComponent] = useState<boolean>(true);
  return (
    <AafesSlider youMayLikeComponent={youMayLikeComponent} {...youMayLikeSliderConfig}>
      {products.slice(0, productMaxLimit).map((product, index: number) => (
        <YouMayLikeTile product={product} showDetails={showDetails} key={index} />
      ))}
    </AafesSlider>
  );
};

export default YouMayLikeComponent;
