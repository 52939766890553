import { useEffect, useRef, FC } from 'react';
import Script from 'next/script';
interface SalsifyInitOptions {
  clientId: string;
  enhancedContent: { idType: string };
  languageCode: string;
}

interface Salsify {
  init: (options: SalsifyInitOptions) => void;
  enhancedContent: {
    exists: (productId: string) => Promise<boolean>;
    renderIframe: (element: HTMLDivElement, productId: string) => void;
  };
}

interface SalsifyProps {
  productId: string;
  config?: any;
}

const SalsifyComponent: FC<SalsifyProps> = ({ productId, config }) => {
  const { script, clientId, ecId, langCode } = config;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    (window as any).salsifyExperiencesSdkLoaded = (salsify: Salsify) => {
      salsify.init({
        clientId: clientId,
        enhancedContent: { idType: ecId },
        languageCode: langCode,
      });

      salsify.enhancedContent.exists(productId).then((exists: boolean) => {
        if (exists && ref.current) {
          salsify.enhancedContent.renderIframe(ref.current, productId);
        }
      });
    };
  }, [productId]);

  return (
    <div>
      <div id="salsify-container" ref={ref}></div>
      <Script defer src={script} />
    </div>
  );
};

export default SalsifyComponent;
