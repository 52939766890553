import UniformBuilderLanding from 'components/commercetools-ui/aafes/uniform-builder/landing';
import React from 'react';

function UBLandingTastic() {
  return (
    <div>
      <UniformBuilderLanding />
    </div>
  );
}

export default UBLandingTastic;
