import React from 'react';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
const CLPSection = ({ contents, handleFilter, isFilterclicked }) => {
  const [isDesktop] = useMediaQuery(tablet);
  return (
    <>
      <TemplateCMS cmsContent={{ assetID: contents?.topBanner }} />
      {!isDesktop && <div className={`mb-7 ${isFilterclicked && 'relative z-[1000] w-full'}`}>{handleFilter()}</div>}
      <TemplateCMS cmsContent={{ assetID: contents?.featuredContent }} />
      <TemplateCMS cmsContent={{ assetID: contents?.bodyContent }} />
    </>
  );
};
export default CLPSection;
