import React, { useEffect, useMemo, useState } from 'react';
import Loader from 'components/commercetools-ui/loader/Loader';

const QuantityDropdown = (props) => {
  const [selectedValue, setSelectedValue] = useState(props.lineItem.count);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  let maxCount =
    props?.selectedStore?.quantity > 0
      ? props?.selectedStore?.quantity
      : props.lineItem?.variant?.attributes?.maxQty
      ? props.lineItem.variant.attributes.maxQty
      : props?.itemQty;
  if (props?.lineItem?.variant?.attributes?.isPreOrderable && props?.lineItem?.variant?.attributes?.preOrderMaxQty) {
    maxCount = props?.lineItem?.variant?.attributes?.preOrderMaxQty;
  }
  useEffect(() => {
    setSelectedValue(props?.lineItem?.count);
    if (props?.lineItem?.variant?.attributes?.isPreOrderable && props?.lineItem?.variant?.attributes?.preOrderMaxQty) {
      maxCount = props?.lineItem?.variant?.attributes?.preOrderMaxQty;
    } else {
      maxCount = props.lineItem?.variant?.attributes?.maxQty
        ? props.lineItem.variant.attributes.maxQty
        : props?.itemQty;
    }
  }, [props.itemQty]);

  const maxCountList = () => {
    const content = [];
    for (let i = 1; i <= maxCount; i++) {
      content.push(
        <option value={i} selected={selectedValue == i}>
          {i}
        </option>,
      );
    }
    return content;
  };

  const maxCountLimit = async (e) => {
    const newQuantity = parseInt(e.target.value);
    setSelectedValue(newQuantity);
    if (props?.isChangeStore) {
      props?.setDropdownSelectedValue(newQuantity);
    }
    setIsLoad(true);
    try {
      if (!props?.isChangeStore) {
        await props.updateQuantity(props.lineItem.lineItemId, newQuantity);
      }
      await defaultPayment();
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setIsLoad(false);
    }
  };

  const defaultPayment = async () => {
    if (props?.loggedIn) {
      await props?.fetchDefaultPayment();
      await props?.reCalculatePayments();
    }
  };

  return (
    <>
      <section className="text-left md:text-center">
        <select
          value={props?.isChangeStore ? props?.dropdownSelectedValue : props?.lineItem?.count}
          onChange={(e) => maxCountLimit(e)}
          className="select cursor-pointer rounded border border-gray-600 text-affes-sm text-gray-900"
        >
          {maxCountList()}
        </select>
      </section>
      {isLoad && <Loader />}
    </>
  );
};

export default QuantityDropdown;
