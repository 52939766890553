import React, { useCallback } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import ListFeatureAndBenefits from '../product-preview/listFeatureAndBenefits';
import SyndicatedContentHOC from '../syndicated-content';
import { Body, Title } from './Typography';
const Overview = ({ description, productId, specifications, variant }) => {
  const [isDesktopSize] = useMediaQuery(tablet);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const parseAndReturnSpecs = useCallback(() => {
    let specs = [];
    try {
      specs = JSON.parse(specifications);
      return specs;
    } catch (err) {
      console.warn(err);
    }
  }, []);
  return (
    <>
      {isDesktopSize ? (
        <section id="overview">
          <Title title={formatProductMessage({ id: 'overView', defaultMessage: 'Overview' })} />
          <Body body={description} />
          {variant?.attributes?.listFeatureAndBenefits && (
            <ListFeatureAndBenefits
              listFeatureAndBenefits={variant?.attributes?.listFeatureAndBenefits}
              isProductOverview={true}
            />
          )}
          <div className="mb-2">
            <SyndicatedContentHOC
              productId={productId}
              model={parseAndReturnSpecs()?.filter((item) => item?.label?.startsWith('Model'))?.[0]}
              variant={variant}
            />
          </div>
        </section>
      ) : (
        <Disclosure key="Overview" defaultOpen={true}>
          {({ open }) => (
            <>
              <section>
                <Disclosure.Button className="group relative flex w-full items-center justify-between text-left">
                  <Title title={formatProductMessage({ id: 'overView', defaultMessage: 'Overview' })} />
                  <span id="overview" className="ml-6 flex items-center">
                    <>
                      {open ? (
                        <ChevronUpIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <Body body={description} />
                  {variant?.attributes?.listFeatureAndBenefits && (
                    <ListFeatureAndBenefits
                      listFeatureAndBenefits={variant?.attributes?.listFeatureAndBenefits}
                      isProductOverview={true}
                    />
                  )}
                  <div className="mb-2">
                    <SyndicatedContentHOC
                      productId={productId}
                      model={parseAndReturnSpecs()?.filter((item) => item?.label?.startsWith('Model'))?.[0]}
                      variant={variant}
                    />
                  </div>
                </Disclosure.Panel>
              </section>
            </>
          )}
        </Disclosure>
      )}
      <hr className="border border-gray-300" />
    </>
  );
};

export default Overview;
