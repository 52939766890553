import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import Loader from 'components/commercetools-ui/loader/Loader';

const Pagination = ({ pageCount, currentPage, setCurrentPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const handlePagenation = (pageNo) => {
    setIsLoading(true);
    setCurrentPage(pageNo);
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    if (pageNo !== 1) url?.searchParams?.set('pageNo', JSON.stringify(pageNo));
    else url?.searchParams?.delete('pageNo');
    router.push(url.toString()).then(() => setIsLoading(false));
  };

  useEffect(() => {
    const URL_DATA = router?.query?.pageNo;
    const decodedPageNo = Array.isArray(URL_DATA) ? URL_DATA[0] : URL_DATA;
    if (URL_DATA) {
      try {
        setIsLoading(true);
        setCurrentPage(JSON.parse(decodedPageNo));
        setIsLoading(false);
      } catch (error) {
        console.error('Error decoding and parsing pageNo:', error);
      }
    } else {
      setCurrentPage(1);
    }
  }, [router?.asPath]);

  const renderPageNumbers = () => {
    const pages = [];

    if (pageCount <= 5) {
      for (let i = 1; i <= pageCount; i++) pages?.push(i);
    } else {
      // Add the current page and neighboring pages
      for (
        let i = Math.max(
          1,
          currentPage - (currentPage <= 3 ? 2 : currentPage === pageCount ? 3 : currentPage === pageCount - 1 ? 2 : 1),
        );
        i <=
        Math.min(
          pageCount,
          currentPage + (currentPage === 1 ? 3 : currentPage === 2 ? 2 : currentPage > pageCount - 3 ? 2 : 1),
        );
        i++
      ) {
        pages?.push(i);
      }

      // Add ellipses for gaps
      if (pages[0] > 1) {
        pages?.unshift('...');
      }
      if (pages[pages.length - 1] < pageCount) {
        pages?.push('...');
      }

      // Add first and last pages
      if (currentPage - 2 > 1) {
        pages?.unshift(1);
      }
      if (currentPage + 2 < pageCount) {
        pages?.push(pageCount);
      }
    }

    return pages?.map((page, index) =>
      typeof page === 'number' ? (
        <div
          key={index}
          tabIndex={0}
          className={`flex h-6 min-w-[24px] cursor-pointer items-center justify-center rounded-sm border px-1 text-sm ${
            page === currentPage ? 'border-gray-900 bg-gray-900 text-white' : 'border-blue-900 text-blue-900'
          }`}
          onClick={() => {
            currentPage !== page && handlePagenation(page);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              currentPage !== page && handlePagenation(page);
            }
          }}
        >
          {page}
        </div>
      ) : (
        <div key={index} className={`flex h-6 min-w-[24px] items-center justify-center tracking-widest text-black-100`}>
          {page}
        </div>
      ),
    );
  };

  return (
    <>
      {pageCount && pageCount > 1 && (
        <div className="my-3 flex justify-center gap-x-2 md:gap-x-4">
          <button
            onClick={() => {
              currentPage > 1 && handlePagenation(currentPage - 1);
            }}
            className={`${
              currentPage === 1 && 'hidden'
            } flex h-6 w-6 items-center justify-center rounded-sm border border-gray-900`}
          >
            {' '}
            <GoChevronLeft />
          </button>
          {renderPageNumbers()}
          <button
            onClick={() => {
              currentPage < pageCount && handlePagenation(currentPage + 1);
            }}
            className={`${
              currentPage === pageCount && 'hidden'
            } flex h-6 w-6 items-center justify-center rounded-sm border border-gray-900`}
          >
            <GoChevronRight />
          </button>
        </div>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Pagination;
