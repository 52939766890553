import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';

export default function BackToPLP() {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [previousPageUrl, setPreviousPageUrl] = useState<string>();
  const router = useRouter();
  const currentUrl = router?.asPath;
  const handlePreviousPage = () => {
    setIsLoad(true);
    router
      ?.push(previousPageUrl ?? currentUrl)
      .then(() => {
        setIsLoad(false);
      })
      .catch(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    const sessionNavHistory = (typeof window !== 'undefined' ? window?.sessionNavHistory : []) || [];
    const previousPageUrl =
      sessionNavHistory && sessionNavHistory?.length >= 1
        ? sessionNavHistory.reverse().find((url) => url.includes('/browse'))
        : null;
    setPreviousPageUrl(previousPageUrl);
  }, []);

  return (
    <>
      <div className="my-3 flex w-fit text-blue-900 hover:underline">
        <div
          onClick={() => {
            handlePreviousPage();
          }}
          className="flex cursor-pointer items-center justify-center"
        >
          <HiOutlineChevronLeft />
          <span>{formatMessage({ id: 'back', defaultMessage: 'Back' })}</span>
        </div>
      </div>
      {isLoad && <Loader />}
    </>
  );
}
