const IdentifyCardType = (cardNumber, isGuestUser) => {
  const cleanNumber = cardNumber.replace(/\D/g, '');

  const cardTypes = {
    Milstar: /^60194[45]\d{10}$/,
    MilstarPlus: /^650155\d{10}$/,
    Visa: /^4\d{12}(?:\d{3})?$/,
    Mastercard: /^(5[1-5]\d{14}|2[2-7]\d{14})$/,
    Amex: /^3[47]\d{13}$/,
    Discover: /^6\d{15}$/,
  };

  for (const [type, pattern] of Object.entries(cardTypes)) {
    if (pattern.test(cleanNumber)) {
      if (isGuestUser && (type === 'Milstar' || type === 'MilstarPlus')) {
        return 'Invalid';
      }
      return type;
    }
  }

  return 'Unknown';
};

export default IdentifyCardType;
