import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import Loader from 'components/commercetools-ui/loader/Loader';
import { PUIS_VAS_PRODUCT_TYPES } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import { useGbTracker } from 'helpers/utils/gbTracker';
import { priceInUSD } from 'helpers/utils/priceConverter';
import { useAccount, useCart, useProduct } from 'frontastic';
import { removeVAS } from 'frontastic/actions/cart';
import Price from '../price';
const AdditionalServicesModal = ({
  servicesModal,
  setServicesModal,
  additionalServices,
  parentSku,
  lineItem = {},
  addOnItemQty,
  isCart,
  hideDeliveryOptions = false,
  handleAddToCart,
  channel,
}: any) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const formDataRef = useRef(null);
  const { trackEvent } = useGbTracker();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { studioConfig } = useCart();
  const { account } = useAccount();
  const cid = account?.cid;
  const { getSkusExternalPrices, getSTAInventories } = useProduct();
  const [additionalConfig, setAdditionalConfig] = useState(null);
  const { addAdditionalItem } = useCart();
  const [loading, setLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState();

  const hasFormValueChanged = (oldData: any, newData: any): boolean => {
    const oldDataString = JSON.stringify(oldData);
    const newDataString = JSON.stringify(newData);
    return oldDataString !== newDataString;
  };

  const isBronzeDeliveryVASPresent = additionalServices?.some((additionalService) => {
    const { name, value = [] } = additionalService;
    return (
      name === 'DELIVERY_SERVICE' &&
      value?.some((product) =>
        product?.variants?.some((variant) => variant?.sku === additionalConfig?.deliveryServiceProductSKU),
      )
    );
  });

  const onSubmit = async (data: any) => {
    if (isCart) {
      const oldData = formDataRef.current;
      const newData = data;
      if (!hasFormValueChanged(oldData, newData)) {
        setServicesModal(false);
        return false;
      }
    }
    const insertBronzeDeliveryForPickupAtStore = (skuIds) => {
      if (hideDeliveryOptions) {
        return [additionalConfig?.deliveryServiceProductSKU, ...skuIds];
      }
      return skuIds;
    };
    const skuIds = Object.keys(data)
      .filter((key) => data[key] !== null && !data[key].includes('nothanks'))
      .map((key) => data[key]);
    const variant = {
      sku: parentSku.sku,
      count: addOnItemQty,
      additionalSku: isBronzeDeliveryVASPresent ? insertBronzeDeliveryForPickupAtStore(skuIds) : skuIds,
      parentLineItemId: lineItem.lineItemId,
    };
    setLoading(true);
    if (!isCart) {
      try {
        await handleAddToCart({
          addToCartConfirm: true,
          additionalPayload: {
            additionalSkus: isBronzeDeliveryVASPresent ? insertBronzeDeliveryForPickupAtStore(skuIds) : skuIds,
          },
          channel: channel,
        }).then(
          trackEvent(
            'addToCart',
            {
              cart: {
                id: data?.cartId,
                items: [
                  {
                    title: lineItem?.name,
                    price: priceInUSD(lineItem.price),
                    quantity: addOnItemQty,
                    collection: variant?.[0]?.attributes?.customProductType,
                    category: lineItem?.categories?.[0]?.name,
                    productId: variant?.sku,
                  },
                ],
              },
            },
            cid,
          ),
        );
        setLoading(false);
        setServicesModal(false);
      } catch (error) {
        setLoading(false);
        setServicesModal(false);
        console.error('Error adding Additional Services:', error);
      }
    } else {
      try {
        setLoading(true);
        const addAdditionalItemResponse: any = await addAdditionalItem(variant, lineItem.count, isCart);
        if (addAdditionalItemResponse && !addAdditionalItemResponse?.errorCode) {
          if (addAdditionalItemResponse?.cartId) {
            await removeVAS(lineItem?.lineItemId);
            setLoading(false);
            setServicesModal(false);
          } else {
            setLoading(false);
            setServicesModal(false);
          }
        } else if (addAdditionalItemResponse?.errorCode) {
          setApiErrorMessage(addAdditionalItemResponse?.body);
          setLoading(false);
          setServicesModal(true);
        } else {
          setLoading(false);
          setServicesModal(false);
        }
      } catch (error) {
        console.error('Error removing Additional Services:', error);
        setLoading(false);
        setServicesModal(false);
      }
    }
  };
  useEffect(() => {
    if (servicesModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [servicesModal]);
  useEffect(() => {
    const getPriceAndConfig = async () => {
      try {
        if (!additionalConfig) {
          const additionalServicesSKU = additionalServices
            .flatMap((service: any) => service?.value?.map((v: any) => v.variants?.[0]?.sku))
            .filter((sku: string) => sku !== undefined);
          const [fetchedPricesForAdditionalSkus, inventoriesForAdditionalSkus] = await Promise.all([
            getSkusExternalPrices(additionalServicesSKU),
            getSTAInventories(additionalServicesSKU),
          ]);
          setAdditionalConfig({
            vasConfig: studioConfig?.vasConfig,
            additionalSkuPrices: Object.assign({}, ...(fetchedPricesForAdditionalSkus ?? [])),
            additionalSkuInventories: Array.isArray(inventoriesForAdditionalSkus) ? inventoriesForAdditionalSkus : [],
            deliveryServiceProductSKU: studioConfig?.deliveryServiceProductSKU,
            priceExcludedSkus: studioConfig?.priceExcludedSkus,
          });
          if (isCart) {
            setTimeout(() => {
              formDataRef.current = getValues();
            }, 1000);
          }
        }
      } catch (error) {
        console.error('Error while setting additional data', error);
      }
    };
    getPriceAndConfig();
  }, []);
  if (!additionalConfig) {
    return <Loader />;
  }
  const vasConfig = (additionalConfig?.vasConfig && JSON.parse(additionalConfig?.vasConfig)) || {};
  const priceExcludedSkus =
    (additionalConfig?.priceExcludedSkus && JSON.parse(additionalConfig?.priceExcludedSkus.replace(/'/g, '"'))) || [];
  return (
    <section>
      {loading && <Loader />}
      {servicesModal && (
        <section>
          <section className="fixed top-0 left-0 z-20 h-full w-full">
            <section
              className="overlay z-1 fixed left-0 top-0 h-full w-full bg-black-200 opacity-30"
              aria-hidden="true"
            ></section>
            <form onSubmit={handleSubmit(onSubmit)}>
              <section
                className="absolute left-1/2 top-2/4 z-30 w-4/5 -translate-x-2/4 -translate-y-2/4 rounded bg-white p-8 md:w-2/4"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modalTitle"
              >
                <section className="mb-[21px] flex justify-between">
                  <h3 id="modalTitle" className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                    {formatCheckoutMessage({
                      id: 'additionalServices',
                      defaultMessage: 'Select Additional Services',
                    })}
                  </h3>
                </section>
                {apiErrorMessage && <p className="text-red-900">{apiErrorMessage}</p>}
                <section className="h-96 overflow-y-scroll">
                  {additionalServices
                    ?.filter(
                      (itemList: any) => !hideDeliveryOptions || PUIS_VAS_PRODUCT_TYPES?.includes(itemList?.name),
                    )
                    ?.map((itemList: any) => {
                      const { name: serviceTypeName, value: itemListValue = [], isMandatory = false } = itemList;
                      const { imageUrl = '', heading = '', validationMessage } = vasConfig?.[serviceTypeName] || {};
                      const noThanksOption = `${serviceTypeName}_nothanks`;
                      const isSkuFound = (sku: string) => {
                        return (
                          lineItem?.attachments?.find((product) => product.obj.key === sku) ||
                          (serviceTypeName === 'DELIVERY_SERVICE' &&
                            !additionalConfig?.additionalSkuPrices?.[sku]?.price?.centAmount)
                        );
                      };
                      const selectNoThanks = () => {
                        return (
                          isCart &&
                          !lineItem?.attachments?.some((product: any) => {
                            const keyToCheck = product.obj?.key;
                            return itemListValue?.some((item: any) =>
                              item?.variants?.some((variant: any) => variant.sku === keyToCheck),
                            );
                          })
                        );
                      };
                      return (
                        <div key={`item_${serviceTypeName}`} className="grid grid-cols-12 gap-4">
                          <section className="col-span-2">
                            {imageUrl && (
                              <Image
                                src={imageUrl}
                                width={80}
                                height={80}
                                loader={({ src }) => imageUrl}
                                alt="Item Service"
                              />
                            )}
                          </section>
                          <section className="col-span-10">
                            {errors?.[serviceTypeName] && (
                              <span className="font-bold text-red-900">{validationMessage}</span>
                            )}
                            <p className="mb-5  text-affes-sm font-bold leading-[16.8px] text-gray-900">{heading}</p>
                            <ul className="mb-5" role="group" aria-labelledby={`serviceGroup_${serviceTypeName}`}>
                              {itemListValue?.map((item: any) => {
                                const { variants = [], description = '', name: itemName = '' } = item;
                                const { sku = '' } = (variants || [])[0] || {};
                                const isOnStock = additionalConfig?.additionalSkuInventories?.find(
                                  (inventory: any) => inventory?.itemId === sku && inventory?.isOnStock,
                                );
                                return (
                                  <li key={`item_${sku}`} className="mb-4">
                                    <label
                                      htmlFor={item.id}
                                      className={`flex items-start ${!isOnStock ? 'text-gray-400' : 'text-gray-900'}`}
                                    >
                                      <input
                                        {...register(serviceTypeName, { required: true })}
                                        type="radio"
                                        name={serviceTypeName}
                                        value={sku}
                                        disabled={!isOnStock}
                                        id={sku}
                                        className={`mr-3 mt-1 ${!isOnStock && 'opacity-50'}`}
                                        defaultChecked={isSkuFound(sku)}
                                        aria-invalid={errors?.[serviceTypeName] ? 'true' : 'false'}
                                      />
                                      <span>
                                        <span>{itemName}</span>

                                        {!priceExcludedSkus.includes(sku) && (
                                          <>
                                            <span className="mx-1">{`-`}</span>
                                            <Price
                                              className="inline"
                                              price={additionalConfig?.additionalSkuPrices?.[sku]?.price}
                                            />
                                          </>
                                        )}
                                      </span>
                                    </label>
                                    <p
                                      className="mt-2 px-7 text-sm"
                                      dangerouslySetInnerHTML={{ __html: description }}
                                    ></p>
                                  </li>
                                );
                              })}
                              {!isMandatory && (
                                <li key={`item_${noThanksOption}`} className="mb-2">
                                  <label className="flex items-start">
                                    <input
                                      {...register(serviceTypeName, { required: true })}
                                      type="radio"
                                      name={serviceTypeName}
                                      value={noThanksOption}
                                      id={noThanksOption}
                                      className={`mr-3 mt-1`}
                                      defaultChecked={selectNoThanks()}
                                    />

                                    <span>{'No Thanks'}</span>
                                  </label>
                                </li>
                              )}
                            </ul>
                          </section>
                        </div>
                      );
                    })}
                </section>
                <button type="submit" className="mt-4 rounded bg-blue-900 p-3 font-bold leading-[19.2px] text-white">
                  {formatCheckoutMessage({ id: 'saveContinue', defaultMessage: 'Save and Continue' })}
                </button>
              </section>
            </form>
          </section>
        </section>
      )}
    </section>
  );
};

export default AdditionalServicesModal;
