import React, { useEffect, useState } from 'react';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Breadcrumb from './breadcrumb';
import StepIndicator from './stepIndicator';

const UBBundleSelect = ({ account }) => {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isDesktop] = useMediaQuery(tablet);
  const { studioConfig } = useCart();
  const bosDesc = studioConfig?.bosDesc;
  const userType = studioConfig?.userTypeLogoMap;
  const [branchOfServiceDescription, setBranchOfServiceDescription] = useState('');
  const userProfile = {};

  if (userType) {
    Array.from(JSON.parse(userType))?.forEach((obj) => (userProfile[Object.keys(obj)[0]] = Object.values(obj)[0]));
  }
  const userProfileLogo = userProfile?.[account?.branchOfService];
  useEffect(() => {
    setIsLoad(true);
    if (bosDesc) {
      try {
        const branchOfServiceDescriptionConfig = JSON.parse(bosDesc);
        setBranchOfServiceDescription(branchOfServiceDescriptionConfig?.[account?.branchOfService]);
      } catch (error) {
        console.error('Error parsing bosDesc :', error);
      }
    }
    setIsLoad(false);
  }, [bosDesc, account?.branchOfService]);

  return (
    <>
      {isLoad && <Loader />}
      <div className="my-3  md:mx-[30px]">
        {isDesktop && <Breadcrumb />}
        {account?.branchOfService ? (
          <StepIndicator
            branchOfServiceDescription={branchOfServiceDescription}
            branchOfServiceLogo={userProfileLogo}
          />
        ) : (
          <div className="mx-auto mt-5 max-w-[950px] text-gray-900">
            <p className="text-2xl font-medium leading-7">
              {formatMessage({ id: 'profileValidation', defaultMessage: 'Profile Validation' })}{' '}
            </p>
            <p className="mt-5 text-sm font-normal leading-5">
              {formatMessage({
                id: 'profileValidationEMessage',
                defaultMessage:
                  'The Exchange Uniform Builder is available for Active and Reserve Army and Air Force members and based on profile validation. Other military customers such as Retired, ROTC, CAP or other Sister Service members may shop our regular full online site.',
              })}{' '}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default UBBundleSelect;
