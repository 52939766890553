import { Money } from '@Types/product/Money';
import Typography from 'components/commercetools-ui/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

export interface PriceProps {
  price: Money;
  className?: string;
  type?: string;
  discount?: boolean;
}

const Price: React.FC<PriceProps> = ({ price, className, type, discount }) => {
  const defaultCurrencyCode = 'USD';

  if (!price) {
    return (
      <div className={className || 'mt-1 text-sm font-medium text-gray-900 dark:text-light-100'}>
        <Typography>{type}</Typography>
        <span>{`Online price temporarily unavailable. Contact store for price.`}</span>
      </div>
    );
  }

  const { currencyCode = defaultCurrencyCode, ...formattedPrice } = price;

  return (
    <div className={className || 'mt-1 text-sm font-medium text-gray-900 dark:text-light-100'}>
      <Typography>{type}</Typography>
      <span className={discount ? 'line-through' : ''}>
        <Typography>{CurrencyHelpers.formatForCurrency(formattedPrice)}</Typography>
      </span>
    </div>
  );
};

export default Price;
