import React, { FC, useEffect, useRef, ReactNode, useState, useCallback } from 'react';
interface ImageMagnifierProps {
  children: ReactNode;
  zoomProps: ImageZoomProps;
}

interface MousePosition {
  x: number;
  y: number;
}
export interface ImageZoomProps {
  magnifiedContainerSize: number;
  magnifierSize: number;
  maxZoom: number;
  minZoom: number;
}

const ImageMagnifier: FC<ImageMagnifierProps> = ({ children, zoomProps }) => {
  const divElement = useRef<HTMLDivElement | null>(null);
  const enlargedImageDiv = useRef<HTMLDivElement | null>(null);
  const cursorBox = useRef<HTMLDivElement | null>(null);
  const [zoomImgSrc, setZoomImgSrc] = useState<any>(null);
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
  });
  const [boxSize, setBoxSize] = useState(zoomProps?.magnifierSize || 50);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(3);
  const [showZoomStatus, setShowZoomStatus] = useState(false);
  const [zoomStatusPosition, setZoomStatusPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);

  const checkScreenSize = useCallback(() => {
    setIsMobile(window.innerWidth <= 1024);
  }, []);
  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (divElement.current) {
        const divRect = divElement.current.getBoundingClientRect();
        const halfBoxSize = boxSize / 2;
        const x = Math.max(halfBoxSize, Math.min(event.clientX - divRect.left, divRect.width - halfBoxSize));
        const y = Math.max(halfBoxSize, Math.min(event.clientY - divRect.top, divRect.height - halfBoxSize));

        setMousePosition({ x: x - halfBoxSize, y: y - halfBoxSize });
        setShowZoom(true);
      }
    },
    [boxSize],
  );
  const handleMouseScroll = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();

      const newZoomLevel =
        event.deltaY < 0 ? Math.min(zoomProps?.minZoom, zoomLevel + 1) : Math.max(zoomProps?.maxZoom, zoomLevel - 1);
      if (newZoomLevel < zoomProps?.maxZoom) {
        return false;
      }
      const newSize = Math.round(500 / newZoomLevel);
      const halfNewSize = newSize / 2;

      setZoomLevel(newZoomLevel);
      setBoxSize(newSize);

      setMousePosition(({ x, y }) => {
        const divRect = divElement.current?.getBoundingClientRect();

        if (!divRect) {
          return { x, y };
        }

        const cursorX = event.clientX - divRect.left;
        const cursorY = event.clientY - divRect.top;

        const newX = Math.min(Math.max(halfNewSize, cursorX), divRect.width - halfNewSize) - halfNewSize;
        const newY = Math.min(Math.max(halfNewSize, cursorY), divRect.height - halfNewSize) - halfNewSize;

        return { x: newX, y: newY };
      });
      setShowZoomStatus(true);
      setZoomStatusPosition({ x: event.clientX, y: event.clientY });
    },
    [zoomLevel],
  );

  const handleMouseOut = useCallback((event: MouseEvent) => {
    if (divElement.current) {
      const divRect = divElement.current.getBoundingClientRect();
      if (
        event.clientX > divRect.right ||
        event.clientY > divRect.bottom ||
        event.clientX < divRect.left ||
        event.clientY < divRect.top
      ) {
        setShowZoom(false);
      }
    }
    setShowZoomStatus(false);
  }, []);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 50;
    const baseInterval = 500;
    const interval = setInterval(() => {
      const imageElements = divElement.current?.querySelectorAll('img');
      let imageElement = null;
      if (imageElements) {
        imageElements.forEach((img) => {
          if (img.id !== '' && img.complete) {
            imageElement = img;
          }
        });
      }
      if (imageElement && imageElement?.naturalWidth > 1 && imageElement?.naturalHeight > 1) {
        setZoomImgSrc(imageElement);
        clearInterval(interval);
      } else if (retryCount >= maxRetries) {
        clearInterval(interval);
      } else {
        retryCount++;
      }
    }, baseInterval);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (zoomImgSrc?.src && enlargedImageDiv.current) {
      const magnificationFactor = zoomProps?.magnifiedContainerSize / boxSize;
      const imgPosX = -mousePosition.x * magnificationFactor;
      const imgPosY = -mousePosition.y * magnificationFactor;

      const innerDiv = enlargedImageDiv.current.querySelector('div');
      const imgElement = enlargedImageDiv.current.querySelector('img');

      if (innerDiv && imgElement) {
        innerDiv.style.left = `${imgPosX}px`;
        innerDiv.style.top = `${imgPosY}px`;

        imgElement.style.width = `${zoomImgSrc.width * magnificationFactor}px`;
        imgElement.style.height = `${zoomImgSrc.height * magnificationFactor}px`;
      }
    }

    if (cursorBox.current) {
      cursorBox.current.style.left = `${mousePosition.x}px`;
      cursorBox.current.style.top = `${mousePosition.y}px`;
    }
  }, [mousePosition, boxSize, zoomImgSrc]);

  useEffect(() => {
    if (zoomImgSrc?.src) {
      const currentDiv = divElement.current;
      if (currentDiv) {
        currentDiv.addEventListener('mousemove', handleMouseMove);
        currentDiv.addEventListener('wheel', handleMouseScroll);
        window.addEventListener('mousemove', handleMouseOut);
      }
      return () => {
        if (currentDiv) {
          currentDiv.removeEventListener('mousemove', handleMouseMove);
          currentDiv.removeEventListener('wheel', handleMouseScroll);
          window.removeEventListener('mousemove', handleMouseOut);
        }
      };
    }
  }, [handleMouseMove, handleMouseOut, handleMouseScroll, zoomImgSrc]);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);
  if (isMobile) {
    return <>{children}</>;
  }
  return (
    <div
      ref={divElement}
      style={{
        position: 'relative',
        cursor: zoomImgSrc?.src ? 'crosshair' : 'wait',
      }}
    >
      {children}
      <div
        ref={enlargedImageDiv}
        className="magnifyarea"
        style={{
          position: 'absolute',
          top: '0px',
          zIndex: '100',
          width: `${zoomProps?.magnifiedContainerSize}px`,
          height: `${zoomProps?.magnifiedContainerSize}px`,
          left: `${divElement.current?.offsetWidth + 10}px`,
          border: showZoom ? '2px solid black' : 'none',
          display: showZoom ? 'block' : 'none',
          overflow: 'hidden',
        }}
      >
        <div style={{ position: 'relative' }}>
          <img src={zoomImgSrc?.src} alt="Zoomed" />
        </div>
      </div>
      <div
        ref={cursorBox}
        style={{
          border: '1px solid black',
          position: 'absolute',
          pointerEvents: 'none',
          display: showZoom ? 'block' : 'none',
          cursor: zoomImgSrc?.src ? 'crosshair' : 'wait',
          width: `${boxSize}px`,
          height: `${boxSize}px`,
          opacity: `0.3`,
          backgroundColor: `rgb(255, 255, 255)`,
        }}
      ></div>
      {showZoomStatus && (
        <div
          className=""
          style={{
            position: 'fixed',
            left: `${zoomStatusPosition.x - 15}px`,
            top: `${zoomStatusPosition.y + 20}px`,
            visibility: 'visible',
            zIndex: 110,
            border: '1px solid black',
            background: 'rgb(192, 192, 192)',
            padding: '4px',
            font: 'bold 13px Arial',
            opacity: 0.8,
          }}
        >
          Current Zoom: {zoomLevel}
          <div style={{ fontSize: '80%' }}>Use Mouse Wheel to Zoom In/Out</div>
        </div>
      )}
    </div>
  );
};

export default ImageMagnifier;
