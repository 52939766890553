import { useState, useRef } from 'react';
import { UniformBuilderProvider } from 'frontastic/provider/uniform-builder';
import UBPeronaliser from 'components/commercetools-ui/aafes/uniform-builder/bundle-select/personalization';
import { UB_PATCH_OPTIONS } from 'helpers/constants/aafes';
const UBPersonalisationTastic = ({ data }) => {
  const [selectedPatchType, setSelectedPatchType] = useState(null);
  const patchSelectedOnLoad = useRef(false);
  const [userSwitchedPatch, setUserSwitchedPatch] = useState(false);
  const initialData = data?.data?.dataSource || {};

  if (initialData?.error) {
    return <h1 className="text-center">{initialData?.error}</h1>;
  }
  return (
    <div key={`${selectedPatchType?.value}_key`}>
      <UniformBuilderProvider
        initialState={{
          ...initialData,
          patchTypeOptions: UB_PATCH_OPTIONS,
          selectedPatchType,
          setSelectedPatchType,
          patchSelectedOnLoad,
          userSwitchedPatch,
          setUserSwitchedPatch,
        }}
      >
        <UBPeronaliser />
      </UniformBuilderProvider>
    </div>
  );
};

export default UBPersonalisationTastic;
