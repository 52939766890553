import React, { useState } from 'react';
import Icon from 'components/icons/infoIcon';
import PayYourWayImage from 'components/images/pay-your-way.png';
import { useFormat } from 'helpers/hooks/useFormat';
import PayYourWay from '../../Modals/payYourWay';

function PayYourWayInfo({ studioConfig }) {
  const [secureCheckoutModal, setSecureCheckoutModal] = useState(false);
  function closeSecureCheckout() {
    setSecureCheckoutModal(false);
  }
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  return (
    <>
      <div className="flex items-center">
        <img className="h-6 w-20" src={PayYourWayImage.src} alt="Pay your way" />
        <span className="font-lato ml-[11.8px] w-[33px] text-sm font-normal leading-[30px] text-[#043C63]">
          {formatProductMessage({ id: 'plans', defaultMessage: 'Plans' })}
        </span>
        <a
          className="ml-[6px]"
          onClick={() => {
            setSecureCheckoutModal(true);
          }}
        >
          <Icon className="cursor-pointer" />
        </a>
      </div>
      <PayYourWay
        secureCheckoutModal={secureCheckoutModal}
        closeModal={closeSecureCheckout}
        studioConfig={studioConfig}
      />
    </>
  );
}

export default PayYourWayInfo;
