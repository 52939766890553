import { GA_BEGIN_CHECKOUT, GA_PURCHASE } from 'helpers/constants/aafes';
import { useCallback } from 'react';
import useGATrackUserAction from './useGATrackUserAction';
import { getUserProperties } from './useGATrackViewActions';

const useGATrackCheckoutAction = () => {
  const { calculateAge } = useGATrackUserAction();
  const getCurrentUrl = (): string => {
    return window.location.href;
  };
  const getSeverUrl = (): string => {
    return window.location.host;
  };
  const trackBeginCheckout = useCallback((trackBeginCheckoutObj: any) => {
    const { cartList, account } = trackBeginCheckoutObj;
    const gtag = window?.gtag;
    try {
      const beginCheckout = {
        currency: cartList?.total?.currencyCode,
        value: cartList?.total?.centAmount / 10 ** cartList?.total?.fractionDigits,
        coupon: cartList?.discountCodes?.[0]?.name || '',
        user_properties: getUserProperties(account, calculateAge),
        items: cartList?.lineItems?.map((lineItem) => ({
          item_id: lineItem?.variant?.sku,
          item_name: lineItem?.name,
          discount: 0,
          product_id: lineItem?.variant?.productKey,
          item_brand: lineItem?.variant?.attributes?.brand || '',
          item_category: lineItem?.productCategories?.[0] || '',
          item_category2: lineItem?.productCategories?.[1] || '',
          item_category3: lineItem?.productCategories?.[2] || '',
          item_category4: lineItem?.productCategories?.[3] || '',
          size: lineItem?.variant?.picker?.Size || '',
          color: lineItem?.variant?.picker?.Color || '',
          price: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          quantity: lineItem?.count,
          revenue: lineItem?.lineItemTotalPrice?.centAmount / 10 ** lineItem?.lineItemTotalPrice?.fractionDigits,
        })),
        webPageDetails: {
          name: 'Checkout',
          server: getSeverUrl(),
          page_url: getCurrentUrl(),
          pageId: 'Checkout Page',
        },
      };
      gtag('event', GA_BEGIN_CHECKOUT, beginCheckout);
    } catch (error) {
      console.error('ga4 begin checkout event fail', error);
    }
  }, []);

  const trackPurchase = useCallback((trackPurchaseObj: any) => {
    const { cartList, orderId, account } = trackPurchaseObj;
    const gtag = window?.gtag;
    try {
      const purchase = {
        transaction_id: orderId,
        value: cartList?.total?.centAmount / 10 ** cartList?.total?.fractionDigits,
        tax: cartList?.importTaxAndDuty?.centAmount,
        shipping: cartList?.shippingCost?.centAmount / 10 ** cartList?.shippingCost?.fractionDigits || '',
        currency: cartList?.total?.currencyCode,
        coupon: cartList?.discountCodes?.[0]?.code || '',
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: cartList.lineItems.map((lineItem) => ({
          item_id: lineItem?.variant?.sku,
          item_name: lineItem?.name,
          discount: 0,
          product_id: lineItem?.variant?.productKey,
          item_brand: lineItem?.variant?.attributes?.brand || '',
          item_category: lineItem?.productCategories?.[0] || '',
          item_category2: lineItem?.productCategories?.[1] || '',
          item_category3: lineItem?.productCategories?.[2] || '',
          item_category4: lineItem?.productCategories?.[3] || '',
          size: lineItem?.variant?.picker?.Size || '',
          color: lineItem?.variant?.picker?.Color || '',
          price: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          quantity: lineItem?.count,
        })),
      };
      gtag('event', GA_PURCHASE, purchase);
    } catch (error) {
      console.error('ga4 purchase info event fail', error);
    }
  }, []);

  return { trackBeginCheckout, trackPurchase };
};

export default useGATrackCheckoutAction;
