import Link from 'next/link';

const RightColumnItem = ({ Item, loggedIn, showLogin, setRedirectUrl, weeklyAd, rightId }) => (
  <>
    {Item?.loginRequired ? (
      loggedIn ? (
        <section key={rightId} className="mr-5 mb-3 flex items-center hover:underline xl:mb-0">
          <Link href={Item?.links}>
            <a className="flex cursor-pointer items-center text-sm font-semibold text-blue-900">
              <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />
              {Item.header}
            </a>
          </Link>
        </section>
      ) : (
        <section
          key={rightId}
          onClick={() => {
            showLogin();
            setRedirectUrl(Item?.links);
          }}
          className="mr-5 mb-3 flex items-center hover:underline xl:mb-0"
        >
          <p className="flex cursor-pointer items-center text-sm font-semibold text-blue-900">
            <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" /> {Item.header}
          </p>
        </section>
      )
    ) : (
      <section key={rightId} className="my-3 mr-5 flex items-center hover:underline md:mt-0 xl:mb-0">
        <Link href={Item?.links}>
          <a className="flex cursor-pointer items-center text-sm font-semibold text-blue-900">
            <img src={Item?.icon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />
            {Item.header}
          </a>
        </Link>
      </section>
    )}
  </>
);

export default RightColumnItem;
