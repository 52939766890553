import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAccount } from 'frontastic';

function GuestFooterContent({ closeModal, privacyActModal, link }) {
  const { getCMSTemplate } = useAccount();
  const [cmsDescription, setCmsDescription] = useState(null);
  const [response, setResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const getCMS = async () => {
    try {
      const cmsPayload = {
        assetPath: link,
        loginCheck: false,
      };

      const getCMSTemp = await getCMSTemplate(cmsPayload);

      if (getCMSTemp?.body === '') {
        setErrorMessage('Content not found');
      } else {
        if (getCMSTemp?.errorCode) {
          setErrorMessage('Something Went Wrong!!');
        } else {
          setResponse(getCMSTemp.body);
        }
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
      setErrorMessage('Content not found');
    }
  };
  getCMS();

  return (
    <>
      <Transition appear show={privacyActModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="fixed inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[300px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[90%] lg:mt-3 xl:w-[1000px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="text-xl  font-semibold text-gray-900 dark:text-white"></h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>
                      <div className="space-y-10 overflow-y-auto py-6">
                        {errorMessage ? (
                          <p className="text-center">{errorMessage}</p>
                        ) : (
                          <div className="cms-container mb-5">
                            <div dangerouslySetInnerHTML={{ __html: response }} />
                          </div>
                        )}
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default GuestFooterContent;
