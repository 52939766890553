import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import cookieCutter from 'cookie-cutter';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import YouMayLikeTastic from 'frontastic/tastics/aafes/you-may-like-tastic';
import BackToPLP from '../compareProducts/backToPLP';
import Breadcrumb from './breadcrumb';
import CompareProducts from './compareSection';
interface NoResultFoundProps {
  isNoProducts?: boolean;
  isMoreProducts?: boolean;
}
function NoResultFound({ isNoProducts, isMoreProducts }: NoResultFoundProps) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isDesktop] = useMediaQuery(tablet);
  const { studioConfig } = useCart();
  const faqURL = studioConfig?.faqLink;
  const ShopByCategoryAssetId = studioConfig?.noResultFoundShopByCategoryAssetId;
  const [compareProducts, setCompareProducts] = useState([]);
  useEffect(() => {
    if (cookieCutter.get('compareProducts')) {
      const storedCompareProducts = JSON.parse(cookieCutter.get('compareProducts') || '[]');
      if (storedCompareProducts?.length > 0) {
        setCompareProducts(storedCompareProducts);
      }
    }
  }, []);

  return (
    <>
      {isDesktop && <Breadcrumb selectedFilters={null} />}
      {(isNoProducts || isMoreProducts) && <BackToPLP />}
      <div className="my-3 flex justify-center">
        {isNoProducts || isMoreProducts ? (
          <div>
            <p className="text-start font-open-sans text-[30px] leading-normal text-gray-800">
              {isNoProducts
                ? formatMessage({
                    id: 'emptyCompareTray',
                    defaultMessage: 'Oops! You do not have any items in your compare tray.',
                  })
                : formatMessage({
                    id: 'compareTrayUpto4',
                    defaultMessage: 'Oops! You have more than 4 items in your compare tray.',
                  })}
            </p>
            <p className="text-start font-open-sans text-[14px] leading-normal text-gray-800">
              {formatMessage({
                id: 'selectItemsToCompare',
                defaultMessage: 'Go back to the previous page to select items to compare.',
              })}
            </p>
          </div>
        ) : (
          <div>
            <p className="text-start font-open-sans text-[30px] leading-normal text-gray-800">
              {formatMessage({ id: 'noResultFound', defaultMessage: 'No Results Found' })}
            </p>
            <p className="text-start font-open-sans text-[14px] leading-normal text-gray-800">
              {formatMessage({ id: 'searchAgain', defaultMessage: 'Try adjusting your keywords and search again.' })}
            </p>
            <div className="flex">
              <p className="text-start font-open-sans text-[14px] leading-normal text-gray-800">
                {formatMessage({ id: 'needAssistance', defaultMessage: 'Need Assistance?' })}
              </p>
              {faqURL && (
                <NextLink href={faqURL}>
                  <a className="text-start font-open-sans pl-[5px] text-[14px] leading-normal text-blue-900 hover:underline">
                    {formatMessage({ id: 'visitOurfaq', defaultMessage: 'Visit our FAQ' })}
                  </a>
                </NextLink>
              )}
            </div>
          </div>
        )}
      </div>
      <div>{ShopByCategoryAssetId && <TemplateCMS cmsContent={{ assetID: ShopByCategoryAssetId }} />}</div>
      <YouMayLikeTastic data={{ shouldAutoScroll: false, autoScrollInterval: 2500 }} />
      <section>
        <div className="min-h-24 fixed bottom-0 left-0 z-[9] mb-0 w-full bg-[#F1F1F1F2] shadow-[0px_-3px_4px_0px_#00000040]">
          <CompareProducts compareProducts={compareProducts} setCompareProducts={setCompareProducts} />
        </div>
      </section>
    </>
  );
}
export default NoResultFound;
