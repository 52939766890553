import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { Title } from './Typography';

const Specification = ({ specificationsList }) => {
  const [isDesktopSize] = useMediaQuery(tablet);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  return (
    <section>
      {isDesktopSize ? (
        <section id="specification">
          <Title title={formatProductMessage({ id: 'specifications', defaultMessage: 'Specifications' })} />
          <div>
            <table className="w-full">
              <tbody>
                {specificationsList?.map((specs: any, i: number) => (
                  <tr key={i} className={`text-sm ${i % 2 === 0 ? 'bg-white' : 'bg-[#F5F5F5]'}`}>
                    <td className="w-1/4 p-2.5 leading-5">{specs?.label}</td>
                    <td className="p-2.5 leading-5">{specs?.desc}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      ) : (
        <Disclosure key="Specifications">
          {({ open }) => (
            <>
              <section>
                <Disclosure.Button className="group relative flex w-full items-center justify-between text-left">
                  <Title title={formatProductMessage({ id: 'specifications', defaultMessage: 'Specifications' })} />
                  <span className="ml-6 flex items-center">
                    <>
                      {open ? (
                        <ChevronUpIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel>
                  <table className="mb-2 w-full">
                    <tbody>
                      {specificationsList?.map((specs: any, i: number) => (
                        <tr key={i} className={`text-sm ${i % 2 === 0 ? 'bg-white' : 'bg-[#F5F5F5]'}`}>
                          <td className="p-2.5 leading-5">{specs?.label}</td>
                          <td className="p-2.5 leading-5">{specs?.desc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Disclosure.Panel>
              </section>
            </>
          )}
        </Disclosure>
      )}
      <hr className="border border-gray-300" />
    </section>
  );
};

export default Specification;
