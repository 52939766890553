import ThankYou from 'components/commercetools-ui/aafes/uniform-builder/thankYou';
import React from 'react';
import { useCart } from 'frontastic/provider';
import { useRouter } from 'next/router';

function UBThankYouTastic({ data }) {
  const { data: cartList } = useCart();
  const router = useRouter();
  const parentBundleLineItemId = router?.query?.id;
  const parentBundleLineItem = cartList?.lineItems?.find((lineItem) => {
    return lineItem?.lineItemId === parentBundleLineItemId;
  });
  return (
    <div>
      <ThankYou homeCta={data?.homeLink?.link} lineItem={parentBundleLineItem} />
    </div>
  );
}

export default UBThankYouTastic;
