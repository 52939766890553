import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';

interface ModalProps {
  closeModal: boolean;
  data: any;
}

function CancelOrderConfirmation({ closeModal, getOrderData, data }) {
  const { cancelOrder } = useCart();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  async function handleCancelOrder(orderId: string) {
    if (cancelOrder) {
      const cancelOrderResponse = await cancelOrder(orderId, true);
      getOrderData();
      closeModal();
    }
  }
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto" onClick={closeModal}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0">
                  <section
                    className="absolute top-1/2 left-1/2 w-[350px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-6 pb-4 dark:bg-primary-200 md:mt-9 md:w-[420px] lg:mt-3 lg:w-[460px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section>
                        <section>
                          <section>
                            <section className="mb-[40px] flex justify-between text-[18px] font-bold leading-[16.8px] text-[#333333]">
                              <h3>
                                {formatAccountMessage({
                                  id: 'cancleorderdelconfirm',
                                  defaultMessage: 'Are you sure you want to cancel this order',
                                })}
                              </h3>
                              <section className="cursor-pointer">
                                <GrClose onClick={closeModal} />
                              </section>
                            </section>
                            {data?.map((order, id) => (
                              <section key={id}>
                                <button
                                  className="mr-3 rounded border border-[#043C63] p-2 text-[14px] text-[#043C63]"
                                  onClick={() => {
                                    handleCancelOrder(order?.orderId);
                                  }}
                                >
                                  {formatAccountMessage({
                                    id: 'cancleorder',
                                    defaultMessage: 'Yes, Cancel',
                                  })}
                                </button>
                                <button
                                  className="rounded border border-[#043C63] p-2 text-[14px] text-[#043C63]"
                                  onClick={closeModal}
                                >
                                  {formatAccountMessage({
                                    id: 'nobtn',
                                    defaultMessage: 'No',
                                  })}
                                </button>
                              </section>
                            ))}
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default CancelOrderConfirmation;
