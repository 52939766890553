const FirstTwoDigitExpDate = () => {
  const currentYear = new Date().getFullYear();
  return Math.floor(currentYear / 100);
};

const CalculateExpDate = (defaultCard) => {
  const yearFirstTwoDigit = FirstTwoDigitExpDate();
  const ExpYear = yearFirstTwoDigit + defaultCard?.substring(0, 2);
  const ExpMonth = defaultCard?.substring(2, 4);
  return ExpMonth + '/' + ExpYear;
};

export default CalculateExpDate;
