import Link from 'next/link';
import { HiPhone } from 'react-icons/hi';
import { MdOutlineWatchLater } from 'react-icons/md';
import StoreNameURL from '../../../../helpers/utils/storeNameURL';

const StoreDetails = ({
  account,
  showStoreModal,
  formatMessage,
  setDefaultStore,
  storeIcon,
  loggedIn,
  defaultStore,
  storeLabel,
  setFindStoreModal,
}) => (
  <section className="relative" onMouseEnter={() => setDefaultStore(true)} onMouseLeave={() => setDefaultStore(false)}>
    <button
      onClick={() => showStoreModal()}
      className="flex cursor-pointer items-center text-sm font-semibold text-blue-900"
    >
      <img src={storeIcon} alt="Find Store Icon" className="mr-3" />{' '}
      {loggedIn && account?.defaultStore?.address ? (
        <section className="flex items-center hover:underline">
          {formatMessage({ id: 'my.Store', defaultMessage: 'My Store :' })}{' '}
          <p className="ml-1 w-[5rem] truncate">{account?.defaultStore?.name}</p>
        </section>
      ) : (
        <p className="hover:underline">{storeLabel}</p>
      )}
    </button>
    {defaultStore && loggedIn && (
      <section className="absolute right-0 top-8 z-20 w-64 rounded-sm border border-gray-600 bg-white">
        <section
          className={`${
            account?.defaultStore?.address
              ? 'boxArrow absolute left-[7.5rem] -top-4 h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600'
              : 'boxArrow absolute left-[11.5rem] -top-4 h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600'
          } `}
        >
          <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
        </section>

        {account?.defaultStore?.address ? (
          <section className="p-2 px-3">
            <section>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setFindStoreModal(false);
                  if (window) {
                    window.location.href = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                      account?.defaultStore?.name,
                    )}`;
                  }
                }}
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    setFindStoreModal(false);
                    if (window) {
                      window.location.href = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                        account?.defaultStore?.name,
                      )}`;
                    }
                  }
                }}
                className="mb-3 cursor-pointer border-b border-dotted border-gray-600 pb-4 text-left text-affes-xl text-gray-900"
              >
                {account?.defaultStore?.name}
              </button>
              <button
                onClick={() => {
                  setFindStoreModal(false);
                  if (window) {
                    window.open(
                      `https://www.google.com/maps/dir/?api=1&destination=${account?.defaultStore?.lat},${account?.defaultStore.long}`,
                      '_blank',
                    );
                  }
                }}
                className="mb-2 cursor-pointer text-left text-affes-sm font-semibold text-[#3C3C3C] underline underline-offset-1 hover:no-underline hover:underline-offset-0"
              >
                {account?.defaultStore?.address?.streetName} {account?.defaultStore?.address?.city}
                <br />
                {account?.defaultStore?.address?.state} {account?.defaultStore?.address?.postalCode}
              </button>
              <Link href={`tel:${account?.defaultStore?.phoneNumber}`}>
                <p className="mb-2 flex cursor-pointer items-center text-affes-sm font-semibold text-[#3C3C3C] underline underline-offset-1 hover:no-underline hover:underline-offset-0">
                  <HiPhone className="mr-2 text-affes-xl" /> {account?.defaultStore?.phoneNumber}
                </p>
              </Link>
              <p className="mb-3 flex text-affes-sm font-semibold text-[#3C3C3C]">
                <MdOutlineWatchLater className="mr-2 mt-1 text-affes-xl" />
                {account?.defaultStore?.storeHours?.[1]} <br />
                {account?.defaultStore?.storeHours?.[0]}
              </p>
            </section>
            <hr />

            <button
              onClick={() => showStoreModal()}
              className="my-3  cursor-pointer text-affes-sm font-bold text-blue-900 underline underline-offset-1 hover:no-underline hover:underline-offset-0"
            >
              {formatMessage({ id: 'changeStore', defaultMessage: 'Change Store' })}
            </button>
          </section>
        ) : (
          <section className="p-2 px-3">
            <section>
              <p className="mb-3 text-affes-xl text-gray-900">
                {formatMessage({
                  id: 'pleaseSelectStore',
                  defaultMessage: 'Please Select a Store',
                })}
              </p>
            </section>
            <hr />

            <button
              onClick={() => showStoreModal()}
              className="my-3  cursor-pointer text-affes-sm font-bold text-blue-900 underline underline-offset-1 hover:no-underline hover:underline-offset-0"
            >
              {formatMessage({ id: 'changeStore', defaultMessage: 'Change Store' })}
            </button>
          </section>
        )}
      </section>
    )}
  </section>
);

export default StoreDetails;
