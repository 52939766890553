import React from 'react';
import { UB_GENDERS } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
type Props = {
  setGender?: React.Dispatch<React.SetStateAction<string>>;
  gender?: string;
};

export default function SelectGender({ setGender, gender }: Props) {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const getBorderStyle = (selectedGender: string) => {
    if (selectedGender === gender) {
      return 'border-blue-900 bg-blue-900 text-white';
    }
    return 'border-[#00000033] text-gray-900';
  };

  return (
    <div className="flex h-full w-full justify-center">
      <div>
        <div className="flex justify-center">
          <span className="font-lato mt-[35px] mb-[23px] h-[19px] min-w-[89px] text-base font-bold leading-[19.2px] text-gray-900">
            {formatMessage({ id: 'yourgender', defaultMessage: 'Your Gender' })}{' '}
          </span>
        </div>
        {UB_GENDERS.map((item, index) => {
          return (
            <div key={index} className="mb-[15px]  min-w-[120px]">
              <button
                className={`h-[36px] w-full cursor-pointer rounded border ${getBorderStyle(item.value)}`}
                onClick={() => {
                  setGender(item.value);
                }}
              >
                {item.name}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
