import React, { useEffect, useState, useMemo } from 'react';
import { UB_APPAREL_CONFIG_IMAGE_PROPS } from 'helpers/constants/aafes';
import UBImageSection from './UBImageSection';
interface UBTextInputConfigItemProps {
  image?: string;
  imageAltTxt?: string;
  onAddOrUpdate?: (value: string) => void;
  onItemRemove?: (value: any) => void;
  updateMode?: boolean;
  setUpdateMode?: (value: boolean) => void;
  currentText?: string;
  maxLength?: number;
}

export const UBTextInputConfigItem: React.FC<UBTextInputConfigItemProps> = ({
  image,
  imageAltTxt,
  onAddOrUpdate,
  updateMode,
  setUpdateMode,
  currentText,
  maxLength,
}) => {
  const [inputValue, setInputValue] = useState<string>(null);
  const remainingLength = useMemo(() => {
    const inputValueLength = inputValue ? inputValue?.length : 0;
    return `${maxLength - inputValueLength} chars remaining`;
  }, [inputValue]);
  useEffect(() => {
    setInputValue(currentText);
  }, [currentText]);

  const blockSpecialChar = (e) => {
    const key = e.key || String.fromCharCode(e.which || e.keyCode);

    if (
      (key >= 'A' && key <= 'Z') ||
      (key >= 'a' && key <= 'z') ||
      key === 'Backspace' ||
      key === 'Tab' ||
      key === 'Enter' ||
      key === ' ' ||
      (key >= '0' && key <= '9')
    ) {
      return true;
    } else if (key === '+') {
      e.preventDefault();
      return false;
    } else {
      return false;
    }
  };

  const preventPaste = (event) => {
    const inputText = event.clipboardData.getData('text/plain');
    if (!/^[a-zA-Z0-9\s]*$/.test(inputText)) {
      event.preventDefault();
    }
  };

  const handleKeyPress = (event) => {
    if (!blockSpecialChar(event) && event.key !== '+') {
      event.preventDefault();
    }
  };

  return (
    <div>
      {updateMode && (
        <div className="mt-4 flex flex-wrap items-center">
          <UBImageSection image={image} altText={imageAltTxt} zoomProps={UB_APPAREL_CONFIG_IMAGE_PROPS} />
          <div className="mt-3.5 block w-full sm:ml-5 sm:mt-auto sm:flex sm:w-auto">
            <div className="w-full sm:w-auto">
              <label
                htmlFor="itemName"
                className="block text-xs font-semibold not-italic leading-affes-3.5"
              >{`Name`}</label>
              <input
                type="text"
                id="itemName"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPaste={preventPaste}
                onKeyDown={handleKeyPress}
                className="mt-2.5 w-full rounded-sm border border-gray-300 pl-3 pr-10 focus:border-gray-300 focus:outline-none focus:ring-0 focus:ring-gray-300"
                maxLength={maxLength}
                aria-describedby="remainingLength"
              />
              <p
                id="remainingLength"
                className="mt-2.5 text-sm font-normal leading-4 text-[#333333]"
                aria-live="polite"
              >
                {remainingLength}
              </p>
            </div>
            <div className="mt-4 w-full sm:mt-0 sm:w-auto">
              <label className="hide invisible h-[18px] text-xs font-semibold not-italic leading-normal sm:block"></label>
              <button
                type="button"
                className="h-[36px] w-full rounded-[4px] border-blue-900 bg-blue-900 text-sm font-normal text-white disabled:bg-gray-400 sm:mt-1.5 sm:w-[106px] lg:ml-2.5"
                disabled={!inputValue?.length}
                onClick={() => {
                  onAddOrUpdate(inputValue);
                  setInputValue('');
                  setUpdateMode(false);
                }}
              >
                {currentText ? 'Update' : 'Add'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UBTextInputConfigItem;
