import React, { useMemo } from 'react';
import Overview from './Overview';
import Reviews from './Reviews';
import ShippingInfo from './ShippingInfo';
import SpecialOffers from './specialOffers';
import Specification from './specification';
import WarrantyInfo from './WarrantyInfo';

const ProductDetails = ({ product, variant, studioConfig, pdpDroplets }) => {
  const specifications = useMemo(() => {
    if (variant?.attributes?.specifications) {
      try {
        const specificationsList = JSON.parse(variant?.attributes?.specifications);
        const hiddenFields: string[] | null =
          studioConfig?.globalHiddenSpecifications && typeof studioConfig?.globalHiddenSpecifications === 'string'
            ? studioConfig?.globalHiddenSpecifications?.split(',').map((item) => item?.trim())
            : null;
        return hiddenFields
          ? specificationsList?.filter((specs: any) => !hiddenFields?.includes(specs?.label?.trim()))
          : specificationsList;
      } catch (error) {
        console.log('Error while parsing specifications :', error);
        return null;
      }
    } else {
      return null;
    }
  }, [variant, studioConfig]);

  const hasPromotions = pdpDroplets && pdpDroplets.length > 0 && pdpDroplets[0]?.promotions;

  return (
    <>
      <div>
        <Overview
          description={product?.description}
          productId={product?.productId}
          specifications={variant?.attributes?.specifications}
          variant={variant}
        />
        {specifications && <Specification specificationsList={specifications} />}
        <Reviews />
        {hasPromotions && <SpecialOffers variantPromotion={pdpDroplets[0]?.promotions} />}
        {variant?.attributes?.warrantyDescription && (
          <WarrantyInfo warrantyData={variant?.attributes?.warrantyDescription} />
        )}
        <ShippingInfo variantAttributes={variant?.attributes} />
      </div>
    </>
  );
};

export default ProductDetails;
