import PLPCLPIndex from 'components/commercetools-ui/aafes/plp-clp';
import NoSearchResult from 'components/commercetools-ui/aafes/plp-clp/noSearchResult';
import YouMayLikeTastic from 'frontastic/tastics/aafes/you-may-like-tastic';
import NoResultFound from 'components/commercetools-ui/aafes/plp-clp/noResultFound';
import Head from 'next/head';
import { useCart } from 'frontastic/provider';
import { useEffect, useRef, useState } from 'react';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
function ProductListTastic({ data }) {
  const res = data?.data?.dataSource;

  const { studioConfig, setCartProductDetailEventSent, data: cartList } = useCart();
  const [monetateConfig, setMonetateConfig] = useState(
    studioConfig ? JSON.parse(studioConfig.monetateConstants.replace(/'/g, '"')) : null,
  );
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  const previousResRef = useRef();
  useEffect(() => {
    const callMonetate = async () => {
      await sendToMonetate(
        {
          page:
            res?.total < 1 || res?.query || (res?.query === '' && !res?.selectedFilters)
              ? 'search'
              : res?.isClp
              ? 'clp'
              : 'plp',
          product: {
            ...res,
          },
          cart: {
            ...cartList,
          },
        },
        monetateConfig,
      );
    };

    const fetchData = async () => {
      if (res && monetateConfig) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };

    if (previousResRef.current !== res && monetateConfig) {
      fetchData();
      previousResRef.current = res;
    }
  }, [res, monetateConfig]);
  if (!data?.data?.dataSource) return <NoResultFound />;

  if (data?.data?.dataSource?.total < 1 && data?.data?.dataSource?.query) {
    return (
      <>
        <NoSearchResult query={data?.data?.dataSource?.query} correctedQuery={data?.data?.dataSource?.correctedQuery} />
      </>
    );
  }

  if (data?.data?.dataSource?.total < 1) {
    return (
      <>
        <NoResultFound />
      </>
    );
  }

  return (
    <>
      {/* <Head>
        <title>{data?.data?.dataSource?.selectedFilters?.[0]?.refinements?.[0]?.name}</title>
      </Head> */}
      <PLPCLPIndex res={data?.data?.dataSource} />
      <YouMayLikeTastic data={{ shouldAutoScroll: false, autoScrollInterval: 2500 }} />
    </>
  );
}

export default ProductListTastic;
