import React, { FormEvent, useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FiAlertTriangle } from 'react-icons/fi';
import { HiChevronDoubleRight } from 'react-icons/hi';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import { customURL } from 'helpers/constants/aafes';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import { getSeparatedDeliverySections } from 'helpers/utils/getSeparatedDeliverySections';
import { sendToMonetate, getRecommendations } from 'helpers/utils/monetateHelper';
import { fetchApiHub, revalidateOptions, useAccount, useCart, useCustomMessages } from 'frontastic';
import { getCartInventory, updateCart } from 'frontastic/actions/cart';
import YouMayLikeComponent from '../you-may-like';
import CartItems from './cartItems';
import EmptyCart from './emptyCart';
import OrderSummary from './orderSummary';
import StarRewardSummary from './starRewardSummary';

function Cart({
  cart,
  saveForLater,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  giftCardImg,
  tasticData,
}) {
  const { setCartProductDetailEventSent, studioConfig: projectConfiguration, fetchDefaultPayment } = useCart();
  const router = useRouter();
  const { account, loggedIn, addresses } = useAccount();
  const [loading, setLoading] = useState(false);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=pickUpInStore,curbsideAvailable,addOnInfoMessage,receiveAGiftCard,maxLimitSFL,addOnVerbiage,deliverEvery,inventoryOutOfStock,cantShip,expireVendorCIWarning|VENDOR_NAME;EXPIRY_DATE,addRecipientDetails,inventoryCantSupply,noCouponFound,emptyAddressShippingGroup,pdp_shippingPolicy.APOFPOAvailable,selectedStoreClosed`,
    fetchApiHub,
    revalidateOptions,
  );

  const { trackViewCart } = useGATrackViewActions();
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);
  const [proceedToCheckoutError, setProceedToCheckoutError] = useState(null);
  useEffect(() => {
    const defaultPayment = async () => {
      try {
        if (loggedIn && account?.isMilStarCardHolder === true) {
          setLoading(true);
          const response = await fetchDefaultPayment();
          updateCart(response).finally(() => {
            setLoading(false);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    defaultPayment();
  }, []);

  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);

  useEffect(() => {
    if (projectConfiguration?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(projectConfiguration?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Error Parsing monetateConstants:', error);
      }
    }
  }, [projectConfiguration]);

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    router.push('/checkout');
  };
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const miniYouMayLikeconfig = {
    slidesPerView: 1,
    showArrows: false,
    showDots: true,
    className: 'slider-mini',
  };

  const [monetateSent, setMonetateSent] = useState(false);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [OOSConfirm, setOOSConfirm] = useState(false);
  const [itemInStock, setItemInStock] = useState([]);
  const [isShowRecipientDetailsErrMsg, setShowRecipientDetailsErrMsg] = useState(false);
  const [defaultShippingAddressNotAvailable, setDefaultShippingAddressNotAvailable] = useState(false);
  const [storeClosed, setStoreClosed] = useState(false);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'cart',
            cart,
          },
          monetateConfig,
        );
      };
      if (cart && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
      const fetchRecommendations = async () => {
        const recommendationRes = await getRecommendations(monetateConfig, tasticData);
        setRecommendations(recommendationRes);
      };
      if (monetateConfig) {
        fetchRecommendations();
      }
    };

    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [cart, monetateConfig]);

  useEffect(() => {
    trackViewCart({ cart, account });
  }, []);

  const prevLineItemsRef = useRef([]);
  useEffect(() => {
    function checkCloseStore() {
      getSeparatedDeliverySections(cart?.displayLineItems)?.map((item, index) => {
        if (item?.channel?.id && item?.channel.isClickToBrick === false) {
          setStoreClosed(true);
        }
      });
    }
    checkCloseStore();
  }, [cart]);

  useEffect(() => {
    const prevLineItems = prevLineItemsRef.current;
    const currentLineItems = cart?.displayLineItems || [];
    const prevItemsString = JSON.stringify(prevLineItems);
    const currentItemsString = JSON.stringify(currentLineItems);
    if (prevItemsString !== currentItemsString && cart?.displayLineItems !== undefined) {
      getCartInventory()
        .then((res) => {
          setItemInStock(res);
          Object.values(res)?.some((item: any) => !item?.isOnStock) ? setOOSConfirm(true) : setOOSConfirm(false);
        })
        .catch((err) => console.log(err))
        .finally();
      prevLineItemsRef.current = currentLineItems;
    }
    setProceedToCheckoutError(null);
  }, [cart?.displayLineItems]);

  if (!cart?.lineItems || (cart?.lineItems?.length === 0 && cart?.customLineItems?.length === 0)) {
    return (
      <>
        <EmptyCart
          pageTitle={pageTitle}
          image={emptyStateImage}
          title={emptyStateTitle}
          subtitle={emptyStateSubtitle}
          ctaLabel={emptyStateCTALabel}
          ctaLink={emptyStateCTALink}
        />
      </>
    );
  }

  return (
    <>
      <section>
        {loggedIn && (
          <div className="mb-6 hidden items-center text-sm text-[#333333] lg:flex">
            <Link href={customURL?.homeUrl}>
              <a className="cursor-pointer text-[#043C63]" aria-label="breadcrumbs link Go to homepage">
                {formatCartMessage({ id: 'home', defaultMessage: 'Home' })}
              </a>
            </Link>
            <HiChevronDoubleRight className="mx-1 inline" />
            <span>{formatCartMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}</span>
          </div>
        )}

        <section className="cart-items-section">
          <h2 className="mb-4 flex text-affes-2xl text-gray-900">
            <strong className="mr-3">
              {formatCartMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}
            </strong>{' '}
            ({cart?.totalLineItemCount} {formatCartMessage({ id: 'items', defaultMessage: 'Items' })}{' '}
            <Price price={cart?.total || {}} className="ml-1 inline" />)
          </h2>
          <section className="flex w-full flex-wrap justify-between">
            {proceedToCheckoutError !== null && (
              <section className="mb-3 flex min-h-[46px] w-full flex-col items-start rounded border border-red-400 bg-red-100 px-[15px] pt-[14px] pb-4 text-affes-sm text-red-700 lg:w-[72%] ">
                {proceedToCheckoutError}
              </section>
            )}
            {OOSConfirm && (
              <section className="mb-3 flex min-h-[46px] w-full items-center rounded border border-red-400 bg-red-100 px-[15px] pt-[14px] pb-4 text-affes-sm text-red-700 lg:w-[72%] ">
                <FiAlertTriangle className="mr-2" />
                {formatCartMessage({
                  id: 'OOScart',
                  defaultMessage:
                    'There are 1 or more items not in stock in your cart. Please remove them or update qty to proceed to checkout.',
                })}
              </section>
            )}
            {isShowRecipientDetailsErrMsg && (
              <section className="mb-3 flex min-h-[46px] w-full items-center rounded border border-red-400 bg-red-100 px-[15px] pt-[14px] pb-4 text-affes-sm text-red-700 lg:w-[72%] ">
                <FiAlertTriangle className="mr-2" />
                {getAllCustomMessages?.addRecipientDetails}
              </section>
            )}
            {defaultShippingAddressNotAvailable && (
              <section className="mb-3 flex min-h-[46px] w-full items-center rounded border border-red-400 bg-red-100 px-[15px] pt-[14px] pb-4 text-affes-sm text-red-700 lg:w-[72%] ">
                <FiAlertTriangle className="mr-2" />
                {getAllCustomMessages?.emptyAddressShippingGroup}
              </section>
            )}
            <main className={`${loggedIn ? 'order-2' : 'order-1'} w-full lg:order-1 lg:w-[72%] xl:mr-2`}>
              <CartItems
                data={cart}
                giftCardImg={giftCardImg}
                itemQty={projectConfiguration?.productMaxQty}
                getAllCustomMessages={getAllCustomMessages}
                itemInStock={itemInStock}
                setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
                defaultShippingAddressNotAvailable={setDefaultShippingAddressNotAvailable}
              />
            </main>
            <aside className={`${loggedIn ? 'order-1' : 'order-2'} w-full lg:order-2 lg:w-[27%]`}>
              <OrderSummary
                cart={cart}
                onSubmit={(event) => onCheckout}
                isCheckout={false}
                cardType={null}
                isOrderConfirmed={false}
                giftCardsApplied={() => {}}
                placeOrderClicked={() => {}}
                itemQty={projectConfiguration?.productMaxQty}
                itemInStock={itemInStock}
                setProceedToCheckoutError={setProceedToCheckoutError}
                isCardErrorMsg={null}
                getAllCustomMessages={getAllCustomMessages}
                cvv={''}
                storeClosed={storeClosed}
              />
              {loggedIn && (
                <>
                  <StarRewardSummary account={account} cart={cart} />
                  {recommendations?.length > 0 && (
                    <section className="hidden lg:block">
                      <div className="mb-3 rounded border border-gray-100">
                        <h3 className="bg-gray-100 p-2 font-bold">
                          {formatCartMessage({ id: 'youMayLike', defaultMessage: 'You May Also Like' })}
                        </h3>
                        <div className="orderSummary p-2">
                          <YouMayLikeComponent
                            {...miniYouMayLikeconfig}
                            showDetails={true}
                            products={recommendations}
                          ></YouMayLikeComponent>
                        </div>
                      </div>
                    </section>
                  )}
                </>
              )}
            </aside>
          </section>
        </section>
        {loading && <Loader />}
      </section>
    </>
  );
}

export default Cart;
