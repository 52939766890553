import React from 'react';
type Props = {
  CurrentStep?: number;
};
export default function ProgressBar({ CurrentStep }: Props) {
  let progressBarWidth = '';

  if (CurrentStep === 1) {
    progressBarWidth += 'w-1/3 ';
  } else if (CurrentStep === 2) {
    progressBarWidth += 'w-2/3 ';
  } else if (CurrentStep === 3) {
    progressBarWidth += 'w-full rounded-tr ';
  }
  return <div className={`${progressBarWidth} h-[5px] rounded-tl bg-blue-900 duration-500`}></div>;
}
