import React, { useCallback, useEffect, useRef } from 'react';
import Script from 'next/script';
export default function Cnet({ skuId, brand, vendorProdId, config }) {
  const { script, zoneId, sKey, market, language } = config;
  const cnetScriptRef = useRef(null);
  const loadCnet = useCallback(() => {
    const scriptContent = `
        $(document).ready(function() {
          $(document.body).on('click', function(e) {
          });
        });
        var ccs_cc_args = [];
        ccs_cc_args.push(['cpn', '${skuId}']);
        ccs_cc_args.push(['mf', '${brand}']);
        ccs_cc_args.push(['pn', '${vendorProdId}']);
        ccs_cc_args.push(['lang', '${language}']);
        ccs_cc_args.push(['market', '${market}']);
        (function () {
          var o = ccs_cc_args; 
          o.push(['_SKey', '${sKey}']); 
          o.push(['_ZoneId', '${zoneId}']);
          var sc = document.createElement('script'); 
          sc.type = 'text/javascript'; 
          sc.async = true;
          sc.src = '${script}';
          var n = document.getElementsByTagName('script')[0]; 
          n.parentNode.insertBefore(sc, n);
        })();
      `;

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.defer = true;
    scriptElement.innerHTML = scriptContent;
    document.body.appendChild(scriptElement);
    cnetScriptRef.current = scriptElement;
  }, []);
  useEffect(() => {
    if ((window as any)?.$) loadCnet();
    return () => {
      if (cnetScriptRef?.current) {
        document.body.removeChild(cnetScriptRef?.current);
        const scriptToRemove = document.querySelector(`script[src="${script}"]`);
        if (scriptToRemove) {
          scriptToRemove.remove();
        }
      }
    };
  }, []);
  return (
    <>
      <Script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js" async onLoad={loadCnet} />
      <div id="SP_ProductImage"></div>
      <div id="SP_ACPage"></div>
      <div id="ccs-cnet-inline"></div>
      <div id="ccs-inline-content"></div>
      <div id="ccs-feature-icons"></div>
      <div id="ccs-cloud-imggallery"></div>
      <div id="ccs-ext-spec"></div>
      <div id="ccs-inline-logos-cnet-review"></div>
      <div id="ccs-logos"></div>
    </>
  );
}
