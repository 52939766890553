import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { Variant } from '@Types/product/Variant';
import ReactPlayer from 'react-player';
import CloseIcon from 'components/icons/close-icon';
import { useDarkMode } from 'frontastic';
import { UIProduct } from '..';

export interface VideoModalProps {
  open?: boolean;
  onClose?: () => void;
  product?: UIProduct;
  variant?: Variant;
}

const VideoModal: React.FC<VideoModalProps> = ({ open, onClose, variant }) => {
  //Darkmode
  const { mode, theme } = useDarkMode();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-[99999] overflow-y-auto`} onClose={onClose}>
        <>
          <section className="flex items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 -translate-y-4 sm:-translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 -translate-y-0 sm:scale-100"
              leaveTo="opacity-0 -translate-y-4 sm:-translate-y-0 sm:scale-95"
            >
              <section className="absolute inset-0" onClick={onClose}>
                <section
                  className="absolute left-1/2 mx-auto w-full -translate-x-1/2 translate-y-0 overflow-auto bg-white p-6 dark:bg-primary-200 md:top-1/2 md:w-[700px] md:-translate-y-1/2"
                  onClick={(e) => e.stopPropagation()}
                >
                  <section className="mb-3 flex justify-end">
                    <section>
                      <button type="button" onClick={onClose} id="modalClose">
                        <CloseIcon className="fill-sol-300 " />
                      </button>
                    </section>
                  </section>
                  <section style={{ position: 'relative', paddingTop: '56.25%' }}>
                    <ReactPlayer
                      style={{ position: 'absolute', top: 0, left: 0 }}
                      url={variant?.attributes?.productVideos}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  </section>
                </section>
              </section>
            </Transition.Child>
          </section>
        </>
      </Dialog>
    </Transition.Root>
  );
};
export default VideoModal;
