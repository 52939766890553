import React from 'react';
import GuestLandingPage from 'components/commercetools-ui/aafes/guestUser/guestLandingPage';

function GuestLandingPageTastic({ data }) {
  return (
    <>
      <GuestLandingPage
        topImage={data.topImage.media.file}
        giftCardReferenceCta={data.giftCard.link}
        eGiftCardReferenceCta={data.eGiftCard.link}
        eligibilityMessage={data.eligibilityMessage}
        middleImage={data.middleImage.media.file}
        armyReferenceCta={data.armyEmergencyReliefFund.link}
        airForceReferenceCta={data.airForceAssistanceFund.link}
        bottomImage={data.bottomImage.media.file}
      />
    </>
  );
}

export default GuestLandingPageTastic;
