import React from 'react';
import Sweepstake from 'components/commercetools-ui/aafes/sweepstakes';
const WinReign = (props) => {
  return (
    <>
      <Sweepstake
        sweepImageUrl={props?.data?.sweepsImage?.media?.file}
        sweepsId={props?.data?.sweepsId}
        startDate={props?.data?.startDate}
        endDate={props?.data?.endDate}
        sweepExpiryMsg={props?.data?.sweepstakeExpiredMsg}
        title={props?.data?.title}
        subTitle={props?.data?.subTitle}
        rulesLinkText={props?.data?.rulesLinkText}
        pdfLink={props?.data?.rulesPDFLink}
        regInfoText1={props?.data?.registerInfoText1}
        regInfoText2={props?.data?.registerInfoText2}
      ></Sweepstake>
    </>
  );
};

export default WinReign;
