export const updateShippingDetails = async (
  lineItem,
  fullSelectedAddress,
  selectedShippingMethod,
  updateMarketplaceLineItemShippingDetails,
  updateLineItemShippingDetails,
) => {
  const addressDetails = {
    firstName: fullSelectedAddress?.firstName || '',
    lastName: fullSelectedAddress?.lastName || '',
    addressId: fullSelectedAddress?.addressId || '',
    streetName: fullSelectedAddress?.streetName || '',
    streetNumber: fullSelectedAddress?.streetNumber || '',
    postalCode: fullSelectedAddress?.postalCode || '',
    city: fullSelectedAddress?.city || '',
    country: fullSelectedAddress?.country || 'US',
    state: fullSelectedAddress?.state || '',
    phone: fullSelectedAddress?.phone || '',
  };

  const updateFunction = lineItem?.isMarketplaceItem
    ? updateMarketplaceLineItemShippingDetails
    : updateLineItemShippingDetails;

  return await updateFunction({
    lineItemId: lineItem.lineItemId,
    address: addressDetails,
    shippingMethodId: selectedShippingMethod,
  });
};
