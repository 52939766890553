import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import useSWR from 'swr';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions, useAccount } from 'frontastic';
import StoresList from '../store-locator/StoresList';
function FindStore({
  setSearchInput,
  searchInput,
  storeList,
  setStoresList,
  findStoreModal,
  closeModal,
  setLoginModal,
  setFindStoreModal,
  channelId,
  updateChannelId,
  setResetDefaultStore,
}) {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [zipError, setZipError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hideText, setHideText] = useState(true);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { getChannelsSearch, account } = useAccount();
  const [clickSearch, setClickSearch] = useState(false);
  const [isStoresFound, setIsStoresFound] = useState(true);
  const storeEvent = (event) => {
    setSearchInput(event.target.value);
    setShowSearchInput(false);
    setIsStoresFound(true);
  };

  const validateForm = () => {
    let isValid = true;
    if (!searchInput) {
      const errMsg = formatCommonMessage({ id: 'search.input.required', defaultMessage: 'Search Input is required' });
      setZipError(errMsg);
      setIsLoading(false);
      isValid = false;
    } else {
      setZipError('');
      setIsLoading(false);
    }
    return isValid;
  };

  const findStoreSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setShowSearchInput(false);
    if (validateForm()) {
      const searchList: any = await getChannelsSearch(searchInput, null);
      setIsStoresFound(searchList?.length < 2 ? false : true);
      setShowSearchInput(searchList?.length < 2 ? false : true);
      setStoresList(searchList);
      setClickSearch(true);
      setHideText(false);
    }
  };

  const DisplayMessage = ({ messageKey }) => {
    const queryParams = new URLSearchParams({
      messageKey: messageKey,
    });

    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return (
      <p id="noStoresMsg" className="text-affes-md mb-2 text-red-900" role="alert">
        {data}
      </p>
    );
  };

  return (
    <>
      <Transition appear show={findStoreModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            if (!account?.resetDefaultStore) {
              closeModal(false);
              setShowSearchInput(false);
              setIsStoresFound(true);
              setZipError('');
            }
          }}
          static={account?.resetDefaultStore ? true : false}
        >
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={() => {
                    if (!account?.resetDefaultStore) {
                      closeModal(false);
                      setShowSearchInput(false);
                      setIsStoresFound(true);
                      setZipError('');
                    }
                  }}
                >
                  <section
                    className="absolute top-1/2 left-1/2 z-[100] max-h-[600px] w-[24rem] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-4 pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[650px] md:px-[30px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                    role="dialog"
                    aria-labelledby="modalTitle"
                    aria-modal="true"
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section className="modalHeader relative mb-5">
                        <h3 id="modalTitle" className="text-affes-xl font-bold text-gray-900">
                          {formatCommonMessage({ id: 'selectStore', defaultMessage: 'Select a Store' })}
                        </h3>
                        <button
                          className="absolute right-0 top-0"
                          onClick={() => {
                            closeModal(false);
                            setShowSearchInput(false);
                            setIsStoresFound(true);
                            setHideText(true);
                            setZipError('');
                            setResetDefaultStore(true);
                          }}
                          aria-label="Close Modal"
                        >
                          <GrClose />
                        </button>
                      </section>
                      <section>
                        <form>
                          <section>
                            {!isStoresFound && <DisplayMessage messageKey="noStoresFound" />}
                            {account?.resetDefaultStore && <DisplayMessage messageKey="defaultStoreClosed" />}
                            <label
                              id="searchLabel"
                              htmlFor="search"
                              className="mb-2 block text-sm font-semibold text-gray-800 dark:text-white"
                            >
                              {formatCommonMessage({ id: 'enterPostalCode', defaultMessage: 'Enter Postal Code' })}
                            </label>
                            <section className="relative mb-4">
                              <input
                                type="text"
                                id="search"
                                onChange={storeEvent}
                                placeholder="Postal Code, City/State or Base Name"
                                className="block w-full rounded-sm border border-gray-300 px-3 py-2.5 text-sm text-gray-900 placeholder:text-affes-sm placeholder:text-gray-800 dark:border-gray-600 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                maxLength={30}
                                required
                                aria-required="true"
                                aria-invalid={zipError ? 'true' : 'false'}
                                aria-describedby={zipError ? 'zipError' : null}
                                aria-labelledby="searchLabel"
                              />
                              <button
                                onClick={() => findStoreSubmit(event)}
                                type="submit"
                                className="absolute right-1.5 top-0 px-4 py-2 text-sm font-medium text-white dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-500 dark:text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  ></path>
                                </svg>
                              </button>
                              {zipError && (
                                <p id="zipError" className="text-[12px] text-red-900" aria-labelledby="zipErrorMessage">
                                  {zipError}
                                </p>
                              )}
                            </section>
                            <section id="searchResultsSection" className="relative">
                              <p className="mb-3 text-affes-sm">
                                {formatCommonMessage({
                                  id: 'searchByCityState',
                                  defaultMessage: 'You can also search by City/State or Base Name',
                                })}
                              </p>
                              {isLoading ? (
                                <section
                                  id="loadingSection"
                                  aria-labelledby="loadingSection"
                                  className="pt-5 text-center"
                                >
                                  <div className="inline-block">{<Spinner />}</div>
                                </section>
                              ) : (
                                <>
                                  {' '}
                                  <StoresList
                                    clickSearch={clickSearch}
                                    searchInput={searchInput}
                                    storeList={storeList}
                                    showSearchInput={showSearchInput}
                                    setLoginModal={setLoginModal}
                                    setFindStoreModal={setFindStoreModal}
                                    channelId={channelId}
                                    updateChannelId={updateChannelId}
                                  />
                                </>
                              )}
                            </section>
                          </section>
                        </form>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default FindStore;
