import React, { useEffect, useState } from 'react';
import Loader from 'components/commercetools-ui/loader/Loader';
import WarningIcon from 'components/icons/warning';
import { useFormat } from 'helpers/hooks/useFormat';
import CalculateExpDate from 'helpers/utils/CalculateExpDate';
import RenderCardsImages from 'helpers/utils/renderCardsImages';
import { useAccount, useCart } from 'frontastic';
import { CreditCard, MilstarAccount } from '../../../../../types/wallet-ews/Wallet';

function CreditCardsModal({
  billingAddress,
  orderId,
  payments,
  lineItem,
  orderLineId,
  showCardSelector,
  allCards,
  addPaymentError,
  cardError,
  setShowCardSelector,
  setReFetch,
}) {
  const { updateSubscriptionPayment } = useAccount();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [updatePayment, setUpdatePayment] = useState(null);
  const finalPayment = payments?.[0];
  const [selectedCardValues, setSelectedCardValues] = useState(finalPayment?.token || '');
  const [isLoading, setIsLoading] = useState(false);
  const { studioConfig: projectConfiguration } = useCart();
  const [cardDisplayNames, setCardDisplayNames] = useState({});

  const updatePaymentSubscription = async (selectedCardValue) => {
    const matchingCard: CreditCard | MilstarAccount = allCards.find((card) => card?.token === selectedCardValue);
    const cartBillingAddress = {
      firstName: matchingCard?.cardholdername,
      streetName: matchingCard?.billingaddress1,
      streetNumber: matchingCard?.billingaddress2,
      postalCode: matchingCard?.zipcode,
      city: matchingCard?.city,
      country: matchingCard?.country,
      state: matchingCard?.state,
      phone: matchingCard?.billingphone,
    };
    if (matchingCard?.token) {
      setIsLoading(true);
      const updatePaymentRes = await updateSubscriptionPayment(
        orderId,
        matchingCard?.token,
        'creditCard',
        matchingCard?.cardtype,
        cartBillingAddress,
        matchingCard?.expirydate,
      );
      setUpdatePayment(updatePaymentRes);
      setReFetch(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (updatePayment?.errorCode !== 500) {
      setShowCardSelector(true);
    }
  }, [updatePayment]);
  const handleCardSelection = (event) => {
    setSelectedCardValues(event.target.value);
    updatePaymentSubscription(event.target.value);
  };
  useEffect(() => {
    setSelectedCardValues(finalPayment?.token);
  }, [finalPayment?.token]);

  useEffect(() => {
    if (projectConfiguration?.cardTypeDisplayName) {
      try {
        setCardDisplayNames(JSON.parse(projectConfiguration?.cardTypeDisplayName));
      } catch (error) {
        console.error('Errorparsing projectConfig for cardTypeDisplayName:', error);
      }
    }
  }, [projectConfiguration]);

  return (
    <>
      {isLoading && <Loader />}
      {!showCardSelector && lineItem?.lineItemId === orderLineId ? (
        <section className="mb-[13px] lg:mb-7">
          <section className=" flex items-center">
            <select
              className="select h-[36px] w-full rounded border border-gray-600 py-[5px] text-affes-sm text-gray-900 md:mr-3 md:w-[362px]"
              onChange={handleCardSelection}
              value={selectedCardValues}
            >
              <option value="">{formatCheckoutMessage({ id: 'pleaseSelect', defaultMessage: 'Please select' })}</option>
              {allCards?.map((card) =>
                card.cardtype !== 'Milstar' ? (
                  <option
                    key={card.token}
                    value={card?.token}
                    selected={lineItem?.lineItemId === orderLineId && card.token}
                  >
                    {cardDisplayNames[card?.cardtype]}{' '}
                    {formatCheckoutMessage({ id: 'ending', defaultMessage: 'ending' })} {card?.token?.slice(-4)}
                    {card.isExpired ? ` ⚠️ Expired` : ''}
                  </option>
                ) : (
                  <option
                    key={card.token}
                    value={card?.token}
                    selected={lineItem?.lineItemId === orderLineId && card.token}
                  >
                    {cardDisplayNames[card?.cardtype]}
                  </option>
                ),
              )}
            </select>
          </section>
          {addPaymentError && <p className="my-2 text-[12px] text-red-900">{addPaymentError}</p>}
          {cardError && (
            <p className="my-2 text-[12px] text-red-900">
              {formatCheckoutMessage({
                id: 'pleaseSelect.Card',
                defaultMessage: 'Please Select a Card',
              })}
            </p>
          )}
        </section>
      ) : (
        <section className="flex">
          <section>{RenderCardsImages(finalPayment?.cardType)}</section>
          <section className="ml-2 flex-col text-gray-900">
            <p>
              {cardDisplayNames[finalPayment?.cardType]} ending{' '}
              {finalPayment?.token ? finalPayment?.token?.slice(-4) : finalPayment?.cardNumber?.slice(-4)}
            </p>
            {selectedCardValues && allCards && (
              <>
                {allCards.map((card) => {
                  if (card.token === finalPayment?.token) {
                    return (
                      card?.isExpired && (
                        <section key="expiry-warning" className="flex items-center">
                          <WarningIcon className="mr-2" />
                          <p className="text-red-900">
                            {formatAccountMessage({ id: 'Expired', defaultMessage: 'Expired' })}
                          </p>
                        </section>
                      )
                    );
                  }
                })}
              </>
            )}
            <p className="mt-[5px] mb-[15px]">Exp : {CalculateExpDate(finalPayment.expDate)}</p>
          </section>
        </section>
      )}
    </>
  );
}

export default CreditCardsModal;
