import { getMaoChatUrl } from 'frontastic/actions/account';
import React, { useEffect } from 'react';

const MaoChat = () => {
  useEffect(() => {
    const initializeChat = async () => {
      try {
        const res = await getMaoChatUrl();
        const chatUrl = res?.chatUrl; // Adjust according to your API response

        if (!chatUrl) {
          throw new Error('Chat URL is undefined');
        }
        // Post a message to open the chat
        if (chatUrl) {
          window.parent.postMessage({ action: 'Manh::Chat::openChat', url: chatUrl }, '*');
        } else {
          console.error('Chat URL is undefined');
        }
      } catch (error) {
        console.error('An error occurred during the upload process:', error);
      }
    };

    initializeChat();
  }, []);

  return <></>;
};

export default MaoChat;
