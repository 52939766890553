import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import router from 'next/router';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { GA_PAGE_VIEW } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { fetchApiHub, revalidateOptions, useAccount, useCart } from 'frontastic';
const LoginModal = dynamic(() => import('../Modals/Login/login'));

function Index({ cmsContent }) {
  const { getCMSTemplate, account, loggedIn } = useAccount();
  const [cmsDescription, setCmsDescription] = useState(null);
  const [response, setResponse] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [subPage, setSubPage] = useState('');
  const [pageName, setPageName] = useState('');
  const { studioConfig, setCartProductDetailEventSent, data: cartList } = useCart();
  const addedScripts = useRef([]);
  const { trackCMSPage } = useGATrackUserAction();

  const HTMLExtension = '.html';
  let URL = cmsContent.folderPath + subPage;

  URL = URL.replace(/\/{2,}/g, '/');

  const [projectConfigurationSuccess, setProjectConfigurationSuccess] = useState(false);
  function showLogin() {
    setLoginModal(true);
  }

  function closeLoginModal() {
    setLoginModal(false);
  }
  useEffect(() => {
    const segments: any = window.location.pathname;
    const segmentsRegex =
      /(cp|savings-center|coupon-acceptance|customer-service|store-locator|specialty-pages|price-match|business-delivery|scs|exchange-stores|guides|marketplace)(\/.*)?$/;
    const matches = segments?.match(segmentsRegex);

    // Static pages may return `undefined` for last segment
    let pos = 1;
    if (!matches?.[matches.length - pos]) {
      pos++;
    }

    let subPageValue = matches?.[matches.length - pos];
    setPageName(subPageValue);
    // We support static assets with or without .html extension in the URL
    // but assetPath requires it so we need to manually add if not present
    if (!subPageValue?.match(/(\.html)$/)) {
      subPageValue += HTMLExtension;
    }

    // To support static pages
    if (!subPageValue.match(/^\//)) {
      subPageValue = '/' + subPageValue;
    }

    setSubPage(subPageValue);
  }, [cmsContent]);

  const { studioConfig: projectConfiguration } = useCart();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (projectConfiguration) {
      setLoading(false);
      setProjectConfigurationSuccess(true);
    }
  }, [projectConfiguration]);

  let getCMSTemp;
  const cmsPayload_footer = { assetPath: URL };
  const queryParams = new URLSearchParams({
    assetPath: cmsPayload_footer.assetPath,
    login: 'false',
  });
  const footerCMSURL = `/action/account/fetchLoginTemplate?${queryParams}`;
  const { data, error } = useSWR(subPage && footerCMSURL, fetchApiHub, revalidateOptions);

  useEffect(() => {
    let isMounted = true;
    if (projectConfigurationSuccess) {
      const templateKeysCleanedResponse = projectConfiguration
        ? projectConfiguration?.templateKeys?.replace(/\\/g, '')
        : '';
      let templateKeysArray = null;
      try {
        if (templateKeysCleanedResponse) {
          templateKeysArray = JSON.parse(templateKeysCleanedResponse);
        }
      } catch (error) {
        console.error('assets-cms: Error parsing JSON: %s \n %O', templateKeysCleanedResponse, error);
      }

      if (!templateKeysArray) {
        console.error('assets-cms: No templateKeys defined in configuration');
        return;
      }

      const executeScript = (scriptContent) => {
        // Check if a script with the same content already exists in the head
        const existingScript = addedScripts.current.find((script) => script.textContent === scriptContent);

        if (existingScript) {
          // If a script with the same content exists, remove it
          existingScript.remove();
          // Remove the existing script from the array
          addedScripts.current = addedScripts.current.filter((script) => script !== existingScript);
        }

        window.jsoncategory = undefined;

        if (typeof window.jsoncategory === 'undefined') {
          // Create script element and append it to the head
          const scriptElement = document.createElement('script');
          scriptElement.type = 'text/javascript';
          scriptElement.textContent = scriptContent;
          document.head.appendChild(scriptElement);
          // Add the new script element to the array
          addedScripts.current.push(scriptElement);
        }
      };

      function appendCMSDomData() {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = getCMSTemp.body;

        // Remove all <script> elements from the temporary element
        const scripts = Array.from(tempElement.querySelectorAll('script'));

        // Remove all <script> elements from the temporary element
        scripts.forEach((script) => script.remove());
        setResponse(tempElement.innerHTML);
        setLoading(false);
        tempElement.remove();

        scripts.forEach((scriptTag) => {
          const scriptContent = scriptTag.innerHTML;
          const srcValue = scriptTag.getAttribute('src');
          if (scriptContent) {
            executeScript(scriptContent);
          }
          if (srcValue) {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.src = srcValue + '?t=' + Date.now();
            document.head.appendChild(scriptElement);
            // Add the script element to the ref
            addedScripts.current.push(scriptElement);
          }
        });
      }

      const getCMS = async () => {
        try {
          setLoading(true);
          const cmsPayload = { assetPath: URL };
          let matchedValue = null;
          for (const key in templateKeysArray) {
            if (templateKeysArray[key] === URL) {
              matchedValue = templateKeysArray[key];
              cmsPayload['loginCheck'] = matchedValue === URL;

              break;
            } else {
              cmsPayload['loginCheck'] = false;
            }
          }
          getCMSTemp = await data;

          if (getCMSTemp?.body === '') {
            setCmsDescription(cmsContent?.descriptionField);
          } else {
            if (matchedValue === URL) {
              if (!loggedIn) {
                showLogin();
              }
              if (getCMSTemp?.errorCode) {
                showLogin();
              } else {
                //setResponse(getCMSTemp.body);
                appendCMSDomData();
              }
            } else {
              //setResponse(getCMSTemp.body);
              appendCMSDomData();
            }
          }

          setShowLoader(false);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data from API:', error);
          setErrorMessage('Content not found');
          setShowLoader(false);
          setLoading(false);
        }
      };

      getCMS();

      return () => {
        isMounted = false;
        // Remove all dynamically added script elements from the head
        setResponse('');
        setCmsDescription(false);
        addedScripts.current.forEach((script) => {
          script.remove();
        });
        // Clear addedScripts ref
        window.jsoncategory = undefined;
        addedScripts.current = [];
      };
    }
  }, [projectConfigurationSuccess, projectConfiguration, account, data]);

  useEffect(() => {
    // Create a function to execute scripts
    const executeScript = (scriptContent) => {
      // Create a new script element
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.textContent = scriptContent;

      // Append the script to the head to execute it
      document.head.appendChild(scriptElement);
    };

    // Find the container that holds the CMS content
    const cmsContainer = document.querySelector('.cms-container');

    // Search for scripts within the container and execute them
    const scriptTags = cmsContainer.querySelectorAll('script');
    scriptTags.forEach((scriptTag) => {
      const scriptContent = scriptTag.innerHTML;
      executeScript(scriptContent);
    });
  }, [response]);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: pageName,
            cart: {
              ...cartList,
            },
          },
          monetateConfig,
        );
      };
      if (cartList && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [cartList, monetateConfig]);

  useEffect(() => {
    const pageTitle = document.title ? document.title : getPageNameFromPath();
    const trackLoginAndErrorPage = setTimeout(() => {
      trackCMSPage(GA_PAGE_VIEW, pageTitle, window.location.href, getSections(), account);
    }, 1000);
    return () => clearTimeout(trackLoginAndErrorPage);
  }, [account]);

  function formatSegment(segment) {
    return segment
      .replace(/-/g, ' ')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function getPageNameFromPath() {
    const path = window.location.pathname;
    const segments = path.split('/');
    const pageName = segments.filter((segment) => segment.trim() !== '').pop();
    const formattedPageName = formatSegment(pageName || '');
    return `Shop Army &amp; Air Force Exchange Service - ${formattedPageName}`;
  }

  function getSections() {
    const path = window.location.pathname;
    const segments = path.split('/').filter((segment) => segment.trim() !== '');
    const formattedSections = segments.filter((section) => section.toLowerCase() !== 'cp').map(formatSegment);
    formattedSections.shift(); // Remove the first element since is the daymaic page on studio
    return formattedSections;
  }

  return (
    <div className="cms-container mb-5">
      {loading && <Loader />}

      <div className="">
        {!cmsDescription && response && !loginModal ? (
          <div dangerouslySetInnerHTML={{ __html: response }} />
        ) : (
          <div className="flex justify-center text-center">
            {cmsDescription && !response && !loading && (
              <section className="mt-10">
                <section>{cmsDescription}</section>
                <Link href={cmsContent?.redirectTo}>
                  <button className="mx-auto mt-2 block rounded-sm bg-blue-900 p-2 text-affes-xl font-bold text-white">
                    {cmsContent?.label}
                  </button>
                </Link>
              </section>
            )}
          </div>
        )}

        <LoginModal
          loginModal={loginModal}
          closeModal={closeLoginModal}
          closeAccountFlyout={closeLoginModal}
          loginCallBack={() => router.push(router.asPath)}
        />
      </div>
    </div>
  );
}

export default Index;
