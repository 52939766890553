import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PIA } from 'helpers/constants/aafes';

function PrivayAct({ closeModal, privacyActModal }) {
  return (
    <>
      <Transition appear show={privacyActModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="fixed inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[300px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[90%] lg:mt-3 xl:w-[1000px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="text-xl  font-semibold text-gray-900 dark:text-white">Terms & Conditions</h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>
                      <p className="font-bold text-blue-900">Privacy Act Statement</p>
                      <div className="space-y-10 overflow-y-auto py-6">
                        <p>
                          <strong>AUTHORITY:</strong> 10 U.S.C. 7013, Secretary of the Army; 10 U.S.C. 9013, Secretary
                          of the Air Force; 10 U.S.C. 2481, Defense Commissary and Exchange Systems: Existence and
                          Purpose; Title 10 §2488. Combined Exchange and Commissary Stores; DoDI 1330.09, Armed Services
                          Exchange Policy; DoDI 1330.21, Armed Services Exchange Regulations; Army Regulation 215-8/Air
                          Force Instruction 34-211(I), Army and Air Force Exchange Service Operations; and E.O. 9397
                          (SSN), as amended.
                        </p>
                        <p>
                          <strong>PRINCIPAL PURPOSE(S):</strong> Information is collected to identify patron eligibility
                          and used to enable and assist military exchanges, MWR, and DeCA in their efforts of enhancing
                          the quality of life for authorized patrons. Information is used to obtain aggregate patron
                          demographics of patrons desires to improve shopping experiences.
                        </p>
                        <p>
                          <strong>ROUTINE USE(S):</strong> Records may be disclosed outside of DoD pursuant to Title 5
                          U.S.C. §552a(b)(3) regarding DoD “Blanket Routine Uses” published at dpcld.defense.gov. This
                          includes disclosure to contractors for improvement of service exchange programs, to incentive
                          programs, and pursuant to 5 U.S.C. 552a (b)(12) to a consumer reporting agency. In addition,
                          records may be disclosed to Federal agencies, and state, local and territorial governments, to
                          U.S. Postal Service in order to provide claim and postal authorities with
                          confirmation/certification of shipment for customer claims for damage or lost shipments and to
                          audit firms under contract with the service exchange to collect delinquency accounts.
                        </p>
                        <p>
                          <strong>DISCLOSURE:</strong> Voluntary. However, failure to provide all requested information
                          may result in denial of access to services or to specific shopping websites.
                        </p>
                        <section>
                          A copy of the Privacy Impact Assessment (PIA) for the collection of information may be located
                          <a href={PIA.link} className="ml-1 cursor-pointer text-blue-900 hover:underline">
                            here.
                          </a>
                        </section>

                        <section>
                          <p>
                            <strong>SYSTEM OF RECORD NOTICE (SORN):</strong> AAFES 0207.02 Exchange Retail Sales
                            Transaction Data may be viewed at
                            <a href={PIA.defense} className="ml-1 cursor-pointer text-blue-900 hover:underline">
                              dpcld.defense.gov
                            </a>
                          </p>
                        </section>
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default PrivayAct;
