import { Money } from '@Types/product/Money';

export const calculateMoneyStats = (moneyArray: (Money | undefined)[]) => {
  // Filter out undefined values and sort the array by centAmount
  const filteredMoneyArray = moneyArray
    ?.filter((money) => money !== undefined)
    ?.sort((a, b) => (a?.centAmount || 0) - (b?.centAmount || 0));

  // Get the lowest and highest values
  const lowest = filteredMoneyArray?.[0];
  const highest = filteredMoneyArray?.[filteredMoneyArray?.length - 1];

  // Calculate the difference in value
  const difference: Money | undefined =
    lowest && highest
      ? {
          centAmount: (highest?.centAmount || 0) - (lowest?.centAmount || 0),
          currencyCode: lowest?.currencyCode,
          fractionDigits: lowest?.fractionDigits,
        }
      : undefined;

  // Calculate the percentage difference
  const percentageDifference = difference ? (difference?.centAmount / (highest?.centAmount || 1)) * 100 : 0;

  return {
    lowest,
    highest,
    difference,
    percentageDifference: Math.round(percentageDifference),
  };
};
