import React from 'react';
import useSWR from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import Order from './order-confirmation';

function OrderConfirmationIndex({ cartList, onSubmit, giftCard }) {
  const queryParams = new URLSearchParams({
    messageKey: 'receiveAGiftCard',
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  return <Order cartList={cartList} onSubmit={onSubmit} giftCard={giftCard} getAllCustomMessages={data} />;
}

export default OrderConfirmationIndex;
