import { produce } from 'immer';
import React from 'react';
import {
  UB_REQUIRED_VALIDATION_TEXT,
  UB_PATCH_OPTIONS,
  UB_SEW_ON_ELIGIBLE_ATTACHMENT_TYPES,
  BUNDLE_ITEMS,
} from 'helpers/constants/aafes';
import { RadioButtonOption } from 'components/commercetools-ui/aafes/radio-group';
import { GiConsoleController } from 'react-icons/gi';
type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>;

interface NestedUpdateProps<T> {
  currentState: T;
  idKey?: string;
  idValue?: string;
  targetKey?: string;
  targetValue?: any;
  arrayName?: string;
  nestedArrayName?: string;
  option?: any;
  newKey?: string;
  newValue?: any;
  removeKey?: string;
  removeValue?: any;
  updateValue?: any;
  setState: SetStateFunction<T>;
}

const updateDraft = (draft: any, { targetKey, targetValue, newKey, newValue }: NestedUpdateProps<any>) => {
  targetKey && (draft[targetKey] = targetValue);
  newKey && (draft[newKey] = newValue);
};

const handleArrayUpdates = (draft: any, params: NestedUpdateProps<any>) => {
  const { arrayName, targetKey, targetValue, nestedArrayName, option, removeKey, removeValue, updateValue } = params;
  const targetObj = draft[arrayName]?.find((obj: any) => obj[targetKey] === targetValue);
  if (!targetObj) return;

  if (updateValue && option) {
    const indexToUpdate = targetObj[nestedArrayName].findIndex((item: any) => item[removeKey] === updateValue);
    if (indexToUpdate !== -1) {
      targetObj[nestedArrayName][indexToUpdate] = option;
    }
  } else {
    option && targetObj[nestedArrayName]?.push(option);
    if (removeKey) {
      const indexToRemove = targetObj[nestedArrayName].findIndex((item: any) => item[removeKey] === removeValue);
      indexToRemove !== -1 && targetObj[nestedArrayName].splice(indexToRemove, 1);
    }
  }
};

const handleNestedUpdateOrInsert = (draft: any, params: NestedUpdateProps<any>): void => {
  if (params.idKey && params.idValue && draft[params.idKey] === params.idValue) {
    updateDraft(draft, params);
    params.arrayName && params.nestedArrayName && handleArrayUpdates(draft, params);
    return;
  }

  if (params.newKey && !params.idKey && !params.idValue) {
    draft[params.newKey] = params.newValue;
    return;
  }

  Object.values(draft).forEach((value) => {
    if (typeof value === 'object' && value !== null) {
      handleNestedUpdateOrInsert(value, params);
    }
  });
};

export const NestedUpdateOrInsert = <T>(props: NestedUpdateProps<T>) => {
  props.setState((prevState) =>
    produce(prevState, (draft) => {
      handleNestedUpdateOrInsert(draft, props);
    }),
  );
};

export const GetOrdinalSuffix = (number: number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const GetAddBundleJson = (input: any) =>
  produce(input, (draft: any) => {
    return {
      bundleSku: draft.bundleSku,
      quantity: draft.quantity,
      isSewOn: draft?.selectedPatchType?.value === UB_PATCH_OPTIONS?.[0]?.value,
      configurableProperties: draft.configurableProperties
        .map((prop: any) => {
          if (!prop.productSku) {
            return null;
          }
          return {
            id: prop.id,
            productSku: prop.productSku,
            productGroups: prop.productGroups.flatMap((group: any) =>
              group.product.map((product: any) => {
                const result: any = { sku: product.sku };
                if (product.personalizationTextLines) {
                  result.personalizationTextLines = product.personalizationTextLines;
                }
                return result;
              }),
            ),
          };
        })
        .filter(Boolean),
    };
  });

export const ValidateAddBundle = (data: any): string | null => {
  for (const prop of data.configurableProperties) {
    if (
      (!prop?.productSku && prop?.isMandatory) ||
      ((prop?.isMandatory || prop?.productSku) &&
        prop.productGroups.some(
          (group: any) => group.minQuantity > 0 && (!group.product || group.product.length === 0),
        ))
    ) {
      return UB_REQUIRED_VALIDATION_TEXT;
    }
  }
  return null;
};

export const GetBundlePrice = ({
  configurableProperties = [],
  skuPrices = [],
  sewOnProduct = {} as any,
  selectedPatchType = {} as RadioButtonOption,
}) => {
  let totalCentAmount = 0;
  let fractionDigits = 2;
  let currencyCode = 'USD';
  let sewOnProductsCount = 0;
  const sewOnPrice = sewOnProduct?.variants?.[0]?.price?.centAmount || 0;

  const getSkuPrice = (sku: string) => {
    const skuPriceObj = skuPrices.find((priceObj: any) => priceObj[sku]);
    return skuPriceObj ? skuPriceObj[sku]?.price : null;
  };

  const updatePriceDetails = (price: any) => {
    if (!price) return;
    totalCentAmount += price.centAmount || 0;
    fractionDigits = price.fractionDigits || fractionDigits;
    currencyCode = price.currencyCode || currencyCode;
  };

  const processProducts = (products: any[], variants: any[]) => {
    products.forEach((item: any) => {
      if (UB_SEW_ON_ELIGIBLE_ATTACHMENT_TYPES.indexOf(item?.attachmentMethod) !== -1) {
        sewOnProductsCount++;
      }
      const product = variants.find((variant: any) => variant.sku === item?.sku) || item;
      updatePriceDetails(product?.price);
    });
  };

  configurableProperties.forEach((cp: any) => {
    updatePriceDetails(getSkuPrice(cp.productSku));

    const products = cp.productSku
      ? [cp.productSku, ...cp.productGroups.flatMap((pg: any) => pg.product)]
      : cp.productGroups.flatMap((pg: any) => pg.product);

    processProducts(products, cp.variants);
  });

  if (totalCentAmount && selectedPatchType?.value === UB_PATCH_OPTIONS?.[0]?.value) {
    totalCentAmount += sewOnPrice * sewOnProductsCount;
  }

  return {
    fractionDigits,
    centAmount: totalCentAmount,
    currencyCode,
  };
};

export const isParentBundleLineItem = (item) => {
  return Boolean(BUNDLE_ITEMS?.includes(item?.variant?.attributes?.customProductType) && item?.isParentBundleProduct);
};
