import YouMayLikeComponent from 'components/commercetools-ui/aafes/you-may-like';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop, mobile, tablet } from 'helpers/utils/screensizes';
import { useEffect, useState } from 'react';
import { getRecommendations } from 'helpers/utils/monetateHelper';
import { useCart } from 'frontastic';
import {
  YOU_MAY_ALSO_LIKE_DESKTOP_VIEW,
  YOU_MAY_ALSO_LIKE_TABLET_VIEW,
  YOU_MAY_ALSO_LIKE_MOBILE_VIEW,
} from 'helpers/constants/aafes';
import router from 'next/router';

const YouMayLikeTastic = ({ data }) => {
  const {
    isCartProductDetailEventSent,
    setCartProductDetailEventSent,
    isProductDetailPageEventSent,
    setProductDetailPageEventSent,
    studioConfig,
  } = useCart();
  const [monetateConfig, setMonetateConfig] = useState();
  const [recommendations, setRecommendations] = useState([]);
  const [hash, setHash] = useState('#');
  const [location, setLocation] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHash(window ? window.location.hash || '#' : '');
      setLocation(window ? window.location.pathname : '');
    }
  }, []);

  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Error Parsing monetateConstants:', error);
      }
    }
  }, [studioConfig?.monetateConstants]);
  const processMonetate = async () => {
    const isHomePage = location === '/';
    const isPLPCLP = location === '/browse';
    const isCompare = location === '/compare';
    const isWishlist = hash === '#wishlist';
    const isDeals = location === '/deals';
    const isCart = location === '/cart';
    const isPdp = /\/([^\/]+)\/(DSCO\d{10}|\d{7})/.test(location);

    if (
      monetateConfig &&
      (isCartProductDetailEventSent ||
        isProductDetailPageEventSent ||
        isHomePage ||
        isPLPCLP ||
        isCompare ||
        isCart ||
        isDeals ||
        isWishlist ||
        isPdp)
    ) {
      const recommendationRes = await getRecommendations(monetateConfig, data);
      setRecommendations(recommendationRes);
      setCartProductDetailEventSent(false);
      setProductDetailPageEventSent(false);
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash || '#');
    };

    window.addEventListener('hashchange', handleHashChange);
    router.events.on('hashChangeComplete', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      router.events.off('hashChangeComplete', handleHashChange);
    };
  }, [hash]);

  useEffect(() => {
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
    return () => {
      setRecommendations(null);
    };
  }, [monetateConfig, isCartProductDetailEventSent, isProductDetailPageEventSent, hash, location]);

  const [isMobileSize] = useMediaQuery(mobile);
  const [isTabletSize] = useMediaQuery(tablet);
  const [isDesktopSize] = useMediaQuery(desktop);

  const youMayLikeConfig = {
    slidesPerView: isMobileSize
      ? isTabletSize
        ? isDesktopSize
          ? data.desktopSlidesPerView ?? YOU_MAY_ALSO_LIKE_DESKTOP_VIEW
          : YOU_MAY_ALSO_LIKE_TABLET_VIEW
        : data.mobileSlidesPerView ?? YOU_MAY_ALSO_LIKE_MOBILE_VIEW
      : YOU_MAY_ALSO_LIKE_MOBILE_VIEW,
    showArrows: isTabletSize ? true : false,
    showDots: true,
    className: 'slider-normal',
  };

  return (
    <>
      {recommendations?.length > 0 && (
        <section>
          <h3
            className="my-2 mt-4 mb-10 text-lg font-bold leading-[1.375rem] text-gray-900"
            style={{ textAlign: data.titlePosition ? data.titlePosition : 'left' }}
          >
            {data.carouselTitle ?? 'You May Also Like'}
          </h3>
          <YouMayLikeComponent
            {...youMayLikeConfig}
            showDetails={data.showAdditionalDetails}
            products={recommendations}
            autoplayInterval={data.shouldAutoScroll ? data.autoScrollInterval : undefined}
          ></YouMayLikeComponent>
        </section>
      )}
    </>
  );
};

export default YouMayLikeTastic;
