import React, { useEffect, useState } from 'react';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

const RatingStars = ({ ratings }) => {
  const totalStars = 5;
  const [percentage, setPercentage] = useState<string>();

  useEffect(() => {
    const fraction = ratings / 5;
    const remainingPercentage = Math.round(fraction * 100);
    setPercentage(remainingPercentage + '%');
  }, [ratings]);

  return (
    <>
      <div className="relative h-fit w-fit text-blue-900">
        <div className="flex w-fit">
          {Array.from({ length: totalStars }, (_, index) => (
            <div>
              <MdOutlineStarBorder className="h-5 w-5" />
            </div>
          ))}
        </div>
        <div style={{ width: percentage }} className="absolute top-0 left-0 flex overflow-hidden">
          {Array.from({ length: totalStars }, (_, index) => (
            <div>
              <MdOutlineStar className="h-5 w-5" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default RatingStars;
