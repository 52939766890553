import React, { useMemo, useState } from 'react';
import IconBackward from 'components/icons/arrowBackward';
import { useFormat } from 'helpers/hooks/useFormat';
import ProgressBar from './progressBar';
import SelectGender from './selectGender';
import SelectRank from './selectRank';
import SelectUniformType from './selectUniformType';

type Props = {
  branchOfServiceDescription?: string;
  branchOfServiceLogo?: string;
};

const StepIndicator = ({ branchOfServiceDescription, branchOfServiceLogo }: Props) => {
  const { formatMessage } = useFormat({ name: 'uniform-builder' });
  const [fetchedUniforms, setFetchedUniforms] = useState([]);
  const [CurrentStep, setCurrentStep] = useState(1);
  const [gender, setGender] = useState('');
  const [rank, setRank] = useState('');
  const isBack = useMemo(() => {
    return CurrentStep === 1;
  }, [CurrentStep]);
  const isNext = useMemo(() => {
    return gender === '' || (CurrentStep === 2 ? rank === '' : false);
  }, [CurrentStep, gender, rank]);

  return (
    <div className="py-5">
      <div className="mx-auto grid max-w-[400px] md:max-w-[600px] lg:max-w-[600px]">
        <section className="flex w-full items-end justify-between">
          {/* Navigate back Button */}
          <div>
            <button
              onClick={() => {
                setCurrentStep(CurrentStep - 1);
              }}
              className={`w-fit ${
                isBack ? 'text-[#043C6366] text-opacity-40' : 'text-blue-900'
              }  flex items-center justify-center`}
              disabled={isBack}
            >
              <IconBackward className="h-[16px] w-[16px] fill-current" />
              <div className="font-lato ml-1 flex h-[17px] min-w-[30px] items-center text-sm font-normal leading-[16.8px]">
                <span>{formatMessage({ id: 'back', defaultMessage: 'Back' })} </span>
              </div>
            </button>
          </div>
          {/* Display current step */}
          <section className="flex flex-col items-center gap-1">
            <img src={branchOfServiceLogo} alt="Branch of Service Logo" className="h-[52px] w-[52px] rounded-sm" />
            <span className="font-lato h-[17px] min-w-[59px] text-sm font-bold leading-[16.8px] text-gray-800">
              {formatMessage({ id: 'step', defaultMessage: 'STEP' })} {CurrentStep}/3
            </span>
          </section>
          {/* Navigate Next Button */}
          <div className="w-[50px]">
            {CurrentStep !== 3 && (
              <button
                onClick={() => {
                  setCurrentStep(CurrentStep + 1);
                }}
                className={`w-fit ${
                  isNext ? 'text-[#043C6366] text-opacity-40' : 'text-blue-900'
                } flex items-center justify-center`}
                disabled={isNext}
              >
                <div className="font-lato mr-1 flex h-[17px] min-w-[30px] items-center text-sm font-normal leading-[16.8px]">
                  <span>{formatMessage({ id: 'next', defaultMessage: 'Next' })} </span>
                </div>
                <IconBackward className="h-[16px] w-[16px] rotate-180 fill-current" />
              </button>
            )}
          </div>
        </section>
        <section className="my-3 min-h-[210px] w-full rounded border-[1px] border-solid border-[#00000033]">
          <ProgressBar CurrentStep={CurrentStep} />
          {CurrentStep === 1 ? (
            <SelectGender setGender={setGender} gender={gender} />
          ) : CurrentStep === 2 ? (
            <SelectRank
              setRank={setRank}
              rank={rank}
              setFetchedUniforms={setFetchedUniforms}
              branchOfServiceDescription={branchOfServiceDescription}
              gender={gender}
            />
          ) : (
            <SelectUniformType fetchedUniforms={fetchedUniforms} rank={rank} />
          )}
        </section>
      </div>
    </div>
  );
};

export default StepIndicator;
