import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Cart } from '@Types/cart/Cart';
import { GrClose } from 'react-icons/gr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { useAccount } from 'frontastic/provider';
import AddAddressModal from './addAddressModal';
interface EditBillingProp {
  closeEditBillingAddressModal: () => void;
  cart: Cart;
  isOrderBilling?: boolean;
  orderId?: string;
}
function EditBillingAddressModal({ closeEditBillingAddressModal, cart, isOrderBilling, orderId }: EditBillingProp) {
  const { setBillingAddress } = useCart();
  const { account, updateSubscriptionBillingAddress, addresses = [] } = useAccount();
  const [selectedAddress, setSelectedAddress] = useState(
    cart?.billingAddress?.addressId ? cart.billingAddress.addressId : account?.addresses?.[0]?.addressId,
  );
  const [showLoader, setShowLoader] = useState(false);
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);

  const addressDropdownSelection = (e) => {
    setSelectedAddress(e.target.value);
  };

  const setBillingAddressToCart = async () => {
    if (selectedAddress && cart?.billingAddress?.addressId !== selectedAddress) {
      setShowLoader(true);
      {
        !isOrderBilling
          ? await setBillingAddress({
              billingAddress: {
                addressId: selectedAddress,
              },
            })
          : await updateSubscriptionBillingAddress({
              orderId: orderId,
              billingAddress: {
                addressId: selectedAddress,
              },
            });
      }
      setShowLoader(false);
    }
    closeEditBillingAddressModal();
  };

  const getFormattedAddress = (address) => {
    const formattedAddress = [
      address.additionalAddressInfo,
      address.streetName,
      address.streetNumber,
      address.city,
      address.state,
      address.postalCode,
    ]
      .filter(Boolean)
      .join(', ');

    return formattedAddress;
  };

  const afterSavingAddress = (data) => {
    setSelectedAddress(data.addresses[data.addresses.length - 1]['addressId']);
    setShowAddAddressModal(false);
  };

  const withoutSavingAddress = (address) => {
    setShowLoader(true);
    setBillingAddress({
      billingAddress: {
        firstName: address.firstName ? address.firstName : '',
        lastName: address.lastName ? address.lastName : '',
        streetName: address.streetName ? address.streetName : '',
        streetNumber: address.streetNumber ? address.streetNumber : '',
        postalCode: address.postalCode ? address.postalCode : '',
        city: address.city ? address.city : '',
        country: address.country ? address.country : 'US',
        state: address.state ? address.state : '',
      },
    })
      .then(() => {
        setShowAddAddressModal(false);
        closeEditBillingAddressModal();
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoader(false));
  };

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeEditBillingAddressModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0">
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section>
                        <section>
                          <section>
                            <section className="mb-[21px] flex justify-between">
                              <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                {' '}
                                {formatCheckoutMessage({
                                  id: 'chooseBillingAddress',
                                  defaultMessage: 'Choose Billing Address',
                                })}
                              </h3>
                              <button onClick={closeEditBillingAddressModal} className="h-[14px] w-[14px]">
                                <GrClose />
                              </button>
                            </section>
                            <section className="w-full">
                              <p className="my-2 mb-[7px] text-affes-sm font-bold leading-[16.8px] text-gray-900">
                                {' '}
                                {formatCheckoutMessage({
                                  id: 'selectBillingAddress',
                                  defaultMessage: 'Select a Billing Address',
                                })}
                              </p>
                              <section className="mb-4">
                                <select
                                  className="select h-[36px] w-full rounded  border-gray-600 py-[6px] px-[10px] leading-[26px] text-gray-900"
                                  onChange={addressDropdownSelection}
                                  value={selectedAddress}
                                >
                                  {addresses?.map((address) => (
                                    <option
                                      selected={selectedAddress == address.addressId}
                                      key={address.addressId}
                                      value={address.addressId}
                                    >
                                      {getFormattedAddress(address)}
                                    </option>
                                  ))}
                                </select>
                              </section>
                            </section>
                            <button
                              className="mb-6 text-affes-sm leading-[16.8px] text-blue-900 md:mb-12"
                              onClick={() => {
                                setShowAddAddressModal(true);
                              }}
                            >
                              {formatCheckoutMessage({
                                id: 'addBillingAddress',
                                defaultMessage: 'Add New Billing Address',
                              })}
                            </button>

                            <section className="md:flex">
                              <button
                                className="mb-4 block h-11 w-full rounded bg-blue-900 text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]"
                                onClick={setBillingAddressToCart}
                              >
                                {formatCommonMessage({ id: 'continue', defaultMessage: 'Continue' })}
                              </button>
                              <button
                                className=" mt-2 block w-full  text-center text-affes-xl font-bold leading-[19.2px] text-blue-900 md:m-0 md:w-32"
                                onClick={closeEditBillingAddressModal}
                              >
                                {formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                              </button>
                            </section>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
                <section>{showLoader && <Loader></Loader>}</section>

                {showAddAddressModal && (
                  <AddAddressModal
                    withoutSavingAddress={withoutSavingAddress}
                    afterSavingAddress={afterSavingAddress}
                    closeAddAddressModal={() => setShowAddAddressModal(false)}
                    parentLoaderState={showLoader}
                    alwaysSave={false}
                    defaultValues={null}
                    editAddress={false}
                    isCart={true}
                    existingAddresses={addresses}
                    addressType="Billing"
                  ></AddAddressModal>
                )}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default EditBillingAddressModal;
