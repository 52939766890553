import React from 'react';
import Footer from 'components/commercetools-ui/aafes/aafesFooter';

function index({ data }) {
  const sweepsTakes = data.headerCol13;
  const termsConditions = data.headerCol14;
  const privacyAct = data.headerCol15;

  const column1 = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
      icon: data.iconCol1,
      loginRequired: data.loginRequired,
    },
    {
      header: data.headerCol5,
      links: data.linksCol5,
      icon: data.iconCol5,
      loginRequired: data.loginRequired,
    },
  ];
  const column2 = [
    {
      header: data.headerCol2,
      links: data.linksCol2,
      icon: data.iconCol2,
      loginRequired: data.loginRequired,
    },
    {
      header: data.headerCol6,
      links: data.linksCol6,
      icon: data.iconCol6,
      loginRequired: data.loginRequired,
    },
  ];
  const column3 = [
    {
      header: data.headerCol3,
      links: data.linksCol3,
      icon: data.iconCol3,
      loginRequired: data.loginRequired,
    },
    ,
    {
      header: data.headerCol7,
      links: data.linksCol7,
      icon: data.iconCol7,
      loginRequired: data.loginRequired,
    },
  ];
  const column4 = [
    {
      header: data.headerCol4,
      links: data.linksCol4,
      icon: data.iconCol4,
      loginRequired: data.loginRequired,
    },
    {
      header: data.headerCol8,
      links: data.linksCol8,
      icon: data.iconCol8,
      loginRequired: data.loginRequired,
    },
  ];
  const footerColumns = {
    column1: column1,
    column2: column2,
    column3: column3,
    column4: column4,
  };

  const otherLinks = {
    sweepsTakes: sweepsTakes,
    termsConditions: termsConditions,
    privacyAct: privacyAct,
  };

  const dgDetails = {
    dgIcon: data.dgicon,
    dgLink: data.dglink,
  };

  return (
    <section>
      <Footer footerColumns={footerColumns} otherLinks={otherLinks} dgDetails={dgDetails} />
    </section>
  );
}

export default index;
