import React, { useCallback, useEffect, useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { MdInfoOutline, MdOutlineClose } from 'react-icons/md';
import InformationRequired from 'components/commercetools-ui/aafes/Modals/informationRequired';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { generateDayOptions, generateMonthOptions, generateYearOptions } from 'helpers/utils/generateDatesOptions';
import { useAccount } from 'frontastic';
import { requestResetUsername, updateCompareOTP } from './../../../../frontastic/actions/account/index';

export interface LoginProps {
  registerLink?: Reference;
  accountLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ registerLink, accountLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  //account actions
  const {
    login,
    loggedIn,
    requestConfirmationEmail,
    requestPasswordReset,
    resetPassword,
    compareUserNameOTPWithToken,
    verifySSN,
  } = useAccount();
  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });
  // Verify Ssn
  // Output: 6/2/1985
  const [informationRequired, setInformationRequired] = useState(false);
  function handleInformationRequired() {
    setInformationRequired(false);
  }
  const [ssn, setSsn] = useState({ day: '', month: '', year: '', ssn: '', lastName: '', dob: '' });
  const [retrieve, setRetrieve] = useState({ cidNumber: '', email: '' });
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  const [isEmpty, setIsEmpty] = useState(false);

  const [showSsn, setShowSsn] = useState(false);

  const [receiveOTP, setReceiveOTP] = useState(undefined);

  const [updateUsername, setUpdateUsername] = useState(undefined);

  const [updatePassword, setUpdatePassword] = useState(undefined);

  //processing...
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [forgotUsername, setForgotUsername] = useState(false);

  const [resetUsername, setResetUSername] = useState(false);

  const [retrieveUsername, setRetrieveUsername] = useState(false);

  const [sendCode, setSendCode] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [sentUsername, setSentUsername] = useState(false);

  const [userOtp, setUserOtp] = useState(undefined);

  const [retryPassword, setRetryPassword] = useState(false);
  const [invalidSsn, setInvalidSsn] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  //not on default login modal
  const subModal = forgotUsername || resendPasswordReset;

  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [customer, setCustomer] = useState(undefined);
  const [confirmResetpass, setConfirmResetpass] = useState('');
  const [newPassword, setNewPassword] = useState('');

  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setSendCode(false);
    setForgotUsername(false);
    setRetryPassword(false);
    setSentUsername(false);
    setResetUSername(false);
    setRetrieveUsername(false);
    setInvalidSsn(false);
    setError('');
  };
  const toForgotUsername = () => {
    setResetUSername(true);
    setForgotUsername(true);
    setResendPasswordReset(true);
    setRetrieveUsername(false);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setInvalidSsn(false);
    setSsn({ day: '', month: '', year: '', ssn: '', lastName: '', dob: '' });
  };

  const toForgotPassword = () => {
    setForgotUsername(true);
    setResetUSername(false);
    setResendPasswordReset(true);
    setRetrieveUsername(false);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setInvalidSsn(false);
    setSsn({ day: '', month: '', year: '', ssn: '', lastName: '', dob: '' });
  };

  const toRetrieveUsername = () => {
    setForgotUsername(false);
    setResendPasswordReset(false);
    setRetrieveUsername(true);
    setSendCode(false);
    setSentUsername(false);
    setRetryPassword(false);
    setError('');
  };

  const toSendCode = () => {
    setForgotUsername(false);
    setResendPasswordReset(false);
    setRetrieveUsername(false);
    setSendCode(true);
    setSentUsername(false);
    setRetryPassword(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };
  const handleNameChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name == 'lastName') {
      value = value.replace(/[^a-zA-Z ]/g, '');
    }
    setSsn((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsEmpty(false);
  };

  const handleResetPassword = (event) => {
    const enteredPassword = event.target.value;

    // Update the password state
    setNewPassword(enteredPassword);
    setErrorMessage('');
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password);
      if ('accountId' in response && !response.accountId)
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };
  //   verify ssn
  const handleVerifySSN = async () => {
    try {
      // Parsing the SSN as a number
      const data = {
        ssn: parseInt(ssn.ssn),
        dobYear: parseInt(ssn.year),
        dobMonth: parseInt(ssn.month),
        dobDay: parseInt(ssn.day),
        lastName: ssn.lastName,
      };
      const verifyResponse = await verifySSN(data);

      setCustomer(verifyResponse);
    } catch (err) {
      console.log('Error:', err);
    }
  };
  useEffect(() => {
    if (customer?.cidNumber) {
      setForgotUsername(false);
      setRetrieveUsername(true);
    } else {
      setInvalidSsn(true);
    }
  }, [customer]);
  const retrivePasswordClick = (customer) => {
    setRetrieve({ cidNumber: customer?.cidNumber, email: customer?.email });
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await requestConfirmationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      const response = await requestPasswordReset(retrieve.email, retrieve.cidNumber);

      setReceiveOTP(response);
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  useEffect(() => {
    if (receiveOTP?.otpCode) {
      setRetrieveUsername(false);
      setSendCode(true);
    }
  }, [receiveOTP]);

  const retrieveOTPcode = async () => {
    try {
      if (!resetUsername) {
        const otpResponse = await updateCompareOTP(receiveOTP?.passwordResetToken?.token);
        setUpdatePassword(otpResponse);
      } else {
        const otpCode = userOtp?.otpCode;
        const token = userOtp?.passwordResetToken?.token;
        const email = userOtp?.passwordResetToken?.email;
        const otpResponse = await compareUserNameOTPWithToken(otpCode, token, email);
        setUpdateUsername(otpResponse);
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  useEffect(() => {
    if (updatePassword) {
      setRetryPassword(true);
      setSendCode(false);
    }
  }, [updatePassword]);

  useEffect(() => {
    if (updateUsername) {
      setSentUsername(true);
      setSendCode(false);
    }
  }, [updateUsername]);

  const newUserName = async () => {
    try {
      const userOtpResponse = await requestResetUsername(retrieve.email, retrieve.cidNumber);
      setUserOtp(userOtpResponse);
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };
  useEffect(() => {
    if (userOtp?.otpCode) {
      setRetryPassword(false);
      setSendCode(true);
    }
  }, [userOtp]);

  const newPasswordForUser = async () => {
    if (newPassword.trim() === '' || confirmResetpass.trim() === '') {
      setErrorMessage('This field is required');
    } else {
      try {
        if (newPassword === confirmResetpass) {
          await resetPassword(updatePassword?.token, confirmResetpass);
        } else {
          setError(formatErrorMessage({ id: 'password not match', defaultMessage: 'password deos not match' }));
        }
      } catch (err) {
        setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
      }
    }
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (forgotUsername) resendVerificationEmailForUser();
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) resendPasswordResetForUser();
    //if user wants to login
    else loginUser();
    //processing ends
    setLoading(false);
  };

  if (loggedIn) return <Redirect target={accountLink} />;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8">
        <div className="relative mx-auto w-full max-w-[500px] rounded-sm bg-white px-6 pb-20 pt-10 shadow-2xl dark:bg-primary-200 lg:px-12">
          <form className="space-y-7" onSubmit={handleSubmit}>
            {!forgotUsername && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
              <div className="py-6 text-center">
                <h2 className="text-3xl font-extrabold text-neutral-700">
                  {resendPasswordReset
                    ? formatAccountMessage({ id: 'reset.password', defaultMessage: 'Reset password' })
                    : formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign in to your account' })}
                </h2>
                {!subModal && (
                  <h3 className="text-md mt-6 text-neutral-600">
                    {formatAccountMessage({ id: 'details.enter', defaultMessage: 'Please enter your details' })}
                  </h3>
                )}
              </div>
            )}
            {success && <p className="text-sm text-green-600">{success}</p>}
            {error && <p className="text-sm text-accent-400">{error}</p>}
            {!forgotUsername && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-neutral-700">
                  {formatMessage({ id: 'email', defaultMessage: 'Email' })}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                    required
                    className="block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {!resendPasswordReset && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-neutral-700">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                </label>
                <div className="relative mt-1">
                  <input
                    id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="current-password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                    onChange={handleChange}
                  />
                  <span
                    className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                  </span>
                </div>
              </div>
            )}

            {subModal ? (
              <div>
                <MdOutlineClose
                  className="absolute top-10 right-8 h-6 w-6 cursor-pointer text-accent-400 hover:text-accent-500"
                  onClick={backToLogin}
                />
              </div>
            ) : (
              !retrieveUsername &&
              !sendCode &&
              !sentUsername &&
              !retryPassword && (
                <div className="mt-2 space-y-2">
                  <div>
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="rememberMe"
                        type="checkbox"
                        className="h-5 w-5 rounded-sm border-gray-300 text-transparent focus:ring-accent-500"
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="remember-me" className="ml-2 block whitespace-nowrap text-sm text-neutral-600">
                        {formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center justify-around pt-8 pb-4">
                    <div className="text-right text-sm">
                      <span
                        className="cursor-pointer text-neutral-600 underline transition hover:text-accent-400"
                        onClick={toForgotUsername}
                      >
                        {formatAccountMessage({ id: 'username.forgot', defaultMessage: 'Forgot username?' })}
                      </span>
                    </div>
                    <div className="text-right text-sm">
                      <span
                        className="cursor-pointer text-neutral-600 underline transition hover:text-accent-400"
                        onClick={toForgotPassword}
                      >
                        {formatAccountMessage({ id: 'passwordforgot', defaultMessage: 'Forgot password?' })}
                      </span>
                    </div>
                  </div>
                </div>
              )
            )}

            {!forgotUsername && !retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                  disabled={loading}
                >
                  {subModal
                    ? formatMessage({ id: 'submit', defaultMessage: 'Submit' })
                    : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
                </button>
                <p className="mt-4 text-center text-sm text-neutral-600">
                  {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                  <ReferenceLink
                    target={registerLink}
                    className="font-medium text-accent-400 underline hover:text-accent-500"
                  >
                    {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Register here' })}
                  </ReferenceLink>
                </p>
              </div>
            )}
          </form>
          {forgotUsername && !retrieveUsername && !sendCode && !sentUsername && (
            <>
              {resetUsername ? (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.username', defaultMessage: 'Reset Username' })}
                  </h3>
                </div>
              ) : (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
                  </h3>
                </div>
              )}

              <div className="mb-5 flex items-start pt-3">
                <p className="mr-2">
                  <MdInfoOutline />
                </p>
                {!invalidSsn ? (
                  <div>
                    <p className="w-full text-sm font-semibold text-black-300 ">
                      {formatAccountMessage({
                        id: 'validate.customer',
                        defaultMessage: 'To begin, We must first validate that you are an authorized Exchange customer',
                      })}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="mb-4 w-full text-sm font-semibold text-black-300 ">
                      {formatAccountMessage({
                        id: 'validate.customer',
                        defaultMessage: 'To begin, We must first validate that you are an authorized Exchange customer',
                      })}
                    </p>
                    <p className="mb-3 w-full text-sm font-semibold text-red-600 ">
                      We are sorry, but we could not locate you by the last 4 digits of your Social Security
                      Number.Please enter your full 9-digit SSN without dashes.
                    </p>
                  </div>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="mb-5 cursor-pointer font-medium text-[#043C63]"
                  onClick={() => setInformationRequired(true)}
                >
                  {' '}
                  {formatAccountMessage({ id: 'whyneed', defaultMessage: 'Why do I need this?' })}
                </button>
              </div>

              <div className=" mb-5">
                {!invalidSsn ? (
                  <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="username">
                    {formatAccountMessage({ id: 'SSN number', defaultMessage: 'Last 4 Digits of SSN' })}
                    <span className="ml-2 text-red-600">
                      {' '}
                      {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                    </span>
                  </label>
                ) : (
                  <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="username">
                    {formatAccountMessage({ id: 'SSN', defaultMessage: 'Full SSN Number' })}{' '}
                    <span className="ml-2 text-red-600">
                      {' '}
                      {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                    </span>
                  </label>
                )}
                {!invalidSsn ? (
                  <div className="relative w-2/4 rounded-sm border border-gray-600 focus:border focus:border-none focus:border-gray-900">
                    <input
                      id="ssn"
                      name="ssn"
                      type={showSsn ? 'text' : 'password'}
                      className="w-2/4 appearance-none rounded-sm border-0 placeholder:text-gray-400 focus:outline-none"
                      placeholder="&bull;&bull;&bull;&bull;&bull;"
                      required
                      maxLength={4}
                      value={ssn?.ssn}
                      onInput={(e: any) => {
                        e.target.value = !invalidSsn ? e.target.value.slice(0, 4) : e.target.value.slice(0, 9);
                      }}
                      onChange={handleNameChange}
                    />

                    <div className="absolute right-3 top-2 flex">
                      <button className="flex items-center text-affes-sm " onClick={() => setShowSsn(!showSsn)}>
                        {showSsn ? <EyeOffIcon className="mr-2 h-5 w-5" /> : <EyeIcon className="mr-2 h-5 w-5" />}
                        {showSsn ? 'HIDE' : 'SHOW'}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="relative w-3/5 rounded-sm border border-gray-600 focus:border focus:border-none focus:border-gray-900">
                    <input
                      id="ssn"
                      name="ssn"
                      type={showSsn ? 'text' : 'password'}
                      className="w-3/5 appearance-none rounded-sm border-0 placeholder:text-gray-400 focus:outline-none"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      maxLength={9}
                      value={ssn?.ssn}
                      onInput={(e: any) => {
                        e.target.value = !invalidSsn ? e.target.value.slice(0, 4) : e.target.value.slice(0, 9);
                      }}
                      onChange={handleNameChange}
                    />

                    <div className="absolute right-3 top-2 flex">
                      <button className="flex items-center text-affes-sm " onClick={() => setShowSsn(!showSsn)}>
                        {showSsn ? <EyeOffIcon className="mr-2 h-5 w-5" /> : <EyeIcon className="mr-2 h-5 w-5" />}
                        {showSsn ? 'HIDE' : 'SHOW'}
                      </button>
                    </div>
                  </div>
                )}
                {isEmpty && <div className="text-red-600">This field is required.</div>}
              </div>
              <div>
                <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="username">
                  {formatAccountMessage({ id: 'dateofbirth', defaultMessage: 'Date of Birth' })}
                  <span className="ml-2 text-red-600">
                    {' '}
                    {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                  </span>
                </label>
                <div className="flex">
                  <div>
                    <select defaultValue="Day" name="day" value={ssn.day} onChange={handleNameChange}>
                      <option disabled>Day</option>
                      {generateDayOptions()}
                    </select>
                  </div>
                  <div className="ml-2">
                    <select defaultValue="Month" name="month" value={ssn.month} onChange={handleNameChange}>
                      <option disabled>Month</option>
                      {generateMonthOptions()}
                    </select>
                  </div>
                  <div className="ml-2">
                    <select defaultValue="Year" name="year" value={ssn.year} onChange={handleNameChange}>
                      <option disabled>Year</option>
                      {generateYearOptions()}
                    </select>
                  </div>
                </div>
              </div>
              {isEmpty && <div className="text-red-600">This field is required.</div>}

              {!invalidSsn && (
                <div className="mt-4 ">
                  <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="username">
                    {formatAccountMessage({ id: 'lastname', defaultMessage: 'Last name' })}
                    <span className="ml-2 text-red-600">
                      {' '}
                      {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                    </span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    className="relative w-full rounded-sm border border-gray-600 focus:border focus:border-gray-900"
                    required
                    onChange={handleNameChange}
                  />
                </div>
              )}
              {isEmpty && !invalidSsn && <div className="text-red-600">This field is required.</div>}

              <div className="mt-5 pt-10">
                <div>
                  <button
                    type="button"
                    className="text-md mb-8 flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                    disabled={loading}
                    onClick={handleVerifySSN}
                  >
                    {formatAccountMessage({ id: 'continue', defaultMessage: 'Continue' })}
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    className="text-md flex w-full  justify-center border border-transparent py-2 px-4 font-medium text-accent-400 transition-colors duration-200 ease-out focus:outline-none  disabled:bg-gray-200"
                    disabled={loading}
                    onClick={backToLogin}
                  >
                    {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                  </button>
                </div>
              </div>
            </>
          )}

          {retrieveUsername && !sendCode && !sentUsername && !retryPassword && (
            <div className="w-full">
              {resetUsername ? (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.username', defaultMessage: 'retrieve Username' })}
                  </h3>
                </div>
              ) : (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
                  </h3>
                </div>
              )}
              <div className="flex-col py-10 text-center">
                {resetUsername ? (
                  <h3 className="mb-4 text-lg font-semibold text-black-900">
                    {formatAccountMessage({
                      id: 'retrieve.username',
                      defaultMessage: 'How would you like to retrieve your username?',
                    })}
                  </h3>
                ) : (
                  <h3 className="mb-4 text-lg font-semibold text-black-900">
                    {formatAccountMessage({
                      id: 'retrieve.password',
                      defaultMessage: 'How would you like to retrieve your password?',
                    })}
                  </h3>
                )}
                <p>
                  {formatAccountMessage({
                    id: 'verification code',
                    defaultMessage:
                      'We will send a temporary verification code to the mobile number or email address that is linked to your account',
                  })}
                </p>
              </div>
              <div className="mb-4 flex items-center">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  name="default-radio"
                  className="h-4 w-4   border-gray-300 focus:ring-2 focus:ring-black-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-black-600"
                />
                <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {formatAccountMessage({ id: 'message.phonenumber', defaultMessage: 'Text Message to phone number ' })}
                  {customer?.phone}
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="default-radio-2"
                  type="radio"
                  value={customer}
                  name="default-radio"
                  className="b h-4 w-4 border-gray-300 focus:ring-2 focus:ring-black-600 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-black-600"
                  checked={retrieve.email === customer?.email}
                  onChange={() => retrivePasswordClick(customer)}
                />
                <label htmlFor="default-radio-2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {formatAccountMessage({ id: 'message.email', defaultMessage: 'Email to email address' })}{' '}
                  {customer?.email}
                </label>
              </div>
              <div className="flex justify-around pt-14">
                <button
                  className="w-1/4 rounded-sm bg-accent-500 px-4 py-2 text-white"
                  onClick={!resetUsername ? resendPasswordResetForUser : newUserName}
                >
                  Continue
                </button>
                <button
                  className="w-1/4 rounded-sm border border-gray-600 bg-gray-100 px-4 py-2 text-black-600"
                  onClick={toForgotPassword}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {sendCode && !sentUsername && (
            <div>
              {resetUsername ? (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.username', defaultMessage: 'retrieve Username' })}
                  </h3>
                </div>
              ) : (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
                  </h3>
                </div>
              )}
              <div className="text-center">
                <div className="flex-col py-10 text-center">
                  <h3 className="mb-4 text-lg font-semibold text-black-900">Please enter your verification code?</h3>
                  <p>
                    {formatAccountMessage({
                      id: 'verification.code',
                      defaultMessage:
                        'The code is valid for ten minutes.If you did not receive a code, please click "Send New Code". ',
                    })}
                  </p>
                </div>
                <div>
                  <input
                    type={receiveOTP?.otpCode || userOtp?.otpCode}
                    className="relative w-3/4 rounded-sm border border-gray-600 pl-1.5 focus:border focus:border-gray-900"
                    required
                    value={receiveOTP?.otpCode || userOtp?.otpCode}
                  />
                </div>
              </div>
              <div className=" pt-14">
                <button className="w-full rounded-sm bg-accent-500 px-4 py-2 text-white" onClick={retrieveOTPcode}>
                  Continue
                </button>
              </div>
              <div className="pt-6 text-center">
                {' '}
                <button className="w-1/4  text-accent-400" onClick={toRetrieveUsername}>
                  Cancel
                </button>
              </div>
              <div className=" pt-6">
                <button
                  className="w-full rounded-sm border border-gray-600 bg-gray-100 px-4 py-2 text-black-600"
                  onClick={resendPasswordResetForUser}
                >
                  Send New Code
                </button>
              </div>
            </div>
          )}

          {retryPassword && !forgotUsername && !retrieveUsername && !sendCode && !sentUsername && (
            <>
              <div>
                <div>
                  {' '}
                  <h3 className=" mb-6 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'reset.password', defaultMessage: 'Password Reset' })}
                  </h3>
                  <p>
                    {' '}
                    {formatAccountMessage({
                      id: 'criteria',
                      defaultMessage: 'Your password must meet the following criteria.',
                    })}
                  </p>
                  <ul>
                    <li>
                      {' '}
                      {formatAccountMessage({
                        id: 'different.username',
                        defaultMessage: 'Must be different from username',
                      })}
                    </li>
                    <li> {formatAccountMessage({ id: 'nospaces', defaultMessage: 'Must not contain spaces' })}</li>
                    <li>
                      {' '}
                      {formatAccountMessage({
                        id: 'security.questions',
                        defaultMessage: 'Must be different from answers to security questions',
                      })}
                    </li>
                    <li>
                      {' '}
                      {formatAccountMessage({ id: 'min.length', defaultMessage: 'Minimum password length' })}:
                      <span className="text-blue-700">6</span>
                    </li>
                    <li>
                      {' '}
                      {formatAccountMessage({ id: 'max.length', defaultMessage: 'Maximum password length' })}:
                      <span className="text-blue-700">15</span>
                    </li>
                    <li>
                      {' '}
                      {formatAccountMessage({
                        id: 'min.uppercase',
                        defaultMessage: 'Minimum uppercase character(A-Z)',
                      })}
                      :<span className="text-blue-700">K</span>
                    </li>
                    <li>
                      {' '}
                      {formatAccountMessage({ id: 'min.digits', defaultMessage: ' Minimum digits(0-9)' })}:
                      <span className="text-blue-700">0</span>
                    </li>
                    <li>
                      {' '}
                      {formatAccountMessage({ id: 'special.character', defaultMessage: 'Allowed special characters' })}:
                      <span className="text-blue-700">
                        &#41;&#40;
                        &#33;&#34;&#35;&#37;&#38;&#39;&#42;&#44;&#46;&#47;&#58;&#63;&#59;&#64;&#92;&#94;&#95;&#96;&#123;&#124;&#125;
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="mt-12">
                  <label className="mb-2 block text-affes-sm font-bold text-gray-800" htmlFor="password">
                    {formatAccountMessage({ id: 'new.password', defaultMessage: 'New Password' })}
                    <span className="ml-2 text-red-600">
                      {' '}
                      {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                    </span>
                  </label>
                  <div className="relative w-full rounded-sm border border-gray-600 focus:border focus:border-gray-900">
                    <input
                      value={newPassword}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      className="w-3/4 rounded-sm border-0 placeholder:text-gray-400"
                      required
                      onChange={handleResetPassword}
                    />

                    <div className="absolute right-3 top-2 flex">
                      <button
                        className="flex items-center text-affes-sm "
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <EyeOffIcon className="mr-2 h-4 w-4" /> : <EyeIcon className="mr-2 h-4 w-4" />}
                        {showPassword ? 'HIDE' : 'SHOW'}
                      </button>
                    </div>
                  </div>
                  {errorMessage && <p className="text-red-600">{errorMessage}</p>}
                  <label className="mb-2 mt-4 block text-affes-sm font-bold text-gray-800" htmlFor="password">
                    {formatAccountMessage({ id: 'retype.password', defaultMessage: 'Retype Password' })}
                    <span className="ml-2 text-red-600">
                      {' '}
                      {formatAccountMessage({ id: 'mandatory', defaultMessage: '*' })}
                    </span>
                  </label>
                  <div className=" relative rounded-sm border border-gray-600 focus:border focus:border-gray-900">
                    <input
                      type="text"
                      className=" w-full rounded-sm  border-0 placeholder:text-gray-400 focus:outline-none"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      value={confirmResetpass}
                      onChange={(e) => {
                        setConfirmResetpass(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </div>
                {errorMessage && <p className="text-red-600">{errorMessage}</p>}
                <div className=" pt-14">
                  <button className="w-full rounded-sm bg-accent-500 px-4 py-2 text-white" onClick={newPasswordForUser}>
                    Continue
                  </button>
                </div>
                <div className="pt-6 text-center">
                  {' '}
                  <button className="w-1/4  text-accent-400" onClick={toRetrieveUsername}>
                    Cancel
                  </button>
                </div>
              </div>
            </>
          )}

          {sentUsername && !retryPassword && (
            <div>
              {resetUsername ? (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.username', defaultMessage: 'retrieve Username' })}
                  </h3>
                </div>
              ) : (
                <div className=" mb-8 ">
                  <h3 className="absolute top-10 text-lg font-semibold text-black-900">
                    {formatAccountMessage({ id: 'Forgot.password', defaultMessage: 'Reset Password' })}
                  </h3>
                </div>
              )}
              <div>
                <div className="flex-col py-10 ">
                  <h3 className="text-md mb-4 font-semibold text-black-900">
                    {formatAccountMessage({
                      id: 'username.sent',
                      defaultMessage: 'Your username has been sent to your email address',
                    })}
                  </h3>
                  <p>
                    {' '}
                    {formatAccountMessage({
                      id: 'continue.account',
                      defaultMessage: 'Please select Continue to login to your account.',
                    })}
                  </p>
                </div>
              </div>
              <div>
                <button className="w-1/4 rounded-sm bg-accent-500 px-4 py-2 text-white" onClick={backToLogin}>
                  Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {informationRequired && (
        <InformationRequired informationRequired={informationRequired} closeModal={handleInformationRequired} />
      )}
    </div>
  );
};

export default Login;
