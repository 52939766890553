import React, { useEffect, useState } from 'react';
import BackToTopArrow from '../../../images/backToTop.png';

function BackToTop() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      {showButton && (
        <button
          onClick={scrollToTop}
          className="back-to-top fixed right-3 bottom-1 z-50 h-[50px] w-[50px] rounded-t border border-[#A9A9A9] bg-transparent opacity-[0.95] md:right-5 md:h-[60px] md:w-[60px]"
        >
          <p className=" text-center text-white">
            <img src={BackToTopArrow.src} alt="Back To Top" className="mx-auto mt-2 mb-1 w-[38px]" />
          </p>
          <p className=" text-[18px] text-blue-900">TOP</p>
        </button>
      )}
    </>
  );
}

export default BackToTop;
