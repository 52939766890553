import React, { useEffect, useState } from 'react';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import toast from 'react-hot-toast';
import { MdCheckCircleOutline, MdInfo } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { useCart } from 'frontastic/provider';

export interface Props {
  className?: string;
  cart: Cart;
  getAllCustomMessages?: any;
}

const DiscountForm: React.FC<Props> = ({ className, cart, getAllCustomMessages }) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { redeemDiscountCode, removeDiscountCode } = useCart();

  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [code, setCode] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [promoFeedback, setPromoFeedback] = useState('');

  const [availPromoCode, setAvailPromoCode] = useState(false);

  const errorMessage = getAllCustomMessages?.noCouponFound;

  const DisplayCoupenNotFoundMsg = () => {
    const queryParams = new URLSearchParams({
      messageKey: 'couponCannotApply',
      values: JSON.stringify([discounts?.[0]?.code]),
    });
    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return (
      <section className="mt-2 flex text-[#dd001c]">
        <MdInfo className="my-auto mr-1 text-xl " aria-hidden="true" />
        <p>{data}</p>
      </section>
    );
  };

  useEffect(() => {
    setDiscounts(cart?.discountCodes);
    if (cart?.discountCodes?.length) {
      console.log('sss', cart);
      setShow(true);
    } else if (cart?.couponRedeemError) {
      setPromoFeedback(errorMessage);
      setCode(cart?.couponRedeemError?.appliedCode);
      setIsValid(false);
      setShow(true);
    } else {
      setShow(false);
    }
  }, [cart?.discountCodes]);

  const codeChange = (e) => {
    setIsValid(true);
    setPromoFeedback('');
    setCode(e.target.value);
  };

  const onApplyDiscount = () => {
    setShowLoader(true);
    if (code.length === 0) {
      setShowLoader(false);
      setPromoFeedback(formatCartMessage({ id: 'emptyCode', defaultMessage: 'Please enter a coupon code.' }));
      setIsValid(false);
      return;
    }
    redeemDiscountCode(code)
      .catch((e: Error) => {
        if ((e.message = '101')) {
          setPromoFeedback(errorMessage);
          toast.error(errorMessage);
        }
      })
      .finally(() => {
        setCode('');
        setShowLoader(false);
      });
  };

  const handleRemove = () => {
    removeDiscountCode(discounts[0]);
  };
  useEffect(() => {
    if (cart?.discountCodes.length > 0) {
      if (cart?.discountCodes[0]?.state === 'MatchesCart') {
        setAvailPromoCode(true);
      } else {
        setAvailPromoCode(false);
      }
    }
  }, [cart?.discountCodes]);
  return (
    <>
      {!show && (
        <button
          type="button"
          className="py-3 text-sm text-blue-900 hover:underline "
          onClick={() => {
            setShow(!show);
          }}
        >
          {formatCartMessage({ id: 'order.promocode', defaultMessage: 'Have a Promo Code?' })}
        </button>
      )}

      {show && (
        <section className="w-full pb-3 ">
          {!(discounts.length > 0) && (
            <section>
              <p className="py-3 text-[12px] font-bold text-[#666666]">
                {formatCartMessage({ id: 'order.enter.promocode', defaultMessage: 'Enter Promotion Code' })}
              </p>
              <section className="flex text-black-500 ">
                <label htmlFor="promoCodeInput" className="sr-only">
                  Enter Promotion Code
                </label>
                <input
                  id="promoCodeInput"
                  aria-describedby="promoCodeError"
                  className={`w-3/4 rounded border py-2 px-3    ${
                    isValid ? 'border-gray-600 text-black-500 ' : 'border-2 border-red-500 text-red-500'
                  } `}
                  onChange={codeChange}
                />
                {!isValid && <RiErrorWarningLine className="my-auto -ml-8 text-2xl text-red-500" aria-hidden="true" />}
                <button
                  className="w-1/4 text-affes-sm font-bold text-[#043C63]"
                  onClick={onApplyDiscount}
                  aria-label="Apply Promotion Code"
                >
                  {formatCommonMessage({ id: 'apply', defaultMessage: 'Apply' })}
                </button>
              </section>
              <section>
                <p id="promoCodeError" role="alert" className="mt-[15px] text-sm text-red-900">
                  {promoFeedback}
                </p>
              </section>
            </section>
          )}

          {discounts.length > 0 && (
            <section>
              {availPromoCode ? (
                <section className="mt-2 flex text-[#268936]">
                  <MdCheckCircleOutline className="my-auto mr-1 text-xl " aria-hidden="true" />
                  {discounts[0].code}
                  {formatCartMessage({ id: 'codeAppliedSuccess', defaultMessage: ' Code Applied Successfully' })}.
                </section>
              ) : (
                cart && cart.discountCodes && cart.discountCodes.length > 0 && <DisplayCoupenNotFoundMsg />
              )}
              <button
                className="mt-2 text-[#043C63] "
                onClick={handleRemove}
                type="button"
                aria-label="Remove Promotion Code"
              >
                {formatCommonMessage({ id: 'remove', defaultMessage: 'Remove' })}
              </button>
            </section>
          )}
        </section>
      )}
      {showLoader && <Loader></Loader>}
    </>
  );
};

export default DiscountForm;
