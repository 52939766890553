import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

const NonPurchasableProductInfoMessage = () => {
  const [nonPurchasableMessage, setNonPurchasableMessage] = useState(null);

  const queryParams = new URLSearchParams({
    messageKey: 'nonPurchasableProductInfoMessage',
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  useEffect(() => {
    if (data) {
      setNonPurchasableMessage(data);
    }
  }, [data]);

  return (
    <div className="flex justify-center rounded border border-red-900 py-3">
      <p className="font-lato h-[19px] min-w-[100px] text-center text-base font-bold leading-5 text-[#DA0F0F]">
        {nonPurchasableMessage}
      </p>
    </div>
  );
};

export default NonPurchasableProductInfoMessage;
