import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="16" viewBox="0 0 10 16" fill="none">
    <path
      d="M7.98125 15.5L9.3125 14.1688L3.14375 8L9.3125 1.83125L7.98125 0.5L0.481249 8L7.98125 15.5Z"
      fill="fill-current"
    />
  </svg>
);

export default Icon;
