import { useAccount, useCart } from 'frontastic/provider';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useEffect } from 'react';

const HomeMonetateTastic: React.FC = () => {
  const { loggedIn } = useAccount();
  const { data, studioConfig } = useCart();
  useEffect(() => {
    const callMonetate = async (monetateData) => {
      const projectConfig = studioConfig;
      if (projectConfig?.monetateConstants) {
        const monetateConfig = JSON.parse(projectConfig.monetateConstants.replace(/'/g, '"'));
        await sendToMonetate(monetateData, monetateConfig);
      }
    };

    const processMonetate = async () => {
      if (data && studioConfig) {
        if (loggedIn) {
          await callMonetate({
            page: 'homepageAfterLogin',
            cart: data,
          });
        } else {
          await callMonetate({
            page: 'homePageWithoutLogin',
          });
        }
      }
    };

    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [data, loggedIn, studioConfig]);

  return null;
};

export default HomeMonetateTastic;
