import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import useSWR from 'swr';
import { fetchApiHub, revalidateOptions, useCart } from 'frontastic';

interface AgeRestrictedProps {
  className?: string;
}

const AgeRestricted: React.FC<AgeRestrictedProps> = (props) => {
  const { className } = props;
  const [getCustomMessagess, setGetCustomMessages] = useState(null);

  const queryParams = new URLSearchParams({
    messageKey: 'ageRestrictionReceiptMessage',
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);
  const { studioConfig: projectConfiguration } = useCart();
  const domainURL = projectConfiguration?.aafesDomain;

  return (
    <div className="mt-4">
      <Link href={`${domainURL}agerestrictions`}>
        <a
          className={`text-sm hover:underline ${className}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={getCustomMessagess}
          dangerouslySetInnerHTML={{ __html: getCustomMessagess }}
        ></a>
      </Link>
    </div>
  );
};

export default AgeRestricted;
