import React, { Fragment, useEffect, useState } from 'react';
import NextLink from 'next/link';
import Loader from 'components/commercetools-ui/loader/Loader';
import DoubleArrow from 'components/icons/double-arrow';
import { useFormat } from 'helpers/hooks/useFormat';
import { isCategory } from 'helpers/utils/findCategory';
type UBBreadcrumbProps = {
  Separator?: React.ReactNode;
  selectedFilters?: any;
  query?: string;
};
const Breadcrumb: React.FC<UBBreadcrumbProps> = ({ Separator = <DoubleArrow />, selectedFilters, query }) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const exchange = formatCommonMessage({ id: 'exchange', defaultMessage: 'Exchange' });
  const [categories, setCategories] = useState([]);
  const [activeItem, setActiveItem] = useState('');
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const PATH_NAME = window?.location?.pathname;
  const generateURL = (name, categories) => {
    const pageCategoriesURL = [];
    for (let i = 0; i < categories?.length; i++) {
      const category = categories[i];
      const refinementName = category?.refinements?.[0]?.name || '';
      if (refinementName === name) {
        pageCategoriesURL?.push(encodeURIComponent(refinementName));
        break;
      }
      pageCategoriesURL?.push(encodeURIComponent(refinementName));
    }
    const concatenatedCategories = pageCategoriesURL?.join(' > ');
    return `${PATH_NAME}?pageCategories=${concatenatedCategories}`;
  };
  useEffect(() => {
    if (selectedFilters) {
      const categoryFilters = selectedFilters?.filter((filter) => isCategory(filter?.name));
      const sortedcategoryFilters = categoryFilters?.sort((a, b) => {
        const nameA = a?.name?.toLowerCase();
        const nameB = b?.name?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      const categoriesFromFilters = sortedcategoryFilters?.map((filter) => ({
        name: filter?.refinements?.[0]?.name,
        url: generateURL(filter?.refinements?.[0]?.name, sortedcategoryFilters),
      }));
      setCategories(categoriesFromFilters);
      const lastCategory = categoriesFromFilters?.[categoriesFromFilters?.length - 1];
      setActiveItem(lastCategory ? lastCategory?.name : null);
    } else {
      setCategories([]);
      setActiveItem(null);
    }
    setIsLoad(false);
  }, [selectedFilters]);
  useEffect(() => {
    if (query && query !== '' && activeItem === null) {
      setActiveItem(formatCommonMessage({ id: 'searchResult', defaultMessage: 'Search Results' }));
    }
  }, [query, activeItem]);
  return (
    <>
      {(categories?.length > 0 || (query && query !== '')) && (
        <nav className="mb-5 flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-2 text-sm">
            <li>
              <div>
                <NextLink href="/">
                  <a className="text-blue-900" aria-label="Go to homepage" onClick={() => setIsLoad(true)}>
                    <span className="sr-only">{exchange}</span>
                    {exchange}
                  </a>
                </NextLink>
              </div>
            </li>
            {categories?.slice(0, -1)?.map((category, index) => {
              return (
                <Fragment key={index}>
                  <li className="mt-[2.5px]">{Separator}</li>
                  <li>
                    <NextLink href={category?.url}>
                      <a className="text-blue-900" aria-label="Go to homepage" onClick={() => setIsLoad(true)}>
                        <span className="sr-only">{category?.name}</span>
                        {category?.name}
                      </a>
                    </NextLink>
                  </li>
                </Fragment>
              );
            })}
            {activeItem && (
              <>
                <li className="mt-[2.5px]">{Separator}</li>
                <li className="leading-[17px] text-gray-900">{activeItem}</li>
              </>
            )}
          </ol>
        </nav>
      )}
      {isLoad && <Loader />}
    </>
  );
};
export default Breadcrumb;
