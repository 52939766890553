import React from 'react';

type Props = {
  className?: string;
};

const CalenderTimeTravler: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="20" height="18" viewBox="0 0 23 23" fill="none">
    <path
      d="M15.3333 1.91669V5.75002M7.66667 1.91669V5.75002M2.875 9.58335H20.125M4.79167 3.83335H18.2083C19.2669 3.83335 20.125 4.69147 20.125 5.75002V19.1667C20.125 20.2252 19.2669 21.0834 18.2083 21.0834H4.79167C3.73312 21.0834 2.875 20.2252 2.875 19.1667V5.75002C2.875 4.69147 3.73312 3.83335 4.79167 3.83335Z"
      stroke="#083558"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalenderTimeTravler;
