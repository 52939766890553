import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import cookieCutter from 'cookie-cutter';
import TemplateCMS from 'components/commercetools-ui/aafes/templateCms';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useCart, useProduct } from 'frontastic';
import YouMayLikeTastic from 'frontastic/tastics/aafes/you-may-like-tastic';
import Breadcrumb from './breadcrumb';
import CompareProducts from './compareSection';

function NoSearchResult({ query, correctedQuery }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isDesktop] = useMediaQuery(tablet);
  const { studioConfig } = useCart();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const { autoSuggestSearch } = useProduct();
  const [suggestedName, setSuggestedName] = useState('');
  const [relativeUrl, setRelativeUrl] = useState('');
  const ShopByCategoryAssetId = studioConfig?.noResultFoundShopByCategoryAssetId;
  const [compareProducts, setCompareProducts] = useState([]);

  useEffect(() => {
    setIsLoad(true);
    if (correctedQuery === '') {
      autoSuggestSearch(query)
        .then((res) => {
          const searchTerms = res?.suggestions || null;
          if (searchTerms && searchTerms?.length > 0 && query !== searchTerms?.[0]?.name) {
            setRelativeUrl(searchTerms?.[0]?.relativeUrl);
            setSuggestedName(searchTerms?.[0]?.name);
          } else {
            setSuggestedName('');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoad(false);
        });
    } else {
      setRelativeUrl(`/browse?query=${encodeURIComponent(correctedQuery)}`);
      setIsLoad(false);
    }
  }, [query, correctedQuery]);

  useEffect(() => {
    if (cookieCutter.get('compareProducts')) {
      const storedCompareProducts = JSON.parse(cookieCutter.get('compareProducts') || '[]');
      if (storedCompareProducts?.length > 0) {
        setCompareProducts(storedCompareProducts);
      }
    }
  }, []);

  return (
    <>
      {isDesktop && <Breadcrumb selectedFilters={null} query={query} />}
      <p className="font-lato mb-4 text-3xl font-bold leading-normal text-black-700">
        {formatMessage({ id: 'matchNotFound', defaultMessage: "We couldn't find a match for" })} &quot;{query}&quot;
      </p>
      {correctedQuery !== '' ? (
        <p className="font-lato text-lg font-normal leading-normal text-gray-900">
          {formatMessage({ id: 'didYouMean', defaultMessage: 'Did you mean: ' })}
          <span className="text-blue-900">
            <Link href={relativeUrl}>
              <a>{correctedQuery}</a>
            </Link>
          </span>
        </p>
      ) : (
        suggestedName && (
          <p className="font-lato text-lg font-normal leading-normal text-gray-900">
            {formatMessage({ id: 'didYouMean', defaultMessage: 'Did you mean: ' })}
            <span className="text-blue-900">
              <a href={relativeUrl}>{suggestedName}</a>
            </span>
          </p>
        )
      )}
      <div>{ShopByCategoryAssetId && <TemplateCMS cmsContent={{ assetID: ShopByCategoryAssetId }} />}</div>
      <YouMayLikeTastic data={{ shouldAutoScroll: false, autoScrollInterval: 2500 }} />
      <section>
        <div className="min-h-24 fixed bottom-0 left-0 z-[9] mb-0 w-full bg-[#F1F1F1F2] shadow-[0px_-3px_4px_0px_#00000040]">
          <CompareProducts compareProducts={compareProducts} setCompareProducts={setCompareProducts} />
        </div>
      </section>
      {isLoad && <Loader />}
    </>
  );
}

export default NoSearchResult;
