import React from 'react';
import { LineItem } from '@Types/cart/LineItem';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import { calculateMoneyStats } from 'helpers/utils/calculateMoneyStats';

const getDecimalDigits = (value) => {
  const stringValue = String(value);
  const decimalIndex = stringValue.indexOf('.');
  if (decimalIndex !== -1) {
    return stringValue.slice(0, decimalIndex + 3);
  }
  return stringValue;
};

function ItemPrice({ item, view }) {
  const { formatMessage } = useFormat({ name: 'cart' });

  const calculateLineItemDiscount = (item) => {
    const pricesToCheck = ['lineItemTotalMsrpPrice', 'lineItemTotalListPrice', 'totalPrice'];
    let highest = 0;
    for (const prop of pricesToCheck) {
      if (item?.[prop]?.centAmount) {
        highest = highest ? Math.max(highest, item?.[prop]?.centAmount) : item?.[prop]?.centAmount;
      }
    }
    const centAmountSaved = highest - item?.totalPrice?.centAmount;
    const percentileSaved = (centAmountSaved / highest) * 100;
    return {
      centAmount: highest - item?.totalPrice?.centAmount,
      currencyCode: item?.price?.currencyCode,
      fractionDigits: item?.price?.fractionDigits,
      percentile: Math.round(percentileSaved),
    };
  };
  const calculateShoppinglistLineItemDiscount = (item) => {
    const pricesToCheck = ['msrpPrice', 'listPrice', 'salePrice'];
    let highest = 0;
    for (const prop of pricesToCheck) {
      if (item?.[prop]?.centAmount) {
        highest = highest ? Math.max(highest, item?.[prop]?.centAmount) : item?.[prop]?.centAmount;
      }
    }
    const centAmountSaved = highest - item?.salePrice?.centAmount;
    const percentileSaved = (centAmountSaved / item?.msrpPrice?.centAmount) * 100;
    return {
      centAmount: highest - item?.salePrice?.centAmount,
      currencyCode: item?.salePrice?.currencyCode,
      fractionDigits: item?.salePrice?.fractionDigits,
      percentile: percentileSaved,
    };
  };

  const isShoppinglist = view === 'wishlist' || view === 'saveForLater';
  const isCartViewDesktop = view === 'cartDesktop';
  const isCartViewMobile = view === 'cartMobile';
  const isMiniCartView = view === 'miniCart';

  const discount = calculateLineItemDiscount(item);
  const wishListDiscount = calculateShoppinglistLineItemDiscount(item);
  const listPriceAndSalePriceAreSame =
    item?.listPrice && item?.salePrice ? item?.listPrice?.centAmount === item?.salePrice?.centAmount : true;
  const totalPriceCentAmount = item?.totalPrice?.centAmount;
  const lineItemTotalListPriceCentAmount = item?.lineItemTotalListPrice?.centAmount;
  const isPromoDiscountApplied =
    totalPriceCentAmount !== undefined && lineItemTotalListPriceCentAmount !== undefined
      ? totalPriceCentAmount !== lineItemTotalListPriceCentAmount
      : false;

  return (
    <>
      <section className="leading-[16.8px]">
        {(isCartViewDesktop || isCartViewMobile || isMiniCartView) && (
          <>
            {!item?.variant?.attributes?.isUseFacilityBasedPrice ? (
              <section>
                {item?.isMarketplaceItem ? (
                  <CustomLineItemPrices lineItem={item} view={view} />
                ) : item?.lineItemTotalMsrpPrice?.centAmount ||
                  item?.lineItemTotalListPrice?.centAmount ||
                  isPromoDiscountApplied ? (
                  <>
                    {(item?.lineItemTotalMsrpPrice || item?.lineItemTotalListPrice || isPromoDiscountApplied) && (
                      <section>
                        {item?.lineItemTotalMsrpPrice && (
                          <section
                            className={`mb-[6px] flex items-center font-medium ${
                              view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                            }`}
                          >
                            <p className="mr-1 text-affes-base line-through">
                              {formatMessage({ id: 'compareAt', defaultMessage: 'Compare at: ' })}
                            </p>{' '}
                            <Price price={item?.lineItemTotalMsrpPrice} className={`text-affes-base line-through`} />
                          </section>
                        )}
                      </section>
                    )}
                    <section
                      className={`mb-[6px] flex items-center ${
                        view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                      } font-medium`}
                    >
                      <Price
                        price={item?.lineItemTotalListPrice ?? item?.lineItemTotalPrice}
                        className="text-affes-base font-bold text-gray-900"
                      />
                    </section>
                    {(!listPriceAndSalePriceAreSame || isPromoDiscountApplied) && (
                      <section
                        className={`mb-[6px] flex items-center ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        } font-bold`}
                      >
                        <p className="mr-1 text-affes-base font-bold text-red-900">
                          {formatMessage({ id: 'sale', defaultMessage: 'Sale ' })}
                        </p>{' '}
                        {item?.lineItemTotalDiscountedPrice ? (
                          <Price
                            price={item?.lineItemTotalDiscountedPrice}
                            className={` text-affes-base text-red-900`}
                          />
                        ) : isPromoDiscountApplied ? (
                          <Price price={item?.totalPrice} className={` text-affes-base text-red-900`} />
                        ) : (
                          <Price price={item?.lineItemTotalPrice} className={` text-affes-base text-red-900`} />
                        )}
                      </section>
                    )}
                    {discount?.centAmount > 0 && (
                      <section
                        className={`mb-[6px] flex items-center ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        } font-normal`}
                      >
                        <p className="mr-1 text-affes-base">
                          {formatMessage({ id: 'youSave', defaultMessage: 'You Save:' })}
                        </p>{' '}
                        <Price price={discount} className={`text-affes-base`} />
                        {Boolean(discount?.percentile) && (
                          <span className="ml-1 text-affes-base">({getDecimalDigits(discount?.percentile)}%)</span>
                        )}
                      </section>
                    )}
                  </>
                ) : (
                  <>
                    {item?.isGiftLineItem ? (
                      <>
                        <Price
                          price={item?.lineItemTotalPrice}
                          className={`mt-1 text-affes-base ${
                            item?.isGiftLineItem ? 'line-through' : ''
                          } font-bold text-gray-900`}
                        />
                        <section
                          className={`mb-[6px] flex items-center ${
                            view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                          } font-bold`}
                        >
                          <p className="mr-1 mt-1 text-affes-base font-bold text-red-900">
                            {formatMessage({ id: 'sale', defaultMessage: 'Sale ' })}
                          </p>{' '}
                          <p className="mt-1 text-affes-base font-bold leading-[16.8px] text-red-900">{`$0.00`}</p>
                        </section>

                        <section
                          className={`mb-[6px] flex items-center ${
                            view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                          } font-normal`}
                        >
                          <p className="mr-1 text-affes-base">
                            {formatMessage({ id: 'youSave', defaultMessage: 'You Save:' })}
                          </p>{' '}
                          <Price price={item?.lineItemTotalPrice} className={`text-affes-base`} />
                          <span className="ml-1 text-affes-base">{`(100%)`}</span>
                        </section>
                      </>
                    ) : (
                      <Price
                        price={item?.totalPrice}
                        className={`mt-1 text-affes-base ${
                          item?.isGiftLineItem ? 'line-through' : ''
                        } font-bold text-gray-900`}
                      />
                    )}
                  </>
                )}
              </section>
            ) : (
              <>
                <section
                  className={`mb-[6px] flex items-center ${
                    view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                  } font-medium`}
                >
                  <Price
                    price={item?.lineItemTotalPrice ?? item?.lineItemTotalPrice}
                    className="text-affes-base font-bold text-gray-900"
                  />
                </section>
              </>
            )}
          </>
        )}
        {isShoppinglist && (
          <section>
            {!item?.storePrice ? (
              <>
                {item?.msrpPrice || item?.listPrice ? (
                  <>
                    {item?.msrpPrice && (
                      <section
                        className={`mb-[6px] flex items-center font-medium ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        <p className="mr-1 text-affes-base line-through">
                          {formatMessage({ id: 'compareAt', defaultMessage: 'Compare at: ' })}
                        </p>
                        <Price
                          price={item?.msrpPrice}
                          className={`text-affes-base line-through`}
                          priceView="shoppingList"
                        />
                      </section>
                    )}
                    <section
                      className={`mb-[6px] flex items-center ${
                        view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                      } font-medium`}
                    >
                      {item?.listPrice && (
                        <Price
                          price={item?.listPrice}
                          className="mr-1 text-affes-base font-bold text-gray-900"
                          priceView="shoppingList"
                        />
                      )}
                    </section>

                    {wishListDiscount?.centAmount > 0 && (
                      <section
                        className={`mb-[6px] flex items-center ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        } font-normal`}
                      >
                        <p className="mr-1 text-affes-base">
                          {formatMessage({ id: 'youSave', defaultMessage: 'You Save:' })}
                        </p>{' '}
                        <Price price={wishListDiscount} className={`text-affes-base`} priceView="shoppingList" />
                        {Boolean(wishListDiscount?.percentile) && (
                          <span className="ml-1 text-affes-base">
                            ({getDecimalDigits(wishListDiscount?.percentile)}%)
                          </span>
                        )}
                      </section>
                    )}

                    {!listPriceAndSalePriceAreSame && (
                      <section
                        className={`mb-[6px] flex items-center ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        } font-bold`}
                      >
                        <p className="mr-1 text-affes-base font-bold text-red-900">
                          {formatMessage({ id: 'sale', defaultMessage: 'Sale ' })}
                        </p>{' '}
                        <Price
                          price={item?.salePrice}
                          className={`text-affes-base text-red-900`}
                          priceView="shoppingList"
                        />
                      </section>
                    )}
                    {discount?.centAmount > 0 && (
                      <section
                        className={`mb-[6px] flex items-center ${
                          view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                        } font-normal`}
                      >
                        <p className="mr-1 text-affes-base">
                          {formatMessage({ id: 'youSave', defaultMessage: 'You Save:' })}
                        </p>{' '}
                        <Price price={wishListDiscount} className={`text-affes-base`} priceView="shoppingList" />
                        {Boolean(wishListDiscount?.percentile) && (
                          <span className="ml-1 text-affes-base">
                            ({getDecimalDigits(wishListDiscount?.percentile)}%)
                          </span>
                        )}
                      </section>
                    )}
                  </>
                ) : (
                  <>
                    <Price
                      price={item?.salePrice}
                      className={`mt-1 text-affes-base ${
                        item?.isGiftLineItem ? 'line-through' : ''
                      } font-bold text-gray-900`}
                      priceView="shoppingList"
                    />
                    {item?.isGiftLineItem && (
                      <p className="mt-1 text-affes-base font-bold leading-[16.8px] text-red-900">$0.00</p>
                    )}
                  </>
                )}
              </>
            ) : (
              <section
                className={`mb-[6px] flex items-center ${
                  view === 'cartDesktop' ? 'justify-end' : 'justify-start'
                } font-medium`}
              >
                {item?.storePrice && (
                  <Price
                    price={item?.extPrice}
                    className={`text-affes-base font-bold text-gray-900`}
                    priceView="shoppingList"
                  />
                )}
              </section>
            )}
          </section>
        )}
      </section>
    </>
  );
}

export default ItemPrice;

const CustomLineItemPrices = ({ lineItem, view }: { lineItem: LineItem; view: string }) => {
  const { formatMessage } = useFormat({ name: 'cart' });
  const isPromoApplied = lineItem?.lineItemTotalPrice?.centAmount !== lineItem?.totalPrice?.centAmount;
  const CustomLineItemYourSave = () => {
    const priceStats = calculateMoneyStats([lineItem?.lineItemTotalPrice, lineItem?.totalPrice]);
    return (
      <section
        className={`mb-[6px] flex items-center ${view === 'cartDesktop' ? 'justify-end' : 'justify-start'} font-normal`}
      >
        <p className="mr-1 text-affes-base">{formatMessage({ id: 'youSave', defaultMessage: 'You Save:' })}</p>{' '}
        <Price price={priceStats?.difference} className={`text-affes-base`} />
        {Boolean(priceStats?.percentageDifference) && (
          <span className="ml-1 text-affes-base">({getDecimalDigits(priceStats?.percentageDifference)}%)</span>
        )}
      </section>
    );
  };
  return (
    <>
      <section
        className={`mb-[6px] flex items-center ${view === 'cartDesktop' ? 'justify-end' : 'justify-start'} font-medium`}
      >
        <Price
          price={lineItem?.lineItemTotalPrice ?? lineItem?.totalPrice}
          className="mr-1 text-affes-base font-bold text-gray-900"
        />
      </section>
      {isPromoApplied && (
        <>
          <section
            className={`mb-[6px] flex items-center ${
              view === 'cartDesktop' ? 'justify-end' : 'justify-start'
            } font-bold`}
          >
            <p className="mr-1 text-affes-base font-bold text-red-900">
              {formatMessage({ id: 'sale', defaultMessage: 'Sale ' })}
            </p>{' '}
            <Price price={lineItem?.totalPrice} className={` text-affes-base text-red-900`} />
          </section>
          <CustomLineItemYourSave />
        </>
      )}
    </>
  );
};
