import React, { useCallback, useEffect, useRef, useState } from 'react';
import Icon from 'components/icons/infoIcon';
import { PDP_PROMOTIONS_CONFIG } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
export default function ApplicablePromotions({ variantPromotion = [], studioConfig, isMarketplaceProduct }) {
  const navigateToSpecialOffers = useCallback(() => {
    const specialOffersElmDesktop = document.querySelector('a[href="#specialOffers"]');
    const specialOffersElmMob = document.querySelector('section[id="specialOffers"] button');
    const specialOffersToggleButton = document.querySelector<HTMLButtonElement>('button.SpecialOffersBtn');
    const isSpecialOffersOpen = specialOffersToggleButton?.getAttribute('aria-expanded') === 'true';
    if (specialOffersElmDesktop instanceof HTMLAnchorElement) {
      specialOffersElmDesktop.click();
    } else if (specialOffersElmMob instanceof HTMLButtonElement) {
      if (!isSpecialOffersOpen) {
        specialOffersElmMob.scrollIntoView({ behavior: 'smooth' });
        specialOffersToggleButton.click();
      } else {
        specialOffersElmMob.scrollIntoView();
      }
    }
  }, []);

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [showAllPromotions, setShowAllPromotions] = useState(false);
  const [popupVisible, setPopupVisible] = useState({});
  const popupRefs = useRef({});
  const buttonRefs = useRef({});
  const handleClickOutside = (event) => {
    const keys = Object.keys(popupRefs.current);
    keys.forEach((key) => {
      if (
        popupRefs.current[key] &&
        !popupRefs.current[key].contains(event.target) &&
        !buttonRefs.current[key].contains(event.target)
      ) {
        setPopupVisible((prev) => ({ ...prev, [key]: false }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const togglePopup = (key) => {
    setPopupVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const displayedPromotions = showAllPromotions
    ? variantPromotion
    : variantPromotion.slice(0, PDP_PROMOTIONS_CONFIG?.maxPromotionsToShow);

  return (
    <>
      {displayedPromotions.map((item) => (
        <div
          className="mb-2 flex cursor-pointer gap-x-[13.09px]"
          key={`key_${item?.name}`}
          onClick={navigateToSpecialOffers}
        >
          <img className="h-[24px] w-[26.91px]" src={item?.imageUrl} alt={item?.name} />
          <div className="font-lato h-[30px] text-sm font-normal leading-[30px] text-[#333333]">{item?.name}</div>
          {isMarketplaceProduct && (
            <section className=" text-sm font-normal text-gray-900">
              <div className="flex">
                <div className="details-popover hidden" id="set"></div>
                <div className="group relative pt-2">
                  <button
                    className="text-primary text-lg"
                    ref={(el) => (buttonRefs.current[item?.name] = el)}
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePopup(item?.name);
                    }}
                    onMouseEnter={(event) => {
                      const popupScroll = event?.currentTarget?.nextElementSibling?.querySelector('.popup-scroll');
                      if (popupScroll && popupScroll?.scrollTop !== 0) {
                        popupScroll.scrollTop = 0;
                      }
                    }}
                  >
                    <Icon className="cursor-pointer" />
                  </button>
                  <div
                    ref={(el) => (popupRefs.current[item?.name] = el)}
                    className={`absolute -right-1/2 top-[40px] z-10 translate-x-1 rotate-0 ${
                      popupVisible[item?.name] ? '' : 'hidden'
                    }`}
                  >
                    <div className="relative rounded-md bg-white">
                      <div className="text-black boxArrow absolute -right-[8rem] w-60 rounded-md border border-solid bg-white text-sm opacity-100 transition-all duration-300 lg:-right-[10rem]">
                        <div className="relative z-[1] flex rounded-t-md border-b border-solid bg-[#EDEDED] p-2 text-base font-bold after:absolute after:-top-[1px] after:left-[80px] after:inline-block after:h-[1px] after:w-[20px] after:bg-white after:content-[''] sm:after:right-[8px] lg:after:left-[50px]">
                          {formatProductMessage({
                            id: 'PromotionDetails',
                            defaultMessage: 'Promotion Details',
                          })}
                        </div>
                        <div className="pb-4">
                          <div className="popup-scroll aafes-orderList h-60 overflow-y-auto px-4 py-2">
                            <div className="mb-10 flex lg:mb-0">
                              <div>{item?.description}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute right-[5px] -top-[16px] z-0 h-4 w-4 -translate-x-1/2 translate-y-1/2 rotate-[225deg] bg-white drop-shadow-md md:-left-[20px] md:right-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      ))}

      {!isMarketplaceProduct && variantPromotion.length > PDP_PROMOTIONS_CONFIG?.maxPromotionsToShow && (
        <button
          onClick={navigateToSpecialOffers}
          className="mb-3 cursor-pointer text-sm text-[#043C63]"
        >{`${PDP_PROMOTIONS_CONFIG?.showMoreText}`}</button>
      )}

      {isMarketplaceProduct &&
        !showAllPromotions &&
        variantPromotion.length > PDP_PROMOTIONS_CONFIG?.maxPromotionsToShow && (
          <button
            onClick={() => setShowAllPromotions(true)}
            className="mb-3 cursor-pointer text-sm text-[#043C63]"
          >{`${PDP_PROMOTIONS_CONFIG?.showMoreText}`}</button>
        )}
    </>
  );
}
