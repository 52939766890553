import React, { useEffect, useRef, useState } from 'react';
import { useFormat } from '../../../../helpers/hooks/useFormat';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

function Index({ slot }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const adRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!window.adsbygoogle) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  useEffect(() => {
    adRef.current.innerHTML = `
        <ins
          class="adsbygoogle"
          style="display:inline-block;width:728px;height:90px"
          data-ad-client="ca-pub-1129357587154206"
          data-ad-slot="${slot}"
          key=${Math.random()}
        ></ins>`;
    if (window.adsbygoogle) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = '';
      }
    };
  }, [slot]);
  return (
    <section>
      <div ref={adRef} className="aafes-google-adsense w-full md:mx-auto md:w-[728px]" style={{ height: '90px' }}></div>
      <div
        className="w-full md:mx-auto md:w-[728px]"
        style={{ fontSize: '0.5em', color: '#555555', textAlign: 'left' }}
      >
        {formatMessage({
          id: 'adSenseDisclaimer',
          defaultMessage:
            'No official Department of Defense endorsement implied by use of external links or commercial advertising.',
        })}
      </div>
    </section>
  );
}

export default Index;
