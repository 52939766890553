export const Title: React.FC<{ title: string }> = ({ title }) => (
  <p className="my-5 max-h-[22px] text-base font-bold leading-[22px] text-[#333333] lg:text-lg">{title}</p>
);

export const Body: React.FC<{ body: string }> = ({ body }) => (
  <p className="aafes-orderList py-2 text-sm leading-5 text-[#333333]" dangerouslySetInnerHTML={{ __html: body }}></p>
);

export const SubTitle: React.FC<{ subtitle: string }> = ({ subtitle }) => (
  <p className="pb-4 text-sm font-bold leading-5 text-[#333333]">{subtitle}</p>
);
