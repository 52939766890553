import { Money } from '@Types/product/Money';
import Typography from 'components/commercetools-ui/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from '../../../helpers/hooks/useFormat';

export interface PriceProps {
  price: Money;
  className?: string;
  type?: string;
  discount?: boolean;
  priceView?: string;
}

const Price: React.FC<PriceProps> = ({ price, className, type, discount, priceView }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  if (!price) {
    return (
      <div
        className={`${priceView === 'shoppingList' || 'storeList' ? 'text-gray-900' : 'text-red-500'}${
          priceView === 'storeList' ? 'text-aafes-sm font-bold' : ''
        }`}
      >
        {' '}
        {formatMessage({
          id: 'priceUnavailable',
          defaultMessage: 'Online price temporarily unavailable. Contact store for price.',
        })}
      </div>
    );
  }

  const { currencyCode = 'USD', ...restPrice } = price;

  return (
    <div className={className || 'mt-1 text-sm font-medium text-gray-900 dark:text-light-100'}>
      <Typography>{type}</Typography>
      <span className={discount ? 'line-through' : ''}>
        <Typography>{CurrencyHelpers.formatForCurrency(restPrice)}</Typography>
      </span>
    </div>
  );
};

export default Price;
