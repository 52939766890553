import React from 'react';

type Props = {
  props?: any;
  className?: string;
  fill?: string;
  onClick?: () => void;
};

const Icon: React.FC<Props> = ({ props, className, fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: 'new -932 485 25.4 76.5',
    }}
    viewBox="-932 485 25.4 76.5"
    height={30}
    {...props}
  >
    <path
      d="M-906.6 523.4V522.8c0-.3-.1-.5-.3-.8l-20.8-35.8c-.6-1.1-2-1.5-3.1-.9s-1.5 2.1-.9 3.2l20.1 34.8-20.1 34.7c-.6 1.1-.3 2.5.8 3.2 1.1.6 2.5.3 3.2-.8l20.7-35.9c.1-.2.2-.5.3-.8.1-.1.1-.2.1-.3z"
      style={{
        fill: fill,
      }}
    />
  </svg>
);

export default Icon;
