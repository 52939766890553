import { useEffect, useRef, FC } from 'react';
import Script from 'next/script';
interface SyndigoPowerPageProps {
  productId?: string;
  config?: any;
}

const SyndigoPowerPage: FC<SyndigoPowerPageProps> = ({ productId, config }) => {
  const { script, clientId } = config;
  const ref = useRef(null);

  useEffect(() => {
    const powerPageElement = document.createElement('syndigo-powerpage');
    powerPageElement.setAttribute('pageid', productId);
    if (ref.current) {
      ref.current.appendChild(powerPageElement);
    }
    return () => {
      if (ref.current) {
        ref.current.removeChild(powerPageElement);
      }
    };
  }, [productId]);

  const currentDate = Math.floor(Date.now() / 86400000);
  const scriptSrc = `${script}/${clientId}/tag.js?cv=${currentDate}`;

  return (
    <div ref={ref}>
      <Script src={scriptSrc} type="text/javascript" async />
    </div>
  );
};

export default SyndigoPowerPage;
