import React, { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import RetrunInfo from './ReturnInfo';
import { Body, Title, SubTitle } from './Typography';

export default function ShippingInfo({ variantAttributes }) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { isPOBoxRestricted, isAPOFPOAvailable, nonShippableZips, shippingDescription, shippableZips } =
    variantAttributes;
  const [shippingDescriptionlink, setShippingDescriptionLink] = useState(shippingDescription);
  const [isDesktopSize] = useMediaQuery(tablet);
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(shippingDescription, 'text/html');
    const linkElements = doc.querySelectorAll('a, link, Hyperlink');
    if (linkElements) {
      linkElements.forEach((linkElement) => {
        linkElement.classList.add('text-[#043C63]', 'hover:underline');
      });
    }
    setShippingDescriptionLink(doc.body.innerHTML);
  }, [shippingDescription]);

  return (
    <>
      {isDesktopSize ? (
        <>
          <section id="shipping" className="text-gray-900">
            <Title title={formatProductMessage({ id: 'shipping', defaultMessage: 'Shipping' })} />
            {isPOBoxRestricted && (
              <SubTitle
                subtitle={formatProductMessage({
                  id: 'shipping.poBoxRestricted',
                  defaultMessage: 'Delivery not available to P.O. boxes',
                })}
              />
            )}
            {!isAPOFPOAvailable && (
              <SubTitle
                subtitle={formatProductMessage({
                  id: 'shipping.apoFPOAvailable',
                  defaultMessage:
                    'Delivery is not available to APO & FPO addresses, but overseas customers may be able to pick up this item from eligible stores',
                })}
              />
            )}
            {shippableZips && (
              <div className="pb-4">
                <SubTitle
                  subtitle={formatProductMessage({
                    id: 'shipping.Shippablezips',
                    defaultMessage: 'shippable zip codes',
                  })}
                />
                <div className="nonShippablezips-grid text-sm">
                  {shippableZips?.split(',')?.map((zip: any, index: number) => (
                    <Body body={zip} key={index} />
                  ))}
                </div>
              </div>
            )}
            {nonShippableZips && (
              <div>
                <SubTitle
                  subtitle={formatProductMessage({
                    id: 'shipping.nonShippablezips',
                    defaultMessage: 'Non-shippable zip codes',
                  })}
                />
                <div className="nonShippablezips-grid text-sm">
                  {nonShippableZips?.split(',')?.map((zip: any, index: number) => (
                    <Body body={zip} key={index} />
                  ))}
                </div>
              </div>
            )}
            {shippingDescription && <Body body={shippingDescriptionlink} />}

            <RetrunInfo />
          </section>
        </>
      ) : (
        <Disclosure key="shipping">
          {({ open }) => (
            <>
              <section className="text-gray-900">
                <Disclosure.Button className="group relative flex w-full items-center justify-between text-left">
                  <Title title={formatProductMessage({ id: 'shipping.mView', defaultMessage: 'Shipping/Returns' })} />
                  <span className="ml-6 flex items-center">
                    <>
                      {open ? (
                        <ChevronUpIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                    </>
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel>
                  {isPOBoxRestricted && (
                    <SubTitle
                      subtitle={formatProductMessage({
                        id: 'shipping.poBoxRestricted',
                        defaultMessage: 'Delivery not available to P.O. boxes',
                      })}
                    />
                  )}
                  {!isAPOFPOAvailable && (
                    <SubTitle
                      subtitle={formatProductMessage({
                        id: 'shipping.apoFPOAvailable',
                        defaultMessage:
                          'Delivery is not available to APO & FPO addresses, but overseas customers may be able to pick up this item from eligible stores',
                      })}
                    />
                  )}
                  {shippableZips && (
                    <div>
                      <SubTitle
                        subtitle={formatProductMessage({
                          id: 'shipping.Shippablezips',
                          defaultMessage: 'shippable zip codes',
                        })}
                      />
                      <div className="nonShippablezips-grid text-sm">
                        {shippableZips?.split(',')?.map((zip: any, index: number) => (
                          <Body body={zip} key={index} />
                        ))}
                      </div>
                    </div>
                  )}
                  {nonShippableZips && (
                    <div>
                      <SubTitle
                        subtitle={formatProductMessage({
                          id: 'shipping.nonShippablezips',
                          defaultMessage: 'Non-shippable zip codes',
                        })}
                      />
                      <div className="nonShippablezips-grid-column text-sm">
                        {nonShippableZips?.split(',')?.map((zip: any, index: number) => (
                          <Body body={zip} key={index} />
                        ))}
                      </div>
                    </div>
                  )}
                  {shippingDescription && <Body body={shippingDescriptionlink} />}
                  <RetrunInfo />
                </Disclosure.Panel>
              </section>
            </>
          )}
        </Disclosure>
      )}
      {!isDesktopSize && <hr className="border border-gray-300" />}
    </>
  );
}
