import React from 'react';
import { MARKETING_BUG_NAMES } from 'helpers/constants/aafes';
import RatingStars from './RatingStars';

type ProductHeaderProps = {
  name: string;
  sku: string;
  productId: string;
  badge?: string;
  averageRating?: number;
  totalReviews?: number;
  isGuestPdp?: any;
};

const ProductHeader = ({ name, productId, sku, badge, isGuestPdp }: ProductHeaderProps) => {
  return (
    <div className="my-5 flex flex-col items-start gap-3 md:flex-row">
      {!isGuestPdp && badge && (
        <div className={`item-tag ${badge} rounded-[3px] px-2 text-xs font-bold leading-5 text-white md:mt-1`}>
          {MARKETING_BUG_NAMES?.[badge]}
        </div>
      )}
      <div className="flex flex-col gap-[10px] md:gap-[2px]">
        <div className="text-[21px] font-normal leading-[30px] text-gray-900 md:text-[24px]">{name}</div>
        {!isGuestPdp && (
          <div className="flex items-center gap-[10px]">
            <div className="text-sm leading-[30px] text-gray-800">Item: #{sku}</div>
            <div className="h-[18px] border-[1px] border-[#00000020]" />
            <div className="mt-1">
              <RatingStars productId={productId} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductHeader;
