import React from 'react';
import Link from 'next/link';
const BrandsList = ({ brands }) => {
  const groupedBrands = brands?.reduce((acc: any, brand: any) => {
    const firstLetter = brand?.name?.[0]?.toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [brand];
    } else {
      acc[firstLetter].push(brand);
    }
    return acc;
  }, {});

  return (
    <div className="border-t-2 border-gray-300 lg:ml-4">
      {Object.entries(groupedBrands)?.map(([letter, brands = []]: any) => (
        <div key={letter} className="border-b-2 border-gray-300 py-6" id={`brand_section_${letter}`}>
          <h3 className="mb-6 text-lg font-bold">{letter}</h3>
          <ul className="grid grid-cols-1 items-end gap-3 md:grid-cols-3 lg:grid-cols-4">
            {brands?.map((brand: any) => (
              <li
                className="mb-2 break-words font-sans text-sm text-gray-900 hover:underline"
                key={`${brand?.name}_key`}
              >
                <Link href={brand?.url}>{brand?.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default BrandsList;
