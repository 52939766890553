export const profileLogo = (projectConfiguration, account) => {
  const userProfile = {};
  const userType = projectConfiguration?.userTypeLogoMap;
  if (userType) {
    Array.from(JSON.parse(projectConfiguration?.userTypeLogoMap))?.forEach(
      (obj) => (userProfile[Object.keys(obj)[0]] = Object.values(obj)[0]),
    );
  }
  return userProfile?.[account?.branchOfService] ?? userProfile['1'];
};
