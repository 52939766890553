import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function SecureCheckout({ closeModal, secureCheckoutModal }) {
  return (
    <>
      <Transition appear show={secureCheckoutModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 lg:mt-3 xl:w-[1300px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                          Secure Checkout Guaranteed
                        </h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>
                      <div className="space-y-4 overflow-y-auto py-6">
                        <p>
                          WE GUARANTEE YOUR SECURITY ON OUR WEBSITE: This site is using secure technology to safeguard
                          the use of your credit card information online. We&apos;re so confident about our safety
                          measures that we&apos;re offering you our Exchange Online Safety Guarantee. Here&apos;s our
                          Exchange Online Safety Guarantee:{' '}
                          <strong>
                            WE WILL REIMBURSE YOU FOR THE AMOUNT OF ANY FRAUDULENT CHARGES NOT COVERED BY YOUR CREDIT
                            CARD ISSUER IF FRAUDULENT CHARGES ARE MADE TO YOUR CREDIT CARD BECAUSE OF CREDIT CARD
                            INFORMATION STOLEN WHILE YOU SHOP IN THE EXCHANGE ONLINE STORE
                          </strong>
                          .
                        </p>
                        <p>
                          We can offer this Exchange Online Safety Guarantee, because we&apos;re certain about our site
                          security. We know that your credit card information is secure because it is encrypted. The use
                          of Secure Sockets Layer (SSL) encryption on our website is the best global secure transaction
                          technology available today. You should make sure your browser is capable of SSL in order to
                          take advantage of the benefits of SSL secure technology while shopping at The Exchange Online
                          Store. Doing so ensures that all sensitive information like Credit Card Information and Social
                          Security numbers are encrypted before being sent over the internet.
                        </p>
                        <p>
                          We&apos;re dedicated to maintaining security for your Credit Card information and we believe
                          our Exchange Online Safety Guarantee will ease your concerns about using your credit card
                          while shopping in The Exchange Online Store. If you still feel uncomfortable transmitting your
                          credit card information over the internet, you may also place your order at one of the numbers
                          listed below. Our Exchange Online Store Entry Associates will be glad to assist you in
                          completing your order.
                        </p>
                        <section className="font-bold text-[#003A6A]">
                          Customers in the 50 United States and Puerto Rico:
                        </section>

                        <ul className="grid grid-cols-1 leading-5 lg:grid-cols-3">
                          <li>Toll Free Phone 1-800-527-2345</li>
                          <li>Fax 1-800-446-0163</li>
                          <li>Toll Free TDD 1-800-423-2011</li>
                        </ul>
                        <section className="font-bold text-[#003A6A]">
                          TOLL FREE telephone numbers for Customers outside the United States & Puerto Rico:
                        </section>
                        <ul className="grid grid-cols-1 leading-8 lg:grid-cols-3 ">
                          <li>Germany 0800-82-16500</li>
                          <li>Japan/Okinawa 0031-11-4132</li>
                          <li>Korea 00308-13-0664</li>
                          <li>Italy 8008-71227</li>
                          <li>Guam 01800-636-3297</li>
                          <li>Belgium 0800-7-2432</li>
                          <li>Netherlands 0800-022-7129</li>
                          <li>United Kingdom 0800-96-8101</li>
                          <li>Spain 900-971-391</li>
                          <li>
                            <span className="text-red-600">*</span>Turkey 00800-18-488-6312
                          </li>
                        </ul>
                        <p>
                          <span className="text-red-600">*</span>International calls cannot be placed from phones on
                          base. Use off-base commercial lines. Sorry we cannot accept collect calls.
                        </p>

                        <section className="font-bold text-[#003A6A]">Customers outside the United States:</section>
                        <ul className="mt-1">
                          <li>Phone 1-214-583-5000</li>
                        </ul>
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default SecureCheckout;
