import React from 'react';
import Link from 'next/link';
import { HiChevronDoubleRight } from 'react-icons/hi';
interface pagesObject {
  name: string;
  href: string;
}
export interface BreadcrumbProps {
  pageName: string;
  pageLink: pagesObject;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { pageName, pageLink } = props;
  const pages = {
    accountPage: [
      { name: 'Exchange', href: '/' },
      { name: 'My Account', href: '/account#' },
    ],
    orderDetail: [
      { name: 'Exchange', href: '/' },
      { name: 'My Account', href: '/account#' },
      { name: 'Order History', href: '/account#orders' },
    ],
    cartPage: [{ name: 'Home', href: '/' }],
    createAccount: [{ name: 'Home', href: '/' }],
    sweepStake: [
      { name: 'Home', href: '/' },
      { name: 'Sweepstakes', href: '/sweepstakes' },
    ],
  };
  const breadCrumbArray = [];

  for (let i = 0; i < Object.keys(pages).length; i++) {
    const key = Object.keys(pages)[i];
    if (key === pageName) {
      for (let i = 0; i < pages[key].length; i++) {
        const element = pages[key][i];
        breadCrumbArray.push(element);
      }
      breadCrumbArray.push(pageLink);
    }
  }
  return (
    <section className="hidden lg:block">
      <ul className="flex items-center">
        {breadCrumbArray &&
          breadCrumbArray.map((currentpage, index) => {
            if (
              index === breadCrumbArray.length - 2 &&
              breadCrumbArray[index + 1].name === '' &&
              breadCrumbArray[index + 1].href === ''
            ) {
              return (
                <li key={index} className="text-[14px] font-normal leading-[16.8px] text-[#333333]">
                  <Link href={currentpage.href}>
                    <a>{currentpage.name}</a>
                  </Link>
                </li>
              );
            } else {
              if (index === breadCrumbArray.length - 1) {
                return (
                  <li key={index} className="text-[14px] font-normal leading-[16.8px] text-[#333333]">
                    <Link href={currentpage.href}>
                      <a>{currentpage.name}</a>
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <Link href={currentpage.href}>
                      <a>
                        <div>
                          <li className="flex items-center text-[14px] font-normal leading-[16.8px] text-[#043C63]">
                            {currentpage.name}
                            <HiChevronDoubleRight className="mx-2 inline h-6" />
                          </li>
                        </div>
                      </a>
                    </Link>
                  </li>
                );
              }
            }
          })}
      </ul>
    </section>
  );
};

export default Breadcrumb;
