export const accordionMockItems = [];

export const billingAddress = {};

export const shippingAddress = {};

export const variant = {};

export const lineItemsOrderHistory = [];

const lineItems = [];
export const wishlist = {
  wishlistId: '0',
  anonymousId: 'an',
  name: 'Wishlist Items',
  accountId: 'Acc-12-43',
  wishlistVersion: 'v 1.0',
  lineItems: lineItems,
};

export const accountToken = {};

export const addresses = [];
export const groups = [];
export const account = {};
export const shippingMethods = [];
export const cart = {};
export const orders = [];

export const frontasticImage = {};
export const productItem = { variants: [] };

export const headerNavigation = {
  categories: [],
};

export const headerLogo = { media: 'https://cpmr-islands.org/wp-content/uploads/sites/4/2019/07/test.png' };

export const headerButtonLink = {};

export const headerAccountLink = {};

export const headerLinks = [];

export const footerColumns = [];

export const footerCopyrightLinks = [];
export const products = {};
