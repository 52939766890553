import React, { Fragment, useState } from 'react';
import Link from 'next/link';
import { Dialog, Transition } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import { useFormat } from 'helpers/hooks/useFormat';
import { validateEmail } from 'helpers/utils/fieldValidators';
import { useAccount, useCart } from 'frontastic';
import Price from '../price';

function GiftDetails({ showGiftModal, closeModal, item }) {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage } = useFormat({ name: 'cart' });
  const calculateLineItemDiscount = (item) => {
    return {
      centAmount: item.count * item.price.centAmount - item.totalPrice.centAmount,
      currencyCode: item.price.currencyCode,
      fractionDigits: item.price.fractionDigits,
    };
  };

  const [editGiftEmail, setEditGiftEmail] = useState(item.giftEmailAddress);
  const [editGiftMsg, setEditGiftMsg] = useState(item.giftEmailMessage);
  const [emailError, setEmailError] = useState('');
  const count = 100;
  const { loggedIn } = useAccount();
  const [typeCount, setTypeCount] = useState(0);
  const { markAsGift } = useCart();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const payload = {
        lineItem: {
          id: item.lineItemId,
          isGift: true,
          giftEmailAddress: editGiftEmail,
          giftEmailMessage: editGiftMsg,
        },
        isGuestUser: !loggedIn,
      };
      markAsGift(payload)
        .then(() => {
          closeModal(false);
        })
        .catch((error) => {
          closeModal(false);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!editGiftEmail) {
      setEmailError('Email is required');
      isValid = false;
    } else if (!validateEmail(editGiftEmail)) {
      setEmailError('Invalid email format');
      isValid = false;
    } else {
      setEmailError('');
    }

    return isValid;
  };

  function handleEmailChange(event) {
    setEditGiftEmail(event.target.value);
  }

  function handleMessageChange(event) {
    setEditGiftMsg(event.target.value);
    setTypeCount(event.target.value.length);
  }

  return (
    <>
      <Transition appear show={showGiftModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={closeModal}
                  role="dialog"
                  aria-labelledby="giftDetailsTitle"
                  aria-modal="true"
                >
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[700px] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                    role="document"
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      <section className="modalHeader relative mb-3">
                        <button
                          aria-label="Close Modal"
                          type="button"
                          className="absolute right-0 top-0"
                          onClick={() => closeModal(false)}
                        >
                          <GrClose />
                        </button>
                        <h3 id="giftDetailsTitle" className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                          {' '}
                          {formatCartMessage({ id: 'giftDetails', defaultMessage: 'Gift Details' })}
                        </h3>
                      </section>
                      <section className="mb-5 flex justify-between">
                        <section className="flex">
                          <section className="productImage mr-3">
                            <section className="mx-auto mb-3 w-[90px]">
                              <Link href={item?._url}>
                                <p className="cursor-pointer">
                                  <img src={item?.variant?.images[0]} alt="Gift Item Image" />
                                </p>
                              </Link>
                              <p>{item?.cartId}</p>
                            </section>
                          </section>
                          <section className="itemDetails">
                            <h2 className="mb-2 text-sm">
                              <Link href={item?._url}>
                                <p className="cursor-pointer font-semibold text-blue-900">
                                  {item?.variant?.attributes?.isPreOrderable &&
                                    `${formatMessage({ id: 'preorder', defaultMessage: 'Preorder' })}`}{' '}
                                  {item?.name}
                                </p>
                              </Link>
                            </h2>
                            <p className=" mb-3 text-sm font-medium">
                              {formatMessage({ id: 'item', defaultMessage: 'Item #:' })} {item?.variant?.sku}
                            </p>
                            <section className="">
                              {item?.discountedPrice?.centAmount ? (
                                <>
                                  <section className="mb-[6px] flex items-center justify-start font-medium">
                                    <p className="mr-1 text-affes-base line-through">
                                      {' '}
                                      {formatMessage({ id: 'compareAt', defaultMessage: 'Compare at: ' })}
                                    </p>{' '}
                                    <Price price={item?.price} className={`text-affes-base line-through`} />
                                  </section>
                                  <section className="mb-[6px] flex items-center justify-end font-medium">
                                    {item?.listPrice && (
                                      <Price
                                        price={item?.listPrice}
                                        className="mr-1 text-affes-base font-bold text-gray-900"
                                      />
                                    )}
                                  </section>
                                  <section className="mb-[6px] flex items-center justify-end font-bold text-red-900">
                                    <p className="mr-1 text-affes-base text-red-900">
                                      {formatMessage({ id: 'sale', defaultMessage: 'Sale ' })}
                                    </p>{' '}
                                    <Price price={item?.discountedPrice} className={` text-affes-base `} />
                                  </section>
                                  <section className="mb-[6px] flex items-center justify-end font-normal">
                                    <p className="mr-1 text-affes-base">
                                      {formatMessage({ id: 'youSave', defaultMessage: 'You Save:  ' })}
                                    </p>{' '}
                                    <Price price={calculateLineItemDiscount(item)} className={`text-affes-base`} />
                                  </section>
                                </>
                              ) : (
                                <>
                                  <Price
                                    price={item?.price}
                                    className="mt-1 text-affes-base font-bold leading-[16.8px] text-red-900"
                                  />
                                </>
                              )}
                            </section>
                          </section>
                        </section>
                      </section>
                      <section>
                        <form>
                          <p className="mb-4">
                            <label htmlFor="giftEmail" className="mb-2 block text-xs">
                              {formatCommonMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                              <sup className="mx-1 text-red-600">*</sup>{' '}
                              <em>
                                {formatCartMessage({
                                  id: 'gift.recipient.receives.notification',
                                  defaultMessage:
                                    'Gift recipient receives notification via email (only) when item ships',
                                })}
                              </em>
                            </label>
                            <input
                              type="email"
                              id="giftEmail"
                              value={editGiftEmail}
                              onChange={handleEmailChange}
                              className="w-full rounded-sm border border-gray-600"
                              maxLength={40}
                              aria-required="true"
                              aria-invalid={emailError ? 'true' : 'false'}
                              aria-describedby={emailError ? 'emailError' : null}
                            />
                            {emailError && (
                              <p id="emailError" className="text-[12px] text-red-900">
                                {emailError}
                              </p>
                            )}
                          </p>
                          <section className="mb-4">
                            <section className="mb-2 flex justify-between">
                              <p className="text-xs text-gray-600">
                                <label htmlFor="giftMessage">
                                  {formatCommonMessage({ id: 'message', defaultMessage: 'Message' })}
                                  <em>( {formatCommonMessage({ id: 'optional', defaultMessage: 'Optional' })})</em>
                                </label>
                              </p>
                              <p className="text-xs text-gray-600">
                                {count - typeCount}{' '}
                                {formatCommonMessage({
                                  id: 'characters.remaining',
                                  defaultMessage: 'characters remaining',
                                })}
                              </p>
                            </section>
                            <textarea
                              id="giftMessage"
                              value={editGiftMsg}
                              className="w-full rounded-sm"
                              onChange={handleMessageChange}
                              maxLength={100}
                              aria-describedby="charactersRemaining"
                              style={{ height: '70px', resize: 'none' }}
                            ></textarea>
                          </section>
                          <section className="block md:flex">
                            <button
                              type="submit"
                              className="mr-4 w-full rounded-sm bg-blue-900 py-3 text-sm text-white md:w-44"
                              onClick={(event) => {
                                handleSubmit(event);
                              }}
                            >
                              {formatCommonMessage({ id: 'save', defaultMessage: 'Save' })}
                            </button>{' '}
                            <button
                              type="button"
                              className="mr-4 w-full py-3 text-sm text-blue-900 md:w-44"
                              onClick={() => closeModal(false)}
                            >
                              {formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                            </button>
                          </section>
                        </form>
                      </section>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default GiftDetails;
