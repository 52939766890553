import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import ChevronDown from '../../../icons/chevron-down';

interface SearchParams {
  text: string;
  options: any[];
  setFilteredOptions: React.Dispatch<React.SetStateAction<any[]>>;
}

interface Option {
  name: string;
  price: string;
}

interface TypeAheadProps {
  options: Option[];
  onSearch: (params: SearchParams) => void;
  renderOption: (option: Option, index: number) => JSX.Element;
  onSelect?: (option: Option) => void;
  label?: string;
}

const TypeAhead: React.FC<TypeAheadProps> = ({ options, onSearch, renderOption, onSelect, label }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [isFocused, setIsFocused] = useState(false);
  let blurTimeout: NodeJS.Timeout;
  const handleSearch = debounce((text: string) => {
    if (text) {
      onSearch({ text, options, setFilteredOptions });
    } else {
      setFilteredOptions(options);
    }
  }, 0);
  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef?.current && !containerRef?.current.contains(event.target)) {
        setIsFocused(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && filteredOptions.length > 0) {
      onSelect?.(filteredOptions[0]);
    }
  };

  return (
    <div ref={containerRef} className="w-full sm:w-auto">
      <label className="inline-block text-xs font-semibold not-italic leading-affes-3.5">{label}</label>
      <div className={`${label ? 'relative mt-2.5' : 'relative'}`}>
        <input
          type="text"
          value={searchText}
          onFocus={() => {
            clearTimeout(blurTimeout);
            setIsFocused(true);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          className="w-full cursor-pointer rounded-sm border border-gray-300 pl-3 pr-10 focus:border-gray-300 focus:outline-none focus:ring-0 focus:ring-gray-300"
          aria-haspopup="listbox"
          aria-expanded={isFocused}
          aria-controls="dropdown-options"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <ChevronDown className="cursor-pointer text-gray-300" />
        </div>
      </div>
      {isFocused && filteredOptions.length > 0 && (
        <ul
          id="dropdown-options"
          role="listbox"
          aria-labelledby="dropdown-label"
          className="mt-1 w-full border border-gray-300 p-3"
        >
          {filteredOptions.map((option, index) => (
            <li
              role="option"
              aria-selected={false}
              key={`${option?.name}_key`}
              onClick={() => onSelect(option)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSelect?.(option);
                }
              }}
              tabIndex={0} // Make the <li> focusable
            >
              {renderOption(option, index)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TypeAhead;
