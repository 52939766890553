import PageTitle from 'components/commercetools-ui/aafes/pageTitle';

const index = ({ data }) => {
  return (
    <>
      <PageTitle data={data} />
    </>
  );
};

export default index;
