import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" className={className} viewBox="0 0 20 18" fill="none">
    <path
      d="M3.05395 0C2.62395 0 2.21395 0.14 1.83395 0.42C1.45395 0.7 1.23395 1.06 1.13395 1.5L0.103953 5.91C-0.146047 7 0.0539534 7.95 0.713953 8.77L0.993953 9.05V16C0.993953 16.5 1.19395 17 1.60395 17.39C2.01395 17.78 2.49395 18 2.99395 18H16.994C17.494 18 17.994 17.8 18.384 17.39C18.774 16.98 18.994 16.5 18.994 16V9.05L19.274 8.77C19.934 7.95 20.134 7 19.884 5.91L18.854 1.5C18.724 1.06 18.494 0.7 18.124 0.42C17.764 0.14 17.374 0 16.934 0H3.05395ZM16.884 1.97L17.964 6.38C18.054 6.81 17.964 7.2 17.684 7.55C17.434 7.86 17.124 8 16.744 8C16.434 8 16.164 7.9 15.944 7.66C15.724 7.43 15.604 7.16 15.574 6.84L14.964 2L16.884 1.97ZM3.05395 2H5.02395L4.41395 6.84C4.29395 7.63 3.90395 8 3.24395 8C2.83395 8 2.52395 7.86 2.30395 7.55C2.02395 7.2 1.93395 6.81 2.02395 6.38L3.05395 2ZM7.04395 2H8.99395V6.7C8.99395 7.05 8.88395 7.35 8.63395 7.62C8.38395 7.88 8.07395 8 7.69395 8C7.35395 8 7.06395 7.88 6.83395 7.59C6.60395 7.3 6.49395 7 6.49395 6.66V6.5L7.04395 2ZM10.994 2H12.944L13.494 6.5C13.574 6.92 13.494 7.27 13.204 7.57C12.944 7.87 12.604 8 12.194 8C11.884 8 11.604 7.88 11.354 7.62C11.104 7.35 10.994 7.05 10.994 6.7V2ZM5.44395 9.05C6.07395 9.67 6.85395 10 7.79395 10C8.63395 10 9.37395 9.67 9.99395 9.05C10.684 9.67 11.444 10 12.294 10C13.164 10 13.914 9.67 14.544 9.05C15.104 9.67 15.854 10 16.794 10H17.024V16H2.99395V10H3.24395C4.15395 10 4.88395 9.67 5.44395 9.05Z"
      fill="#333333"
    />
  </svg>
);

export default Icon;
