export const isPreOrderable = (item?: any): boolean => {
  const currentDate = new Date().getTime();
  const endDate = new Date(item?.variant?.attributes?.preOrderEndDate).getTime();
  const streetDate = new Date(item?.variant?.attributes?.preOrderStreetDate).getTime();
  return item?.variant?.attributes?.isPreOrderable && endDate > currentDate && streetDate > currentDate;
};
export const shouldShowAddToCartButton = (item?: any): boolean => {
  const preOrderEndDate = new Date(item?.variant?.attributes?.preOrderEndDate).getTime();
  const preOrderStreetDate = new Date(item?.variant?.attributes?.preOrderStreetDate).getTime();
  if (item?.variant?.attributes?.searchable === false) {
    return false;
  }
  if (item?.variant?.attributes?.isPreOrderable && !preOrderEndDate && !preOrderStreetDate) {
    return true;
  }
  return !item?.variant?.attributes?.isPreOrderable && webDateEnabled(item);
};
export const arePreOrderDatesInFuture = (item?: any): boolean => {
  const currentDate = new Date().getTime();
  const preOrderEndDate = new Date(item?.variant?.attributes?.preOrderEndDate).getTime();
  const preOrderStreetDate = new Date(item?.variant?.attributes?.preOrderStreetDate).getTime();
  if (item?.variant?.attributes?.isPreOrderable && !preOrderStreetDate && !preOrderEndDate) {
    return true;
  }

  return preOrderEndDate > currentDate && preOrderStreetDate > currentDate;
};

export const arePreOrderDatesInPast = (item?: any): boolean => {
  const currentDate = new Date().getTime();
  const preOrderEndDate = new Date(item?.variant?.attributes?.preOrderEndDate).getTime();
  const preOrderStreetDate = new Date(item?.variant?.attributes?.preOrderStreetDate).getTime();
  if (item?.variant?.attributes?.isPreOrderable && !preOrderStreetDate && !preOrderEndDate) {
    return true;
  }
  return preOrderEndDate < currentDate && preOrderStreetDate < currentDate;
};
export const webDateEnabled = (item?: any): boolean => {
  const endDate = new Date(item.variant.attributes.endDate).getTime();
  const startDate = new Date(item.variant.attributes.startDate).getTime();
  const currentDate = new Date().getTime();

  if (!startDate && !endDate) {
    return true;
  }
  if (startDate && !endDate) {
    const startDateTimestamp = new Date(startDate).getTime();
    return currentDate >= startDateTimestamp;
  }
  if (!startDate && endDate) {
    const endDateTimestamp = new Date(endDate).getTime();
    return currentDate <= endDateTimestamp;
  }

  const startDateTimestamp = new Date(startDate).getTime();
  const endDateTimestamp = new Date(endDate).getTime();
  return currentDate >= startDateTimestamp && currentDate <= endDateTimestamp;
};

export const isPreOrderEndFutureAndStreetPast = (item?: any): boolean => {
  const currentDate = new Date().getTime();
  const preOrderEndDate = new Date(item?.variant?.attributes?.preOrderEndDate).getTime();
  const preOrderStreetDate = new Date(item?.variant?.attributes?.preOrderStreetDate).getTime();
  if (item?.variant?.attributes?.isPreOrderable && !preOrderStreetDate && !preOrderEndDate) {
    return true;
  }
  return preOrderEndDate > currentDate && preOrderStreetDate < currentDate;
};
