import React from 'react';

type Props = {
  fill?: string;
  className?: string;
};

const Icon: React.FC<Props> = ({ fill, className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="16.03"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M1.75342 14.2466H2.98082L10.5425 6.68493L9.31507 5.45753L1.75342 13.0192V14.2466ZM14.2904 5.4137L10.5644 1.73151L11.7918 0.50411C12.1279 0.168037 12.5408 0 13.0306 0C13.5198 0 13.9324 0.168037 14.2685 0.50411L15.4959 1.73151C15.832 2.06758 16.0073 2.47321 16.0219 2.94838C16.0365 3.42298 15.8758 3.82831 15.5397 4.16438L14.2904 5.4137ZM13.0192 6.70685L3.72603 16H0V12.274L9.29315 2.98082L13.0192 6.70685Z"
      fill={fill ?? '#043C63'}
    />
  </svg>
);

export default Icon;
