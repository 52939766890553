import VisaCard from '../../components/images/visa.png';
import DiscoverCard from '../../components/images/discover-card.png';
import MasterCard from '../../components/images/MastrCard.png';
import MilstarCard from '../../components/images/military-star-cardholders.png';
import MilstarPlus from '../../components/images/milstar-plus.png';
import AmexCard from '../../components/images/amex.png';

const RenderCardsImages = (cardType) => {
  switch (cardType) {
    case 'Visa':
      return <img src={VisaCard.src} alt="Visa" style={{ width: 38, height: 24 }} />;
    case 'Mastercard':
      return <img src={MasterCard.src} alt="Mastercard" style={{ width: 38, height: 24 }} />;
    case 'Discover':
      return <img src={DiscoverCard.src} alt="Discover" style={{ width: 38, height: 24 }} />;
    case 'Amex':
      return <img src={AmexCard.src} alt="Amex" style={{ width: 38, height: 24 }} />;
    case 'Milstar':
      return <img src={MilstarCard.src} alt="Milstar" style={{ width: 38, height: 24 }} />;
    case 'MilstarPlus':
      return <img src={MilstarPlus.src} alt="MilstarPlus" style={{ width: 38, height: 24 }} />;
    default:
      return <img src={''} alt="Default Card" />;
  }
};
export default RenderCardsImages;
