import React, { useEffect } from 'react';

const Index = ({ data }) => {
  useEffect(() => {
    if (!data || !data?.tags) return;

    const { dataSource } = data.data;

    const placeholderRegex = /{{(.*?)}}/g;
    const isCLP = dataSource?.isCLP;

    const manipulatedTags = data.tags.replace(placeholderRegex, (match, placeholder) => {
      switch (placeholder.trim()) {
        case 'category':
          const categoryFilter = isCLP
            ? dataSource?.selectedFilters?.find((filter) => filter?.displayName === 'categoriesL1')
            : dataSource?.selectedFilters?.find((filter) => filter?.displayName === 'categoriesL2');
          return categoryFilter ? categoryFilter?.refinements[0]?.name : '';
        case 'parentCategory':
          const parentCategoryFilter = isCLP
            ? null
            : dataSource?.selectedFilters?.find((filter) => filter?.displayName === 'categoriesL1');
          return parentCategoryFilter ? parentCategoryFilter?.refinements[0]?.name : '';
        case 'htmlEncodedBreadcrumb':
          const searchParams = new URLSearchParams(window.location.search);
          const pageCategories = searchParams.get('pageCategories');
          return pageCategories ? pageCategories : '';

        default:
          return match;
      }
    });

    const cleanedTags = manipulatedTags
      .split('|')
      .map((tag) => tag.trim())
      .filter((tag) => tag)
      .join(
        !data?.data?.dataSource?.isCLP &&
          !data?.data?.dataSource?.selectedFilters?.find(
            (filter) => filter?.displayName === ('categoriesL1' || 'categoriesL2'),
          )
          ? ''
          : ' | ',
      );
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = cleanedTags;

    const addedTags = [];

    const head = document.head;
    while (tempDiv.firstChild) {
      const tag = tempDiv.firstChild;
      head.appendChild(tag);
      addedTags.push(tag);
    }

    return () => {
      addedTags.forEach((tag) => {
        if (tag.parentNode) {
          tag.parentNode.removeChild(tag);
        }
      });
    };
  }, [data?.data?.dataSource?.selectedFilters]);

  return null;
};

export default Index;
