import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';

export default function PageView({ pageViewData, selectedPageView, setSelectedPageView, setCurrentPage }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isShowPageViewContent, setIsShowPageViewContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownReference = useRef(null);
  const ButtonReference = useRef(null);
  const router = useRouter();

  const handleSetPageView = (pageViewOption) => {
    if (pageViewOption === selectedPageView) return setIsShowPageViewContent(false);
    setSelectedPageView(pageViewOption);
    setIsLoading(true);
    const CURRENT_URL = window?.location?.href;
    const url = new URL(CURRENT_URL);
    const pageSize = pageViewOption;
    if (pageViewOption === pageViewData?.[0]) url?.searchParams?.delete('pageSize');
    else url.searchParams.set('pageSize', pageSize);
    url.searchParams.delete('pageNo');
    setCurrentPage(1);
    router.push(url.toString()).then(() => setIsLoading(false));
    setIsShowPageViewContent(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (!dropdownReference?.current?.contains(event?.target) && !ButtonReference?.current?.contains(event?.target)) {
        setIsShowPageViewContent(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative z-[3] w-fit">
        <button
          ref={ButtonReference}
          onClick={() => {
            setIsShowPageViewContent((prev) => !prev);
          }}
          className="flex w-full items-center justify-between gap-x-2 rounded border border-[#00000033] p-2"
        >
          <p className="text-sm font-bold leading-4 text-gray-900">
            <span className="font-normal text-gray-800">{formatMessage({ id: 'view', defaultMessage: 'View:' })} </span>
            {selectedPageView}
          </p>
          {isShowPageViewContent ? (
            <RiArrowDownSLine className="h-6 w-6 text-[#1E1E1E]" />
          ) : (
            <RiArrowRightSLine className="h-6 w-6 text-[#1E1E1E]" />
          )}
        </button>
        {isShowPageViewContent && (
          <div ref={dropdownReference} className="absolute w-full rounded border border-[#00000033] bg-white">
            <div>
              {pageViewData?.map((pageOption, index) => (
                <div key={index} className="py-1">
                  <button
                    className="w-full px-2.5 text-left text-gray-800 hover:bg-gray-200"
                    onClick={() => handleSetPageView(pageOption)}
                  >
                    {pageOption}
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {isLoading && <Loader />}
    </>
  );
}
