import React, { Fragment } from 'react';
import NextLink from 'next/link';
import DoubleArrow from 'components/icons/double-arrow';
const Breadcrumb = ({ currentCategory, categories, handleCategorySelect, formatBrandsListingMessage }) => {
  const buildBreadcrumb = (categoryId: string, categories: any) => {
    for (const category of categories) {
      if (category?.categoryId === categoryId) {
        return [
          <Fragment key={category?.categoryId}>
            <NextLink href={category?.url || '/'}>
              <a className="text-blue-900">{category?.categoryName}</a>
            </NextLink>
          </Fragment>,
        ];
      } else if (category?.children) {
        const childBreadcrumb = buildBreadcrumb(categoryId, category?.children);
        if (childBreadcrumb?.length > 0) {
          return [
            <Fragment key={category?.categoryId}>
              <span className="cursor-pointer text-blue-900" onClick={() => handleCategorySelect(category?.categoryId)}>
                {category?.categoryName}
              </span>
            </Fragment>,
            ...childBreadcrumb,
          ];
        }
      }
    }
    return [];
  };

  const breadcrumbTrail = buildBreadcrumb(currentCategory, categories);

  return (
    <ol role="list" className="flex items-center space-x-2 py-5 text-sm">
      <li>
        <div>
          <NextLink href="/">
            <a className="text-blue-900" aria-label="Go to homepage">
              {formatBrandsListingMessage({ id: 'breadcrumb.home.title', defaultMessage: 'Exchange' })}
            </a>
          </NextLink>
        </div>
      </li>
      {breadcrumbTrail?.map((item: any, index: number) => (
        <Fragment key={index}>
          <li className="mt-[2.5px]">{<DoubleArrow />}</li>
          {item}
        </Fragment>
      ))}
    </ol>
  );
};

export default Breadcrumb;
