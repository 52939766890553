import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronCompactRight, BsChevronUp } from 'react-icons/bs';
const Accordian = ({ categories, onCategorySelect, currentCategory, isDesktop, close }) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (category: any, isSubCategory = false, parentSlug = null) => {
    if (!isSubCategory) {
      setActiveCategory(activeCategory === category?.slug ? null : category?.slug);
    } else {
      setActiveCategory(parentSlug);
    }
    category?.categoryId !== currentCategory && onCategorySelect(category?.categoryId);
  };
  const isCategorySelected = ({ category, currentCategory, activeCategory }) => {
    return activeCategory === category?.slug;
  };
  const handleAccordionClick = (category: any, isSubCategory = false) => {
    if (!isSubCategory) {
      setActiveCategory(activeCategory === category.slug ? null : category.slug);
    }
  };
  useEffect(() => {
    setActiveCategory(
      categories?.find(
        (cat) =>
          cat?.categoryId === currentCategory ||
          cat?.children?.some((subCat: any) => subCat?.categoryId === currentCategory),
      )?.slug,
    );
    return () => null;
  }, []);
  return (
    <div className="w-full">
      <div
        className={`${
          !isDesktop
            ? 'mb-[5px] border-b border-b-[#CCD8FF] bg-[#F2F2F2] py-3 text-blue-900'
            : 'border-b-2 border-gray-300 py-4'
        } text-base font-bold leading-4`}
      >
        <span
          role="button"
          onClick={() => {
            onCategorySelect('all');
            close();
          }}
          className={`w-fit ${isDesktop && 'hover:underline'}`}
        >
          All Brands
        </span>
      </div>
      {categories
        ?.filter((cat) => cat?.brands?.length > 0)
        ?.map((category: any) => {
          return (
            <div key={category?.slug} className="w-full">
              <div
                className={`${
                  !isDesktop
                    ? 'mb-[5px] border-y border-y-[#CCD8E1] bg-[#F2F2F2] py-3 text-blue-900'
                    : 'border-b-2 border-gray-300 py-4'
                } flex w-full justify-between text-base font-bold leading-4 ${
                  activeCategory === category?.slug ? 'font-bold' : ''
                }`}
                onClick={() => handleAccordionClick(category)}
              >
                <span
                  role="button"
                  onClick={() => {
                    if (isDesktop) {
                      handleCategoryClick(category);
                    }
                  }}
                  className={`${isDesktop ? 'hover:underline' : ''}`}
                >
                  {category?.categoryName}
                </span>
                <span className={`self-center text-xl ${!isDesktop && 'px-6'}`}>
                  {activeCategory === category?.slug ? (
                    <BsChevronDown className="text-sm text-gray-900" />
                  ) : (
                    <BsChevronUp className="text-sm text-gray-900" />
                  )}
                </span>
              </div>
              {isCategorySelected({ category, currentCategory, activeCategory }) && (
                <div
                  className={`flex w-full flex-col ${
                    !isDesktop && category?.children && category?.children?.length > 0
                      ? 'mb-3 border-x border-b'
                      : 'gap-y-1'
                  }`}
                >
                  {/* Conditionally render "All Brands" if category contains children */}
                  {category?.children &&
                    category?.children?.filter((cat) => cat?.brands?.length > 0)?.length > 0 &&
                    !isDesktop && (
                      <span
                        role="button"
                        onClick={() => {
                          handleCategoryClick(category);
                          close();
                        }}
                        className={`mx-2 flex justify-between border-b ${
                          currentCategory === category?.categoryId && ' bg-gray-300'
                        }`}
                      >
                        <span className={`w-fit px-4 py-2 text-base font-normal`}>All Brands</span>
                        <span className={`self-center px-1 text-xl leading-5`}>
                          <BsChevronCompactRight className="h-5 bg-white text-lg text-gray-600" />
                        </span>
                      </span>
                    )}

                  {category?.children
                    ?.filter((cat) => cat?.brands?.length > 0)
                    ?.sort((a, b) => {
                      const categoryName1 = a?.categoryName?.toLowerCase();
                      const categoryName2 = b?.categoryName?.toLowerCase();
                      if (categoryName1 < categoryName2) {
                        return -1;
                      }
                      if (categoryName1 > categoryName2) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((subCategory: any, index, array) => (
                      <div
                        key={subCategory?.slug}
                        onClick={() => {
                          handleCategoryClick(subCategory, true, category?.slug);
                          close();
                        }}
                        onKeyDown={(event) =>
                          event?.key === 'Enter' && handleCategoryClick(subCategory, true, category?.slug)
                        }
                        className={`flex justify-between ${
                          currentCategory === subCategory?.categoryId ? `bg-gray-300 ${index === 0 && 'border-b'}` : ''
                        } ${
                          !isDesktop && currentCategory !== subCategory?.categoryId
                            ? index === array.length - 1
                              ? ''
                              : 'border-b'
                            : ''
                        } ${!isDesktop ? 'mx-2 py-1' : 'my-1'}`}
                      >
                        <span
                          role="button"
                          className={`w-fit p-1 text-base  ${!isDesktop ? 'px-4 py-2 font-normal' : 'hover:underline'}`}
                        >
                          {subCategory?.categoryName}
                        </span>
                        <span className={`self-center px-1 text-xl leading-5 ${isDesktop && 'hidden'}`}>
                          <BsChevronCompactRight className="h-5 bg-white text-lg text-gray-600" />
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Accordian;
