import React from 'react';
import Head from 'next/head';
function PageTitle({ data }) {
  return (
    <>
      <Head>
        <title>{data?.pageTitle}</title>
        <meta name="description" content={data?.pageDesc} />
      </Head>
    </>
  );
}

export default PageTitle;
