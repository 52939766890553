import React from 'react';
import Cart from 'components/commercetools-ui/aafes/cart';
import { useCart } from 'frontastic/provider';
import { useFormat } from 'helpers/hooks/useFormat';

const CartTastic = ({ data }) => {
  const { data: cartList, moveToSaveForLater } = useCart();
  return (
    <>
      <Cart
        cart={cartList}
        saveForLater={moveToSaveForLater}
        pageTitle={data.pageTitle}
        emptyStateImage={data.emptyStateImage}
        emptyStateTitle={data.emptyStateTitle}
        emptyStateSubtitle={data.emptyStateSubtitle}
        emptyStateCTALabel={data.emptyStateCTALabel}
        emptyStateCTALink={data.emptyStateCTALink}
        giftCardImg={data.receiveGiftCardImage.media.file}
        tasticData={data}
      />
    </>
  );
};

export default CartTastic;
