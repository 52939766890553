import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import militaryStar from '../../../../images/military-star.png';

function Index({ cardData }) {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  return (
    <section>
      <div className="order-8 mb-5 h-[217px] rounded border border-[#DFDFDF] bg-militaryStar py-3 px-5 md:order-none ">
        <div className="mb-4 grid grid-cols-6 gap-4">
          <div className="col-span-2 pt-8 text-center">
            <img src={militaryStar.src} alt="Military Star" className="mx-auto" />
            <p className="mt-2 text-xs font-bold leading-[14.4px]">
              {formatCommonMessage({ id: 'militaryStar', defaultMessage: 'Military Star' })}
            </p>
            <a href={cardData?.headerCol2} target="_blank" rel="noreferrer">
              <button
                type="button"
                className="mt-3 h-11 rounded border bg-primaryBtn px-3 text-center text-sm font-bold leading-[16.8px] text-white"
              >
                {formatCommonMessage({ id: 'applyNow', defaultMessage: 'Apply Now' })}
              </button>
            </a>
          </div>
          <div className="col-span-4 leading-7">
            <p className="mb-[17px] text-base font-bold leading-[1.2rem] text-[#B81335]">{cardData?.headerCol1}</p>
            <ul className="text-[13px] leading-[19px]">
              {cardData?.linksCol1?.map((listing, index) => (
                <li className="mb-2 flex items-start" key={index}>
                  <div className="mr-2 inline-block shrink-0 text-green-700">
                    <img src={cardData?.iconCol2?.media?.file} width="16px" height="16px" alt="card listing" />
                  </div>{' '}
                  <p>{listing?.name}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
