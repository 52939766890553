import React, { useState, FC, Children, CSSProperties } from 'react';
import classnames from 'classnames';
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import 'swiper/css'; // eslint-disable-line import/no-unresolved
import 'swiper/css/navigation'; // eslint-disable-line import/no-unresolved
import 'swiper/css/pagination'; // eslint-disable-line import/no-unresolved
import 'swiper/css/scrollbar'; // eslint-disable-line import/no-unresolved
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
export type SliderProps = {
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number;
  arrows?: boolean;
  dots?: boolean;
  fitToSlides?: boolean;
  slideWidth?: number;
  withThumbs?: boolean;
  overlapDots?: boolean;
  youMayLikeComponent?: boolean;
} & SwiperProps;

const AafesSlider: FC<SliderProps> = ({
  className,
  slideWidth,
  slidesPerView,
  fitToSlides = false,
  arrows = false,
  dots = true,
  spaceBetween = 20,
  overlapDots = false,
  withThumbs,
  children,
  youMayLikeComponent,
  ...props
}) => {
  const [swiper, setSwiper] = React.useState(null);
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper && arrows) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const validToFit: boolean = Boolean(fitToSlides) && Boolean(slideWidth) && Boolean(slidesPerView);
  const sliderWidth: CSSProperties['width'] = validToFit
    ? `${spaceBetween * (slidesPerView! - 1) + slideWidth! * slidesPerView!}px`
    : '';

  const slides = Children.map(children, (child) => (
    <SwiperSlide style={slideWidth ? { width: `${slideWidth}px` } : {}}>{child}</SwiperSlide>
  ));
  while (slides?.length && slides.length < slidesPerView) {
    const slideToAdd = slides[slides.length - 1];
    const clonedElem = React.cloneElement(slideToAdd, {
      style: {
        visibility: 'hidden',
      },
    });
    slides.push(clonedElem);
  }
  const thumbs = Children.map(children, (child) => (
    <SwiperSlide className="slider__thumb" style={{ height: '80px', overflow: 'hidden' }}>
      {child}
    </SwiperSlide>
  ));

  const containerClassName = classnames('slider__container custom_slider__container', {
    'slider__container--fit': validToFit,
    'slider__container--with-thumbs': withThumbs,
  });
  const slidesClassName = classnames('slider', className);
  const thumbsClassName = classnames('slider__thumbs');
  const [isDesktopSize] = useMediaQuery(screenSizes.desktop);
  const getSlidesPerGroup = () => {
    if (youMayLikeComponent) {
      if (isDesktopSize && className.includes('slider-normal')) {
        return 5;
      }
      return 1;
    }
    return 1;
  };
  const mainSlider = (
    <>
      {arrows && <button className="custom-btn swiper-button swiper-button-prev" ref={prevRef}></button>}
      <Swiper
        className={slidesClassName}
        modules={[Navigation, Pagination, Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        pagination={dots ? { clickable: true, bulletActiveClass: 'slider__bullet--active' } : false}
        slidesPerView={slidesPerView ?? 'auto'}
        slidesPerGroup={getSlidesPerGroup()}
        spaceBetween={spaceBetween}
        navigation={
          arrows
            ? {
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              }
            : false
        }
        style={{ width: sliderWidth, paddingBottom: overlapDots ? 0 : 40 }}
        onSwiper={setSwiper}
        {...props}
      >
        {slides}
      </Swiper>
      {arrows && <button className="custom-btn swiper-button swiper-button-next" ref={nextRef}></button>}
    </>
  );

  return (
    <div className="">
      <div className={containerClassName}>
        {withThumbs ? (
          <>
            <div className="slider__thumbs">
              <Swiper
                className={thumbsClassName}
                modules={[Navigation, Thumbs]}
                navigation
                spaceBetween={15}
                slidesPerView={5}
                direction={'vertical'}
                watchSlidesProgress
                onSwiper={setThumbsSwiper}
              >
                {thumbs}
              </Swiper>
            </div>
            <div className="slider__slides">{mainSlider}</div>
          </>
        ) : (
          mainSlider
        )}
      </div>
    </div>
  );
};

export default AafesSlider;
