import React, { useState } from 'react';
import Link from 'next/link';
import { CgChevronDoubleRight } from 'react-icons/cg';
import { FaChevronDown, FaChevronUp, FaChrome } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useFormat } from 'helpers/hooks/useFormat';
import { trustedSiteVerify } from '../../../../helpers/constants/aafes';
import TrustedLogo from '../../../icons/trustedSitePopupLogo';
import Google from '../../../images/google.png';
import PhisTank from '../../../images/phistank.png';
import SSL from '../../../images/ssl.png';
import Logo from '../../../images/trustedExchangelogo.png';

function TrustedSite({ showTrustedSiteModal, setTrustedSiteModal }) {
  const [openCertifideSecure, setCertifideSecure] = useState(false);
  const [openDataProtection, setDataProtection] = useState(false);
  const [openBusiness, setBusiness] = useState(false);
  const [openTrustedSite, setTrustedSite] = useState(false);
  const [trustExperience, setTrustExperience] = useState(false);
  const [greetings, setGreetings] = useState(false);
  const { formatMessage } = useFormat({ name: 'trustedSite' });

  return (
    <section>
      {showTrustedSiteModal && (
        <section>
          <section className="fixed  top-0 left-0 z-20 h-full w-full">
            <section className="overlay  z-1 fixed left-0 top-0 h-full w-full bg-black-200  opacity-80"></section>

            <section className="absolute left-1/2 top-2/4  w-[90%]  -translate-x-2/4 -translate-y-2/4   px-5 md:w-[400px] ">
              {/* section 1 */}
              <section
                className=" relative left-4 mb-2 flex justify-end opacity-95  "
                onClick={() => {
                  setTrustedSiteModal(!showTrustedSiteModal);
                }}
              >
                {' '}
                <button>
                  <MdClose />
                </button>
              </section>

              <section className="modalHeader  relative -mx-5 flex h-[42px]  items-center justify-between  border  bg-white px-5">
                <p>
                  <TrustedLogo />
                </p>

                <section className="my-auto flex items-center  text-center ">
                  <p className=" text-[11px] text-[#666A68] hover:underline">
                    <Link href={trustedSiteVerify.verify}>
                      {formatMessage({ id: 'verify', defaultMessage: 'Verify' })}
                    </Link>
                  </p>
                  <p className="text-[11px]">
                    <CgChevronDoubleRight />
                  </p>
                </section>
              </section>
              {/* section 2 */}
              <section className="-mx-5 h-[400px] overflow-auto bg-[#f8f8f8] px-5 ">
                <section className="   ">
                  <section className=" -mx-5 bg-[#80C600] px-5 pb-[45px] pt-[25px]">
                    <p className="mb-[5px] text-[22px] leading-6 text-white">
                      {formatMessage({ id: 'site', defaultMessage: 'shopmyexchange.com' })}
                    </p>
                    <p className="text-[13px] text-white opacity-80">
                      {formatMessage({
                        id: 'header.desc',
                        defaultMessage: 'This site has earned the following certifications.',
                      })}
                    </p>
                  </section>

                  {/* section 3 */}
                  <section className="relative bottom-5 ">
                    <section className="pb-[15px]">
                      <section className="mb-2 rounded-sm  border  bg-white p-[15px]">
                        <section
                          className="  flex w-full items-center justify-between  text-center text-sm font-medium "
                          onClick={() => setCertifideSecure(!openCertifideSecure)}
                        >
                          <p className="text-[13px] font-bold text-black-100">
                            {formatMessage({ id: 'certified.secure', defaultMessage: 'Certified Secure' })}
                          </p>
                          <section className="flex items-center text-center ">
                            <p className=" text-[11px] font-bold text-[#80C600]">
                              {formatMessage({ id: 'certified', defaultMessage: 'certified' })}
                            </p>
                            <p className="ml-[10px] text-[10px]">
                              {openCertifideSecure ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                          </section>
                        </section>

                        {openCertifideSecure && (
                          <>
                            <hr className="mt-[15px]" />
                            <section className="pt-[15px]">
                              <p className="border-b pb-3  text-[13px]">
                                TrustedSite actively monitors this site for security issues such as malware, malicious
                                links, and phishing. No issues were detected on its most recent security check.
                              </p>
                              <section className="flex  items-center border-b py-3">
                                <img src={Google.src} className="h-7 w-7" alt="Google Safe Browsing Icon" />
                                <p className="ml-6 text-[13px]">
                                  {formatMessage({
                                    id: 'google.desc',
                                    defaultMessage: 'No issues detected in Google Safe Browsing',
                                  })}
                                </p>
                              </section>
                              <section className="flex items-center border-b py-3">
                                <img src={PhisTank.src} className="h-7 w-7" alt="Phish Tank Icon" />
                                <p className="ml-6 text-[13px]">
                                  {formatMessage({
                                    id: 'phistank.desc',
                                    defaultMessage: 'No issues reported in PhishTank',
                                  })}
                                </p>
                              </section>
                              <section className="flex items-center py-3 ">
                                <img src={SSL.src} className="h-7 w-7" alt="SSL Certificate Icon" />
                                <p className="ml-6 text-[13px]">
                                  {formatMessage({ id: 'validSSl', defaultMessage: 'Valid SSL Certificate' })}
                                </p>
                              </section>
                            </section>
                          </>
                        )}
                      </section>

                      <section className="mb-2 rounded-sm  border  bg-white p-[15px]">
                        <section
                          className="  flex w-full items-center justify-between  text-center text-sm font-medium "
                          onClick={() => setDataProtection(!openDataProtection)}
                        >
                          <p className="text-[13px] font-bold text-black-100">
                            {formatMessage({ id: 'data.protection', defaultMessage: 'Data Protection' })}
                          </p>
                          <section className="flex items-center text-center ">
                            <p className=" text-[11px] font-bold text-[#80C600]">
                              {formatMessage({ id: 'certified', defaultMessage: 'certified' })}
                            </p>
                            <p className="ml-[10px] text-[10px]">
                              {openDataProtection ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                          </section>
                        </section>

                        {openDataProtection && (
                          <>
                            <hr className="mt-[15px]" />
                            <section className="pt-[15px]">
                              <p className=" pb-3  text-[13px]">
                                TrustedSite has verified that this site uses SSL to encrypt communications with
                                industry-standard security protocols.
                              </p>
                              <section className="flex  py-3">
                                <section className="w-1/2">
                                  <p className="text-[11px] font-bold text-[#060803]">
                                    {formatMessage({ id: 'SSL.valid.until', defaultMessage: 'SSL VALID UNTIL' })}
                                  </p>
                                  <p className="text-[13px] text-[#666A68]">2024-01-13</p>
                                </section>
                                <section className="w-1/2">
                                  <p className="text-[11px] font-bold text-[#060803]">
                                    {formatMessage({ id: 'SSL.valid.until', defaultMessage: 'SSL VALID UNTIL' })}
                                  </p>
                                  <p className="text-[13px] text-[#666A68]">
                                    {formatMessage({
                                      id: 'SSL.issued.by.desc',
                                      defaultMessage: 'COMODO RSA Organization Validation Secure Server CA',
                                    })}
                                  </p>
                                </section>
                              </section>
                            </section>
                          </>
                        )}
                      </section>

                      <section className="mb-2 rounded-sm  border  bg-white p-[15px]">
                        <section
                          className="  flex w-full items-center justify-between  text-center text-sm font-medium "
                          onClick={() => setBusiness(!openBusiness)}
                        >
                          <p className="text-[13px] font-bold text-black-100">
                            {formatMessage({ id: 'about.business', defaultMessage: 'About the Business' })}
                          </p>
                          <section className="flex items-center text-center ">
                            <p className="ml-[10px] text-[10px]">
                              {openBusiness ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                          </section>
                        </section>

                        {openBusiness && (
                          <>
                            <hr className="mt-[15px]" />
                            <section className="pt-[15px]">
                              <section className="flex w-full  ">
                                <section className=" w-[40%]">
                                  <img src={Logo.src} className="h-[100px] w-[150px] border" alt="Military Star Logo" />
                                </section>
                                <section className="ml-5 w-[60%] text-[13px]">
                                  <p className="font-bold text-[#060803]">
                                    Military Discounts On Top Brands, Women&apos;s Clothing, Fitness, Electronics
                                  </p>
                                  <p>
                                    Shop the largest military government ecommerce site with exclusive discounts,
                                    military clothing and tactical gear, tax free shopping and sales on name brands up
                                    to 50% off. FREE shipping at shopmyexchange.com. Army and Air Force Exchange Service
                                  </p>
                                </section>
                              </section>
                              <hr className="my-5" />

                              <section>
                                <section className="grid grid-cols-2  pb-5">
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803]">
                                      {formatMessage({ id: 'business.founded', defaultMessage: 'BUSINESS FOUNDED' })}
                                    </p>
                                    <p className="text-[13px] ">
                                      {formatMessage({ id: 'none', defaultMessage: 'None' })}
                                    </p>
                                  </section>
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803]">
                                      {formatMessage({ id: 'employees', defaultMessage: 'EMPLOYEES' })}
                                    </p>
                                    <p className="text-[13px] ">
                                      {formatMessage({ id: 'none', defaultMessage: 'None' })}
                                    </p>
                                  </section>
                                </section>
                                <section className="grid grid-cols-2 pb-5 ">
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803] ">
                                      {formatMessage({
                                        id: 'business.management',
                                        defaultMessage: 'BUSINESS MANAGEMENT',
                                      })}
                                    </p>
                                    <p className="text-[13px] ">
                                      {formatMessage({ id: 'none', defaultMessage: 'None' })}
                                    </p>
                                  </section>
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803]">
                                      {formatMessage({ id: 'certified.since', defaultMessage: 'CERTIFIED SINCE' })}
                                    </p>
                                    <p className="text-[13px]"> 2018-06-12</p>
                                  </section>
                                </section>
                              </section>

                              <hr className="my-5" />
                              <section>
                                <section className="grid grid-cols-2 pb-5 ">
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803]">
                                      {formatMessage({ id: 'links', defaultMessage: 'Links' })}
                                    </p>
                                    <p className="text-[13px] ">
                                      {formatMessage({ id: 'none', defaultMessage: 'None' })}
                                    </p>
                                  </section>
                                  <section>
                                    <p className="text-[11px] font-bold text-[#060803]">
                                      {formatMessage({ id: 'social', defaultMessage: 'Social' })}
                                    </p>
                                    <p className="text-[13px]">
                                      {formatMessage({ id: 'none', defaultMessage: 'None' })}
                                    </p>
                                  </section>
                                </section>
                              </section>
                            </section>
                          </>
                        )}
                      </section>

                      <section className="mb-2 rounded-sm  border  bg-white p-[15px]">
                        <section
                          className="  flex w-full items-center justify-between  text-center text-sm font-medium "
                          onClick={() => setTrustedSite(!openTrustedSite)}
                        >
                          <p className="text-[13px] font-bold text-black-100">
                            {formatMessage({ id: 'about.trustedsite', defaultMessage: 'About TrustedSite' })}
                          </p>
                          <section className="items-center text-center ">
                            <p className="ml-[10px] text-[10px]">
                              {openTrustedSite ? <FaChevronUp /> : <FaChevronDown />}
                            </p>
                          </section>
                        </section>

                        {openTrustedSite && (
                          <>
                            <hr className="mt-[15px]" />
                            <section className="pt-[15px]">
                              <section className=" pb-3  text-[13px]">
                                <p>
                                  TrustedSite offers cybersecurity solutions to help organizations protect their
                                  customer data and earn trust with their visitors. Sites earn TrustedSite
                                  certifications by maintaining a high level of website safety standards and good
                                  business practices.
                                </p>
                                <button className="underline">
                                  {formatMessage({ id: 'learn.more', defaultMessage: 'Learn more' })}
                                </button>
                              </section>

                              <hr />
                              <section className="text-[13px]">
                                <p className="py-2 font-bold text-[#060803]">
                                  {formatMessage({ id: 'for.business', defaultMessage: 'For Business' })}
                                </p>
                                <p>
                                  Discover TrustedSite’s attack surface management platform and certification program
                                  for trustworthy businesses.
                                </p>
                                <button className="underline">
                                  {formatMessage({ id: 'learn.more', defaultMessage: 'Learn more' })}
                                </button>
                              </section>
                            </section>
                          </>
                        )}
                      </section>
                    </section>

                    <section className="flex  w-full  items-center  justify-center text-xs">
                      <p className="mr-1 text-center">
                        <FaChrome />
                      </p>
                      <p className="text-center ">
                        {formatMessage({
                          id: 'browse.smarter',
                          defaultMessage: 'Browse smarter with TrustedSite for Chrome',
                        })}
                      </p>
                    </section>
                  </section>
                </section>
              </section>

              <section className="modalFooter -mx-5 border bg-white  px-5  py-[10px] text-center">
                {!trustExperience && (
                  <>
                    <p className="pl-6 text-[11px]">
                      {formatMessage({ id: 'feedbackQuestion', defaultMessage: 'How trustworthy is this site?' })}
                    </p>

                    <section className="mt-2 flex items-center justify-center text-center">
                      <p className="text-[10px]">
                        ({formatMessage({ id: 'not.at.all', defaultMessage: 'Not At All' })})
                      </p>
                      <section className="mx-3 flex gap-x-2">
                        <button
                          className="h-5 w-5 items-center  rounded-full bg-[#E6E7E8] text-center text-[11px] font-medium  leading-5 text-black-100   "
                          onClick={() => setTrustExperience(true)}
                        >
                          1
                        </button>
                        <button
                          className="  h-5 w-5 items-center rounded-full bg-[#E6E7E8] text-center text-[11px] font-medium  leading-5 text-black-100   "
                          onClick={() => setTrustExperience(true)}
                        >
                          2
                        </button>
                        <button
                          className="  h-5 w-5 items-center rounded-full bg-[#E6E7E8] text-center text-[11px] font-medium  leading-5 text-black-100   "
                          onClick={() => setTrustExperience(true)}
                        >
                          3
                        </button>
                        <button
                          className="  h-5 w-5 items-center rounded-full bg-[#E6E7E8] text-center text-[11px] font-medium  leading-5 text-black-100   "
                          onClick={() => setTrustExperience(true)}
                        >
                          4
                        </button>
                        <button
                          className="  h-5 w-5 items-center rounded-full bg-[#E6E7E8] text-center text-[11px] font-medium  leading-5 text-black-100   "
                          onClick={() => setTrustExperience(true)}
                        >
                          5
                        </button>
                      </section>
                      <p className="text-[10px]">({formatMessage({ id: 'very', defaultMessage: 'very' })})</p>
                    </section>
                  </>
                )}
                {trustExperience && !greetings && (
                  <section className="py-[10px]">
                    <p className="mb-[5px] text-[13px]">
                      {formatMessage({
                        id: 'trust.query',
                        defaultMessage:
                          'Does knowing this site is TrustedSite certified increase your trust in this site?',
                      })}
                    </p>
                    <section className="flex justify-center gap-x-2 text-center text-[11px] font-bold underline">
                      <button onClick={() => setGreetings(true)}>
                        {formatMessage({ id: 'not.at.all', defaultMessage: 'Not At All' })}
                      </button>
                      <button onClick={() => setGreetings(true)}>
                        {formatMessage({ id: 'somewhat', defaultMessage: 'Somewhat' })}
                      </button>
                      <button onClick={() => setGreetings(true)}>
                        {formatMessage({ id: 'Definitely', defaultMessage: 'Definitely' })}
                      </button>
                    </section>
                  </section>
                )}
                {greetings && (
                  <p className="text-[13px]">
                    {formatMessage({ id: 'greetings', defaultMessage: 'Thanks for your feedback!' })}
                  </p>
                )}
              </section>
            </section>
          </section>
        </section>
      )}
    </section>
  );
}

export default TrustedSite;
