import { useEffect, useState, useRef } from 'react';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

function GiftCardInfo({ product, giftCardPrice, onChangeVariantIdx }) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state
  const dropdownReference = useRef(null);
  const ButtonReference = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (!dropdownReference?.current?.contains(event?.target) && !ButtonReference?.current?.contains(event?.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="font-lato mb-[15px] min-w-[57px] text-lg font-bold leading-[30px] text-[#333333]">
        {giftCardPrice?.centAmount > 0 ? (
          <span>{CurrencyHelpers.formatForCurrency(giftCardPrice)}</span>
        ) : (
          <span>
            {formatProductMessage({
              id: 'priceTemporarilyUnavailable',
              defaultMessage: 'Online price temporarily unavailable. Check back soon.',
            })}
          </span>
        )}
      </div>
      <div>
        <div className="font-lato mb-[6px] h-[30px] min-w-[39px] text-sm font-bold leading-[30px] text-[#333333]">
          <span>{formatProductMessage({ id: 'giftCardPriceLabel', defaultMessage: 'Value:' })}</span>
        </div>
        <div>
          <button
            ref={ButtonReference}
            type="button"
            className="font-lato buttonclass h-[36px] w-[185px] rounded-[3px] border-[1px] border-[#999999] bg-[#FFFFFF] text-[14px] font-normal leading-[20px] text-[#333333]"
            aria-expanded={isDropdownOpen}
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            <div className="flex justify-between px-4">
              <div>{CurrencyHelpers.formatForCurrency(giftCardPrice)}</div>
              <svg
                className={`h-5 w-5 transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </button>
        </div>
        {isDropdownOpen && (
          <div
            className="font-lato w-[185px] rounded-[3px] border-[1px] border-[#999999] bg-[#FFFFFF] text-[14px] font-normal leading-[30px] text-[#333333]"
            ref={dropdownReference}
          >
            {product?.variants?.map((item, index) =>
              item?.price && item?.price?.centAmount > 0 ? (
                <div
                  key={index}
                  className="cursor-pointer px-4"
                  onClick={() => {
                    onChangeVariantIdx(index);
                    setIsDropdownOpen(false);
                  }}
                >
                  {CurrencyHelpers.formatForCurrency(item?.price)}
                </div>
              ) : null,
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GiftCardInfo;
