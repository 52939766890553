import React, { useState, useEffect, useRef, useCallback } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GrClose } from 'react-icons/gr';
import { RESTRICTED_PATHS_UPON_LOGIN, CONTAINS_KEYWORDS } from 'helpers/constants/aafes';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { profileLogo } from 'helpers/utils/profileLogo';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import { logout } from 'frontastic/actions/account';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import AccountIcon from '../../../icons/myAccount';
import AccountIconMobile from '../../../icons/myAccount-mobile';
const LoginModal = dynamic(() => import('../Modals/Login/login'));
const LoginModalContent = dynamic(() => import('../Modals/Login/loginModalContent'));
import SessionLoggedOutModal from '../Modals/sessionLoggedOutModal';
import MonetateProfileData from './monetateProfileData';

function MyAccount({ projectConfiguration }) {
  const router = useRouter();
  const [accountLogin, setAccountLogin] = useState(false);
  const [isDesktopSize] = useMediaQuery(tablet);
  const [loginModal, setLoginModal] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [accountFlyout, setAccountFlyout] = useState(false);
  const [hideLoginFlyout, setHideLoginFlyout] = useState(true);
  const [accountFlyoutMobile, setAccountFlyoutMobile] = useState(false);
  const [redirectURL, setRedirectURL] = useState(undefined);
  const { loggedIn } = useAccount();
  const [sessionLoggedOutModal, setSessionLoggedOutModal] = useState(false);
  const currentUrl = router?.asPath;
  let hideTimer;
  const { account } = useAccount();
  const hideLoginPopoup = () => {
    if (!loggedIn) {
      hideTimer = setTimeout(() => {
        setAccountLogin(false);
        hideTimer = null;
      }, 200);
    }
  };
  const handleLogout = () => {
    logout(account?.accountId).then(() => {
      setAccountFlyout(false);
    });
    router.push('/');
    setRedirectURL(undefined);
  };
  useEffect(() => {
    const handleRouterChange = () => {
      setRedirectURL(currentUrl || undefined);
    };
    const handleLocationChange = (url) => {
      setRedirectURL(url || undefined);
    };
    window.addEventListener('hashchange', handleRouterChange);
    router.events.on('routeChangeComplete', handleLocationChange);
    router.events.on('hashChangeComplete', handleRouterChange);

    return () => {
      window.removeEventListener('hashchange', handleRouterChange);
      router.events.off('routeChangeComplete', handleLocationChange);
      router.events.off('hashChangeComplete', handleRouterChange);
    };
  }, [router]);

  const showLoginPopup = () => {
    clearTimeout(hideTimer);
    !loggedIn && setAccountLogin(true);
    setHideLoginFlyout(true);
  };
  const handleMyAccountMobile = () => {
    if (loggedIn) {
      router.push('/account/#');
    }
  };
  const tabs = [
    { name: formatMessage({ id: 'myAccount', defaultMessage: 'My Account' }), href: '/account/#' },
    { name: formatAccountMessage({ id: 'orders.history', defaultMessage: 'Order History' }), href: '/account#orders' },
    { name: formatAccountMessage({ id: 'wishlist', defaultMessage: 'Wishlist' }), href: '/account#wishlist' },
  ];

  const userProfileLogo = profileLogo(projectConfiguration, account);
  const btnRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setAccountLogin(false);
        hideLoginPopoup();
      }
    };
    const handleWindowClick = (event) => {
      handleClickOutside(event);
    };
    window.addEventListener('mouseover', handleWindowClick);
    return () => {
      window.removeEventListener('mouseover', handleWindowClick);
    };
  }, [btnRef]);
  const { login, callBackUrl } = router.query;
  const RedirectUser = useCallback(() => {
    const params = new URLSearchParams(router?.asPath?.split('?')[1]);
    const callBackUrl = params.get('callBackUrl');
    if (callBackUrl) {
      setRedirectURL(callBackUrl);
    }
  }, [callBackUrl]);
  useEffect(() => {
    RedirectUser();
  }, [callBackUrl]);
  useEffect(() => {
    const isSessionExpired = sessionStorage?.getItem('isSessionExpired');
    if (login === 'true' && isSessionExpired !== 'true') {
      setLoginModal(true);
    } else if (isSessionExpired === 'true') {
      sessionStorage.removeItem('isSessionExpired');
    }
  }, [login]);
  const flyoutRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (flyoutRef?.current && !flyoutRef?.current?.contains(event.target)) {
        setAccountFlyoutMobile(false);
      }
    }

    function handleEscapeKey(event) {
      if (event.key === 'Escape') {
        setAccountFlyoutMobile(false);
      }
    }

    if (accountFlyoutMobile) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [accountFlyoutMobile]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setAccountFlyout(false);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  function toggleLoginDropDown() {
    if (loggedIn) {
      setLoginModal(false);
      setAccountFlyout(true);
    } else {
      setLoginModal(true);
      setAccountFlyout(false);
    }
  }
  function closeFlyout() {
    setHideLoginFlyout(false);
  }
  const [isInputFocused, setIsInputFocused] = useState(false);
  const sectionRef = useRef(null);
  const inputRef = useRef(null);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const handleMouseLeave = () => {
    if (!isInputFocused) {
      closeFlyout();
    }
  };

  const handleClickOutside = (event) => {
    if (
      sectionRef.current &&
      !sectionRef.current.contains(event.target) &&
      !isInputFocused &&
      !event.target.closest('button') &&
      !event.target.closest('input') &&
      !event.target.closest('select')
    ) {
      closeFlyout();
    }
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const targetElement = mutation.target as HTMLElement;
        if (
          (targetElement?.tagName === 'INPUT' || targetElement?.tagName === 'INPUT') &&
          (targetElement as HTMLInputElement)?.value
        ) {
          inputRef.current.focus();
        }
      });
    });

    const config = { attributes: true, childList: false, subtree: false };

    const inputElement = inputRef.current;
    if (inputElement) {
      observer.observe(inputElement, config);
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      observer.disconnect();
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <section
        className="relative order-4 mr-3 flex  text-black-900"
        onMouseEnter={showLoginPopup}
        onMouseLeave={() => {
          setAccountFlyout(false);
          handleMouseLeave();
        }}
      >
        <section className="my-auto" onClick={() => toggleLoginDropDown()}>
          <div className="flex cursor-pointer items-center">
            {' '}
            {isDesktopSize && !loggedIn && (
              <section>
                <AccountIcon className="mr-2 hidden md:flex" />
              </section>
            )}
            {isDesktopSize && loggedIn && (
              <section onClick={() => setAccountFlyoutMobile(true)}>
                <img
                  src={userProfileLogo}
                  alt={account?.branchOfServiceDescription}
                  width={24}
                  height={24}
                  className="mx-2 rounded-[2px] lg:ml-0"
                />
              </section>
            )}
            {!isDesktopSize && !loggedIn && (
              <button className="block md:hidden">
                <AccountIconMobile className="mx-2 " />
              </button>
            )}
            {!isDesktopSize && loggedIn && (
              <section>
                <button className="block " onClick={() => setAccountFlyoutMobile(true)}>
                  <img
                    src={userProfileLogo}
                    alt={account?.branchOfServiceDescription}
                    width={24}
                    height={24}
                    className="mx-2 rounded-[2px] lg:ml-0"
                  />
                </button>
              </section>
            )}
            {accountFlyoutMobile && (
              <section
                ref={flyoutRef}
                className="fixed top-1/2 left-1/2 z-20 w-[300px] -translate-x-1/2 -translate-y-1/2 border border-gray-500 bg-white p-4 md:w-[360px] lg:w-[360px]"
              >
                <p className="text-start mb-2 flex border-b-2 px-1 pb-2 text-[21px]">
                  {formatAccountMessage({ id: 'welcome', defaultMessage: 'Welcome' })},{account?.firstName}!
                  <button className="absolute right-2 top-5" onClick={() => setAccountFlyoutMobile(false)}>
                    <GrClose />
                  </button>
                </p>
                <div className="px-1">
                  <nav className="-mb-px flex flex-col">
                    {tabs.map((tab) => (
                      <Link key={tab.name} href={tab.href} as={tab.href}>
                        <a
                          onClick={() => setAccountFlyoutMobile(false)}
                          className="text-start flex pb-2 text-[14px]  underline"
                        >
                          {tab.name}{' '}
                        </a>
                      </Link>
                    ))}
                  </nav>
                  <section>
                    <button
                      onClick={() => {
                        handleLogout();
                        setAccountFlyoutMobile(false);
                        router.push('/');
                      }}
                      type="submit"
                      className="h-[36px] w-full cursor-pointer rounded-sm bg-blue-900 px-3 py-2 text-affes-sm font-bold leading-[16.8px] text-white disabled:opacity-50"
                    >
                      {formatAccountMessage({ id: 'signout', defaultMessage: 'Log Out' })}
                    </button>
                  </section>
                </div>
              </section>
            )}
            <section className="hidden  text-[#1E1E1E] xl:flex">
              <button>
                <p className="whitespace-nowrap text-sm">
                  {formatMessage({ id: 'myAccount', defaultMessage: 'My Account' })}
                </p>
                {loggedIn && (
                  <section onMouseEnter={() => setAccountFlyout(true)}>
                    <MonetateProfileData></MonetateProfileData>
                    <p className="text-sm">Hi, {account?.firstName}</p>
                  </section>
                )}
              </button>
              {accountFlyout && (
                <section className="absolute top-full  z-20 w-[173px] rounded-md border border-gray-200 bg-white p-4 md:-left-56 lg:-left-[11.5rem] xl:-left-5">
                  <p className="mb-2 border-b-2 px-2 pb-2 text-center text-[16px] font-bold">
                    {formatAccountMessage({ id: 'welcome', defaultMessage: 'Welcome' })}{' '}
                    {account?.firstName?.length > 6 ? `${account?.firstName.substring(0, 4)}..` : account?.firstName}
                  </p>
                  <div className="px-2">
                    <nav className="-mb-px flex flex-col text-center">
                      {tabs.map((tab) => (
                        <Link key={tab.name} href={tab.href} as={tab.href}>
                          <a className="pb-2 hover:underline">{tab.name} </a>
                        </Link>
                      ))}
                    </nav>
                    <section className="text-center" onClick={handleLogout}>
                      <button type="button" className="hover:underline">
                        {' '}
                        {formatAccountMessage({ id: 'signout', defaultMessage: 'Sign Out' })}
                      </button>
                    </section>
                  </div>
                </section>
              )}
            </section>
          </div>
        </section>
        <>
          {!loginModal && !loggedIn && (
            <section
              className="absolute top-full -left-64 z-20 w-96 border border-gray-600 bg-white p-4 md:-left-56 lg:-left-[11.5rem] xl:-left-32"
              style={{ display: !sessionLoggedOutModal && accountLogin && hideLoginFlyout ? 'block' : 'none' }}
            >
              <section className="boxArrow absolute left-[15.5rem] -top-4 h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600 md:left-[13.5rem] lg:left-44">
                <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
              </section>
              <LoginModalContent
                loginCallBack={() => {
                  if (redirectURL != undefined) {
                    router.push(redirectURL);
                  } else if (CONTAINS_KEYWORDS(currentUrl, RESTRICTED_PATHS_UPON_LOGIN)) {
                    router.push('/');
                  }
                }}
                closeAccountFlyout={setAccountFlyout}
                closeModal={() => {
                  setAccountLogin(false);
                  setHideLoginFlyout(false);
                }}
                closeFlyout={closeFlyout}
                sectionRef={sectionRef}
                inputRef={inputRef}
                handleInputFocus={handleInputFocus}
                handleInputBlur={handleInputBlur}
                handleForgotCredentials={() => {}}
              ></LoginModalContent>
            </section>
          )}
        </>
        <LoginModal
          loginCallBack={() => {
            if (redirectURL != undefined) {
              router.push(redirectURL);
            } else if (CONTAINS_KEYWORDS(currentUrl, RESTRICTED_PATHS_UPON_LOGIN)) {
              router.push('/');
            }
          }}
          closeAccountFlyout={setAccountFlyout}
          loginModal={loginModal}
          closeModal={() => {
            setLoginModal(false);
            setHideLoginFlyout(false);
          }}
        />
        {sessionLoggedOutModal && (
          <SessionLoggedOutModal
            ctaCallback={() => {
              setSessionLoggedOutModal(false);
              setLoginModal(true);
            }}
            closeModal={() => {
              setSessionLoggedOutModal(false);
              setLoginModal(false);
              setHideLoginFlyout(false);
            }}
          />
        )}
      </section>
    </>
  );
}

export default MyAccount;
