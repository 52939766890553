import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import ImageMagnifier from 'components/commercetools-ui/aafes/ImageMagnifier';
import QuantitySelector from 'components/commercetools-ui/aafes/quantity-selector';
import { RadioButtonGroupProps } from 'components/commercetools-ui/aafes/radio-group';
import { InsigniaAttachMethod } from 'components/commercetools-ui/aafes/uniform-builder/bundle-select/personalization/InsigniaAttachMethod';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import {
  UB_INSIGNIA_METHOD_TEXT_PROPS,
  UB_REQUIRED_VALIDATION_TEXT,
  UB_ADD_TO_CART_TEXT,
  UB_UPDATE_TO_CART_TEXT,
  UB_MAIN_IMAGE_ZOOM_PROPS,
} from 'helpers/constants/aafes';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import SmoothScroller from 'helpers/utils/SmoothScroller';
import { useCart } from 'frontastic';
import { UniformBuilderContext } from 'frontastic/provider/uniform-builder';
import Breadcrumb from '../breadcrumb';
import UBPeronaliserWrapper from './UBPeronaliserWrapper';
const UBPeronaliser = () => {
  const router = useRouter();
  const [showPopover, setShowPopover] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isDesktop] = useMediaQuery(tablet);
  const [errorMessage, setErrorMessage] = useState('');
  const togglePopover = useCallback(() => {
    setShowPopover((prev) => !prev);
  }, []);
  const [ubState, setUbState, NestedUpdateOrInsert, , GetAddBundleJson, ValidateAddBundle, GetBundlePrice] =
    useContext(UniformBuilderContext);

  const {
    name,
    images,
    configurableProperties,
    patchTypeOptions,
    selectedPatchType,
    bundleSku,
    uniformId,
    maxQuantity,
  } = ubState;
  const radioOptions: RadioButtonGroupProps = {
    name: 'patchType',
    options: patchTypeOptions,
  };
  const { addBundle, updateBundle } = useCart();
  const addtoCart = async () => {
    setErrorMessage('');
    const validationResult = ValidateAddBundle(ubState);
    if (validationResult) {
      togglePopover();
      return;
    } else {
      setShowLoader(true);
    }
    const payload = GetAddBundleJson(ubState);
    let result: any;
    if (uniformId) {
      result = await updateBundle(payload, uniformId);
    } else {
      result = await addBundle(payload);
    }
    const newUniformId = result?.lineItems?.[result?.lineItems?.length - 1]?.parentBundleLineItemId || null;
    if (newUniformId) {
      router.push(`/ub/uniform-confirmation?id=${newUniformId}`);
    }
    if (result?.errorCode && result?.body) {
      setErrorMessage(result.body);
      SmoothScroller.scrollToTop();
    }
    setShowLoader(false);
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (buttonRef.current && !buttonRef.current.contains(event.target) && event.target !== buttonRef.current) {
        setShowPopover(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);

  const computeBundlePrice = useMemo(() => {
    const price = GetBundlePrice(ubState);
    if (!price?.centAmount) {
      return null;
    }
    return price;
  }, [ubState]);
  return (
    <>
      {(showLoader || !bundleSku) && <Loader />}
      {bundleSku && (
        <div className="lg:flex lg:flex-col">
          {isDesktop && <Breadcrumb />}
          {errorMessage && <p className="py-5 text-lg text-red-200">{errorMessage}</p>}
          <div className="text-2xl text-[#333333] sm:text-center lg:text-left">{name}</div>
          <div className="mt-4 lg:flex lg:space-x-4">
            <div className="relative mx-auto h-[400px] w-[400px] sm:my-5 md:h-[542px] md:w-[542px] lg:mb-auto lg:mt-0">
              {images?.[0] && (
                <ImageMagnifier zoomProps={UB_MAIN_IMAGE_ZOOM_PROPS}>
                  <Image
                    src={images[0]}
                    alt="Description"
                    width={542}
                    height={542}
                    loader={({ src }) => src}
                    id={`key_${images[0]}`}
                  />
                </ImageMagnifier>
              )}
            </div>

            <div className="mt-8 grow sm:mt-0">
              <InsigniaAttachMethod
                heading={UB_INSIGNIA_METHOD_TEXT_PROPS?.heading}
                info={UB_INSIGNIA_METHOD_TEXT_PROPS?.info}
                note={UB_INSIGNIA_METHOD_TEXT_PROPS?.note}
                radioOptions={radioOptions}
                defaultSelected={selectedPatchType}
              />
              {configurableProperties &&
                configurableProperties.length > 0 &&
                configurableProperties.map((item: any) => {
                  return <UBPeronaliserWrapper key={`key_${item?.id}`} item={item} setShowLoader={setShowLoader} />;
                })}
              <div
                className={`fixed bottom-0 left-0 z-[100000] w-full bg-[#E7E7E7] py-6 ${uniformId ? 'px-7' : 'px-10'}`}
              >
                {!!computeBundlePrice && (
                  <p className="mb-3 block sm:hidden">
                    <Price
                      price={{
                        ...computeBundlePrice,
                        centAmount: computeBundlePrice?.centAmount * ubState?.quantity,
                      }}
                      className="text-lg font-semibold text-[#333]"
                    />
                  </p>
                )}
                <div className="flex items-center lg:justify-end">
                  <div className="hidden md:block">
                    <p className="text-lg text-[#333]">{name}</p>
                    {!!computeBundlePrice && (
                      <p>
                        <Price price={computeBundlePrice} className="text-lg font-semibold text-[#333]" />
                      </p>
                    )}
                  </div>
                  <div className="ml-0 md:ml-[7em]">
                    <QuantitySelector
                      quantity={ubState?.quantity || 1}
                      minQuantity={1}
                      maxQuantity={maxQuantity}
                      onChange={(value: number) => {
                        NestedUpdateOrInsert({
                          currentState: ubState,
                          newKey: `quantity`,
                          newValue: value,
                          setState: setUbState,
                        });
                      }}
                    />
                  </div>
                  {!!computeBundlePrice && (
                    <p className="ml-7 hidden sm:block">
                      <Price
                        price={{
                          ...computeBundlePrice,
                          centAmount: computeBundlePrice?.centAmount * ubState?.quantity,
                        }}
                        className="text-lg font-semibold text-[#333]"
                      />
                    </p>
                  )}
                  <div className="relative ml-auto md:ml-7">
                    <button
                      type="button"
                      className={`ml-auto flex items-center justify-center rounded-md border border-transparent bg-accent-400 fill-white py-1.5 text-base font-medium text-white transition duration-150 ease-out hover:bg-accent-500 focus:bg-accent-500 focus:outline-none  focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 ${
                        uniformId ? 'px-8' : 'px-11'
                      }`}
                      onClick={addtoCart}
                      ref={buttonRef}
                    >
                      {!uniformId ? UB_ADD_TO_CART_TEXT : UB_UPDATE_TO_CART_TEXT}
                    </button>
                    {showPopover && (
                      <div className="absolute bottom-full flex w-full items-end justify-center pb-2">
                        <div
                          className="relative z-10 rounded p-4 text-center text-white shadow-lg"
                          style={{ background: 'black' }}
                        >
                          {UB_REQUIRED_VALIDATION_TEXT}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UBPeronaliser;
